import { get_global_custom_data_by_flag, set_global_custom_data_by_flag } from '@/api/sys-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/baseinfo/baseinfo-wechatmainpage-config';

import { reactive } from 'vue';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    flag: 'wechat_mini_main_page',
    is_active: true,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config = {};
const edit_model_config_options = {};

const update_model_config = {};
const update_model_config_options = {};

const titleList = {
  title: t('微信小程序主页'),
  addModalTitle: t('新建'),
  updateTitle: t('修改微信小程序主页'),
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: false, // 是否有【删除】按钮
  is_recover: false, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    flag: 'id',
  },
  delete: {
    flag: 'id',
  },
  recover: {
    flag: 'id',
  },
  updateData: {
    org_id: 'org_id',
    flag: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.baseinfo.exchangerate.column.title.index'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('图片'),
    dataIndex: 'img_url_a',
    checked: true,
  },
  {
    title: t('文字'),
    dataIndex: 'text_a',
    checked: true,
  },
  {
    title: t('page.baseinfo.exchangerate.column.title.action'),
    width: 160,
    dataIndex: 'action',
    checked: true,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  // slotName: 'is_active',
  field_true: '可用',
  field_false: '禁用',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: get_global_custom_data_by_flag,
    addData: None,
    updateData: set_global_custom_data_by_flag,
    removeData: None,
    recoverData: None,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
