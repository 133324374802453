import { addRole, queryRole, recoverRole, removeRole, updateRole } from '@/api/permission/role-manage';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/permission/permission-role-manage-config';

import { reactive } from 'vue';

export default () => {
  const None = null;
  const True = true;
  const False = false;

  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

  const { t } = useI18n();
  const search_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
      {
        type: 'remoteselect',
        name: 'aim_org_id',
        label: '目标组织',
        label_i18n: '目标组织',
        placeholder_i18n: '请选择目标组织',
        disabled: false,
        placeholder: '请选择目标组织',
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        labelKey: 'name',
        valueKey: 'id',
        modalType: 'sys-query_org_for_login_list',
      },
      {
        type: 'input',
        name: 'name',
        label: '角色名称',
        label_i18n: '角色名称',
        placeholder_i18n: '请填写角色名称',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        maxlength: 100,
        defaultValue: '',
        placeholder: '请填写角色名称',
      },
      {
        type: 'radio',
        name: 'is_active',
        label: '状态',
        label_i18n: '状态',
        mode: 'default',
        disabled: false,
        defaultValue: '',
        labelKey: 'name',
        valueKey: 'value',
        placeholder: '请选择状态',
        datasource: 'is_active',
      },
    ],
    rules: {},
    model: reactive({
      org_id: current_org.id,
      aim_org_id: current_org.id,
      is_active: true,
      name: '',
    }),
  };
  const search_options = {
    is_active: [
      { name: t('全部'), value: '' },
      { name: t('正常'), value: true },
      { name: t('作废'), value: false },
    ],
  };

  const edit_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [
      {
        type: 'input',
        name: 'name',
        label: '角色名称',
        label_i18n: '角色名称',
        placeholder_i18n: '请填写角色名称',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        maxlength: 100,
        defaultValue: '',
        placeholder: '请填写角色名称',
      },
      {
        type: 'input',
        name: 'flag',
        label: '角色标识',
        label_i18n: '角色标识',
        placeholder_i18n: '请填写角色标识',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        maxlength: 100,
        defaultValue: '',
        placeholder: '请填写角色标识',
      },
      {
        type: 'textarea',
        name: 'desc',
        label: '角色描述',
        label_i18n: '角色描述',
        placeholder_i18n: '请填写角色描述',
        disabled: false,
        allowClear: true,
        defaultValue: '',
        placeholder: '请填写角色描述',
      },
    ],
    rules: {
      name: [{ required: true, message: t('角色名称必须填写') }],
    },
    model: {
      org_id: current_org.id,
      aim_org_id: current_org.id,
      id: null,
      name: '',
      desc: '',
    },
  };
  const edit_model_config_options = {};

  const update_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [
      {
        type: 'input',
        name: 'name',
        label: '角色名称',
        label_i18n: '角色名称',
        placeholder_i18n: '请填写角色名称',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        maxlength: 100,
        defaultValue: '',
        placeholder: '请填写角色名称',
      },
      {
        type: 'input',
        name: 'flag',
        label: '角色标识',
        label_i18n: '角色标识',
        placeholder_i18n: '请填写角色标识',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        maxlength: 100,
        defaultValue: '',
        placeholder: '请填写角色标识',
      },
      {
        type: 'textarea',
        name: 'desc',
        label: '角色描述',
        label_i18n: '角色描述',
        placeholder_i18n: '请填写角色描述',
        disabled: false,
        allowClear: true,
        defaultValue: '',
        placeholder: '请填写角色描述',
      },
    ],
    rules: {},
    model: {
      org_id: current_org.id,
      aim_org_id: current_org.id,
      id: null,
      name: '',
      desc: '',
    },
  };
  const update_model_config_options = {};

  const titleList = {
    title: t('角色管理'),
    addModalTitle: t('新建角色'),
    updateTitle: t('修改'),
    is_create: true, // 是否有【新建】按钮
    is_update: true, // 是否有【修改】按钮
    is_remove: true, // 是否有【删除】按钮
    is_recover: true, // 是否有【恢复】按钮
  };

  const api_fields: Record = {
    update: {
      role_id: 'id',
    },
    delete: {
      role_id: 'id',
    },
    recover: {
      role_id: 'id',
    },
    updateData: {
      org_id: 'org_id',
      role_id: 'id',
    },
  };

  const baseColumns: TableColumn[] = [
    {
      title: t('序号'),
      width: 50,
      dataIndex: 'index',
      fixed: 'left',
    },
    {
      title: t('名称'),
      width: 150,
      dataIndex: 'name',
      fixed: 'left',
    },
    {
      title: t('描述'),
      dataIndex: 'desc',
    },
    {
      title: t('角色标识'),
      width: 80,
      dataIndex: 'flag',
    },
    {
      title: t('是否可用'),
      width: 100,
      dataIndex: 'is_active',
      checked: true,
      resizable: true,
      cellComponent: 'boolean',
      name1: t('可用'),
      name2: t('禁用'),
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('可用'), value: 'true' },
        { text: t('禁用'), value: 'false' },
      ],
    },
    {
      title: t('操作'),
      width: 200,
      dataIndex: 'action',
      cellComponent: 'action',
    },
  ];
  const newSlotList: any = [];
  Object.assign(newSlotList, slotList);

  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: queryRole,
    addData: addRole,
    updateData: updateRole,
    removeData: removeRole,
    recoverData: recoverRole,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};