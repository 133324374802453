import {
  create_remittance,
  delete_remittance,
  query_remittance_list,
  recover_remittance,
  update_remittance,
} from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/cerp/cerp-remittance-config';

import { reactive } from 'vue';
import numerals from '@/utils/numeral';
import router from '@/router';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'select',
      name: 'is_active',
      label: '状态',
      label_i18n: t('page.cerp.remittance.form.label.is_active'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择状态',
      placeholder_i18n: t('page.cerp.remittance.form.placeholder.is_active'),
      datasource: 'is_active',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    remittance_set_id: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'remoteselect',
      name: 'invoice_id',
      label: '请款单',
      label_i18n: t('page.cerp.remittance.form.label.invoice_id'),
      disabled: false,
      labelKey: 'invoice_no',
      placeholder: '请选择请款单',
      placeholder_i18n: t('page.cerp.remittance.form.placeholder.invoice_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'cerp-invoice',
    },
    {
      type: 'money',
      name: 'amount',
      label: '金额',
      label_i18n: t('page.cerp.remittance.form.label.amount'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写金额',
      placeholder_i18n: t('page.cerp.remittance.form.placeholder.amount'),
    },
    {
      type: 'remoteselect',
      name: 'remittance_set_id',
      label: '汇款凭证',
      label_i18n: t('page.cerp.remittance.form.label.remittance_set_id'),
      disabled: false,
      placeholder: '请选择汇款凭证',
      placeholder_i18n: t('page.cerp.remittance.form.placeholder.remittance_set_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'cerp-remittanceset',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    invoice_id: null,
    amount: null,
    remittance_set_id: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'remoteselect',
      name: 'invoice_id',
      label: '请款单',
      label_i18n: t('page.cerp.remittance.form.label.invoice_id'),
      disabled: false,
      labelKey: 'invoice_no',
      placeholder: '请选择请款单',
      placeholder_i18n: t('page.cerp.remittance.form.placeholder.invoice_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'cerp-invoice',
    },
    {
      type: 'money',
      name: 'amount',
      label: '金额',
      label_i18n: t('page.cerp.remittance.form.label.amount'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写金额',
      placeholder_i18n: t('page.cerp.remittance.form.placeholder.amount'),
    },
    {
      type: 'remoteselect',
      name: 'remittance_set_id',
      label: '汇款凭证',
      label_i18n: t('page.cerp.remittance.form.label.remittance_set_id'),
      disabled: false,
      placeholder: '请选择汇款凭证',
      placeholder_i18n: t('page.cerp.remittance.form.placeholder.remittance_set_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'cerp-remittanceset',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    invoice_id: null,
    amount: null,
    remittance_set_id: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: '汇款凭证项管理',
  addModalTitle: '新建汇款凭证项',
  updateTitle: '修改汇款凭证项',
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    remittance_id: 'id',
  },
  delete: {
    remittance_id: 'id',
  },
  recover: {
    remittance_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    remittance_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.cerp.remittance.column.title.index'),
    dataIndex: 'index',
    width: 50,
    slots: { customRender: 'index' },
  },
  {
    title: t('page.cerp.remittance.column.title.id'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.cerp.remittance.column.title.description'),
    width: 150,
    dataIndex: 'description',
    checked: true,
  },
  {
    title: t('page.cerp.remittance.column.title.amount'),
    width: 150,
    dataIndex: 'amount',
    checked: true,
    slots: { customRender: 'amount' },
    // slots: { customRender: 'money' },
  },
  {
    title: t('page.cerp.remittance.column.title.remittance_set__total_amount'),
    width: 150,
    dataIndex: 'remittance_set__total_amount',
    checked: true,
  },
  {
    title: t('page.cerp.remittance.column.title.remittance_set__remittance_name'),
    width: 150,
    dataIndex: 'remittance_set__remittance_name',
    checked: true,
  },
  {
    title: t('page.cerp.remittance.column.title.adviser__full_name'),
    width: 150,
    dataIndex: 'adviser__full_name',
    checked: true,
  },
  {
    title: t('page.cerp.remittance.column.title.invoice_no'),
    width: 200,
    dataIndex: 'invoice_no',
    checked: true,
    slots: { customRender: 'invoice_no' },
  },
  {
    title: t('page.cerp.remittance.column.title.invoice_status'),
    width: 150,
    dataIndex: 'invoice_status',
    checked: true,
    slots: { customRender: 'invoice_status' },
  },
  {
    title: t('page.cerp.remittance.column.title.payslip_no'),
    width: 150,
    dataIndex: 'payslip_no',
    checked: true,
  },
  {
    title: t('page.cerp.remittance.column.title.transaction_amount'),
    width: 150,
    dataIndex: 'transaction_amount',
    checked: true,
    slots: { customRender: 'money' },
  },
  {
    title: t('page.cerp.remittance.column.title.cc_bank_account__account_number'),
    width: 150,
    dataIndex: 'cc_bank_account__account_name',
    checked: true,
  },
  {
    title: t('币种'),
    width: 150,
    dataIndex: 'remittance_set__transaction__currency__currency',
    checked: true,
  },
  {
    title: t('顾问工号'),
    width: 80,
    dataIndex: 'adviser__employee_number',
    checked: true,
  },
  {
    title: t('待办未完成'),
    width: 100,
    dataIndex: 'to_do_num',
    checked: true,
    slots: { customRender: 'to_do_num' },
  },
  {
    title: t('page.cerp.remittance.column.title.create_time'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    slots: { customRender: 'datetime' },
  },
  {
    title: t('page.cerp.remittance.column.title.update_time'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    slots: { customRender: 'datetime' },
  },
  {
    title: t('page.cerp.remittance.column.title.local_solution'),
    width: 150,
    dataIndex: 'local_solution',
    checked: true,
  },
  {
    title: t('page.cerp.remittance.column.title.is_active'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,
    slots: { customRender: 'is_active' },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('page.cerp.remittance.column.title.action'),
    width: 200,
    dataIndex: 'action',
    checked: false,
    slots: { customRender: 'action' },
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push(
  {
    type: 'custom',
    // slotName: 'is_active',
    field_true: '可用',
    field_false: '禁用',
  },
  {
    type: 'color',
    slotName: 'amount',
    cb: (record: any) => {
      if (!record.amount) {
        return '';
      }
      if (record.invoice_total_amount || record.invoice_total_amount == 0) {
        if (record.invoice_total_amount.toFixed(2) < record.amount.toFixed(2)) {
          return 'color: #00BBFF;font-weight: bolder';
        } else if (record.invoice_total_amount.toFixed(2) == record.amount.toFixed(2)) {
          return 'color: #00DD00;font-weight: bolder';
        } else if (record.invoice_total_amount.toFixed(2) > record.amount.toFixed(2)) {
          return 'color: #FF0000;font-weight: bolder';
        }
      } else {
        return '';
      }
    },
    content: (record: any) => {
      if (record.amount) {
        return numerals.money(record.amount);
      } else {
        return '';
      }
    },
  },
  {
    type: 'click1',
    slotName: 'invoice_no',
    content: (record: any) => {
      return record.invoice_no;
    },
    cb: (record: any) => {
      router.push({
        path: '/adviser-manage/cerp-invoice',
        query: { id: record.invoice_id, t: new Date().getTime() },
      });
    },
  },
  {
    type: 'click',
    slotName: 'to_do_num',
    cb: (record: any) => {
      router.push({
        path: '/adviser-manage/cerp-todoevent',
        query: { adviser_id: record.adviser_id, t: new Date().getTime() },
      });
    },
  },
  {
    type: 'color',
    slotName: 'invoice_status',
    cb: (record: any) => {
      if (
        record.invoice_status == 1 ||
        record.invoice_status == 2 ||
        record.invoice_status == 3 ||
        record.invoice_status == 5 ||
        record.invoice_status == 6
      ) {
        return 'font-weight: bolder';
      } else if (record.invoice_status == 4) {
        return 'color: #FF0000;font-weight: bolder';
      }
    },
    content: (record: any) => {
      if (record.invoice_status == 1) {
        return t('新创建');
      } else if (record.invoice_status == 2) {
        return t('已发送');
      } else if (record.invoice_status == 3) {
        return t('已收到请款');
      } else if (record.invoice_status == 4) {
        return t('已作废');
      } else if (record.invoice_status == 5) {
        return t('生成工资单');
      } else if (record.invoice_status == 6) {
        return t('生成PDF');
      } else if (record.invoice_status == 7) {
        return t('垫付');
      }
    },
  },
);

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_remittance_list,
    addData: create_remittance,
    updateData: update_remittance,
    removeData: delete_remittance,
    recoverData: recover_remittance,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
