import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';
import { reactive, ref } from 'vue';
import { create_adviser, query_adviser_list } from '@/api/cerp-model';
import { message } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { useRouter } from 'vue-router';
import {
  create_cc_company,
  create_recuitment_company,
  query_cc_company_list,
  query_recuitment_company_list,
} from '@/api/baseinfo-model';
import { create_customer, update_customer } from '@/api/receipt-model';
import cerp_adviser_editModal from '@/components/page-model/cerp/cerp-adviser-editModal';
import { query_person_for_cmes_list } from '@/api/sys-model';
import notification from '@/views/account/settings/pages/notification.vue';
import { useI18n } from 'vue-i18n';

const router = useRouter();

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const trim = (str: any) => {
  return str.replace(/(^\s*)|(\s*$)/g, '');
};

const { t } = useI18n();
export const event_obj = mitt();
export const btn_list = [
  {
    name: t('修改'),
    clazz: 'primary',
    type: 'dropdown',
    cb: (record: any) => {
      const is_adviser: any = ref(true);
      const is_recuitment_company: any = ref(true);
      const is_cc_company: any = ref(true);
      if (record.customer_type == 0) {
        is_adviser.value = false;
      } else if (record.customer_type == 1) {
        is_recuitment_company.value = false;
      } else if (record.customer_type == 2) {
        is_cc_company.value = false;
      }
      const editModal = reactive({
        visible: true,
        title: t('修改客户信息'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [6, 6],
          },
          fields: [
            {
              type: 'select',
              name: 'customer_type',
              label: '客户类型',
              label_i18n: t('客户类型'),
              placeholder: '请选择客户类型',
              placeholder_i18n: t('请选择客户类型'),
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              datasource: 'customer_type',
            },
            {
              type: 'remotemodal',
              name: 'adviser_id',
              label: '隶属顾问',
              label_i18n: t('隶属顾问'),
              disabled: is_adviser,
              defaultValue: '',
              labelKey: 'full_name',
              valueKey: 'id',
              placeholder: '隶属顾问',
              show_search: false,
              maxTagCount: 5,
              mode: 'default',
              title: t('选择隶属顾问'),
              modalType: 'cerp-adviser',
            },
            {
              type: 'remoteselect',
              name: 'recuitment_company_id',
              label: '顾问公司',
              label_i18n: t('顾问公司'),
              disabled: is_recuitment_company,
              placeholder: '请选择顾问公司',
              placeholder_i18n: t('请选择顾问公司'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'company_name',
              valueKey: 'id',
              modalType: 'baseinfo-recuitmentcompany',
            },
            {
              type: 'remoteselect',
              name: 'cc_company_id',
              label: 'CC公司',
              label_i18n: t('CC公司'),
              disabled: is_cc_company,
              placeholder: '请选择CC公司',
              placeholder_i18n: t('请选择CC公司'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-cccompany',
            },
            {
              type: 'input',
              name: 'name',
              label: '客户名称',
              label_i18n: t('客户名称'),
              disabled: false,
              placeholder: '请填写客户名称',
              placeholder_i18n: t('请填写客户名称'),
              datasourceType: 'remote',
              allowClear: true,
            },
            // {
            //   type: 'attributetable',
            //   name: 'data',
            //   label: '额外数据',
            //   disabled: false,
            //   allowClear: true,
            //   inputType: 'text',
            //   defaultValue: '',
            //   placeholder: '请填写额外数据',
            // },
            {
              type: 'radio',
              name: 'customer_attr',
              label: '客户属性',
              label_i18n: t('客户属性'),
              placeholder: '请选择客户属性',
              placeholder_i18n: t('请选择客户属性'),
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              datasource: 'customer_attr',
            },
            {
              type: 'input',
              name: 'address',
              label: '地址',
              label_i18n: t('地址'),
              disabled: false,
              placeholder: '请填写地址',
              placeholder_i18n: t('请填写地址'),
              datasourceType: 'remote',
              allowClear: true,
            },
            {
              type: 'input',
              name: 'phone',
              label: '电话',
              label_i18n: t('电话'),
              disabled: false,
              placeholder: '请填写电话',
              placeholder_i18n: t('请填写电话'),
              datasourceType: 'remote',
              allowClear: true,
            },
            {
              type: 'input',
              name: 'email',
              label: '邮箱',
              label_i18n: t('邮箱'),
              disabled: false,
              placeholder: '请填写邮箱',
              placeholder_i18n: t('请填写邮箱'),
              datasourceType: 'remote',
              allowClear: true,
            },
            {
              type: 'remoteselect',
              name: 'payment_email_template_id',
              label: '付款凭证邮件模板',
              label_i18n: t('付款凭证邮件模板'),
              disabled: false,
              placeholder: '请选择付款凭证邮件模板',
              placeholder_i18n: t('请选择付款凭证邮件模板'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-emailtemplate',
            },
            {
              type: 'radio',
              name: 'is_auto_send',
              label: '是否自动发送付款凭证',
              label_i18n: t('是否自动发送付款凭证'),
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              placeholder: '请选择是否自动发送付款凭证',
              placeholder_i18n: t('请选择是否自动发送付款凭证'),
              datasource: 'is_auto_send',
            },
            // {
            //   type: 'radio',
            //   name: 'is_need_extra',
            //   label: '是否需要增扣提醒',
            //   label_i18n: t('是否需要增扣提醒'),
            //   mode: 'default',
            //   disabled: false,
            //   defaultValue: '',
            //   labelKey: 'name',
            //   valueKey: 'value',
            //   placeholder: '请选择是否需要增扣提醒',
            //   placeholder_i18n: t('请选择是否需要增扣提醒'),
            //   datasource: 'is_need_extra',
            // },
            {
              type: 'textarea',
              name: 'mark',
              label: '备注',
              label_i18n: t('备注'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 200,
              defaultValue: '',
              placeholder: '请填写备注',
              placeholder_i18n: t('请填写备注'),
            },
          ],
          rules: {},
          model: reactive({
            org_id: current_org?.id,
            customer_id: record.id,
            customer_type: record.customer_type,
            adviser_id: record.adviser_id,
            recuitment_company_id: record.recuitment_company_id,
            cc_company_id: record.cc_company_id,
            name: record.name,
            address: record.address,
            phone: record.phone,
            email: record.email,
            data: record.data,
            mark: record.mark,
            customer_attr: record.customer_attr,
            payment_email_template_id: record.payment_email_template_id,
            is_auto_send: record.is_auto_send,
            // is_need_extra: record.is_need_extra,
          }),
          watch: {
            adviser_id: (data1: any) => {
              if (data1.adviser_id) {
                query_adviser_list({
                  org_id: current_org?.id,
                  ids: data1.adviser_id,
                }).then((res: any) => {
                  if (res && res.data && res.data.length > 0) {
                    data1.address = res.data[0].work_address__display_name
                      ? res.data[0].work_address__display_name
                      : res.data[0].home_address__display_name;
                    data1.name = res.data[0].name;
                    data1.phone = res.data[0].phone;
                    data1.email = res.data[0].personal_email;
                  }
                });
              }
            },
            recuitment_company_id: (data1: any) => {
              if (data1.recuitment_company_id) {
                query_recuitment_company_list({
                  org_id: current_org?.id,
                  ids: data1.recuitment_company_id,
                }).then((res: any) => {
                  if (res && res.data && res.data.length > 0) {
                    data1.address = res.data[0].address;
                    data1.name = res.data[0].company_name;
                  }
                });
              }
            },
            cc_company_id: (data1: any) => {
              if (data1.cc_company_id) {
                query_cc_company_list({
                  org_id: current_org?.id,
                  ids: data1.cc_company_id,
                }).then((res: any) => {
                  if (res && res.data && res.data.length > 0) {
                    data1.address = res.data[0].address;
                    data1.name = res.data[0].name;
                  }
                });
              }
            },
            customer_type: (data1: any) => {
              data1.cc_company_id = null;
              data1.recuitment_company_id = null;
              data1.adviser_id = null;
              if (data1.customer_type == 0) {
                is_adviser.value = false;
                is_recuitment_company.value = true;
                is_cc_company.value = true;
              } else if (data1.customer_type == 1) {
                is_adviser.value = true;
                is_recuitment_company.value = false;
                is_cc_company.value = true;
              } else if (data1.customer_type == 2) {
                is_adviser.value = true;
                is_recuitment_company.value = true;
                is_cc_company.value = false;
              } else if (data1.customer_type == 3) {
                is_adviser.value = true;
                is_recuitment_company.value = true;
                is_cc_company.value = true;
              }
            },
          },
        },
        options: {
          customer_type: [
            { value: 0, name: t('顾问') },
            { value: 1, name: t('顾问公司') },
            { value: 2, name: t('CC公司') },
            { value: 3, name: t('其他') },
          ],
          customer_attr: [
            { value: 0, name: t('个人') },
            { value: 1, name: t('公司') },
          ],
          is_auto_send: [
            { value: true, name: t('是') },
            { value: false, name: t('否') },
          ],
          // is_need_extra: [
          //   { value: true, name: t('是') },
          //   { value: false, name: t('否') },
          // ],
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            update_customer({
              ...payload,
            })
              .then(() => {
                message.success(t('修改成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: t('创建顾问'),
    clazz: 'primary',
    type: 'dropdown',
    is_show: (record: any) => {
      return record.customer_type == 3;
    },
    cb: (record: any) => {
      //新顾问 按钮被点击
      const {
        settings_adviser,
        fields_adviser,
        fields_adviser_update,
        options_adviser,
        baseColumns,
      } = cerp_adviser_editModal(t, {});
      const editModal = {
        visible: true,
        title: t('创建顾问'),
        loading: false,
        form: {
          settings: settings_adviser,
          fields: fields_adviser,
          rules: {
            // personal_email: [{ required: true, message: '请输入个人邮箱' }],
            first_name: [{ required: true, message: '请填输入名字' }],
            last_name: [{ required: true, message: '请填输入姓' }],
            person_type_id: [{ required: true, message: '请选择个人类别' }],
            status: [{ required: true, message: '请选择个人状态' }],
          },
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            title: '',
            first_name: record.name,
            last_name: null,
            other_name: null,
            birth_date: null,
            nationality: null,
            phone: record.phone,
            mobile: null,
            personal_email: record.email,
            work_email: null,
            wechat: null,
            whats_app: null,
            qq: null,
            tag_ids: null,
            user_email_template_id: null,
            skypelid: null,
            person_type_id: null,
            status: null,
            home_address_id: null,
            person_note: record.mark,
            work_address_id: null,
            creater: null,
          }),
          watch: {
            creater_id: (data: any) => {
              query_person_for_cmes_list({
                org_id: current_org?.id,
                ids: [data.creater_id],
              }).then((res: any) => {
                data['creater'] = res.data[0].realname;
              });
            },
            mobile: (data: any) => {
              if (data?.mobile && data.mobile.includes('+')) {
                data.mobile = data.mobile.replace(/\+/g, '00');
              }
            },
          },
        },
        options: options_adviser,
        ok: (payload: any) => {
          // 模态框，确认按钮点击。
          // todo: 创建新顾问，并询问是否录入 合同。
          return new Promise(resolve => {
            if (!payload.personal_email && !payload.work_email) {
              notification.error({
                message: t('提示'),
                description: t('个人邮箱、工作邮箱至少要填写一个'),
              });
              return false;
            }
            editModal.loading = true;
            create_adviser({
              ...payload,
              first_name: trim(payload.first_name),
              last_name: trim(payload.last_name),
              phone: payload.phone?.trim(),
              mobile: payload.mobile?.trim(),
            })
              .then(function (param: any) {
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      };
      modalBox(editModal);
    },
  },
  {
    name: t('创建顾问公司'),
    clazz: 'primary',
    type: 'dropdown',
    is_show: (record: any) => {
      return record.customer_type == 3;
    },
    cb: (record: any) => {
      const editModal = reactive({
        visible: true,
        title: t('创建顾问公司'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'client_id',
              label: '顾问公司ID',
              label_i18n: t('page.baseinfo.recuitmentcompany.form.label.client_id'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写顾问公司ID',
              placeholder_i18n: t('page.baseinfo.recuitmentcompany.form.placeholder.client_id'),
            },
            {
              type: 'input',
              name: 'company_name',
              label: '顾问公司名',
              label_i18n: t('page.baseinfo.recuitmentcompany.form.label.company_name'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写顾问公司名',
              placeholder_i18n: t('page.baseinfo.recuitmentcompany.form.placeholder.company_name'),
            },
            {
              type: 'input',
              name: 'address',
              label: '顾问公司地址',
              label_i18n: t('page.baseinfo.recuitmentcompany.form.label.address'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写顾问公司地址',
              placeholder_i18n: t('page.baseinfo.recuitmentcompany.form.placeholder.address'),
            },
            {
              type: 'input',
              name: 'contact_number',
              label: '顾问公司联系方式',
              label_i18n: t('page.baseinfo.recuitmentcompany.form.label.contact_number'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写顾问公司联系方式',
              placeholder_i18n: t(
                'page.baseinfo.recuitmentcompany.form.placeholder.contact_number',
              ),
            },
            {
              type: 'input',
              name: 'vat_no',
              label: 'VATNo',
              label_i18n: t('page.baseinfo.recuitmentcompany.form.label.vat_no'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写VATNo',
              placeholder_i18n: t('page.baseinfo.recuitmentcompany.form.placeholder.vat_no'),
            },
            {
              type: 'remoteselect',
              name: 'bank_account_ids',
              label: 'CC银行账户',
              label_i18n: t('CC银行账户'),
              disabled: false,
              placeholder: '请选择CC银行账户',
              placeholder_i18n: t('请选择CC银行账户'),
              datasourceType: 'remote',
              mode: 'multiple',
              allowClear: true,
              labelKey: 'account_flag',
              valueKey: 'id',
              modalType: 'baseinfo-ccbankaccountscc',
            },
          ],
          rules: {
            company_name: [{ required: true, message: t('请填输入顾问公司名') }],
            address: [{ required: true, message: t('请填输入顾问公司地址') }],
            contact_number: [{ required: true, message: t('请填输入顾问公司联系方式') }],
            vat_no: [{ required: true, message: t('请填输入VATNo') }],
          },
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            company_name: record.name,
            address: record.address,
            contact_number: record.phone,
            vat_no: null,
          }),
          watch: {},
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            create_recuitment_company({
              ...payload,
            })
              .then(() => {
                message.success(t('新建成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: t('创建CC公司'),
    clazz: 'primary',
    type: 'dropdown',
    is_show: (record: any) => {
      return record.customer_type == 3;
    },
    cb: (record: any) => {
      const editModal = reactive({
        visible: true,
        title: t('创建CC公司'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'name',
              label: '公司名称',
              label_i18n: t('page.baseinfo.cccompany.form.label.name'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写公司名称',
              placeholder_i18n: t('请填写公司名称'),
            },
            {
              type: 'input',
              name: 'address',
              label: '地址',
              label_i18n: t('page.baseinfo.cccompany.form.label.address'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写地址',
              placeholder_i18n: t('page.baseinfo.cccompany.form.placeholder.address'),
            },
            {
              type: 'input',
              name: 'contact_number',
              label: '联系方式',
              label_i18n: t('page.baseinfo.cccompany.form.label.contact_number'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写联系方式',
              placeholder_i18n: t('page.baseinfo.cccompany.form.placeholder.contact_number'),
            },
            {
              type: 'remoteselect',
              name: 'country_id',
              label: '国家',
              label_i18n: t('page.baseinfo.cccompany.form.label.country_id'),
              disabled: false,
              placeholder: '请选择国家',
              placeholder_i18n: t('page.baseinfo.cccompany.form.placeholder.country_id'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'name',
              valueKey: 'id',
              modalType: 'baseinfo-country',
            },
          ],
          rules: {
            name: [{ required: true, message: t('请填写公司名称') }],
            address: [{ required: true, message: t('请填写地址') }],
            country_id: [{ required: true, message: t('请选择国家') }],
          },
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            name: record.name,
            address: record.address,
            contact_number: record.phone,
            country_id: null,
          }),
          watch: {},
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            create_cc_company({
              ...payload,
            })
              .then(() => {
                message.success(t('新建成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list = [
  {
    name: t('新建客户'),
    clazz: 'primary',
    cb: (data: any) => {
      const is_adviser: any = ref(true);
      const is_recuitment_company: any = ref(true);
      const is_cc_company: any = ref(true);
      const editModal = reactive({
        visible: true,
        title: t('新建客户'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [6, 6],
          },
          fields: [
            {
              type: 'select',
              name: 'customer_type',
              label: '客户类型',
              label_i18n: t('客户类型'),
              placeholder: '请选择客户类型',
              placeholder_i18n: t('请选择客户类型'),
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              datasource: 'customer_type',
            },
            {
              type: 'remotemodal',
              name: 'adviser_id',
              label: '隶属顾问',
              label_i18n: t('隶属顾问'),
              disabled: is_adviser,
              defaultValue: '',
              labelKey: 'full_name',
              valueKey: 'id',
              placeholder: '隶属顾问',
              show_search: false,
              maxTagCount: 5,
              mode: 'default',
              title: t('选择隶属顾问'),
              modalType: 'cerp-adviser',
            },
            {
              type: 'remoteselect',
              name: 'recuitment_company_id',
              label: '顾问公司',
              label_i18n: t('顾问公司'),
              disabled: is_recuitment_company,
              placeholder: '请选择顾问公司',
              placeholder_i18n: t('请选择顾问公司'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'company_name',
              valueKey: 'id',
              modalType: 'baseinfo-recuitmentcompany',
            },
            {
              type: 'remoteselect',
              name: 'cc_company_id',
              label: 'CC公司',
              label_i18n: t('CC公司'),
              disabled: is_cc_company,
              placeholder: '请选择CC公司',
              placeholder_i18n: t('请选择CC公司'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-cccompany',
            },
            {
              type: 'input',
              name: 'name',
              label: '客户名称',
              label_i18n: t('客户名称'),
              disabled: false,
              placeholder: '请填写客户名称',
              placeholder_i18n: t('请填写客户名称'),
              datasourceType: 'remote',
              allowClear: true,
            },
            // {
            //   type: 'attributetable',
            //   name: 'data',
            //   label: '额外数据',
            //   disabled: false,
            //   allowClear: true,
            //   inputType: 'text',
            //   defaultValue: '',
            //   placeholder: '请填写额外数据',
            // },
            {
              type: 'radio',
              name: 'customer_attr',
              label: '客户属性',
              label_i18n: t('客户属性'),
              placeholder: '请选择客户属性',
              placeholder_i18n: t('请选择客户属性'),
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              datasource: 'customer_attr',
            },
            {
              type: 'input',
              name: 'address',
              label: '地址',
              label_i18n: t('地址'),
              disabled: false,
              placeholder: '请填写地址',
              placeholder_i18n: t('请填写地址'),
              datasourceType: 'remote',
              allowClear: true,
            },
            {
              type: 'input',
              name: 'phone',
              label: '电话',
              label_i18n: t('电话'),
              disabled: false,
              placeholder: '请填写电话',
              placeholder_i18n: t('请填写电话'),
              datasourceType: 'remote',
              allowClear: true,
            },
            {
              type: 'input',
              name: 'email',
              label: '邮箱',
              label_i18n: t('邮箱'),
              disabled: false,
              placeholder: '请填写邮箱',
              placeholder_i18n: t('请填写邮箱'),
              datasourceType: 'remote',
              allowClear: true,
            },
            {
              type: 'remoteselect',
              name: 'payment_email_template_id',
              label: '付款凭证邮件模板',
              label_i18n: t('付款凭证邮件模板'),
              disabled: false,
              placeholder: '请选择付款凭证邮件模板',
              placeholder_i18n: t('请选择付款凭证邮件模板'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-emailtemplate',
            },
            {
              type: 'radio',
              name: 'is_auto_send',
              label: '是否自动发送付款凭证',
              label_i18n: t('是否自动发送付款凭证'),
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              placeholder: '请选择是否自动发送付款凭证',
              placeholder_i18n: t('请选择是否自动发送付款凭证'),
              datasource: 'is_auto_send',
            },
            // {
            //   type: 'radio',
            //   name: 'is_need_extra',
            //   label: '是否需要增扣提醒',
            //   label_i18n: t('是否需要增扣提醒'),
            //   mode: 'default',
            //   disabled: false,
            //   defaultValue: '',
            //   labelKey: 'name',
            //   valueKey: 'value',
            //   placeholder: '请选择是否需要增扣提醒',
            //   placeholder_i18n: t('请选择是否需要增扣提醒'),
            //   datasource: 'is_need_extra',
            // },
            {
              type: 'textarea',
              name: 'remark',
              label: '备注',
              label_i18n: t('备注'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 200,
              defaultValue: '',
              placeholder: '请填写备注',
              placeholder_i18n: t('请填写备注'),
            },
          ],
          rules: {
            name: [{ required: true, message: t('请填写客户名称') }],
          },
          model: reactive({
            org_id: current_org?.id,
            customer_type: null,
            adviser_id: null,
            recuitment_company_id: null,
            cc_company_id: null,
            name: null,
            address: null,
            phone: null,
            email: null,
            data: null,
            mark: null,
            customer_attr: null,
            payment_email_template_id: null,
            is_auto_send: true,
            // is_need_extra: false,
          }),
          watch: {
            adviser_id: (data1: any) => {
              if (data1.adviser_id) {
                query_adviser_list({
                  org_id: current_org?.id,
                  ids: data1.adviser_id,
                }).then((res: any) => {
                  if (res && res.data && res.data.length > 0) {
                    data1.address = res.data[0].work_address__display_name
                      ? res.data[0].work_address__display_name
                      : res.data[0].home_address__display_name;
                    data1.name = res.data[0].full_name;
                    data1.phone = res.data[0].phone;
                    data1.email = res.data[0].personal_email;
                  }
                });
              }
            },
            recuitment_company_id: (data1: any) => {
              if (data1.recuitment_company_id) {
                query_recuitment_company_list({
                  org_id: current_org?.id,
                  ids: data1.recuitment_company_id,
                }).then((res: any) => {
                  if (res && res.data && res.data.length > 0) {
                    data1.address = res.data[0].address;
                    data1.name = res.data[0].company_name;
                  }
                });
              }
            },
            cc_company_id: (data1: any) => {
              if (data1.cc_company_id) {
                query_cc_company_list({
                  org_id: current_org?.id,
                  ids: data1.cc_company_id,
                }).then((res: any) => {
                  if (res && res.data && res.data.length > 0) {
                    data1.address = res.data[0].address;
                    data1.name = res.data[0].name;
                  }
                });
              }
            },
            customer_type: (data1: any) => {
              data1.cc_company_id = null;
              data1.recuitment_company_id = null;
              data1.adviser_id = null;
              data1.address = null;
              data1.phone = null;
              data1.email = null;
              data1.name = null;
              if (data1.customer_type == 0) {
                is_adviser.value = false;
                is_recuitment_company.value = true;
                is_cc_company.value = true;
                data1.customer_attr = 0;
              } else if (data1.customer_type == 1) {
                is_adviser.value = true;
                is_recuitment_company.value = false;
                is_cc_company.value = true;
                data1.customer_attr = 1;
              } else if (data1.customer_type == 2) {
                is_adviser.value = true;
                is_recuitment_company.value = true;
                is_cc_company.value = false;
                data1.customer_attr = 1;
              } else if (data1.customer_type == 3) {
                is_adviser.value = true;
                is_recuitment_company.value = true;
                is_cc_company.value = true;
                data1.customer_attr = 1;
              }
            },
          },
        },
        options: {
          customer_type: [
            { value: 0, name: t('顾问') },
            { value: 1, name: t('顾问公司') },
            { value: 2, name: t('CC公司') },
            { value: 3, name: t('其他') },
          ],
          customer_attr: [
            { value: 0, name: t('个人') },
            { value: 1, name: t('公司') },
          ],
          is_auto_send: [
            { value: true, name: t('是') },
            { value: false, name: t('否') },
          ],
          // is_need_extra: [
          //   { value: true, name: t('是') },
          //   { value: false, name: t('否') },
          // ],
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            create_customer({
              ...payload,
            })
              .then(() => {
                message.success(t('新建成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
];
