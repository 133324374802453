import {
  create_receipt_type,
  delete_receipt_type,
  query_receipt_type_list,
  recover_receipt_type,
  update_receipt_type,
} from '@/api/receipt-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/receipt/receipt-type-config';
import { reactive } from 'vue';
import { useRoute } from 'vue-router';
import { getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('名称'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('请填写名称'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '票据种类名称',
      label_i18n: t('票据种类名称'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写票据种类名称',
      placeholder_i18n: t('请填写票据种类名称'),
    },
    {
      type: 'input',
      name: 'description',
      label: '描述',
      label_i18n: t('描述'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写描述',
      placeholder_i18n: t('请填写描述'),
    },
  ],
  rules: {
    name: [{ required: true, message: t('请填写票据种类名称') }],
  },
  model: reactive({
    org_id: current_org?.id,
    name: null,
    description: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '票据种类名称',
      label_i18n: t('票据种类名称'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写票据种类名称',
      placeholder_i18n: t('请填写票据种类名称'),
    },
    {
      type: 'input',
      name: 'description',
      label: '描述',
      label_i18n: t('描述'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写描述',
      placeholder_i18n: t('请填写描述'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    name: null,
    description: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('票据种类'),
  addModalTitle: t('新建票据种类'),
  updateTitle: t('修改票据种类'),
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    receipt_type_id: 'id',
  },
  delete: {
    receipt_type_id: 'id',
  },
  recover: {
    receipt_type_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    receipt_type_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('ID'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('票据种类'),
    width: 200,
    dataIndex: 'name',
    checked: true,
  },
  {
    title: t('描述'),
    width: 300,
    dataIndex: 'description',
    checked: true,
  },
  {
    title: t('创建时间'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('修改时间'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('是否可用'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('操作'),
    width: 150,
    dataIndex: 'action',
    checked: true,
    cellComponent: 'action',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push();

export default () => {
  const route = useRoute();
  search_config.model = reactive({ ...search_config.model });
  search_config.model.ids = Number(route.query.ids);
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_receipt_type_list,
    addData: create_receipt_type,
    updateData: update_receipt_type,
    removeData: delete_receipt_type,
    recoverData: recover_receipt_type,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
