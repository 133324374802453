import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import { deal_wechatupdaterecord } from '@/api/cerp-model';
import { message, Modal } from 'ant-design-vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';

export const event_obj = mitt();
export const btn_list = [
  {
    name: '通过',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.status == 0;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('是否审批通过'),
        onOk() {
          return new Promise(resolve => {
            const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
            deal_wechatupdaterecord({
              wechat_update_record_id: record.id,
              status: 1,
              org_id: current_org?.id,
            })
              .then(function (res) {
                message.success(t('操作成功'));
                Object.assign(record, res);
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '拒绝',
    clazz: 'danger',
    is_show: (record: any) => {
      return record.status == 0;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('是否审批拒绝'),
        onOk() {
          return new Promise(resolve => {
            const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
            deal_wechatupdaterecord({
              wechat_update_record_id: record.id,
              status: 2,
              org_id: current_org?.id,
            })
              .then(function (res) {
                message.success(t('操作成功'));
                Object.assign(record, res);
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
