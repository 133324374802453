import {
  create_email_template,
  delete_email_template,
  query_email_template_list,
  recover_email_template,
  update_email_template,
} from '@/api/baseinfo-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/baseinfo/baseinfo-emailtemplate-config';
import { createVNode, reactive, ref } from 'vue';
import { message, Modal, notification } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'search_text',
      label: '模糊搜索',
      label_i18n: t('page.baseinfo.emailtemplate.form.label.search_text'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写搜索内容',
      placeholder_i18n: t('page.baseinfo.emailtemplate.form.placeholder.search_text'),
    },
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('page.baseinfo.emailtemplate.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.baseinfo.emailtemplate.form.placeholder.name'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    search_text: null,
    name: null,
  }),
};
const search_options = {};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('page.baseinfo.emailtemplate.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.baseinfo.emailtemplate.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'title',
      label: '标题',
      label_i18n: t('page.baseinfo.emailtemplate.form.label.title'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写标题',
      placeholder_i18n: t('page.baseinfo.emailtemplate.form.placeholder.title'),
    },
    {
      type: 'select',
      name: 'content_type',
      label: '内容类型',
      label_i18n: t('page.baseinfo.emailtemplate.form.label.content_type'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择内容类型',
      placeholder_i18n: t('page.baseinfo.emailtemplate.form.placeholder.content_type'),
      datasource: 'content_type',
    },
    {
      type: 'textarea',
      name: 'content',
      label: '内容',
      label_i18n: t('page.baseinfo.emailtemplate.form.label.content'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填写内容',
      placeholder_i18n: t('page.baseinfo.emailtemplate.form.placeholder.content'),
    },
  ],
  rules: {
    name: [{ required: true, message: t('page.baseinfo.emailtemplate.form.placeholder.name') }],
    content: [{ required: true, message: t('page.baseinfo.emailtemplate.form.placeholder.content') }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    title: null,
    content_type: null,
    content: null,
  }),
};
const edit_model_config_options = {
  content_type: [
    { value: 0, name: t('文本') },
    { value: 1, name: t('html') },
  ],
};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('page.baseinfo.emailtemplate.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.baseinfo.emailtemplate.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'title',
      label: '标题',
      label_i18n: t('page.baseinfo.emailtemplate.form.label.title'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写标题',
      placeholder_i18n: t('page.baseinfo.emailtemplate.form.placeholder.title'),
    },
    {
      type: 'select',
      name: 'content_type',
      label: '内容类型',
      label_i18n: t('page.baseinfo.emailtemplate.form.label.content_type'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择内容类型',
      placeholder_i18n: t('page.baseinfo.emailtemplate.form.placeholder.content_type'),
      datasource: 'content_type',
    },
    {
      type: 'textarea',
      name: 'content',
      label: '内容',
      label_i18n: t('page.baseinfo.emailtemplate.form.label.content'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填写内容',
      placeholder_i18n: t('page.baseinfo.emailtemplate.form.placeholder.content'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    title: null,
    content_type: null,
    content: null,
  }),
};
const update_model_config_options = {
  content_type: [
    { value: 0, name: t('文本') },
    { value: 1, name: t('html') },
  ],
};

const titleList = {
  title: t('邮箱模板'),
  addModalTitle: t('新建'),
  updateTitle: t('修改邮箱模板'),
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    emailtemplate_id: 'id',
  },
  delete: {
    emailtemplate_id: 'id',
  },
  recover: {
    emailtemplate_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    emailtemplate_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.baseinfo.emailtemplate.column.title.index'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('page.baseinfo.emailtemplate.column.title.id'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.baseinfo.emailtemplate.column.title.name'),
    width: 150,
    dataIndex: 'name',
    checked: true,
  },
  {
    title: t('page.baseinfo.emailtemplate.column.title.title'),
    width: 150,
    dataIndex: 'title',
    checked: true,
  },
  {
    title: t('page.baseinfo.emailtemplate.column.title.image'),
    width: 200,
    dataIndex: 'image',
    checked: true,
    cellComponent: 'click',
    content: (record: any) => {
      if (record.image) {
        return record.image?.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.image && record.image.file_url) {
        const loading = ref(false);
        const editModal = reactive({
          visible: true,
          title: t('图片展示'),
          component: 'image-display-modal',
          loading: loading,
          url: 'http://erp-cc.oss-cn-hongkong.aliyuncs.com/' + record.image.file_url,
        });
        modalBox(editModal);
      } else {
        notification.error({
          message: t('提示'),
          description: t('未上传图片'),
        });
      }
    },
  },
  {
    title: t('page.baseinfo.emailtemplate.column.title.appendix_json_list'),
    minWidth: 400,
    dataIndex: 'appendix_json_list',
    checked: true,
    cellComponent: 'clicklist',
    cb: (record: any) => {
      if (record && record.file_url) {
        const loading = ref(false);
        const url: string = 'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.file_url);
        window.open(url, '_blank');
        // axios.get('/' + record.file_url,{
        //   responseType: 'blob' }).then((res: any) => {
        //   var blob = res.data;
        //   // FileReader主要用于将文件内容读入内存
        //   var reader = new FileReader();
        //   reader.readAsDataURL(blob);
        //   // onload当读取操作成功完成时调用
        //   reader.onload = (e: any) => {
        //     var a = document.createElement('a');
        //     // 获取文件名fileName
        //     var fileName = res.config["url"].split("/");
        //     fileName = fileName[fileName.length - 1];
        //     // fileName = fileName.replace(/"/g, "");
        //     a.download = fileName;
        //     a.href = e.target.result;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        //   }
        // })
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
    del: (record: any, text: any, file: any) => {
      if (file.file_id) {
        Modal.confirm({
          title: t('提示'),
          content: t('是否删除') + '[' + file.name + ']',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            return new Promise(resolve => {
              let ids: any = [];
              text.forEach((itme: any) => {
                if (itme.file_id != file.file_id) {
                  ids.push(itme.file_id);
                }
              });
              if (ids.length == 0) {
                ids = 0;
              }
              update_email_template({
                emailtemplate_id: record.id,
                appendix_ids: ids,
                org_id: current_org?.id,
              })
                .then(() => {
                  message.success(t('删除成功'));
                  event_obj.emit('refresh');
                })
                .finally(() => {
                  resolve(null);
                });
            });
          },
          onCancel() {},
        });
      }
    },
  },
  {
    title: t('page.baseinfo.emailtemplate.column.title.content'),
    width: 200,
    dataIndex: 'content',
    checked: true,
    cellComponent: 'click',
    content: (record: any) => {
      if (record.content) {
        let a: any =  record.content.substr(0,30)
        return a;
      } else {
        return t('点击添加');
      }
    },
    cb: (record: any) => {
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('代码内容'),
        component: 'code-editing-modal',
        loading: loading,
        mode: 'htmlmixed',
        params: record.content,
        hold: (code: any) => {
          update_email_template({
            org_id: current_org?.id,
            content: code,
            emailtemplate_id: record.id,
          }).then(() => {
            loading.value = false;
            message.success(t('保存成功'));
            event_obj.emit('refresh');
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    title: t('page.baseinfo.emailtemplate.column.title.content_type'),
    width: 100,
    dataIndex: 'content_type',
    checked: true,
    content: (record: any) => {
      let color = '';
      switch (record.content_type) {
        case 0:
          color = t('文本');
          break;
        case 1:
          color = t('html');
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('文本'), value: 0 },
      { text: t('html'), value: 1 },
    ],
  },
  {
    title: t('page.baseinfo.emailtemplate.column.title.is_active'),
    width: 100,
    dataIndex: 'is_active',
    checked: true,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },
  {
    title: t('page.baseinfo.emailtemplate.column.title.update_time'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,content: (record: any, text: any) => {
      return getDateTime(text);
    },
  },
  {
    title: t('page.baseinfo.emailtemplate.column.title.action'),
    width: 120,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push();

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_email_template_list,
    addData: create_email_template,
    updateData: update_email_template,
    removeData: delete_email_template,
    recoverData: recover_email_template,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
