import {
  create_bank_transaction,
  delete_bank_transaction,
  query_bank_transaction_list,
  recover_bank_transaction,
  update_bank_transaction,
} from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/cerp/cerp-banktransaction-config';

import { reactive } from 'vue';
import moment from 'moment';
import router from '@/router';
import { useRoute } from 'vue-router';
import { notification } from 'ant-design-vue';
import { getDate, getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('page.cerp.banktransaction.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.cerp.banktransaction.form.placeholder.name'),
    },
    {
      type: 'select',
      name: 'transaction_type',
      label: '流水种类',
      label_i18n: t('page.cerp.banktransaction.form.label.transaction_type'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择流水种类',
      placeholder_i18n: t('page.cerp.banktransaction.form.placeholder.transaction_type'),
      datasource: 'transaction_type',
    },
    {
      type: 'remoteselect',
      name: 'cc_bank_account_id',
      label: 'CC银行账户',
      label_i18n: 'CC银行账户',
      placeholder_i18n: '请选择CC银行账户',
      disabled: false,
      placeholder: '请选择CC银行账户',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'account_flag',
      valueKey: 'id',
      modalType: 'baseinfo-ccbankaccountscc',
    },
    {
      type: 'remoteselect',
      name: 'currency_id',
      labelKey: 'currency',
      label: '币种',
      label_i18n: t('币种'),
      disabled: false,
      placeholder: '请选择薪资币种',
      placeholder_i18n: t('请选择薪资币种'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-country1',
    },
    {
      type: 'rangepicker',
      name: 'time_interval',
      label: '时间区间',
      label_i18n: '时间区间',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: true,
      valueFormat: 'YYYY-MM-DD HH:mm:ss',
      placeholder: '请填写时间区间',
    },
    {
      type: 'number',
      name: 'amount',
      label: '总金额',
      label_i18n: '总金额',
      placeholder_i18n: '请填写总金额',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写总金额',
    },
    {
      type: 'number',
      name: 'employee_number',
      label: '顾问工号',
      label_i18n: '顾问工号',
      placeholder_i18n: '请填写顾问工号',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写顾问工号',
    },
    {
      type: 'input',
      name: 'description',
      label: '备注',
      label_i18n: '备注',
      placeholder_i18n: '请填写备注',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写备注',
    },
    {
      type: 'radio',
      name: 'is_no_add',
      label: '是否未添加CC银行账户',
      label_i18n: '是否未添加CC银行账户',
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'radio',
      name: 'is_no_currency',
      label: '是否未添加币种',
      label_i18n: '是否未添加币种',
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    transaction_type: null,
    cc_bank_account_id: null,
    time_interval: null,
    amount: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: t('全部') },
    { value: True, name: t('可用') },
    { value: False, name: t('禁用') },
  ],
  transaction_type: [
    { value: '收入', name: t('收入') },
    { value: '支出', name: t('支出') },
  ],
  is_no_add: [
    { value: None, name: t('全部') },
    { value: True, name: t('是') },
    { value: False, name: t('否') },
  ],
  is_no_currency: [
    { value: None, name: t('全部') },
    { value: True, name: t('是') },
    { value: False, name: t('否') },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('page.cerp.banktransaction.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.cerp.banktransaction.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'description',
      label: '备注',
      label_i18n: t('page.cerp.banktransaction.form.label.description'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写备注',
      placeholder_i18n: t('page.cerp.banktransaction.form.placeholder.description'),
    },
    {
      type: 'money',
      name: 'amount',
      label: '金额',
      label_i18n: t('page.cerp.banktransaction.form.label.amount'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写金额',
      placeholder_i18n: t('page.cerp.banktransaction.form.placeholder.amount'),
    },
    {
      type: 'datepicker',
      name: 'date',
      label: '日期',
      label_i18n: t('page.cerp.banktransaction.form.label.date'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写日期',
      placeholder_i18n: t('page.cerp.banktransaction.form.placeholder.date'),
    },
    {
      type: 'select',
      name: 'transaction_type',
      label: '流水种类',
      label_i18n: t('page.cerp.banktransaction.form.label.transaction_type'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择流水种类',
      placeholder_i18n: t('page.cerp.banktransaction.form.placeholder.transaction_type'),
      datasource: 'transaction_type',
    },
    {
      type: 'remoteselect',
      name: 'cc_bank_account_id',
      label: 'CC银行账户',
      label_i18n: 'CC银行账户',
      placeholder_i18n: '请选择CC银行账户',
      disabled: false,
      placeholder: '请选择CC银行账户',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'account_flag',
      valueKey: 'id',
      modalType: 'baseinfo-ccbankaccountscc',
    },
    {
      type: 'remoteselect',
      name: 'currency_id',
      labelKey: 'currency',
      label: '币种',
      label_i18n: t('币种'),
      disabled: false,
      placeholder: '请选择薪资币种',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      placeholder_i18n: t('请选择薪资币种'),
      modalType: 'baseinfo-country1',
    },
  ],
  rules: {
    name: [{ required: true, message: t('请填输入名称') }],
    transaction_type: [{ required: true, message: t('请填输入流水种类') }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    description: null,
    amount: null,
    date: moment().format('YYYY-MM-DD HH:mm:ss'),
    currency_id: null,
    transaction_type: null,
    cc_bank_account_id: null,
  }),
};
const edit_model_config_options = {
  transaction_type: [
    { value: '收入', name: t('收入') },
    { value: '支出', name: t('支出') },
  ],
};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('page.cerp.banktransaction.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.cerp.banktransaction.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'description',
      label: '备注',
      label_i18n: t('page.cerp.banktransaction.form.label.description'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写备注',
      placeholder_i18n: t('page.cerp.banktransaction.form.placeholder.description'),
    },
    {
      type: 'money',
      name: 'amount',
      label: '金额',
      label_i18n: t('page.cerp.banktransaction.form.label.amount'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写金额',
      placeholder_i18n: t('page.cerp.banktransaction.form.placeholder.amount'),
    },
    {
      type: 'datepicker',
      name: 'date',
      label: '日期',
      label_i18n: t('page.cerp.banktransaction.form.label.date'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写日期',
      placeholder_i18n: t('page.cerp.banktransaction.form.placeholder.date'),
    },
    {
      type: 'select',
      name: 'transaction_type',
      label: '流水种类',
      label_i18n: t('page.cerp.banktransaction.form.label.transaction_type'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择流水种类',
      placeholder_i18n: t('page.cerp.banktransaction.form.placeholder.transaction_type'),
      datasource: 'transaction_type',
    },
    {
      type: 'remoteselect',
      name: 'cc_bank_account_id',
      label: 'CC银行账户',
      label_i18n: 'CC银行账户',
      placeholder_i18n: '请选择CC银行账户',
      disabled: false,
      placeholder: '请选择CC银行账户',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'account_flag',
      valueKey: 'id',
      modalType: 'baseinfo-ccbankaccountscc',
    },
    {
      type: 'remoteselect',
      name: 'currency_id',
      labelKey: 'currency',
      label: '币种',
      label_i18n: t('币种'),
      disabled: false,
      placeholder: '请选择薪资币种',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      placeholder_i18n: t('请选择薪资币种'),
      modalType: 'baseinfo-country1',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    description: null,
    amount: null,
    date: moment().format('YYYY-MM-DD HH:mm:ss'),
    currency_id: null,
    transaction_type: null,
    cc_bank_account_id: null,
  }),
};
const update_model_config_options = {
  transaction_type: [
    { value: '收入', name: t('收入') },
    { value: '支出', name: t('支出') },
  ],
};

const titleList = {
  title: t('银行流水管理'),
  addModalTitle: t('新建银行流水'),
  updateTitle: t('修改银行流水'),
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    transaction_id: 'id',
  },
  delete: {
    transaction_id: 'id',
  },
  recover: {
    transaction_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    transaction_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.cerp.banktransaction.column.title.index'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('page.cerp.banktransaction.column.title.id'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.cerp.banktransaction.column.title.name'),
    width: 150,
    dataIndex: 'name',
    checked: true,
    cellComponent: 'click',
    cb: (record: any) => {
      if(record.is_payslip){
        router.push({
          path: '/adviser-manage/basic-remittance/' + record.remittance_set_id + '/' + record.remittance_set__remittance_name,
        });
      }else if(record.is_payslip == false){
        router.push({ path: '/adviser-manage/cerp-remittanceset', query: { ids: [parseInt(record.remittance_set_id)], t: (new Date()).getTime() } });
      }else{
        notification.error({
          message: t('错误'),
          description: t('无对应项'),
        });
      }
    },
  },
  {
    title: t('page.cerp.banktransaction.column.title.amount'),
    width: 150,
    dataIndex: 'amount',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.banktransaction.column.title.date'),
    width: 150,
    dataIndex: 'date',
    checked: true,
    content: (record: any) => {
      return getDate(record.date);
    },
  },
  {
    title: t('page.cerp.banktransaction.column.title.transaction_type'),
    width: 150,
    dataIndex: 'transaction_type',
    checked: true,
    content: (record: any) => {
      return record.transaction_type == '收入' ? t('收入') : record.transaction_type == '支出' ? t('支出') : '';
    },
  },
  {
    title: t('page.cerp.banktransaction.column.title.cc_bank_account__account_flag'),
    width: 150,
    dataIndex: 'cc_bank_account__account_flag',
    checked: true,
  },
  {
    title: t('page.cerp.banktransaction.column.title.currency__currency'),
    width: 150,
    dataIndex: 'currency__currency',
    checked: true,
  },
  {
    title: t('page.cerp.banktransaction.column.title.adviser__full_name'),
    width: 150,
    dataIndex: 'adviser__full_name',
    checked: true,
  },
  {
    title: t('page.cerp.banktransaction.column.title.adviser__employee_number'),
    width: 150,
    dataIndex: 'adviser__employee_number',
    checked: true,
  },
  {
    title: t('page.cerp.banktransaction.column.title.description'),
    width: 150,
    dataIndex: 'description',
    checked: true,
  },
  {
    title: t('page.cerp.banktransaction.column.title.create_time'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('page.cerp.banktransaction.column.title.update_time'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('page.cerp.banktransaction.column.title.is_active'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('page.cerp.banktransaction.column.title.action'),
    width: 200,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  // slotName: 'is_active',
  field_true: '可用',
  field_false: '禁用',
},
  {
    type: 'color_btn',
    slotName: 'name',
    cb: (record: any) => {
      if(record.is_payslip){
        router.push({
          path: '/adviser-manage/basic-remittance/' + record.remittance_set_id + '/' + record.remittance_set__remittance_name,
        });
      }else if(record.is_payslip == false){
        router.push({ path: '/adviser-manage/cerp-remittanceset', query: { ids: [parseInt(record.remittance_set_id)], t: (new Date()).getTime() } });
      }else{
        notification.error({
          message: '错误',
          description: '无对应项',
        });
      }
    },
    rt: (record: any) => {
      return '';
    },
});

export default () => {
  const route = useRoute();
  search_config.model = reactive({ ...search_config.model });
  if(route.query.id){
    search_config.model.ids = [Number(route.query.id)];
  }
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_bank_transaction_list,
    addData: create_bank_transaction,
    updateData: update_bank_transaction,
    removeData: delete_bank_transaction,
    recoverData: recover_bank_transaction,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
