import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import { createVNode } from 'vue';
import { message, Modal } from 'ant-design-vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { update_service } from '@/api/service-model';
import { GET_USER } from '@/store/modules/user/actions';
import { useStore } from 'vuex';

const store = useStore();
export const event_obj = mitt();
export const btn_list = [
  {
    name: '上班',
    clazz: 'primary',
    type: 'dropdown',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      Modal.confirm({
        title: t('确认上班') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            update_service({
              org_id: current_org.id,
              status: 1,
              service_id: data.id,
            }).then((res: any) => {
              if (res) {
                Object.assign(data, res);
                message.success(t('操作成功'));
                store.dispatch(`user/${GET_USER}`, { org_id: Number(current_org?.id) });
                resolve(null);
              }
            })
          })
        },
        onCancel() {
        },
      })
    },
  },
  {
    name: '下班',
    clazz: 'primary',
    type: 'dropdown',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      Modal.confirm({
        title: t('确认下班') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            update_service({
              org_id: current_org.id,
              status: 2,
              service_id: data.id,
            }).then((res: any) => {
              if (res) {
                message.success(t('操作成功'));
                Object.assign(data, res);
                store.dispatch(`user/${GET_USER}`, {org_id: Number(current_org?.id)});
                resolve(null);
              }
            })
          })
        },
        onCancel() {
        },
      })
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
