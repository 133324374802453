import { create_notice, delete_notice, query_notice_list, recover_notice, update_notice } from '@/api/baseinfo-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/baseinfo/baseinfo-markdown-config';

import modalBox from '@/components/form-modal/modal-tools';
import { reactive } from 'vue';
import { getDateTime } from '@/utils/function';
import { notification } from 'ant-design-vue';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'title',
      label: '名称',
      label_i18n: t('page.baseinfo.markdown.form.label.title'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.baseinfo.markdown.form.placeholder.title'),
    },],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    title: null,
  }),
};
const search_options = {};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'title',
      label: '标题',
      label_i18n: '标题',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写标题',
      placeholder_i18n: '请填写标题',
    },
    {
      type: 'remoteselect',
      name: 'tag_ids',
      label: '标签',
      label_i18n: '标签',
      disabled: false,
      placeholder: '请选择标签',
      placeholder_i18n: '请选择标签',
      datasourceType: 'remote',
      mode: 'multiple',
      allowClear: true,
      labelKey: 'name',
      valueKey: 'id',
      modalType: 'baseinfo-tag',
    },
    {
      type: 'radio',
      name: 'notice_type',
      label: '类型',
      label_i18n: '类型',
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'input',
      name: 'sorted_index',
      label: '排序',
      label_i18n: '排序',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写排序',
      placeholder_i18n: '请填写排序',
    },
  ],
  rules: {
    tag_ids: [{ required: true, message: t('请选择标签') }],
    title: [{ required: true, message: t('请填写标题') }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    title: null,
    tag_ids: null,
    notice_type: 0,
  }),
};
const edit_model_config_options = {
  notice_type: [
    { value: 0, name: t('公司公告') },
    { value: 1, name: t('重要信息') },
  ],
};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'title',
      label: '标题',
      label_i18n: '标题',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写标题',
      placeholder_i18n: '请填写标题',
    },
    {
      type: 'remoteselect',
      name: 'tag_ids',
      label: '标签',
      label_i18n: '标签',
      disabled: false,
      placeholder: '请选择标签',
      placeholder_i18n: '请选择标签',
      datasourceType: 'remote',
      mode: 'multiple',
      allowClear: true,
      labelKey: 'name',
      valueKey: 'id',
      modalType: 'baseinfo-tag',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('公告与重要信息'),
  addModalTitle: t('新建公告（重要信息）'),
  updateTitle: t('修改'),
  is_create: true, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    notice_id: 'id',
  },
  delete: {
    notice_id: 'id',
  },
  recover: {
    notice_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    notice_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    width: 50,
    fixed: 'left',
  },
  {
    title: 'ID',
    width: 150,
    dataIndex: 'id',
    checked: false,
    resizable: true,
  },
  {
    title: t('标题'),
    minWidth: 150,
    dataIndex: 'title',
    checked: true,
    resizable: true,
  },
  {
    title: t('类型'),
    minWidth: 150,
    dataIndex: 'notice_type',
    checked: true,
    resizable: true,
    cellComponent: 'tag',
    content: (record: any, text: any) => {
      let color = '';
      switch (text) {
        case 0:
          color = t('公司公告');
          break;
        case 1:
          color = t('重要信息');
          break;
        default:
          color = '';
          break;
      }
      return color;
    },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: null },
      { text: t('公司公告'), value: 0 },
      { text: t('重要信息'), value: 1 },
    ],
  },
  {
    title: t('标签'),
    minWidth: 150,
    dataIndex: 'tags',
    checked: true,
    resizable: true,
    cellComponent: 'list',
  },
  {
    title: t('内容'),
    width: 150,
    dataIndex: 'content',
    checked: false,
    resizable: true,
  },
  {
    title: t('已读数'),
    width: 80,
    dataIndex: 'read_num',
    checked: true,
    resizable: true,
    cellComponent: 'click',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'tagModal',
        is_read: true,
        title: t('已读人员'),
        record: {
          ...record,
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {};
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
  {
    title: t('未读数'),
    width: 80,
    dataIndex: 'no_read_num',
    checked: true,
    resizable: true,
    cellComponent: 'click',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'tagModal',
        is_read: false,
        title: t('未读人员'),
        record: {
          ...record,
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {};
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
  {
    title: 'PDF',
    width: 300,
    dataIndex: 'appendix_json_list',
    checked: true,
    cellComponent: 'click',
    cb: (record: any) => {
      if (record && record.appendix_json_list && record.appendix_json_list.length > 0 && record.appendix_json_list[0].file_url) {
        const url: string = 'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.appendix_json_list[0].file_url);
        window.open(url, '_blank');
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
    content: (record: any, text: any) => {
      if (record && record.appendix_json_list && record.appendix_json_list.length > 0) {
        return record.appendix_json_list[0].name;
      }
    },
  },
  {
    title: t('排序'),
    width: 150,
    dataIndex: 'sorted_index',
    checked: true,
    resizable: true,
  },
  {
    title: t('创建时间'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    resizable: true,
    content: (record: any, text: any) => getDateTime(text),
  },
  {
    title: t('更新时间'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    resizable: true,
    content: (record: any, text: any) => getDateTime(text),
  },
  {
    title: t('是否可用'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,
    resizable: true,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [{ text: t('全部'), value: '' }, { text: t('可用'), value: 'true' }, { text: t('禁用'), value: 'false' }],
  },
  {
    title: t('操作'),
    width: 200,
    dataIndex: 'action',
    fixed: 'right',
    checked: false,
    cellComponent: 'action',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  // slotName: 'is_active',
  field_true: '可用',
  field_false: '禁用',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_notice_list,
    addData: create_notice,
    updateData: update_notice,
    removeData: delete_notice,
    recoverData: recover_notice,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
