import {
  create_recuitment_company_person,
  delete_recuitment_company_person,
  query_recuitment_company_person_list,
  recover_recuitment_company_person,
  update_recuitment_company_person,
} from '@/api/baseinfo-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/baseinfo/baseinfo-recuitmentcompanyperson-config';

import { reactive } from 'vue';
import { getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'remoteselect',
      name: 'recuitment_company_id',
      label: '顾问公司',
      label_i18n: t('page.baseinfo.recuitmentcompanyperson.form.label.recuitment_company_id'),
      disabled: false,
      placeholder: '请选择顾问公司',
      placeholder_i18n: t(
        'page.baseinfo.recuitmentcompanyperson.form.placeholder.recuitment_company_id',
      ),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'company_name',
      valueKey: 'id',
      modalType: 'baseinfo-recuitmentcompany',
    },
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('page.baseinfo.recuitmentcompanyperson.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.baseinfo.recuitmentcompanyperson.form.placeholder.name'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    recuitment_company_id: null,
    name: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('page.baseinfo.recuitmentcompanyperson.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.baseinfo.recuitmentcompanyperson.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'contact_number',
      label: '联系方式',
      label_i18n: t('page.baseinfo.recuitmentcompanyperson.form.label.contact_number'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写联系方式',
      placeholder_i18n: t('page.baseinfo.recuitmentcompanyperson.form.placeholder.contact_number'),
    },
    {
      type: 'remoteselect',
      name: 'recuitment_company_ids',
      label: '顾问公司',
      label_i18n: t('page.baseinfo.recuitmentcompanyperson.form.label.recuitment_company_id'),
      disabled: false,
      placeholder: '请选择顾问公司',
      placeholder_i18n: t(
        'page.baseinfo.recuitmentcompanyperson.form.placeholder.recuitment_company_id',
      ),
      datasourceType: 'remote',
      mode: 'multiple',
      allowClear: true,
      labelKey: 'company_name',
      valueKey: 'id',
      modalType: 'baseinfo-recuitmentcompany',
    },
    {
      type: 'input',
      name: 'invoice_email_to',
      label: '请款单发送邮箱',
      label_i18n: t('page.baseinfo.recuitmentcompanyperson.form.label.invoice_email_to'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写请款单发送邮箱',
      placeholder_i18n: t(
        'page.baseinfo.recuitmentcompanyperson.form.placeholder.invoice_email_to',
      ),
    },
  ],
  rules: { name: [{ required: true, message: t('page.baseinfo.recuitmentcompanyperson.form.placeholder.name') }] },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    contact_number: null,
    recuitment_company_ids: null,
    invoice_email_to: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('page.baseinfo.recuitmentcompanyperson.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.baseinfo.recuitmentcompanyperson.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'contact_number',
      label: '联系方式',
      label_i18n: t('page.baseinfo.recuitmentcompanyperson.form.label.contact_number'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写联系方式',
      placeholder_i18n: t('page.baseinfo.recuitmentcompanyperson.form.placeholder.contact_number'),
    },
    {
      type: 'remoteselect',
      name: 'recuitment_company_id',
      label: '顾问公司',
      label_i18n: t('page.baseinfo.recuitmentcompanyperson.form.label.recuitment_company_id'),
      disabled: false,
      placeholder: '请选择顾问公司',
      placeholder_i18n: t(
        'page.baseinfo.recuitmentcompanyperson.form.placeholder.recuitment_company_id',
      ),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'company_name',
      valueKey: 'id',
      modalType: 'baseinfo-recuitmentcompany',
    },
    {
      type: 'input',
      name: 'invoice_email_to',
      label: '请款单发送邮箱',
      label_i18n: t('page.baseinfo.recuitmentcompanyperson.form.label.invoice_email_to'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写请款单发送邮箱',
      placeholder_i18n: t(
        'page.baseinfo.recuitmentcompanyperson.form.placeholder.invoice_email_to',
      ),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    contact_number: null,
    recuitment_company_id: null,
    invoice_email_to: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('顾问公司联系人管理'),
  addModalTitle: t('新建顾问公司联系人'),
  updateTitle: t('修改顾问公司联系人'),
  is_create: true, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    recuitment_company_person_id: 'id',
  },
  delete: {
    recuitment_company_person_id: 'id',
  },
  recover: {
    recuitment_company_person_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    recuitment_company_person_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.baseinfo.recuitmentcompanyperson.column.title.index'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('page.baseinfo.recuitmentcompanyperson.column.title.id'),
    width: 50,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.baseinfo.recuitmentcompanyperson.column.title.name'),
    width: 150,
    dataIndex: 'name',
    checked: true,
  },
  {
    title: t('page.baseinfo.recuitmentcompanyperson.column.title.contact_number'),
    width: 150,
    dataIndex: 'contact_number',
    checked: true,
  },
  {
    title: t('page.baseinfo.recuitmentcompanyperson.column.title.recuitment_company__company_name'),
    minWidth: 450,
    dataIndex: 'recuitment_companys',
    checked: true,
    content: (record: any) => {
      let str: any = '';
      if(record.recuitment_companys.length > 0){
        record.recuitment_companys.map((item: any, index: any) => {
          if(index == record.recuitment_companys.length - 1){
            str = str + item.company_name;
          }else{
            str = str + item.company_name + '，';
          }
        })
      }
      return str;
    }
  },
  {
    title: t('page.baseinfo.recuitmentcompanyperson.column.title.invoice_email_to'),
    width: 150,
    dataIndex: 'invoice_email_to',
    checked: true,
  },
  {
    title: t('page.baseinfo.recuitmentcompanyperson.column.title.create_time'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('page.baseinfo.recuitmentcompanyperson.column.title.update_time'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('page.baseinfo.recuitmentcompanyperson.column.title.is_active'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('page.baseinfo.recuitmentcompanyperson.column.title.action'),
    width: 120,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom1',
  slotName: 'recuitment_companys',
  content: (record: any) => {
    let str: any = '';
    if(record.recuitment_companys.length > 0){
      record.recuitment_companys.map((item: any, index: any) => {
        if(index == record.recuitment_companys.length - 1){
          str = str + item.company_name;
        }else{
          str = str + item.company_name + '，';
        }
      })
    }
    return str;
  }
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_recuitment_company_person_list,
    addData: create_recuitment_company_person,
    updateData: update_recuitment_company_person,
    removeData: delete_recuitment_company_person,
    recoverData: recover_recuitment_company_person,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
