import {
  create_todoevent,
  delete_todoevent,
  query_todoevent_list,
  recover_todoevent,
  update_todoevent,
} from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { btn_list, config, event_obj, head_btn_list, slotList } from './cerp-todoevent-config';

import { reactive } from 'vue';
import { useRoute } from 'vue-router';
import { getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '顾问',
      label_i18n: t('page.cerp.todoevent.form.label.adviser_id'),
      disabled: false,
      allowClear: true,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      placeholder_i18n: t('page.cerp.todoevent.form.placeholder.adviser_id'),
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: t('选择顾问'),
      modalType: 'cerp-adviser',
    },
    {
      type: 'input',
      name: 'q',
      label: '模糊搜索',
      label_i18n: t('page.cerp.todoevent.form.label.q'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写搜索内容',
      placeholder_i18n: t('page.cerp.todoevent.form.placeholder.q'),
    },

    {
      type: 'select',
      name: 'is_finished',
      label: '是否完成',
      label_i18n: t('page.cerp.todoevent.form.label.is_finished'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择是否完成',
      placeholder_i18n: t('page.cerp.todoevent.form.placeholder.is_finished'),
      datasource: 'is_finished',
    },
    {
      type: 'radio',
      name: 'is_asc',
      label: '优先级排序',
      label_i18n: t('page.cerp.todoevent.form.label.is_asc'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      datasource: 'is_asc',
    },
    {
      type: 'select',
      name: 'is_active',
      label: '状态',
      label_i18n: t('page.cerp.todoevent.form.label.is_active'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择状态',
      placeholder_i18n: t('page.cerp.todoevent.form.placeholder.is_active'),
      datasource: 'is_active',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    adviser_id: null,
    q: '',
    is_finished: false,
    is_asc: false,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: t('全部') },
    {
      value: True,
      name: t('可用'),
    },
    { value: False, name: t('禁用') },
  ],
  is_finished: [
    { value: None, name: t('全部') },
    {
      value: True,
      name: t('完成'),
    },
    { value: False, name: t('未完成') },
  ],
  is_asc: [
    {
      value: True,
      name: t('正序'),
    },
    { value: False, name: t('倒序') },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'title',
      label: '标题',
      label_i18n: t('page.cerp.todoevent.form.label.title'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写标题',
      placeholder_i18n: t('page.cerp.todoevent.form.placeholder.title'),
    },
    {
      type: 'input',
      name: 'content',
      label: '内容',
      label_i18n: t('page.cerp.todoevent.form.label.content'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写内容',
      placeholder_i18n: t('page.cerp.todoevent.form.placeholder.content'),
    },
    {
      type: 'radio',
      name: 'is_finished',
      label: '是否完成',
      label_i18n: t('page.cerp.todoevent.form.label.is_finished'),
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '顾问',
      label_i18n: t('page.cerp.todoevent.form.label.adviser_id'),
      disabled: false,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      placeholder_i18n: t('page.cerp.todoevent.form.placeholder.adviser_id'),
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: t('选择顾问'),
      modalType: 'cerp-adviser',
    },
    {
      type: 'datepicker',
      name: 'alarm_time',
      label: '提醒时间',
      label_i18n: '提醒时间',
      placeholder_i18n: '请填写提醒时间',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: true,
      valueFormat: 'YYYY-MM-DD HH:mm:00',
      placeholder: '请填写提醒时间',
    },
    {
      type: 'number',
      name: 'alarm_number',
      label: '提醒间隔天数',
      label_i18n: '提醒间隔天数',
      placeholder_i18n: '请填写提醒间隔天数',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: 0,
      placeholder: '请填写提醒间隔天数',
    },
    {
      type: 'remoteselect',
      name: 'todo_priority_id',
      label: '优先等级',
      label_i18n: '优先等级',
      placeholder_i18n: '请选择优先等级',
      disabled: false,
      placeholder: '请选择优先等级',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'name',
      valueKey: 'id',
      modalType: 'baseinfo.todoevent',
    },
  ],
  rules: {
    title: [{ required: true, message: '请填输入标题' }],
    content: [{ required: true, message: '请填输入内容' }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    title: null,
    content: null,
    is_finished: null,
    adviser_id: null,
    alarm_time: null,
    alarm_number: null,
    todo_priority_id: null,
  }),
};
const edit_model_config_options = {
  is_finished: [
    { value: True, name: t('完成') },
    { value: False, name: t('未完成') },
  ],
};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'title',
      label: '标题',
      label_i18n: t('page.cerp.todoevent.form.label.title'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写标题',
      placeholder_i18n: t('page.cerp.todoevent.form.placeholder.title'),
    },
    {
      type: 'input',
      name: 'content',
      label: '内容',
      label_i18n: t('page.cerp.todoevent.form.label.content'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写内容',
      placeholder_i18n: t('page.cerp.todoevent.form.placeholder.content'),
    },
    {
      type: 'radio',
      name: 'is_finished',
      label: '是否完成',
      label_i18n: t('page.cerp.todoevent.form.label.is_finished'),
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '顾问',
      label_i18n: t('page.cerp.todoevent.form.label.adviser_id'),
      disabled: false,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      placeholder_i18n: t('page.cerp.todoevent.form.placeholder.adviser_id'),
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: t('选择顾问'),
      modalType: 'cerp-adviser',
    },
    {
      type: 'datepicker',
      name: 'alarm_time',
      label: '提醒时间',
      label_i18n: '提醒时间',
      placeholder_i18n: '请填写提醒时间',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: true,
      valueFormat: 'YYYY-MM-DD HH:mm:00',
      placeholder: '请填写提醒时间',
    },
    {
      type: 'number',
      name: 'alarm_number',
      label: '提醒间隔天数',
      label_i18n: '提醒间隔天数',
      placeholder_i18n: '请填写提醒间隔天数',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: 0,
      placeholder: '请填写提醒间隔天数',
    },
    {
      type: 'remoteselect',
      name: 'todo_priority_id',
      label: '优先等级',
      label_i18n: '优先等级',
      placeholder_i18n: '请选择优先等级',
      disabled: false,
      placeholder: '请选择优先等级',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'name',
      valueKey: 'id',
      modalType: 'baseinfo.todoevent',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    title: null,
    content: null,
    is_finished: null,
    adviser_id: null,
    alarm_time: null,
    alarm_number: null,
    todo_priority_id: null,
  }),
};
const update_model_config_options = {
  is_finished: [
    { value: True, name: t('完成') },
    { value: False, name: t('未完成') },
  ],
};

const titleList = {
  title: t('待办事项管理'),
  addModalTitle: t('新建待办事项'),
  updateTitle: t('修改待办事项'),
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    todoevent_id: 'id',
  },
  delete: {
    todoevent_id: 'id',
  },
  recover: {
    todoevent_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    todoevent_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.cerp.todoevent.column.title.index'),
    dataIndex: 'index',
    width: 50,
  },

  {
    title: t('page.cerp.todoevent.column.title.title'),
    width: 150,
    dataIndex: 'title',
    checked: true,
  },
  {
    title: t('page.cerp.todoevent.column.title.content'),
    width: 150,
    dataIndex: 'content',
    checked: true,
  },
  {
    title: t('page.cerp.todoevent.column.title.adviser__full_name'),
    width: 150,
    dataIndex: 'adviser__full_name',
    checked: true,
    sorter: {
      multiple: 1,
    },
  },

  {
    title: t('page.cerp.todoevent.column.title.adviser__employee_number'),
    width: 150,
    dataIndex: 'adviser__employee_number',
    checked: true,
    sorter: {
      multiple: 1,
    },
  },
  {
    title: t('page.cerp.todoevent.column.title.person_name'),
    width: 150,
    dataIndex: 'person_name',
    checked: true,
    sorter: {
      multiple: 1,
    },
  },
  {
    title: t('page.cerp.todoevent.column.title.person_phone'),
    width: 150,
    dataIndex: 'person_phone',
    checked: true,
  },
  {
    title: t('page.cerp.todoevent.column.title.alarm_time'),
    width: 150,
    dataIndex: 'alarm_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.alarm_time);
    },
  },
  {
    title: t('page.cerp.todoevent.column.title.alarm_number'),
    width: 150,
    dataIndex: 'alarm_number',
    checked: true,
  },
  {
    title: t('page.cerp.todoevent.column.title.priority'),
    width: 150,
    dataIndex: 'todo_priority__name',
    checked: true,
    sorter: {
      multiple: 1,
    },
  },
  {
    title: t('page.cerp.todoevent.column.title.is_finished'),
    width: 150,
    dataIndex: 'is_finished',
    checked: true,
    cellComponent: 'boolean',
    name1: t('是'),
    name2: t('否'),
  },
  {
    title: t('page.cerp.todoevent.column.title.create_time'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    sorter: {
      multiple: 1,
    },
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('page.cerp.todoevent.column.title.update_time'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('page.cerp.todoevent.column.title.is_active'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,

    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('page.cerp.todoevent.column.title.action'),
    width: 200,
    checked: false,
    dataIndex: 'action',
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push();

export default () => {
  const route = useRoute();
  search_config.model = reactive({ ...search_config.model });
  search_config.model.ids = Number(route.query.ids);
  search_config.model.adviser_id = Number(route.query.adviser_id);
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_todoevent_list,
    addData: create_todoevent,
    updateData: update_todoevent,
    removeData: delete_todoevent,
    recoverData: recover_todoevent,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
