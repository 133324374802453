import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';

import { update_recuitment_company } from '@/api/baseinfo-model';
import { message } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import { reactive } from 'vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';

export const event_obj = mitt();
export const btn_list = [
  {
    name: '修改',
    clazz: 'primary',
    type: 'dropdown',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const list: any = [];
      data.bank_account_list.map((item: any) => {
        list.push(item.id);
      });
      const editModal = reactive({
        visible: true,
        title: t('修改顾问公司'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'client_id',
              label: '顾问公司ID',
              label_i18n: t('顾问公司ID'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写顾问公司ID',
              placeholder_i18n: t('请填写顾问公司ID'),
            },
            {
              type: 'input',
              name: 'company_name',
              label: '顾问公司名',
              label_i18n: t('顾问公司名'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写顾问公司名',
              placeholder_i18n: t('请填写顾问公司名'),
            },
            {
              type: 'input',
              name: 'address',
              label: '顾问公司地址',
              label_i18n: t('顾问公司地址'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写顾问公司地址',
              placeholder_i18n: t('请填写顾问公司地址'),
            },
            {
              type: 'input',
              name: 'contact_number',
              label: '顾问公司联系方式',
              label_i18n: t('顾问公司联系方式'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写顾问公司联系方式',
              placeholder_i18n: t('请填写顾问公司联系方式'),
            },
            {
              type: 'input',
              name: 'vat_no',
              label: 'VATNo',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: 'VATNo',
            },
            {
              type: 'remoteselect',
              name: 'bank_account_ids',
              label: 'CC银行账户',
              label_i18n: t('CC银行账户'),
              disabled: false,
              placeholder: '请选择CC银行账户',
              placeholder_i18n: t('请选择CC银行账户'),
              datasourceType: 'remote',
              mode: 'multiple',
              allowClear: true,
              labelKey: 'account_flag',
              valueKey: 'id',
              modalType: 'baseinfo-ccbankaccountscc',
            },
          ],
          rules: {},
          model: reactive({
            org_id: current_org?.id,
            client_id: data.client_id,
            company_name: data.company_name,
            address: data.address,
            contact_number: data.contact_number,
            vat_no: data.vat_no,
            bank_account_ids: list,
            recuitment_company_id: data.id,
          }),
          watch: {},
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            update_recuitment_company(payload)
              .then(() => {
                message.success(t('修改成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        params: {
          object_id: record.id,
          note_type_flag: 'baseinfo.recuitmentcompany',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
