import { TableColumn } from '@/typing';
import { slotList } from '@/components/page-model/service/service-service-config';
import { getDateTime } from '@/utils/function';
import { reactive, ref } from 'vue';
import modalBox from '@/components/form-modal/modal-tools';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
export default (current_org: any) => {
  const None = null;
  const True = true;
  const False = false;
  const baseColumns: TableColumn[] = [
    {
      title: t('序号'),
      dataIndex: 'index',
      width: 50,
    },
    {
      title: 'ID',
      width: 150,
      dataIndex: 'id',
      checked: false,
    },
    {
      title: t('名称'),
      minWidth: 200,
      dataIndex: 'name',
      checked: true,
    },
    {
      title: t('邮箱模板'),
      width: 200,
      dataIndex: 'email_template__name',
      checked: true,
    },
    {
      title: t('查询表类型'),
      width: 150,
      dataIndex: 'modal_type',
      checked: true,
      cellComponent: 'tag',
      content: (record: any) => {
        let content = '';
        switch (record.modal_type) {
          case 'cerp-adviser':
            content = t('顾问');
            break;
          case 'cerp-todoevent':
            content = t('待办');
            break;
          case 'cerp-contract':
            content = t('合同');
            break;
          case 'cerp-insurance':
            content = t('保险');
            break;
          case 'cerp-wechatupdaterecord':
            content = t('微信小程序');
            break;
          case 'cerp-invoice':
            content = t('请款单');
            break;
          default:
            content = '';
            break;
        }
        return content;
      },
    },
    {
      title: t('排序'),
      width: 60,
      dataIndex: 'sorted_index',
      checked: true,
    },
    {
      title: t('自动发送邮件频率（天）'),
      width: 200,
      dataIndex: 'tip_day',
      checked: true,
    },
    {
      title: t('是否定时发送邮件'),
      width: 150,
      dataIndex: 'is_send',
      checked: true,
      cellComponent: 'boolean',
      name1: t('是'),
      name2: t('否'),
    },
    {
      title: t('邮件发送时间'),
      width: 150,
      dataIndex: 'email_send_time',
      checked: true,
      content: (record: any) => {
        return getDateTime(record.email_send_time);
      },
    },
    {
      title: t('邮件发送记录'),
      width: 150,
      dataIndex: 'email_json',
      checked: true,
      cellComponent: 'click',
      content: (record: any, text: any) => {
        let str = '';
        if (text && text.err_list && text.err_list.length > 0) {
          str = str + t('发送失败') + text.err_list.length + '条';
        }
        if (text && text.ok_list && text.ok_list.length > 0) {
          str = str + t('发送成功') + text.ok_list.length + '条';
        }
        return str;
      },
      cb: (record: any, text: any) => {
        const loading = ref(false);
        const editModal = reactive({
          visible: true,
          title: t('邮件发送记录'),
          component: 'ListModal',
          loading: loading,
          dataSource: text.err_list,
        });
        modalBox(editModal);
      },
    },
    {
      title: t('创建时间'),
      width: 150,
      dataIndex: 'create_time',
      checked: true,
      content: (record: any) => {
        return getDateTime(record.create_time);
      },
    },
    {
      title: t('更新时间'),
      width: 150,
      dataIndex: 'update_time',
      checked: true,
      content: (record: any) => {
        return getDateTime(record.update_time);
      },
    },
    {
      title: t('是否可用'),
      width: 150,
      dataIndex: 'is_active',
      checked: true,
      cellComponent: 'boolean',
      name1: t('可用'),
      name2: t('禁用'),
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('可用'), value: true },
        { text: t('禁用'), value: false },
      ],
    },

    {
      title: t('操作'),
      width: 150,
      dataIndex: 'action',
      checked: true,
      cellComponent: 'action',
      fixed: 'right',
    },
  ];

  const newSlotList: any = [];
  Object.assign(newSlotList, slotList);
  const modal_type_slot: any = { type: 'custom', slotName: 'modal_type' };
  modal_type_slot['field_cerp-adviser'] = '顾问';
  modal_type_slot['field_cerp-todoevent'] = '待办';
  modal_type_slot['field_cerp-contract'] = '合同';
  modal_type_slot['field_cerp-insurance'] = '保险';
  modal_type_slot['field_cerp-wechatupdaterecord'] = '微信小程序';
  modal_type_slot['field_cerp-invoice'] = '请款单';
  newSlotList.push(modal_type_slot);

  return {
    baseColumns: baseColumns,
    newSlotList: newSlotList,
  };
};
