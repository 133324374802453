import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import { message } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import { reactive } from 'vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { update_recuitment_company_person } from '@/api/baseinfo-model';

export const event_obj = mitt();
export const btn_list = [
  {
    name: '修改',
    clazz: 'primary',
    type: 'dropdown',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const list: any = [];
      data.recuitment_companys.map((item: any) => {
        list.push(item.id);
      });
      const editModal = reactive({
        visible: true,
        title: t('修改顾问公司联系人'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'name',
              label: '名称',
              label_i18n: t('名称'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写名称',
              placeholder_i18n: t('请填写名称'),
            },
            {
              type: 'input',
              name: 'contact_number',
              label: '联系方式',
              label_i18n: t('联系方式'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写联系方式',
              placeholder_i18n: t('请填写联系方式'),
            },
            {
              type: 'remoteselect',
              name: 'recuitment_company_ids',
              label: '顾问公司',
              label_i18n: t('顾问公司'),
              disabled: false,
              placeholder: '请选择顾问公司',
              placeholder_i18n: t('请选择顾问公司'),
              datasourceType: 'remote',
              mode: 'multiple',
              allowClear: true,
              labelKey: 'company_name',
              valueKey: 'id',
              modalType: 'baseinfo-recuitmentcompany',
            },
            {
              type: 'input',
              name: 'invoice_email_to',
              label: '请款单发送邮箱',
              label_i18n: t('请款单发送邮箱'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写请款单发送邮箱',
              placeholder_i18n: t('请填写请款单发送邮箱'),
            },
          ],
          rules: {},
          model: reactive({
            org_id: current_org?.id,
            name: data.name,
            contact_number: data.contact_number,
            invoice_email_to: data.invoice_email_to,
            recuitment_company_ids: list,
            recuitment_company_person_id: data.id,
          }),
          watch: {},
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            update_recuitment_company_person(payload)
              .then(() => {
                message.success(t('修改成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        params: {
          object_id: record.id,
          note_type_flag: 'baseinfo.recuitmentcompanyperson',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
