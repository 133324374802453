import {
  create_contract_template,
  delete_contract_template,
  query_contract_template_list,
  recover_contract_template,
  update_contract_template,
} from '@/api/baseinfo-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/baseinfo/baseinfo-contracttemplate-config';

import { reactive } from 'vue';
import { notification } from 'ant-design-vue';
import { getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    // {
    //   type: 'input',
    //   name: 'search_text',
    //   label: '模糊搜索',
    //   label_i18n: t('page.baseinfo.contracttemplate.form.label.search_text'),
    //   disabled: false,
    //   allowClear: true,
    //   inputType: 'text',
    //   defaultValue: '',
    //   placeholder: '请填写搜索内容',
    //   placeholder_i18n: t('page.baseinfo.contracttemplate.form.placeholder.search_text'),
    // },
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('page.baseinfo.contracttemplate.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.baseinfo.contracttemplate.form.placeholder.name'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    // search_text: '',
    name: null,
  }),
};
const search_options = {};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('page.baseinfo.contracttemplate.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.baseinfo.contracttemplate.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'description',
      label: '描述',
      label_i18n: t('page.baseinfo.contracttemplate.form.label.description'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写描述',
      placeholder_i18n: t('page.baseinfo.contracttemplate.form.placeholder.description'),
    },
  ],
  rules: {
    name: [{ required: true, message: t('page.baseinfo.contracttemplate.form.placeholder.name') }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    description: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('page.baseinfo.contracttemplate.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.baseinfo.contracttemplate.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'description',
      label: '描述',
      label_i18n: t('page.baseinfo.contracttemplate.form.label.description'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写描述',
      placeholder_i18n: t('page.baseinfo.contracttemplate.form.placeholder.description'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    description: null,
    template_id: null,
    business_id: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('合同模板'),
  addModalTitle: t('新建'),
  updateTitle: t('修改合同模板'),
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    contracttemplate_id: 'id',
  },
  delete: {
    contracttemplate_id: 'id',
  },
  recover: {
    contracttemplate_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    contracttemplate_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.baseinfo.contracttemplate.column.title.index'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('page.baseinfo.contracttemplate.column.title.id'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.baseinfo.contracttemplate.column.title.name'),
    width: 150,
    dataIndex: 'name',
    checked: true,
  },
  {
    title: t('page.baseinfo.contracttemplate.column.title.description'),
    minWidth: 150,
    dataIndex: 'description',
    checked: true,
  },
  // {
  //   title: t('page.baseinfo.contracttemplate.column.title.template_id'),
  //   width: 150,
  //   dataIndex: 'template_id',
  //   checked: true,
  // },
  // {
  //   title: t('page.baseinfo.contracttemplate.column.title.business_id'),
  //   width: 150,
  //   dataIndex: 'business_id',
  //   checked: true,
  // },
  {
    title: t('page.baseinfo.contracttemplate.column.title.modal'),
    minWidth: 200,
    dataIndex: 'modal',
    checked: true,
    cellComponent: 'click',
    content: (record: any) => {
      if (record.files_json && record.files_json.name) {
        return record.files_json.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.files_json && record.files_json.file_url) {
        const url: string = 'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.files_json.file_url);
        window.open(url, '_blank');
        // axios.get('/' + record.files_json.file_url, {
        //   responseType: 'blob'
        // }).then((res: any) => {
        //   var blob = res.data;
        //   // FileReader主要用于将文件内容读入内存
        //   var reader = new FileReader();
        //   reader.readAsDataURL(blob);
        //   // onload当读取操作成功完成时调用
        //   reader.onload = (e: any) => {
        //     var a = document.createElement('a');
        //     // 获取文件名fileName
        //     var fileName = res.config["url"].split("/");
        //     fileName = fileName[fileName.length - 1];
        //     // fileName = fileName.replace(/"/g, "");
        //     a.download = fileName;
        //     a.href = e.target.result;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        //   }
        // })
      } else {
        notification.error({
          message: t('提示'),
          description: t('未上传合同模板'),
        });
      }
    }
  },
  {
    title: t('page.baseinfo.contracttemplate.column.title.create_time'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('page.baseinfo.contracttemplate.column.title.update_time'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('page.baseinfo.contracttemplate.column.title.is_active'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },
  {
    title: t('page.baseinfo.contracttemplate.column.title.action'),
    width: 120,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'click',
  slotName: 'modal',
  content: (record: any) => {
    if (record.files_json && record.files_json.name) {
      return record.files_json.name;
    } else {
      return '';
    }
  },
  cb: (record: any) => {
    if (record.files_json && record.files_json.file_url) {
      const url: string = 'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.files_json.file_url);
      window.open(url, '_blank');
      // axios.get('/' + record.files_json.file_url,{
      //   responseType: 'blob' }).then((res: any) => {
      //   var blob = res.data;
      //   // FileReader主要用于将文件内容读入内存
      //   var reader = new FileReader();
      //   reader.readAsDataURL(blob);
      //   // onload当读取操作成功完成时调用
      //   reader.onload = (e: any) => {
      //     var a = document.createElement('a');
      //     // 获取文件名fileName
      //     var fileName = res.config["url"].split("/");
      //     fileName = fileName[fileName.length - 1];
      //     // fileName = fileName.replace(/"/g, "");
      //     a.download = fileName;
      //     a.href = e.target.result;
      //     document.body.appendChild(a);
      //     a.click();
      //     document.body.removeChild(a);
      //   }
      // })
    } else {
      notification.error({
        message: t('提示'),
        description: '未上传合同模板',
      });
    }
  },
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_contract_template_list,
    addData: create_contract_template,
    updateData: update_contract_template,
    removeData: delete_contract_template,
    recoverData: recover_contract_template,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
