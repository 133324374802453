import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';

import modalBox from '@/components/form-modal/modal-tools';
import {reactive, ref} from 'vue';
import ls from "@/utils/local-storage";
import {STORAGE_CURRENT_ORG_KEY} from "@/store/mutation-type";
import {message, notification} from "ant-design-vue";
import {create_tip, update_contract_template, update_process_tip, update_tip} from "@/api/baseinfo-model";
export const event_obj = mitt();
export const btn_list = [];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
