import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import { reactive } from 'vue';
import { create_bank_account } from '@/api/cerp-model';
import { message } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { query_bank_manage_list } from '@/api/baseinfo-model';

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

export const event_obj = mitt();
export const btn_list = [
  {
    name: '新增银行账户',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const editModal = reactive({
        visible: true,
        title: t('新增银行账户'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [7, 6],
          },
          fields: [
            {
              type: 'input',
              name: 'company_name',
              label: '保险公司',
              label_i18n: t('保险公司'),
              disabled: true,
              placeholder: '请选择保险公司',
              placeholder_i18n: t('请选择保险公司'),
              datasourceType: 'remote',
              allowClear: false,
            },
            {
              type: 'remoteselect',
              name: 'bank_name1',
              label: '预设银行名称(非必选)',
              label_i18n: t('预设银行名称(非必选)'),
              disabled: false,
              placeholder: '请选择预设银行名称',
              placeholder_i18n: t('请选择预设银行名称'),
              datasourceType: 'remote',
              show_search: true,
              mode: 'default',
              allowClear: true,
              labelKey: 'english_name',
              valueKey: 'id',
              modalType: 'baseinfo-bankmanage',
            },
            {
              type: 'input',
              name: 'bank_name',
              label: '银行名称',
              label_i18n: t('银行名称'),
              disabled: false,
              placeholder: '请选择银行名称',
              placeholder_i18n: t('请选择银行名称'),
              datasourceType: 'remote',
              allowClear: false,
            },
            {
              type: 'input',
              name: 'english_name',
              label: '银行英文名称',
              label_i18n: t('银行英文名称'),
              disabled: false,
              placeholder: '请填输入银行名称',
              placeholder_i18n: t('请填输入银行名称'),
              datasourceType: 'remote',
              allowClear: false,
            },
            {
              type: 'input',
              name: 'bic_or_swift_code',
              label: 'bic_or_swift_code',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写bic_or_swift_code',
            },
            {
              type: 'input',
              name: 'account_holder',
              label: '账户持有人',
              label_i18n: t('账户持有人'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写账户持有人',
              placeholder_i18n: t('请填写账户持有人'),
            },
            {
              type: 'textarea',
              name: 'note',
              label: '备注',
              label_i18n: t('备注'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 200,
              defaultValue: '',
              placeholder: '请填写备注',
              placeholder_i18n: t('请填写备注'),
            },
            {
              type: 'input',
              name: 'account_number',
              label: '账号',
              label_i18n: t('账号'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写账号',
              placeholder_i18n: t('请填写账号'),
            },
            {
              type: 'input',
              name: 'bank_address',
              label: '开户行地址',
              label_i18n: t('开户行地址'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写开户行地址',
              placeholder_i18n: t('请填写开户行地址'),
            },
            {
              type: 'input',
              name: 'iban',
              label: 'iban',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: 'iban',
            },
            {
              type: 'input',
              name: 'sort_code_or_branch_code',
              label: 'sort_code_or_branch_code',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: 'sort_code_or_branch_code',
            },
            {
              type: 'input',
              name: 'cnaps',
              label: 'cnaps',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: 'cnaps',
            },
            {
              type: 'radio',
              name: 'is_current',
              label: '是否当前账号',
              label_i18n: t('是否当前账号'),
              disabled: false,
              allowClear: true,
              labelKey: 'name',
              valueKey: 'value',
              defaultValue: '',
            },
          ],
          rules: {
            bank_name: [{ required: true, message: t('请填输入银行名称') }],
            account_holder: [{ required: true, message: t('请填写账户持有人') }],
            account_number: [{ required: true, message: t('请填写账号') }],
            bank_address: [{ required: true, message: t('请填写开户行地址') }],
            // iban: [{ required: true, message: '请填输入iban' }],
            // bic_or_swift_code: [{ required: true, message: '请填输入bic_or_swift_code' }],
            // sort_code_or_branch_code: [
            //   { required: true, message: '请填输入sort_code_or_branch_code' },
            // ],
            // cnaps: [{ required: true, message: '请填输入cnaps' }],
          },
          model: reactive({
            org_id: current_org?.id,
            insurance_company_id: Number(data.id),
            company_name: data.company_name,
            is_active: true,
            bank_name: null,
            account_holder: null,
            account_number: null,
            bank_address: null,
            iban: null,
            note: null,
            kind: 1,
            bic_or_swift_code: null,
            sort_code_or_branch_code: null,
            cnaps: null,
            is_current: null,
          }),
          watch: {
            bank_name1: (data: any) => {
              query_bank_manage_list({
                org_id: current_org?.id,
                ids: [data.bank_name1].join(','),
              }).then((res: any) => {
                data['bank_name'] = res.data[0].name;
                data['english_name'] = res.data[0].english_name;
                data['bic_or_swift_code'] = res.data[0].swift_code;
              });
            },
          },
        },
        options: {
          is_current: [
            { value: true, name: t('是') },
            { value: false, name: t('否') },
          ],
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            create_bank_account(payload)
              .then(() => {
                message.success(t('添加成功'));
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        params: {
          object_id: record.id,
          note_type_flag: 'baseinfo.insurancecompany',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
