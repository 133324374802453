import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { reactive, ref } from 'vue';
import { import_todoevent } from '@/api/cerp-model';
import { message } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';

export const event_obj = mitt();
export const btn_list = [
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        params: {
          object_id: record.id,
          note_type_flag: 'cerp.remittance',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list = [
  {
    name: '待办事项批量导入',
    clazz: 'primary',
    cb: (record: any, dataSource: any, selectedRowKeys: any, searchConfigOptions: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const loading = ref(false);
      let n: any = 0;
      const editModal = reactive({
        visible: true,
        title: t('待办事项批量导入'),
        group: '待办事项',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;

            import_todoevent({
              nsfile_id: payload[0],
              org_id: current_org?.id,
            })
              .then((res: any) => {
                if(res && res.err_list && res.err_list.length > 0){
                  res.err_list.map((item: any) => {
                    item.id = n++;
                  })
                }
                if(res && res.ok_list && res.ok_list.length > 0){
                  res.ok_list.map((item: any) => {
                    item.id = n++;
                  })
                }
                console.log('res',res)
                const editModal1 = reactive({
                  visible: true,
                  title: t('待办事项批量导入预览'),
                  component: 'TodoeventModal',
                  column: [
                    {
                      title: t('顾问'),
                      width: 150,
                      dataIndex: 'adviser',
                      checked: true,
                    },
                    {
                      title: t('标题'),
                      width: 300,
                      dataIndex: 'title',
                      checked: true,
                    },
                    {
                      title: t('内容'),
                      width: 150,
                      dataIndex: 'content',
                      checked: true,
                    },
                    {
                      title: t('提醒时间'),
                      width: 120,
                      dataIndex: 'alarm_time',
                      checked: true,
                    },
                    {
                      title: t('间隔天数'),
                      width: 80,
                      dataIndex: 'alarm_number',
                      checked: true,
                    },
                    {
                      title: t('优先等级'),
                      width: 80,
                      dataIndex: 'todo_priority',
                      checked: true,
                    },
                  ],
                  flag: 'todoevent',
                  obj: res,
                  ok: (payload: any) => {
                    return new Promise(resolve1 => {
                      message.success(t('添加成功'));
                      event_obj.emit('refresh');
                          resolve1(null);
                      resolve(null);

                    });
                  },
                });
                modalBox(editModal1);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
];
