import {
  create_country,
  delete_country,
  query_country_list,
  recover_country,
  update_country,
} from '@/api/baseinfo-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/baseinfo/baseinfo-country-config';

import { reactive } from 'vue';
import { getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'code',
      label: '国家代码',
      label_i18n: t('page.baseinfo.country.form.label.code'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写国家代码',
      placeholder_i18n: t('page.baseinfo.country.form.placeholder.code'),
    },
    {
      type: 'input',
      name: 'name',
      label: '国家名称',
      label_i18n: t('page.baseinfo.country.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写国家名称',
      placeholder_i18n: t('page.baseinfo.country.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'currency',
      label: '币种',
      label_i18n: t('page.baseinfo.country.form.label.currency'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写币种',
      placeholder_i18n: t('page.baseinfo.country.form.placeholder.currency'),
    },
    {
      type: 'input',
      name: 'nationality',
      label: '国籍',
      label_i18n: t('page.baseinfo.country.form.label.nationality'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写国籍',
      placeholder_i18n: t('page.baseinfo.country.form.placeholder.nationality'),
    },
    {
      type: 'select',
      name: 'is_select',
      label: '只查询有币种的',
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择是否可用',
      datasource: 'is_select',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_select: false,
    code: null,
    name: null,
    currency: null,
    nationality: null,
  }),
};
const search_options = {
  is_select: [
    { value: True, name: t('是') },
    { value: False, name: t('否') },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '国家名称',
      label_i18n: t('page.baseinfo.country.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写国家名称',
      placeholder_i18n: t('page.baseinfo.country.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'code',
      label: '国家代码',
      label_i18n: t('page.baseinfo.country.form.label.code'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写国家代码',
      placeholder_i18n: t('page.baseinfo.country.form.placeholder.code'),
    },
    {
      type: 'input',
      name: 'currency',
      label: '币种',
      label_i18n: t('page.baseinfo.country.form.label.currency'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写币种',
      placeholder_i18n: t('page.baseinfo.country.form.placeholder.currency'),
    },
    {
      type: 'input',
      name: 'nationality',
      label: '国籍',
      label_i18n: t('page.baseinfo.country.form.label.nationality'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写国籍',
      placeholder_i18n: t('page.baseinfo.country.form.placeholder.nationality'),
    },
    {
      type: 'input',
      name: 'symbol',
      label: '符号',
      label_i18n: t('page.baseinfo.country.form.label.symbol'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写符号',
      placeholder_i18n: t('page.baseinfo.country.form.placeholder.symbol'),
    },
    {
      type: 'input',
      name: 'sort',
      label: '排序',
      label_i18n: t('page.baseinfo.country.form.label.sort'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写顺序号',
      placeholder_i18n: t('page.baseinfo.country.form.placeholder.sort'),
    },
  ],
  rules: {
    name: [{ required: true, message: t('page.baseinfo.country.form.placeholder.name') }],
    code: [{ required: true, message: t('page.baseinfo.country.form.placeholder.code') }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    code: null,
    currency: null,
    nationality: null,
    symbol: null,
    sort: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '国家名称',
      label_i18n: t('page.baseinfo.country.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写国家名称',
      placeholder_i18n: t('page.baseinfo.country.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'code',
      label: '国家代码',
      label_i18n: t('page.baseinfo.country.form.label.code'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写国家代码',
      placeholder_i18n: t('page.baseinfo.country.form.placeholder.code'),
    },
    {
      type: 'input',
      name: 'currency',
      label: '币种',
      label_i18n: t('page.baseinfo.country.form.label.currency'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写币种',
      placeholder_i18n: t('page.baseinfo.country.form.placeholder.currency'),
    },
    {
      type: 'input',
      name: 'nationality',
      label: '国籍',
      label_i18n: t('page.baseinfo.country.form.label.nationality'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写国籍',
      placeholder_i18n: t('page.baseinfo.country.form.placeholder.nationality'),
    },
    {
      type: 'input',
      name: 'symbol',
      label: '符号',
      label_i18n: t('page.baseinfo.country.form.label.symbol'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写符号',
      placeholder_i18n: t('page.baseinfo.country.form.placeholder.symbol'),
    },
    {
      type: 'input',
      name: 'sort',
      label: '排序',
      label_i18n: t('page.baseinfo.country.form.label.sort'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写顺序号',
      placeholder_i18n: t('page.baseinfo.country.form.placeholder.sort'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    code: null,
    currency: null,
    nationality: null,
    symbol: null,
    sort: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('国家管理'),
  addModalTitle: t('新建国家'),
  updateTitle: t('修改国家'),
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    country_id: 'id',
  },
  delete: {
    country_id: 'id',
  },
  recover: {
    country_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    country_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.baseinfo.country.column.title.index'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('page.baseinfo.country.column.title.id'),
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.baseinfo.country.column.title.code'),
    dataIndex: 'code',
    checked: true,
  },
  {
    title: t('page.baseinfo.country.column.title.name'),
    dataIndex: 'name',
    checked: true,
  },
  {
    title: t('page.baseinfo.country.column.title.currency'),
    dataIndex: 'currency',
    checked: true,
  },
  {
    title: t('page.baseinfo.country.column.title.nationality'),
    dataIndex: 'nationality',
    checked: true,
  },
  {
    title: t('page.baseinfo.country.column.title.symbol'),
    dataIndex: 'symbol',
    checked: true,
  },
  {
    title: t('page.baseinfo.country.column.title.sort'),
    dataIndex: 'sort',
    checked: true,
  },
  {
    title: t('page.baseinfo.country.column.title.create_time'),
    dataIndex: 'create_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('page.baseinfo.country.column.title.update_time'),
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('page.baseinfo.country.column.title.is_active'),
    dataIndex: 'is_active',
    checked: true,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('page.baseinfo.country.column.title.action'),
    width: 120,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  // slotName: 'is_active',
  field_true: '可用',
  field_false: '禁用',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_country_list,
    addData: create_country,
    updateData: update_country,
    removeData: delete_country,
    recoverData: recover_country,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
