import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';
export const event_obj = mitt();
export const btn_list = [];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list = [];
