import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import { reactive } from 'vue';
import { importApiInfoByJsonFile } from '@/api/api-doc/apiinfo';
import { notification } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import defaultSlotList from '@/components/page-model/slotList';

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

export const event_obj = mitt();
export const btn_list = null;
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [
  {
    name: '导入接口JSON',
    clazz: 'primary',
    cb: () => {
      const editModal = reactive({
        visible: true,
        title: '导入接口JSON',
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
          },
          fields: [
            {
              type: 'remoteselect',
              name: 'app_id',
              label: '应用',
              disabled: false,
              labelKey: 'name',
              valueKey: 'id',
              placeholder: '请选应用',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'apidoc-app',
            },
            {
              type: 'remoteselect',
              name: 'project_id',
              label: '隶属Git项目',
              disabled: false,
              labelKey: 'name',
              valueKey: 'id',
              placeholder: '请选隶属Git项目',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'apidoc-gitproject',
            },
            {
              type: 'remoteselect',
              name: 'git_branch_id',
              label: '隶属Git分支',
              disabled: false,
              labelKey: 'name',
              valueKey: 'id',
              placeholder: '请选隶属的Git分支',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'apidoc-gitbranch',
            },
            {
              type: 'textarea',
              name: 'data',
              label: 'JSON数据',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 100,
              defaultValue: '',
              placeholder: '请填写JSON数据',
            },
          ],
          rules: {
            app_id: [{ required: true, message: '请选应用' }],
            git_branch_id: [{ required: true, message: '请选隶属的Git分支' }],
            data: [{ required: true, message: '请填写JSON数据' }],
          },
          model: {
            org_id: current_org?.id,
            is_active: true,
          },
        },
        options: {},
        ok: (data: any) => {
          return new Promise(resolve => {
            importApiInfoByJsonFile({ ...data, org_id: current_org?.id }).then((res: any) => {
              if (res.success) {
                event_obj.emit('reset_and_reload', {
                  app_id: data.app_id,
                  git_branch_id: data.git_branch_id,
                });
                notification.success({
                  message: '成功',
                  description: 'api接口信息导入成功',
                });
                resolve(null);
              }
            });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '导出接口TypeScript',
    clazz: 'primary',
    cb: () => {
      const editModal = reactive({
        visible: true,
        title: '导出接口TypeScript',
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
          },
          fields: [
            {
              type: 'remoteselect',
              name: 'app_id',
              label: '应用',
              disabled: false,
              labelKey: 'name',
              valueKey: 'id',
              placeholder: '请选应用',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'apidoc-app',
            },
            {
              type: 'remoteselect',
              name: 'project_id',
              label: '隶属Git项目',
              disabled: false,
              labelKey: 'name',
              valueKey: 'id',
              placeholder: '请选隶属Git项目',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'apidoc-gitproject',
            },
            {
              type: 'remoteselect',
              name: 'git_branch_id',
              label: '隶属Git分支',
              disabled: false,
              labelKey: 'name',
              valueKey: 'id',
              placeholder: '请选隶属的Git分支',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'apidoc-gitbranch',
            },
          ],
          rules: {
            app_id: [{ required: true, message: '请选应用' }],
            project_id: [{ required: true, message: '请选隶属的Git项目' }],
            git_branch_id: [{ required: true, message: '请选隶属的Git分支' }],
          },
          model: {
            org_id: current_org?.id,
            is_active: true,
          },
        },
        options: {},
        ok: (data: any) => {
          const url: string =
            'http://ccerp.8mu.com.cn/apidoc/export_api_info_by_type_script?app_id=' +
            data.app_id +
            '&git_branch_id=' +
            data.git_branch_id;
          window.open(url, '_blank');
        },
      });
      modalBox(editModal);
    },
  },
];
