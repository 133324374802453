import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { create_person_to_company, update_person_to_company } from '@/api/baseinfo-model';
import modalBox from '@/components/form-modal/modal-tools';
import { reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import { query_person_for_cmes_list } from '@/api/sys-model';

export const event_obj = mitt();
export const btn_list = [
  {
    name: '修改',
    clazz: 'primary',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const list: any = [];
      data.cc_companys.map((item: any) => {
        list.push(item.id);
      });
      const editModal = reactive({
        visible: true,
        title: t('修改CC用户权限'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'remoteselect',
              name: 'user_id',
              label: 'CC用户',
              label_i18n: 'CC用户',
              placeholder_i18n: '请选择CC用户',
              disabled: true,
              placeholder: '请选择CC用户',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'realname',
              valueKey: 'user_id',
              modalType: 'sys-query_person_for_cmes_list',
            },
            {
              type: 'remoteselect',
              name: 'cc_company_ids',
              label: 'CC公司',
              label_i18n: 'CC公司',
              placeholder_i18n: '请选择CC公司',
              disabled: false,
              placeholder: '请选择CC公司',
              datasourceType: 'remote',
              mode: 'multiple',
              allowClear: true,
              modalType: 'baseinfo-cccompany',
            },
          ],
          rules: {},
          model: reactive({
            org_id: current_org?.id,
            person_to_adviser_id: data.id,
            user_id: data.user_id,
            cc_company_ids: list,
          }),
          watch: {},
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            update_person_to_company(payload)
              .then(() => {
                message.success(t('修改成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list = [
  {
    name: '创建',
    clazz: 'primary',
    cb: (record: any, dataSource: any, selectedRowKeys: any, searchConfigOptions: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const name = ref('');
      const editModal = reactive({
        visible: true,
        title: t('创建CC用户权限'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'remoteselect',
              name: 'id',
              label: 'CC用户',
              label_i18n: 'CC用户',
              placeholder_i18n: '请选择CC用户',
              disabled: false,
              placeholder: '请选择CC用户',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'realname',
              valueKey: 'id',
              modalType: 'sys-query_person_for_cmes_list',
            },
            {
              type: 'remoteselect',
              name: 'cc_company_ids',
              label: 'CC公司',
              label_i18n: 'CC公司',
              placeholder_i18n: '请选择CC公司',
              disabled: false,
              placeholder: '请选择CC公司',
              datasourceType: 'remote',
              mode: 'multiple',
              allowClear: true,
              modalType: 'baseinfo-cccompany',
            },
          ],
          rules: {},
          model: reactive({
            org_id: current_org?.id,
            id: null,
            user_id: null,
            cc_company_ids: null,
          }),
          watch: {
            id: (data1: any) => {
              if (data1.id) {
                query_person_for_cmes_list({
                  org_id: current_org?.id,
                  ids: data1.id,
                }).then((res: any) => {
                  if (res && res.data && res.data.length > 0) {
                    name.value = res.data[0].realname;
                    data1.user_id = res.data[0].user_id;
                  }
                });
              }
            },
          },
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            create_person_to_company({
              ...payload,
              user_name: name.value,
            })
              .then(() => {
                message.success(t('创建成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
];
