import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import {
  cancel_pay_payslip,
  confirm_payslip,
  create_bank_transaction,
  create_payslip_pdf,
  delete_payslip,
  modify_payslip_mangefee,
  pay_payslip,
  query_note_list,
  recover_payslip,
  send_payslip_by_email,
} from '@/api/cerp-model';
import { message, Modal } from 'ant-design-vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { createVNode, reactive } from 'vue';
import modalBox from '@/components/form-modal/modal-tools';
import table_info_modal from '@/components/info-modal/table_info_modal';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

export const event_obj = mitt();
export const btn_list = [
  {
    name: '查看备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY);
      query_note_list({
        org_id: current_org?.id,
        object_id: record.id,
        note_type_flag: 'cerp.payslip',
      }).then((res: any) => {
        table_info_modal(
          '备注',
          '备注记录',
          [
            { title: '备注', dataIndex: 'note', width: 500 },
            { title: '日期', dataIndex: 'date', width: 150 },
            { title: '创建人', dataIndex: 'person_name', width: 150 },
          ],
          res.data,
        );
      });
    },
  },
  {
    name: '修改',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return (
        (record.status === 1 || record.status === 2 || record.status === 4) &&
        record.payment_status != 2
      );
    },
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'PayslipModal',
        type: 'update',
        obj: {
          ...record,
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
  {
    name: '预览',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'PreviewModal',
        type: 'payslip',
        obj: {
          ...record,
        },
      });
      modifyModal.visible = true;
      modalBox(modifyModal);
    },
  },
  {
    name: '付款',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.payment_status != 2;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const editModal = reactive({
        visible: true,
        title: '付款（银行流水）',
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'name',
              label: '名称',
              label_i18n: '名称',
              placeholder_i18n: '请填写名称',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写名称',
            },
            {
              type: 'input',
              name: 'description',
              label: '描述',
              label_i18n: '描述',
              placeholder_i18n: '请填描述',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写描述',
            },
            {
              type: 'money',
              name: 'amount',
              label: '总金额',
              label_i18n: '总金额',
              placeholder_i18n: '请填写总金额',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              placeholder: '请填写总金额',
            },
            {
              type: 'datepicker',
              name: 'date',
              label: '日期',
              label_i18n: '日期',
              placeholder_i18n: '请填写日期',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请填写日期',
            },
            {
              type: 'select',
              name: 'transaction_type',
              label: '流水种类',
              label_i18n: '流水种类',
              placeholder_i18n: '请选择流水种类',
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              placeholder: '请选择流水种类',
              datasource: 'transaction_type',
            },
            {
              type: 'remoteselect',
              name: 'cc_bank_account_id',
              label: 'CC银行账户',
              label_i18n: 'CC银行账户',
              placeholder_i18n: '请选择CC银行账户',
              disabled: false,
              placeholder: '请选择CC银行账户',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'account_flag',
              valueKey: 'id',
              modalType: 'baseinfo-ccbankaccountscc',
            },
            {
              type: 'remoteselect',
              name: 'currency_id',
              labelKeyList: ['code', 'currency'],
              label: '币种',
              label_i18n: t('币种'),
              disabled: false,
              placeholder: '请选择薪资币种',
              placeholder_i18n: t('请选择薪资币种'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-country1',
            },
          ],
          rules: {
            name: [{ required: true, message: '请填输入名称' }],
            amount: [{ required: true, message: '请填输入总金额' }],
            transaction_type: [{ required: true, message: '请填输入流水种类' }],
          },
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            name: record.payslip_no,
            description: null,
            amount: record.total_amount,
            currency_id: record.invoice__contract__currency_id,
            date: moment().format('YYYY-MM-DD HH:mm:ss'),
            transaction_type: '支出',
            cc_bank_account_id: record.cc_bank_account_id,
          }),
        },
        options: {
          transaction_type: [
            { value: '收入', name: '收入' },
            { value: '支出', name: '支出' },
          ],
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            create_bank_transaction(payload).then((res: any) => {
              pay_payslip({
                org_id: current_org?.id,
                transaction_id: res?.id,
                payslip_id: record.id,
              })
                .then((res: any) => {
                  resolve(null);
                  event_obj.emit('refresh');
                  message.success('付款成功');
                })
                .finally(() => {
                  editModal.loading = false;
                });
            });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '取消付款',
    type: 'dropdown',
    clazz: 'danger',
    is_show: (record: any) => {
      return record.transaction_id;
    },
    cb: (record: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      Modal.confirm({
        title: '是否取消付款?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            cancel_pay_payslip({
              org_id: current_org.id,
              payslip_id: record.id,
            }).then((res: any) => {
              message.success('取消付款成功');
              Object.assign(record, res);
            }).finally(() => {
              resolve(null);
            })
          })
        },
        onCancel() {},
      });
    },
  },
  {
    name: '生成PDF',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.status === 1;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('提示'),
        content: '是否生成工资单PDF',
        onOk() {
          return new Promise(resolve => {
            create_payslip_pdf({ org_id: current_org?.id, payslip_id: record.id })
              .then(() => {
                message.success('生成工资单PDF成功');
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '发送邮件',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.status === 4;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('提示'),
        content: '是否发送邮件',
        onOk() {
          return new Promise(resolve => {
            send_payslip_by_email({ org_id: current_org?.id, payslip_id: record.id })
              .then(() => {
                message.success('发送邮件成功');
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '重新发送',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.status === 2;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('提示'),
        content: '是否重新发送邮件',
        onOk() {
          return new Promise(resolve => {
            send_payslip_by_email({ org_id: current_org?.id, payslip_id: record.id })
              .then(() => {
                message.success('重新发送邮件成功');
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '确认收到',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.status === 2;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('提示'),
        content: '是否确认收到工资单',
        onOk() {
          return new Promise(resolve => {
            confirm_payslip({ org_id: current_org?.id, payslip_id: record.id })
              .then(() => {
                message.success('工资单确认成功');
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '管理费',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return (
        (record.status === 1 || record.status === 2 || record.status === 4) &&
        record.payment_status != 2
      );
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const editModal = reactive({
        visible: true,
        title: '修改工资单管理费',
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'management_fee',
              label: '管理费',
              label_i18n: '管理费',
              placeholder_i18n: '请填写管理费',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写管理费',
            },
          ],
          rules: {
            management_fee: [{ required: true, message: t('请填写管理费描述') }],
          },
          model: {
            org_id: current_org?.id,
            is_active: true,
            payslip_id: record.id,
            management_fee: record.management_fee,
          },
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            modify_payslip_mangefee(payload)
              .then(() => {
                message.success('修改工资单管理费成功');
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        params: {
          object_id: record.id,
          note_type_flag: 'cerp.payslip',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
  {
    name: '删除',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.is_active;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('是否确认删除') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
            delete_payslip({
              org_id: current_org?.id,
              payslip_id: record.id,
            }).then((res:any) => {
              message.success(t('操作成功'));
              record.is_active = false;
              Object.assign(record, res);
              event_obj.emit('refresh_data')
              event_obj.emit('onCustomEvent', {type:'get_extra_field_num'})
              resolve(null);
            });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '恢复',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return !record.is_active;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      recover_payslip({
        org_id: current_org?.id,
        payslip_id: record.id,
      }).then((res:any) => {
        message.success(t('恢复成功'));
        record.is_active = false;
        Object.assign(record, res);
        event_obj.emit('refresh_data')
        event_obj.emit('onCustomEvent', {type:'get_extra_field_num'})
      });
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [7, 7],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
