import { Card, Modal } from 'ant-design-vue';
import { h } from 'vue';

export default (title: string, card_title: string, columns: Array<any>, datasource: Array<any>) => {
  let width = 0;
  columns.map(item => {
    if (!item.width) {
      item.width = 50;
    }
    width += item.width;
  });
  const children: any = [];
  for (const k of columns) {
    children.push(
      h(
        Card.Grid,
        { style: { width: k.width + 'px', 'text-align': 'center', padding: '10px' } },
        k.title,
      ),
    );
  }
  let index = 1;
  for (const item of datasource) {
    for (const k of columns) {
      if (k.dataIndex == 'index') {
        children.push(
          h(
            Card.Grid,
            { style: { width: k.width + 'px', 'text-align': 'center', padding: '10px' } },
            index,
          ),
        );
      } else {
        children.push(
          h(
            Card.Grid,
            { style: { width: k.width + 'px', 'text-align': 'center', padding: '10px' } },
            item[k.dataIndex],
          ),
        );
      }
    }
    index += 1;
  }

  Modal.info({
    title: title,
    width: width + 103,
    content: h(Card, { title: card_title }, children),
    onOk() {
      console.log('ok');
    },
  });
};
