import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export async function queryApiInfo(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/apidoc/query_api_info_list', params);
}

export async function removeApiInfo(params: Record<string, any>) {
  return request.post('/apidoc/delete_api_info', params);
}
export async function recoverApiInfo(params: Record<string, any>) {
  return request.post('/apidoc/recover_api_info', params);
}

export async function createApiInfo(params: Record<string, any>) {
  return request.post('/apidoc/create_api_info', params);
}

export async function updateApiInfo(params: Record<string, any>) {
  return request.post('/apidoc/update_api_info', params);
}

export async function importApiInfoByJsonFile(params: Record<string, any>) {
  return request.post('/apidoc/import_api_info_by_json_file', params);
}
