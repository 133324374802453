import {
  create_insurance_company,
  delete_insurance_company,
  query_insurance_company_list,
  recover_insurance_company,
  update_insurance_company,
} from '@/api/baseinfo-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/baseinfo/baseinfo-insurancecompany-config';

import { reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import { getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'company_name',
      label: '公司名称',
      label_i18n: t('page.baseinfo.insurancecompany.form.label.company_name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写公司名称',
      placeholder_i18n: t('page.baseinfo.insurancecompany.form.placeholder.company_name'),
    },
    {
      type: 'input',
      name: 'address',
      label: '地址',
      label_i18n: t('page.baseinfo.insurancecompany.form.label.address'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写地址',
      placeholder_i18n: t('page.baseinfo.insurancecompany.form.placeholder.address'),
    },
    {
      type: 'input',
      name: 'contact',
      label: '联系人',
      label_i18n: t('page.baseinfo.insurancecompany.form.label.contact'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写联系人',
      placeholder_i18n: t('page.baseinfo.insurancecompany.form.placeholder.contact'),
    },
    {
      type: 'input',
      name: 'email',
      label: '邮箱',
      label_i18n: t('page.baseinfo.insurancecompany.form.label.email'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写邮箱',
      placeholder_i18n: t('page.baseinfo.insurancecompany.form.placeholder.email'),
    },
    {
      type: 'input',
      name: 'agent',
      label: '保险经纪人',
      label_i18n: t('page.baseinfo.insurancecompany.form.label.agent'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写保险经纪人',
      placeholder_i18n: t('page.baseinfo.insurancecompany.form.placeholder.agent'),
    },
    {
      type: 'input',
      name: 'agent_contact',
      label: '经纪人联系方式',
      label_i18n: t('page.baseinfo.insurancecompany.form.label.agent_contact'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写经纪人联系方式',
      placeholder_i18n: t('page.baseinfo.insurancecompany.form.placeholder.agent_contact'),
    },
    {
      type: 'radio',
      name: 'is_active',
      label: '是否可用',
      label_i18n: t('page.baseinfo.insurancecompany.form.label.is_active'),
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    company_name: null,
    address: null,
    contact: null,
    email: null,
    agent: null,
    bank_account: null,
    agent_contact: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: t('全部') },
    { value: True, name: t('可用') },
    { value: False, name: t('禁用') },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'company_name',
      label: '公司名称',
      label_i18n: t('page.baseinfo.insurancecompany.form.label.company_name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写公司名称',
      placeholder_i18n: t('page.baseinfo.insurancecompany.form.placeholder.company_name'),
    },
    {
      type: 'input',
      name: 'address',
      label: '地址',
      label_i18n: t('page.baseinfo.insurancecompany.form.label.address'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写地址',
      placeholder_i18n: t('page.baseinfo.insurancecompany.form.placeholder.address'),
    },
    {
      type: 'input',
      name: 'contact',
      label: '联系人',
      label_i18n: t('page.baseinfo.insurancecompany.form.label.contact'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写联系人',
      placeholder_i18n: t('page.baseinfo.insurancecompany.form.placeholder.contact'),
    },
    {
      type: 'input',
      name: 'email',
      label: '邮箱',
      label_i18n: t('page.baseinfo.insurancecompany.form.label.email'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写邮箱',
      placeholder_i18n: t('page.baseinfo.insurancecompany.form.placeholder.email'),
    },
    {
      type: 'input',
      name: 'remark',
      label: '备注',
      label_i18n: t('page.baseinfo.insurancecompany.form.label.remark'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写备注',
      placeholder_i18n: t('page.baseinfo.insurancecompany.form.placeholder.remark'),
    },
    {
      type: 'input',
      name: 'agent',
      label: '保险经纪人',
      label_i18n: t('page.baseinfo.insurancecompany.form.label.agent'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写保险经纪人',
      placeholder_i18n: t('page.baseinfo.insurancecompany.form.placeholder.agent'),
    },
    {
      type: 'input',
      name: 'agent_contact',
      label: '经纪人联系方式',
      label_i18n: t('page.baseinfo.insurancecompany.form.label.agent_contact'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写经纪人联系方式',
      placeholder_i18n: t('page.baseinfo.insurancecompany.form.placeholder.agent_contact'),
    },
  ],
  rules: {
    company_name: [{ required: true, message: t('page.baseinfo.insurancecompany.form.placeholder.company_name') }],
    address: [{ required: true, message: t('page.baseinfo.insurancecompany.form.placeholder.address') }],
    contact: [{ required: true, message: t('page.baseinfo.insurancecompany.form.placeholder.contact') }],
    email: [{ required: true, message: t('page.baseinfo.insurancecompany.form.placeholder.email') }],
    remark: [{ required: true, message: t('page.baseinfo.insurancecompany.form.placeholder.remark') }],
    agent: [{ required: true, message: t('page.baseinfo.insurancecompany.form.placeholder.agent') }],
    agent_contact: [{ required: true, message: t('page.baseinfo.insurancecompany.form.placeholder.agent_contact') }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    company_name: null,
    address: null,
    contact: null,
    email: null,
    remark: null,
    agent: null,
    agent_contact: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'company_name',
      label: '公司名称',
      label_i18n: t('page.baseinfo.insurancecompany.form.label.company_name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写公司名称',
      placeholder_i18n: t('page.baseinfo.insurancecompany.form.placeholder.company_name'),
    },
    {
      type: 'input',
      name: 'address',
      label: '地址',
      label_i18n: t('page.baseinfo.insurancecompany.form.label.address'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写地址',
      placeholder_i18n: t('page.baseinfo.insurancecompany.form.placeholder.address'),
    },
    {
      type: 'input',
      name: 'contact',
      label: '联系人',
      label_i18n: t('page.baseinfo.insurancecompany.form.label.contact'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写联系人',
      placeholder_i18n: t('page.baseinfo.insurancecompany.form.placeholder.contact'),
    },
    {
      type: 'input',
      name: 'email',
      label: '邮箱',
      label_i18n: t('page.baseinfo.insurancecompany.form.label.email'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写邮箱',
      placeholder_i18n: t('page.baseinfo.insurancecompany.form.placeholder.email'),
    },
    {
      type: 'input',
      name: 'remark',
      label: '备注',
      label_i18n: t('page.baseinfo.insurancecompany.form.label.remark'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写备注',
      placeholder_i18n: t('page.baseinfo.insurancecompany.form.placeholder.remark'),
    },
    {
      type: 'input',
      name: 'agent',
      label: '保险经纪人',
      label_i18n: t('page.baseinfo.insurancecompany.form.label.agent'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写保险经纪人',
      placeholder_i18n: t('page.baseinfo.insurancecompany.form.placeholder.agent'),
    },
    {
      type: 'input',
      name: 'agent_contact',
      label: '经纪人联系方式',
      label_i18n: t('page.baseinfo.insurancecompany.form.label.agent_contact'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写经纪人联系方式',
      placeholder_i18n: t('page.baseinfo.insurancecompany.form.placeholder.agent_contact'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    company_name: null,
    address: null,
    contact: null,
    email: null,
    remark: null,
    agent: null,
    agent_contact: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('保险公司'),
  addModalTitle: t('新建保险公司'),
  updateTitle: t('修改保险公司'),
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    insurance_company: 'id',
  },
  delete: {
    insurance_company: 'id',
  },
  recover: {
    insurance_company: 'id',
  },
  updateData: {
    org_id: 'org_id',
    insurance_company: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.baseinfo.insurancecompany.column.title.index'),
    dataIndex: 'index',
    width: 50,
    fixed: 'left',
  },
  {
    title: t('page.baseinfo.insurancecompany.column.title.company_name'),
    width: 150,
    dataIndex: 'company_name',
    checked: true,
    fixed: 'left',
  },
  {
    title: t('page.baseinfo.insurancecompany.column.title.id'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.baseinfo.insurancecompany.column.title.address'),
    width: 200,
    dataIndex: 'address',
    checked: true,
  },
  {
    title: t('page.baseinfo.insurancecompany.column.title.contact'),
    width: 150,
    dataIndex: 'contact',
    checked: true,
  },
  {
    title: t('page.baseinfo.insurancecompany.column.title.email'),
    width: 200,
    dataIndex: 'email',
    checked: true,
  },
  {
    title: t('page.baseinfo.insurancecompany.column.title.remark'),
    width: 150,
    dataIndex: 'remark',
    checked: true,
    cellComponent: 'remark',
  },
  {
    title: t('page.baseinfo.insurancecompany.column.title.agent'),
    width: 150,
    dataIndex: 'agent',
    checked: true,
  },
  {
    title: t('page.baseinfo.insurancecompany.column.title.agent_contact'),
    width: 150,
    dataIndex: 'agent_contact',
    checked: true,
  },
  {
    title: t('page.baseinfo.insurancecompany.column.title.content'),
    width: 200,
    dataIndex: 'content',
    checked: true,
    cellComponent: 'click',
    content: (record: any) => {
      if (record.content) {
        return record.content;
      } else {
        return t('点击添加');
      }
    },
    cb: (record: any) => {
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('理赔流程'),
        component: 'code-editing-modal',
        loading: loading,
        mode: 'htmlmixed',
        params: record.content,
        hold: (code: any) => {
          update_insurance_company({
            org_id: current_org?.id,
            content: code,
            insurance_company: record.id,
          }).then(() => {
            loading.value = false;
            message.success(t('保存成功'));
            event_obj.emit('refresh');
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    title: t('page.baseinfo.insurancecompany.column.title.create_time'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('page.baseinfo.insurancecompany.column.title.update_time'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('page.baseinfo.insurancecompany.column.title.is_active'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('page.baseinfo.insurancecompany.column.title.action'),
    width: 150,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push(
  {
    type: 'click',
    slotName: 'content',
    content: (record: any) => {
      if (record.content) {
        return record.content;
      } else {
        return t('点击添加');
      }
    },
    cb: (record: any) => {
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('理赔流程'),
        component: 'code-editing-modal',
        loading: loading,
        mode: 'htmlmixed',
        params: record.content,
        hold: (code: any) => {
          update_insurance_company({
            org_id: current_org?.id,
            content: code,
            insurance_company: record.id,
          }).then(() => {
            loading.value = false;
            message.success(t('保存成功'));
            event_obj.emit('refresh');
          });
        },
      });
      modalBox(editModal);
    },
  },
);

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_insurance_company_list,
    addData: create_insurance_company,
    updateData: update_insurance_company,
    removeData: delete_insurance_company,
    recoverData: recover_insurance_company,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
