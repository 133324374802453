import {
  create_list_result_field,
  delete_list_result_field,
  query_list_result_field_list,
  recover_list_result_field,
  update_list_result_field,
} from '@/api/apidoc-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/apidoc/apidoc-listresultfield-config';

import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {},
    {
      type: 'remoteselect',
      name: 'api_id',
      label: '隶属接口',
      disabled: false,
      placeholder: '请选择隶属接口',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'apidoc-apiinfo',
    },
    {
      type: 'input',
      name: 'name',
      label: '参数名',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写参数名',
    },
    {
      type: 'input',
      name: 'info',
      label: '参数中文',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写参数中文',
    },
    {
      type: 'input',
      name: 'display_style',
      label: '槽',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写槽',
    },
    {
      type: 'input',
      name: 'custom_display_style',
      label: '自定义槽',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写自定义槽',
    },
    {
      type: 'input',
      name: 'fixed',
      label: '列悬浮设置',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写列悬浮设置',
    },
    {
      type: 'input',
      name: 'width',
      label: '列宽度',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写列宽度',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    app_ids: null,
    api_id: null,
    name: null,
    info: null,
    display_style: null,
    custom_display_style: null,
    hidden: null,
    fixed: null,
    width: null,
    is_bool: null,
  }),
};
const search_options = {
  hidden: [
    { value: None, name: '全部' },
    {
      value: 0,
      name: '完全隐藏（界面不可改变状态）',
    },
    { value: 1, name: '默认隐藏（界面可改变状态）' },
    { value: 3, name: '不隐藏' },
  ],
  is_bool: [
    { value: None, name: '全部' },
    { value: True, name: 'bool型变量' },
    {
      value: False,
      name: '非bool型变量',
    },
  ],
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '参数名',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写参数名',
    },
    {
      type: 'input',
      name: 'info',
      label: '参数中文',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写参数中文',
    },
    {
      type: 'input',
      name: 'display_style',
      label: '槽',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写槽',
    },
    {
      type: 'input',
      name: 'custom_display_style',
      label: '自定义槽',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写自定义槽',
    },
    {
      type: 'radio',
      name: 'hidden',
      label: '列显示设置',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择列显示设置',
    },
    {
      type: 'input',
      name: 'fixed',
      label: '列悬浮设置',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写列悬浮设置',
    },
    {
      type: 'input',
      name: 'width',
      label: '列宽度',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写列宽度',
    },
    {
      type: 'input',
      name: 'sort',
      label: '排序',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写排序',
    },
    {
      type: 'radio',
      name: 'is_bool',
      label: '是否是bool型',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否是bool型',
    },
  ],
  rules: {
    name: [{ required: true, message: '请填输入参数名' }],
    info: [{ required: true, message: '请填输入参数中文' }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    info: null,
    display_style: null,
    custom_display_style: null,
    hidden: null,
    fixed: null,
    width: null,
    sort: null,
    is_bool: null,
  }),
};
const edit_model_config_options = {
  hidden: [
    { value: 0, name: '完全隐藏（界面不可改变状态）' },
    {
      value: 1,
      name: '默认隐藏（界面可改变状态）',
    },
    { value: 3, name: '不隐藏' },
  ],
  is_bool: [
    { value: True, name: 'bool型变量' },
    { value: False, name: '非bool型变量' },
  ],
};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '参数名',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写参数名',
    },
    {
      type: 'input',
      name: 'info',
      label: '参数中文',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写参数中文',
    },
    {
      type: 'input',
      name: 'display_style',
      label: '槽',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写槽',
    },
    {
      type: 'input',
      name: 'custom_display_style',
      label: '自定义槽',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写自定义槽',
    },
    {
      type: 'radio',
      name: 'hidden',
      label: '列显示设置',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择列显示设置',
    },
    {
      type: 'input',
      name: 'fixed',
      label: '列悬浮设置',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写列悬浮设置',
    },
    {
      type: 'input',
      name: 'width',
      label: '列宽度',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写列宽度',
    },
    {
      type: 'input',
      name: 'sort',
      label: '排序',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写排序',
    },
    {
      type: 'radio',
      name: 'is_bool',
      label: '是否是bool型',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否是bool型',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    info: null,
    display_style: null,
    custom_display_style: null,
    hidden: null,
    fixed: null,
    width: null,
    sort: null,
    is_bool: null,
  }),
};
const update_model_config_options = {
  hidden: [
    { value: 0, name: '完全隐藏（界面不可改变状态）' },
    {
      value: 1,
      name: '默认隐藏（界面可改变状态）',
    },
    { value: 3, name: '不隐藏' },
  ],
  is_bool: [
    { value: True, name: 'bool型变量' },
    { value: False, name: '非bool型变量' },
  ],
};

const titleList = {
  title: '接口返回值字段管理',
  addModalTitle: '新建接口返回值字段',
  updateTitle: '修改接口返回值字段',
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    listresultfield_id: 'id',
  },
  delete: {
    listresultfield_id: 'id',
  },
  recover: {
    listresultfield_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    listresultfield_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    width: 50,
    slots: { customRender: 'index' },
  },
  {
    title: '项目主键',
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: '参数名',
    width: 150,
    dataIndex: 'name',
    checked: true,
  },
  {
    title: '参数中文',
    width: 150,
    dataIndex: 'info',
    checked: true,
  },
  {
    title: '槽',
    width: 150,
    dataIndex: 'display_style',
    checked: true,
  },
  {
    title: '自定义槽',
    width: 150,
    dataIndex: 'custom_display_style',
    checked: true,
  },
  {
    title: '列显示设置',
    width: 150,
    dataIndex: 'hidden',
    checked: true,

    slots: { customRender: 'hidden' },
    filterMultiple: true,
    filters: [
      { text: t('全部'), value: '' },
      { text: '完全隐藏（界面不可改变状态）', value: '0' },
      {
        text: '默认隐藏（界面可改变状态）',
        value: '1',
      },
      { text: '不隐藏', value: '3' },
    ],
  },
  {
    title: '列悬浮设置',
    width: 150,
    dataIndex: 'fixed',
    checked: true,
  },
  {
    title: '列宽度',
    width: 150,
    dataIndex: 'width',
    checked: true,
  },
  {
    title: '排序',
    width: 150,
    dataIndex: 'sort',
    checked: true,
  },
  {
    title: '可选项数据',
    width: 150,
    dataIndex: 'choices_list',
    checked: true,
  },
  {
    title: '是否是bool型',
    width: 150,
    dataIndex: 'is_bool',
    checked: true,

    slots: { customRender: 'is_bool' },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: 'bool型变量', value: true },
      { text: '非bool型变量', value: false },
    ],
  },
  {
    title: t('创建时间'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,

    slots: { customRender: 'datetime' },
  },
  {
    title: '更新日期',
    width: 150,
    dataIndex: 'update_time',
    checked: false,

    slots: { customRender: 'datetime' },
  },
  {
    title: t('是否可用'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,

    slots: { customRender: 'is_active' },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('操作'),
    width: 200,
    dataIndex: 'action',
    checked: false,
    slots: { customRender: 'action' },
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push(
  {
    type: 'custom',
    slotName: 'hidden',
    field_0: '完全隐藏（界面不可改变状态）',
    field_1: '默认隐藏（界面可改变状态）',
    field_3: '不隐藏',
  },
  { type: 'custom', slotName: 'is_bool', field_true: 'bool型变量', field_false: '非bool型变量' },
  {
    type: 'custom',
    // slotName: 'is_active',
    field_true: '可用',
    field_false: '禁用',
  },
);

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_list_result_field_list,
    addData: create_list_result_field,
    updateData: update_list_result_field,
    removeData: delete_list_result_field,
    recoverData: recover_list_result_field,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
