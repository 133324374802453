import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import modalBox from '@/components/form-modal/modal-tools';
import { reactive, ref } from 'vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { message, notification } from 'ant-design-vue';
import {
  update_cc_company,
  upload_cc_company_invoice_template,
  upload_cc_company_logo,
  upload_cc_company_payslip_template,
  upload_cc_company_vat_invoice_template,
} from '@/api/baseinfo-model';

export const event_obj = mitt();
export const btn_list = [
  {
    name: '上传签章模板',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('上传签章模板'),
        group: '合同模板',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          if (payload.length > 1) {
            notification.error({
              message: t('提示'),
              description: t('只能上传一个模板，默认上传第一个'),
            });
          }
          return new Promise(resolve => {
            loading.value = true;
            update_cc_company({
              cc_company_id: data.id,
              nsfile_id: payload[0],
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('上传成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '上传请款单模板',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('上传请款单模板'),
        group: '合同模板',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          if (payload.length > 1) {
            notification.error({
              message: t('提示'),
              description: t('只能上传一个模板，默认上传第一个'),
            });
          }
          return new Promise(resolve => {
            loading.value = true;
            upload_cc_company_invoice_template({
              cc_company_id: data.id,
              invoice_nsfile_id: payload[0],
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('上传成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '上传工资单模板',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('上传工资单模板'),
        group: '合同模板',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          if (payload.length > 1) {
            notification.error({
              message: t('提示'),
              description: t('只能上传一个模板，默认上传第一个'),
            });
          }
          return new Promise(resolve => {
            loading.value = true;
            upload_cc_company_payslip_template({
              cc_company_id: data.id,
              payslip_nsfile_id: payload[0],
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('上传成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '上传VAT请款单模板',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('上传VAT请款单模板'),
        group: '合同模板',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          if (payload.length > 1) {
            notification.error({
              message: t('提示'),
              description: t('只能上传一个模板，默认上传第一个'),
            });
          }
          return new Promise(resolve => {
            loading.value = true;
            upload_cc_company_vat_invoice_template({
              cc_company_id: data.id,
              nsfile_id: payload[0],
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('上传成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '上传logo',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('上传logo'),
        group: '合同模板',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          if (payload.length > 1) {
            notification.error({
              message: t('提示'),
              description: t('只能上传一个模板，默认上传第一个'),
            });
          }
          return new Promise(resolve => {
            loading.value = true;
            upload_cc_company_logo({
              cc_company_id: data.id,
              logo_nsfile_id: payload[0],
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('上传成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        type: 'update',
        params: {
          object_id: record.id,
          note_type_flag: 'baseinfo.cccompany',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
