import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';

import modalBox from '@/components/form-modal/modal-tools';
import { reactive } from 'vue';
export const event_obj = mitt();
export const btn_list = [];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
