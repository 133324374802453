import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import {
  create_recuitment_company_user_account,
  delete_recuitment_company_user_account,
  disable_recuitment_company_account,
  enable_recuitment_company_account,
  reset_recuitment_company_account_password,
  send_recuitment_company_user_account_email,
} from '@/api/baseinfo-model';
import modalBox from '@/components/form-modal/modal-tools';
import { reactive } from 'vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { message, Modal } from 'ant-design-vue';

export const event_obj = mitt();
const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

export const btn_list = [
  {
    name: '创建账号',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return !record.username;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const editModal = reactive({
        visible: true,
        title: t('创建账号'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'username',
              label: '登录账号',
              label_i18n: '登录账号',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写登录账号',
              placeholder_i18n: '请填写登录账号',
            },
          ],
          rules: {
            username: [{ required: true, message: t('请填写登录账号') }],
          },
          model: reactive({
            org_id: current_org?.id,
            recuitment_company_user_id: Number(record.id),
            username: null,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            create_recuitment_company_user_account(payload)
              .then((res: any) => {
                message.success(t('创建成功'));
                Object.assign(record, res);
                event_obj.emit('refresh_data');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '发送邮件',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return !!record.username;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('是否发送') + ' [' + record.name + ']' + t('账号提示邮件') + '？',
        onOk() {
          return new Promise(resolve => {
            send_recuitment_company_user_account_email({
              org_id: current_org?.id,
              recuitment_company_user_id: record.id,
            })
              .then(() => {
                message.success(t('发送成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '删除账号',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return !!record.username;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('是否删除') + ' [' + record.name + ']' + t('账号') + '？',
        onOk() {
          return new Promise(resolve => {
            delete_recuitment_company_user_account({
              org_id: current_org?.id,
              recuitment_company_user_id: record.id,
            })
              .then((res: any) => {
                message.success(t('删除成功'));
                Object.assign(record, res);
                event_obj.emit('refresh_data');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '重置密码',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return !!record.username;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('是否重置') + ' [' + record.name + ']' + t('账号密码') + '？',
        onOk() {
          return new Promise(resolve => {
            reset_recuitment_company_account_password({
              org_id: current_org?.id,
              recuitment_company_user_id: record.id,
            })
              .then((res: any) => {
                message.success(t('操作成功'));
                Object.assign(record, res);
                event_obj.emit('refresh_data');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '禁用账号',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.is_account_enable;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('是否禁用') + ' [' + record.name + ']' + t('账号') + '？',
        onOk() {
          return new Promise(resolve => {
            disable_recuitment_company_account({
              org_id: current_org?.id,
              recuitment_company_user_id: record.id,
            })
              .then((res: any) => {
                message.success(t('操作成功'));
                Object.assign(record, res);
                event_obj.emit('refresh_data');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: '恢复账号',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return !record.is_account_enable;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('提示'),
        content: t('是否恢复账号可用'),
        onOk() {
          return new Promise(resolve => {
            enable_recuitment_company_account({
              org_id: current_org?.id,
              recuitment_company_user_id: record.id,
            })
              .then((res: any) => {
                message.success(t('操作成功'));
                Object.assign(record, res);
                event_obj.emit('refresh_data');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
