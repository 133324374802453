import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { createVNode, reactive, ref } from 'vue';
import {
  create_extra_field,
  delete_extra_field,
  get_cc_company_by_adviser_id,
  get_extra_month,
  import_extra_field,
  update_extra_field,
} from '@/api/cerp-model';
import { message, Modal, notification } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { query_receipt_list } from '@/api/receipt-model';

export const event_obj = mitt();
export const btn_list = [
  {
    name: '修改',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const list: any = ref([]);
      get_extra_month({
        org_id: current_org?.id,
        adviser_id: record.adviser_id,
        type: record.type,
        cc_company_id: record.cc_company_id,
      }).then((res: any) => {
        if (res.data.length > 0) {
          list.value = res.data;
        } else {
          notification.error({
            message: t('错误'),
            description: t('合同无可选月份'),
          });
        }
      });
      const editModal = reactive({
        visible: true,
        title: t('修改'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'remotemodal',
              name: 'adviser_id',
              label: '顾问',
              label_i18n: '顾问',
              placeholder_i18n: '请选择隶属顾问',
              disabled: false,
              allowClear: true,
              defaultValue: '',
              labelKey: 'full_name',
              valueKey: 'id',
              placeholder: '请选择顾问',
              show_search: false,
              maxTagCount: 5,
              mode: 'default',
              title: t('选择顾问'),
              modalType: 'cerp-adviser',
            },
            {
              type: 'radio',
              name: 'type',
              label: '类型',
              label_i18n: '类型',
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              datasource: 'type',
            },
            {
              type: 'remoteselect',
              name: 'cc_company_id',
              label: 'CC公司',
              label_i18n: 'CC公司',
              placeholder_i18n: '请选择CC公司',
              disabled: false,
              placeholder: '请选择CC公司',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-cccompany',
            },
            {
              type: 'input',
              name: 'custom_field_name',
              label: '自定义项描述',
              label_i18n: '自定义项描述',
              placeholder_i18n: '请填写自定义项描述',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写自定义项描述',
            },
            {
              type: 'input',
              name: 'custom_field_value',
              label: '自定义项值',
              label_i18n: '自定义项值',
              placeholder_i18n: '请填写自定义项值',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写自定义项值',
            },
            {
              type: 'input',
              name: 'content',
              label: '内容',
              label_i18n: '内容',
              placeholder_i18n: '请填写内容',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写内容',
            },
            {
              type: 'monthpicker',
              name: 'month',
              label: '月份',
              label_i18n: '月份',
              placeholder_i18n: '请选择月份',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              format: 'YYYY-MM',
              placeholder: '请选择月份',
              disabledTime: (date: any) => {
                return list.value.indexOf(date.format('YYYY-MM')) < 0;
              },
            },
            {
              type: 'radio',
              name: 'is_finished',
              label: '状态',
              label_i18n: '状态',
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              datasource: 'is_finished',
            },
            {
              type: 'textarea',
              name: 'note',
              label: '备注',
              label_i18n: '备注',
              placeholder_i18n: '请填写备注',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 200,
              defaultValue: '',
              placeholder: '请填写备注',
            },
          ],
          rules: {},
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            adviser_id: record.adviser_id,
            custom_field_name: record.custom_field_name,
            custom_field_value: record.custom_field_value,
            is_finished: record.is_finished,
            content: record.content,
            note: record.note,
            type: record.type,
            month: record.month,
            extra_field_id: record.id,
            cc_company_id: record.cc_company_id,
          }),
          watch: {
            adviser_id: (data: any) => {
              if (data.adviser_id && (data.type == 0 || data.type == 1)) {
                get_extra_month({
                  org_id: current_org?.id,
                  adviser_id: data.adviser_id,
                  type: data.type,
                  cc_company_id: data.cc_company_id,
                }).then((res: any) => {
                  if (res.data.length > 0) {
                    list.value = res.data;
                  } else {
                    notification.error({
                      message: t('错误'),
                      description: t('合同无可选月份'),
                    });
                  }
                });
              }
            },
            cc_company_id: (data: any) => {
              if (data.adviser_id && (data.type == 0 || data.type == 1)) {
                get_extra_month({
                  org_id: current_org?.id,
                  adviser_id: data.adviser_id,
                  type: data.type,
                  cc_company_id: data.cc_company_id,
                }).then((res: any) => {
                  if (res.data.length > 0) {
                    list.value = res.data;
                  } else {
                    notification.error({
                      message: t('错误'),
                      description: t('合同无可选月份'),
                    });
                  }
                });
              }
            },
            type: (data: any) => {
              if (data.adviser_id && (data.type == 0 || data.type == 1)) {
                get_extra_month({
                  org_id: current_org?.id,
                  adviser_id: data.adviser_id,
                  type: data.type,
                  cc_company_id: data.cc_company_id,
                }).then((res: any) => {
                  if (res.data.length > 0) {
                    list.value = res.data;
                  } else {
                    notification.error({
                      message: t('错误'),
                      description: t('合同无可选月份'),
                    });
                  }
                });
              }
            },
          },
        },
        options: {
          type: [
            { value: 0, name: t('请款单') },
            { value: 1, name: t('工资单') },
          ],
          is_finished: [
            { value: true, name: t('完成') },
            { value: false, name: t('未完成') },
          ],
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            update_extra_field({
              ...payload,
            })
              .then(() => {
                message.success(t('修改成功'));
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '删除',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.is_active;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('是否确认删除') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
            query_receipt_list({
              org_id: current_org?.id,
              extra_id: record.id,
            }).then((res1: any) => {
              if (res1 && res1.data && res1.data.length > 0) {
                message.error(t('只能在票据模块删除'));
                resolve(null);
              } else {
                delete_extra_field({
                  org_id: current_org?.id,
                  extra_field_id: record.id,
                }).then((res: any) => {
                  message.success(t('操作成功'));
                  record.is_active = false;
                  Object.assign(record, res);
                  event_obj.emit('refresh_data');
                  event_obj.emit('onCustomEvent', { type: 'get_extra_field_num' });
                  resolve(null);
                });
              }
            });
          });
        },
        onCancel() {},
      });
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list = [
  {
    name: '新建增扣提醒',
    clazz: 'primary',
    cb: (record: any, dataSource: any, selectedRowKeys: any, searchConfigOptions: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const list: any = ref([]);
      const company_list: any = ref([]);
      const editModal = reactive({
        visible: true,
        title: t('新建增扣提醒'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'remotemodal',
              name: 'adviser_id',
              label: '顾问',
              label_i18n: '顾问',
              placeholder_i18n: '请选择隶属顾问',
              disabled: false,
              allowClear: true,
              defaultValue: '',
              labelKey: 'full_name',
              valueKey: 'id',
              placeholder: '请选择顾问',
              show_search: false,
              maxTagCount: 5,
              mode: 'default',
              title: t('选择顾问'),
              modalType: 'cerp-adviser',
            },
            {
              type: 'radio',
              name: 'type',
              label: '类型',
              label_i18n: '类型',
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              datasource: 'type',
            },
            {
              type: 'select',
              name: 'cc_company_id',
              label: 'CC公司',
              label_i18n: 'CC公司',
              placeholder_i18n: '请选择CC公司',
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              placeholder: '请选择CC公司',
              datasource: 'cc_company_id',
            },
            {
              type: 'input',
              name: 'custom_field_name',
              label: '自定义项描述',
              label_i18n: '自定义项描述',
              placeholder_i18n: '请填写自定义项描述',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写自定义项描述',
            },
            {
              type: 'input',
              name: 'custom_field_value',
              label: '自定义项值',
              label_i18n: '自定义项值',
              placeholder_i18n: '请填写自定义项值',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写自定义项值',
            },
            {
              type: 'input',
              name: 'content',
              label: '内容',
              label_i18n: '内容',
              placeholder_i18n: '请填写内容',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写内容',
            },
            {
              type: 'monthpicker',
              name: 'month',
              label: '月份',
              label_i18n: '月份',
              placeholder_i18n: '请选择月份',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              format: 'YYYY-MM',
              placeholder: '请选择月份',
              disabledTime: (date: any) => {
                return list.value.indexOf(date.format('YYYY-MM')) < 0;
              },
            },
            {
              type: 'textarea',
              name: 'note',
              label: '备注',
              label_i18n: '备注',
              placeholder_i18n: '请填写备注',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 200,
              defaultValue: '',
              placeholder: '请填写备注',
            },
          ],
          rules: {
            adviser_id: [{ required: true, message: t('请选择顾问') }],
            type: [{ required: true, message: t('请选择类型') }],
            custom_field_name: [{ required: true, message: t('请填写自定义项描述') }],
            custom_field_value: [{ required: true, message: t('请填写自定义项值') }],
            month: [{ required: true, message: t('请选择月份') }],
            cc_company_id: [{ required: true, message: t('请选择CC公司') }],
          },
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            adviser_id: null,
            custom_field_name: null,
            custom_field_value: null,
            is_finished: false,
            content: null,
            note: null,
            type: null,
            month: null,
            cc_company_id: null,
          }),
          watch: {
            adviser_id: (data: any) => {
              if (data.adviser_id && (data.type == 0 || data.type == 1)) {
                get_extra_month({
                  org_id: current_org?.id,
                  adviser_id: data.adviser_id,
                  type: data.type,
                  cc_company_id: data.cc_company_id,
                }).then((res: any) => {
                  if (res.data.length > 0) {
                    list.value = res.data;
                  } else {
                    notification.error({
                      message: t('错误'),
                      description: t('合同无可选月份'),
                    });
                  }
                });
                company_list.value.length = 0;
                get_cc_company_by_adviser_id({
                  org_id: current_org?.id,
                  adviser_id: data.adviser_id,
                }).then((res: any) => {
                  if (res && res.data && res.data.length > 0) {
                    res.data.forEach((item: any) => {
                      company_list.value.push({
                        name: item.name,
                        value: item.id,
                      });
                    });
                  }
                });
              }
            },
            cc_company_id: (data: any) => {
              if (data.adviser_id && (data.type == 0 || data.type == 1)) {
                get_extra_month({
                  org_id: current_org?.id,
                  adviser_id: data.adviser_id,
                  type: data.type,
                  cc_company_id: data.cc_company_id,
                }).then((res: any) => {
                  if (res.data.length > 0) {
                    list.value = res.data;
                  } else {
                    notification.error({
                      message: t('错误'),
                      description: t('合同无可选月份'),
                    });
                  }
                });
              }
            },
            type: (data: any) => {
              if (data.adviser_id && (data.type == 0 || data.type == 1)) {
                get_extra_month({
                  org_id: current_org?.id,
                  adviser_id: data.adviser_id,
                  type: data.type,
                  cc_company_id: data.cc_company_id,
                }).then((res: any) => {
                  if (res.data.length > 0) {
                    list.value = res.data;
                  } else {
                    notification.error({
                      message: t('错误'),
                      description: t('合同无可选月份'),
                    });
                  }
                });
                company_list.value.length = 0;
                get_cc_company_by_adviser_id({
                  org_id: current_org?.id,
                  adviser_id: data.adviser_id,
                }).then((res: any) => {
                  if (res && res.data && res.data.length > 0) {
                    res.data.forEach((item: any) => {
                      company_list.value.push({
                        name: item.name,
                        value: item.id,
                      });
                    });
                  }
                });
              }
            },
          },
        },
        options: {
          type: [
            { value: 0, name: t('请款单') },
            { value: 1, name: t('工资单') },
          ],
          cc_company_id: company_list.value,
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            create_extra_field({
              ...payload,
            })
              .then(() => {
                message.success(t('创建成功'));
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '增扣提醒批量导入',
    clazz: 'primary',
    cb: (record: any, dataSource: any, selectedRowKeys: any, searchConfigOptions: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const loading = ref(false);
      let n: any = 0;
      const editModal = reactive({
        visible: true,
        title: t('增扣提醒批量导入'),
        group: '增扣提醒',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;

            import_extra_field({
              nsfile_id: payload[0],
              org_id: current_org?.id,
            })
              .then((res: any) => {
                if (res && res.err_list && res.err_list.length > 0) {
                  res.err_list.map((item: any) => {
                    item.id = n++;
                  });
                }
                if (res && res.ok_list && res.ok_list.length > 0) {
                  res.ok_list.map((item: any) => {
                    item.id = n++;
                  });
                }
                const editModal1 = reactive({
                  visible: true,
                  title: t('待增扣提醒批量导入预览'),
                  component: 'TodoeventModal',
                  column: [
                    {
                      title: t('顾问'),
                      width: 300,
                      dataIndex: 'adviser',
                    },
                    {
                      title: t('自定义项描述'),
                      width: 200,
                      dataIndex: 'custom_field_name',
                    },
                    {
                      title: t('自定义项值'),
                      width: 120,
                      dataIndex: 'custom_field_value',
                    },
                    {
                      title: t('类型'),
                      width: 120,
                      dataIndex: 'type',
                    },
                    {
                      title: t('内容'),
                      width: 150,
                      dataIndex: 'content',
                    },
                    {
                      title: t('CC公司'),
                      width: 200,
                      dataIndex: 'cc_company',
                    },
                    {
                      title: t('月份'),
                      width: 120,
                      dataIndex: 'month',
                    },
                    {
                      title: t('备注'),
                      width: 200,
                      dataIndex: 'note',
                    },
                  ],
                  flag: 'extra_field',
                  obj: res,
                  ok: (payload: any) => {
                    return new Promise(resolve1 => {
                      message.success(t('添加成功'));
                      event_obj.emit('refresh');
                      resolve1(null);
                      resolve(null);
                    });
                  },
                });
                modalBox(editModal1);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
];
