import {
  create_exchange_rate,
  delete_exchange_rate,
  query_exchange_rate_list,
  recover_exchange_rate,
  update_exchange_rate,
} from '@/api/baseinfo-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/baseinfo/baseinfo-exchangerate-config';

import { reactive } from 'vue';
import { getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'remoteselect',
      name: 'local_currency_id',
      labelKeyList: ['code', 'currency'],
      label: '本地币种',
      disabled: false,
      placeholder: '请选择本地币种',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-country',
    },
    {
      type: 'monthpicker',
      name: 'service_month',
      label: '服务月份',
      label_i18n: '服务月份',
      placeholder_i18n: '请填写服务月份',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      format: 'YYYY-MM',
      placeholder: '请填写开始时间',
    },
    {
      type: 'select',
      name: 'is_active',
      label: '是否可用',
      label_i18n: t('page.baseinfo.exchangerate.form.label.is_active'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择状态',
      placeholder_i18n: t('page.baseinfo.exchangerate.form.placeholder.is_active'),
      datasource: 'is_active',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    local_currency_id: null,
    service_month: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: t('全部') },
    { value: True, name: t('可用') },
    { value: False, name: t('禁用') },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'remoteselect',
      name: 'aim_currency_id',
      labelKeyList: ['code', 'currency'],
      label: '合同币种',
      label_i18n: '合同币种',
      disabled: false,
      placeholder: '请选择对应币种',
      placeholder_i18n: '请选择对应币种',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-country',
    },
    {
      type: 'remoteselect',
      name: 'local_currency_id',
      labelKeyList: ['code', 'currency'],
      label: '本地币种',
      label_i18n: '本地币种',
      disabled: false,
      placeholder: '请选择本地币种',
      placeholder_i18n: '请选择本地币种',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-country',
    },
    {
      type: 'input',
      name: 'rate',
      label: '汇率值',
      label_i18n: t('page.baseinfo.exchangerate.form.label.rate'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写汇率值',
      placeholder_i18n: t('page.baseinfo.exchangerate.form.placeholder.rate'),
    },
    {
      type: 'monthpicker',
      name: 'service_month',
      label: '服务月份',
      label_i18n: '服务月份',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      format: 'YYYY-MM',
    },
  ],
  rules: {
    local_currency_id: [{ required: true, message: t('请选择本地币种') }],
    aim_currency_id: [{ required: true, message: t('请选择对应币种') }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    local_currency_id: null,
    aim_currency_id: null,
    rate: null,
    service_month: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'remoteselect',
      name: 'aim_currency_id',
      labelKeyList: ['code', 'currency'],
      label: '合同币种',
      label_i18n: '合同币种',
      disabled: false,
      placeholder: '请选择对应币种',
      placeholder_i18n: '请选择对应币种',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-country',
    },
    {
      type: 'remoteselect',
      name: 'local_currency_id',
      labelKeyList: ['code', 'currency'],
      label: '本地币种',
      label_i18n: '本地币种',
      disabled: false,
      placeholder: '请选择本地币种',
      placeholder_i18n: '请选择本地币种',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-country',
    },
    {
      type: 'input',
      name: 'rate',
      label: '汇率值',
      label_i18n: t('page.baseinfo.exchangerate.form.label.rate'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写汇率值',
      placeholder_i18n: t('page.baseinfo.exchangerate.form.placeholder.rate'),
    },
    {
      type: 'monthpicker',
      name: 'service_month',
      label: '服务月份',
      label_i18n: '服务月份',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      format: 'YYYY-MM',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    local_currency_id: null,
    aim_currency_id: null,
    rate: null,
    service_month: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('汇率'),
  addModalTitle: t('新建'),
  updateTitle: t('修改汇率'),
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    exchange_rate_id: 'id',
  },
  delete: {
    exchange_rate_id: 'id',
  },
  recover: {
    exchange_rate_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    exchange_rate_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.baseinfo.exchangerate.column.title.index'),
    dataIndex: 'index',
  },
  {
    title: t('本地币种'),
    dataIndex: 'local_currency__currency',
    checked: true,
  },
  {
    title: t('合同币种'),
    dataIndex: 'aim_currency__currency',
    checked: true,
  },
  {
    title: t('page.baseinfo.exchangerate.column.title.rate'),
    dataIndex: 'rate',
    checked: true,
  },
  {
    title: t('服务月份'),
    dataIndex: 'service_month',
    checked: true,
    sorter: {
      multiple: 1,
    },
  },
  {
    title: t('创建时间'),
    dataIndex: 'create_time',
    checked: true,
    sorter: {
      multiple: 1,
    },
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('更新时间'),
    dataIndex: 'update_time',
    checked: true,
    sorter: {
      multiple: 1,
    },
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('page.baseinfo.exchangerate.column.title.is_active'),
    dataIndex: 'is_active',
    checked: true,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },
  {
    title: t('page.baseinfo.exchangerate.column.title.action'),
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  // slotName: 'is_active',
  field_true: '可用',
  field_false: '禁用',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_exchange_rate_list,
    addData: create_exchange_rate,
    updateData: update_exchange_rate,
    removeData: delete_exchange_rate,
    recoverData: recover_exchange_rate,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
