import { query_payslip_detail_list } from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/cerp/cerp-payslip_report-config';
import { reactive } from 'vue';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '隶属顾问',
      label_i18n: t('page.cerp.address.form.label.adviser_id'),
      disabled: false,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      placeholder_i18n: t('page.cerp.address.form.placeholder.adviser_id'),
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: t('选择隶属顾问'),
      modalType: 'cerp-adviser',
    },
    {
      type: 'remoteselect',
      name: 'cc_bank_account_id',
      label: 'CC银行账户',
      label_i18n: 'CC银行账户',
      placeholder_i18n: '请选择CC银行账户',
      disabled: false,
      placeholder: '请选择CC银行账户',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'account_flag',
      valueKey: 'id',
      modalType: 'baseinfo-ccbankaccountscc',
    },
    {
      type: 'remoteselect',
      name: 'pay_currency_id',
      labelKeyList: ['code', 'currency'],
      label: '付款币种',
      label_i18n: 'page.cerp.contract.colums.column.title.pay_currency__currency',
      disabled: false,
      placeholder: '请选择付款币种',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.pay_currency_id',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-country',
    },
    {
      type: 'remoteselect',
      name: 'currency_id',
      labelKeyList: ['code', 'currency'],
      label: '合同薪资币种',
      label_i18n: '合同薪资币种',
      placeholder_i18n: '请选择合同薪资币种',
      disabled: false,
      placeholder: '请选择合同薪资币种',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-country',
    },
    {
      type: 'remoteselect',
      name: 'local_pay_currency_id',
      labelKeyList: ['code', 'currency'],
      label: '合同本地发薪币种',
      label_i18n: '合同本地发薪币种',
      placeholder_i18n: '请选择合同本地发薪币种',
      disabled: false,
      placeholder: '请选择合同本地发薪币种',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-country',
    },
    {
      type: 'monthpicker',
      name: 'service_month',
      label: '服务月份',
      label_i18n: t('page.cerp.invoice.form.label.service_month'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      format: 'YYYY-MM',
      placeholder: '请填写开始时间',
      placeholder_i18n: t('page.cerp.invoice.form.placeholder.service_month'),
    },
    {
      type: 'select',
      name: 'invoice_type',
      label: '请款类型',
      label_i18n: t('page.cerp.invoice.form.label.invoice_type'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择请款类型',
      placeholder_i18n: t('page.cerp.invoice.form.placeholder.invoice_type'),
      datasource: 'invoice_type',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    service_month: null,
    invoice_type: null,
  }),
};
const search_options = {
  invoice_type: [
    { value: None, name: t('全部') },
    { value: 1, name: t('工资') },
    { value: 2, name: t('报销') },
    { value: 3, name: 'credit note' },
    { value: 4, name: t('加班') },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [],
  rules: {},
  model: reactive({}),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [],
  rules: {},
  model: reactive({}),
};
const update_model_config_options = {};

const titleList = {
  title: t('工资单报表'),
  addModalTitle: t('新建工资单'),
  updateTitle: t('修改工资单'),
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: false, // 是否有【删除】按钮
  is_recover: false, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    payslip_id: 'id',
  },
  delete: {
    payslip_id: 'id',
  },
  recover: {
    payslip_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    payslip_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.cerp.payslip.column.title.index'),
    dataIndex: 'index',
    width: 50,
    fixed: 'left',
  },
  {
    title: t('page.cerp.payslip.column.title.id'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.cerp.invoice.column.title.adviser__employee_number'),
    width: 80,
    dataIndex: 'adviser__employee_number',
    checked: true,
    sorter: {
      multiple: 1,
    },
  },
  {
    title: t('page.cerp.payslip.column.title.adviser__full_name'),
    width: 150,
    dataIndex: 'adviser__full_name',
    checked: true,
    fixed: 'left',
  },
  {
    title: t('page.cerp.invoice.column.title.service_month'),
    width: 150,
    dataIndex: 'invoice__service_month',
    checked: true,
    sorter: {
      multiple: 1,
    },
    cellComponent: 'month',
  },
  {
    title: t('page.cerp.payslip.column.title.invoice_type'),
    width: 100,
    dataIndex: 'invoice__invoice_type',
    checked: true,
    cellComponent: 'custom',
    field_1: t('工资'),
    field_2: t('报销'),
    field_3: 'credit note',
    field_4: t('加班'),
    field_5: t('CC报销'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('工资'), value: 1 },
      { text: t('报销'), value: 2 },
      { text: 'credit note', value: 3 },
      { text: t('加班'), value: 4 },
      { text: t('CC报销'), value: 5 },
    ],
  },
  {
    title: t('page.cerp.payslip.column.title.management_fee'),
    width: 150,
    dataIndex: 'management_fee',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.payslip.column.title.bank_charge_cost'),
    width: 150,
    dataIndex: 'bank_charge_cost',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.payslip.column.title.insurance_fee'),
    width: 150,
    dataIndex: 'insurance_fee',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.payslip.column.title.exchange_rate'),
    width: 150,
    dataIndex: 'exchange_rate',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.payslip.column.title.pay_currency__currency'),
    width: 150,
    dataIndex: 'pay_currency__currency',
    checked: true,
  },
  {
    title: t('page.cerp.payslip.column.title.invoice__contract__currency__currency'),
    width: 150,
    dataIndex: 'invoice__contract__currency__currency',
    checked: true,
  },
  {
    title: t('page.cerp.payslip.column.title.invoice__contract__local_pay_currency__currency'),
    width: 150,
    dataIndex: 'invoice__contract__local_pay_currency__currency',
    checked: true,
  },
  {
    title: t('page.cerp.payslip.column.title.cc_bank_account__account_flag'),
    width: 150,
    dataIndex: 'cc_bank_account__account_flag',
    checked: true,
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  slotName: 'invoice_type',
  field_1: '工资',
  field_2: '报销',
  field_3: 'credit note',
  field_4: '加班',
  field_5: 'CC报销',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_payslip_detail_list,
    addData: null,
    updateData: null,
    removeData: null,
    recoverData: null,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
