import { queryUrlNotBind } from '@/api/permission/apiurl-manage.ts';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/permission/permission-apiurl_manage2-config';

import { reactive } from 'vue';

export default () => {
  const None = null;
  const True = true;
  const False = false;

  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

  const { t } = useI18n();
  const search_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
      {
        type: 'input',
        name: 'name',
        label: '接口名称',
        label_i18n: '接口名称',
        placeholder_i18n: '请填写接口名称',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        maxlength: 100,
        defaultValue: '',
        placeholder: '请填写接口名称',
      },
      {
        type: 'radio',
        name: 'is_auth',
        label: '是否需要授权',
        label_i18n: '是否需要授权',
        placeholder_i18n: '请选择是否授权',
        disabled: false,
        labelKey: 'name',
        valueKey: 'value',
        placeholder: '请选择是否授权',
        datasource: 'is_auth',
        mode: 'default',
      },
      {
        type: 'radio',
        name: 'is_active',
        label: '状态',
        label_i18n: '状态',
        disabled: false,
        defaultValue: '',
        labelKey: 'name',
        valueKey: 'value',
        placeholder: '请选择状态',
        datasource: 'is_active',
        mode: 'default',
      },
    ],
    rules: {},
    model: reactive({
      org_id: current_org.id,
      is_active: true,
      is_auth: '',
      name: '',
    }),
  };
  const search_options = {
    is_active: [
      { name: t('全部'), value: '' },
      { name: t('正常'), value: true },
      { name: t('作废'), value: false },
    ],
    is_auth: [
      { name: t('全部'), value: '' },
      { name: t('是'), value: true },
      { name: t('否'), value: false },
    ],
  };

  const edit_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [
      {
        type: 'input',
        name: 'name',
        label: '接口名称',
        label_i18n: '接口名称',
        placeholder_i18n: '请填写接口名称',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        maxlength: 100,
        defaultValue: '',
        placeholder: '接口名称',
      },
      {
        type: 'input',
        name: 'url',
        label: '绝对路径',
        label_i18n: '绝对路径',
        placeholder_i18n: '绝对路径',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        maxlength: 100,
        defaultValue: '',
        placeholder: '绝对路径',
      },
      {
        type: 'radio',
        name: 'is_auth', // 传值字段
        label: '是否需要授权',
        label_i18n: '是否需要授权',
        placeholder_i18n: '请选择是否授权',
        disabled: false,
        labelKey: 'name', // options数据
        valueKey: 'value',
        placeholder: '请选择是否授权',
        datasource: 'is_auth', // 接参字段
      },
    ],
    rules: {
      name: [{ required: true, message: t('请输入接口名称') }],
      url: [{ required: true, message: t('请输入绝对路径') }],
    },
    model: {
      org_id: current_org.id,
      name: '',
      url: '',
      is_auth: null,
    },
  };
  const edit_model_config_options = {
    is_auth: [
      { name: t('是'), value: true },
      { name: t('否'), value: false },
    ],
  };

  const update_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [
      {
        type: 'input',
        name: 'name',
        label: '接口名称',
        label_i18n: '接口名称',
        placeholder_i18n: '请填写接口名称',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        maxlength: 100,
        defaultValue: '',
        placeholder: '接口名称',
      },
      {
        type: 'input',
        name: 'url',
        label: '绝对路径',
        label_i18n: '绝对路径',
        placeholder_i18n: '绝对路径',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        maxlength: 100,
        defaultValue: '',
        placeholder: '绝对路径',
      },
      {
        type: 'radio',
        name: 'is_auth', // 传值字段
        label: '是否需要授权',
        label_i18n: '是否需要授权',
        placeholder_i18n: '请选择是否授权',
        disabled: false,
        labelKey: 'name', // options数据
        valueKey: 'value',
        placeholder: '请选择是否授权',
        datasource: 'is_auth', // 接参字段
      },
    ],
    rules: {},
    model: reactive({
      org_id: current_org.id,
      name: '',
      url: '',
      is_auth: null,
    }),
  };
  const update_model_config_options = {
    is_auth: [
      { name: t('是'), value: true },
      { name: t('否'), value: false },
    ],
  };

  const titleList = {
    title: t('接口列表'),
    addModalTitle: t('新建接口'),
    updateTitle: t('修改'),
    is_create: false, // 是否有【新建】按钮
    is_update: false, // 是否有【修改】按钮
    is_remove: false, // 是否有【删除】按钮
    is_recover: false, // 是否有【恢复】按钮
  };

  const api_fields: Record = {
    update: {
      apiurl_id: 'id',
    },
    delete: {
      apiurl_id: 'id',
    },
    recover: {
      apiurl_id: 'id',
    },
    updateData: {
      org_id: 'org_id',
      apiurl_id: 'id',
    },
  };

  const baseColumns: TableColumn[] = [
    {
      title: t('序号'),
      width: 50,
      dataIndex: 'index',
      fixed: 'left',
    },
    {
      title: t('名称'),
      dataIndex: 'name',
      fixed: 'left',
    },
    {
      title: 'url',
      dataIndex: 'url',
    },
    {
      title: t('是否需要授权'),
      width: 130,
      dataIndex: 'is_auth',
      cellComponent: 'boolean',
      name1: t('是'),
      name2: t('否'),
    },
    {
      title: t('是否可用'),
      width: 100,
      dataIndex: 'is_active',
      checked: true,
      resizable: true,
      cellComponent: 'boolean',
      name1: t('可用'),
      name2: t('禁用'),
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('可用'), value: 'true' },
        { text: t('禁用'), value: 'false' },
      ],
    },
  ];
  const newSlotList: any = [];
  Object.assign(newSlotList, slotList);
  newSlotList.push(
    {
      type: 'custom',
      // slotName: 'is_active',
      field_true: '可用',
      field_false: '禁用',
    },
    {
      type: 'custom',
      slotName: 'address_type',
      field_0: '家庭地址',
      field_1: '工作地址',
    },
  );

  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: queryUrlNotBind,
    addData: null,
    updateData: null,
    removeData: null,
    recoverData: null,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};