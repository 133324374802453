import {
  create_note_type,
  delete_note_type,
  query_note_type_list,
  recover_note_type,
  update_note_type,
} from '@/api/baseinfo-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/baseinfo/baseinfo-notetype-config';

import { reactive } from 'vue';
import { getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '记录类型',
      label_i18n: t('page.baseinfo.notetype.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写记录类型',
      placeholder_i18n: t('page.baseinfo.notetype.form.placeholder.name'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: t('全部') },
    { value: True, name: t('可用') },
    { value: False, name: t('禁用') },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '记录类型',
      label_i18n: t('page.baseinfo.notetype.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写记录类型',
      placeholder_i18n: t('page.baseinfo.notetype.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'description',
      label: '描述',
      label_i18n: t('page.baseinfo.notetype.form.label.description'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写描述',
      placeholder_i18n: t('page.baseinfo.notetype.form.placeholder.description'),
    },
    {
      type: 'input',
      name: 'flag',
      label: '标记',
      label_i18n: t('page.baseinfo.notetype.form.label.flag'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写标记',
      placeholder_i18n: t('page.baseinfo.notetype.form.placeholder.flag'),
    },
  ],
  rules: {
    name: [{ required: true, message: t('page.baseinfo.notetype.form.placeholder.name') }],
    description: [{ required: true, message: t('page.baseinfo.notetype.form.placeholder.description') }],
    flag: [{ required: true, message: t('page.baseinfo.notetype.form.placeholder.flag') }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    description: null,
    flag: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '记录类型',
      label_i18n: t('page.baseinfo.notetype.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写记录类型',
      placeholder_i18n: t('page.baseinfo.notetype.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'description',
      label: '描述',
      label_i18n: t('page.baseinfo.notetype.form.label.description'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写描述',
      placeholder_i18n: t('page.baseinfo.notetype.form.placeholder.description'),
    },
    {
      type: 'input',
      name: 'flag',
      label: '标记',
      label_i18n: t('page.baseinfo.notetype.form.label.flag'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写标记',
      placeholder_i18n: t('page.baseinfo.notetype.form.placeholder.flag'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    description: null,
    flag: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('记录类型管理'),
  addModalTitle: t('新建记录类型'),
  updateTitle: t('修改记录类型'),
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    note_type_id: 'id',
  },
  delete: {
    note_type_id: 'id',
  },
  recover: {
    note_type_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    note_type_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.baseinfo.notetype.column.title.index'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('page.baseinfo.notetype.column.title.id'),
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.baseinfo.notetype.column.title.name'),
    dataIndex: 'name',
    checked: true,
  },
  {
    title: t('page.baseinfo.notetype.column.title.description'),
    dataIndex: 'description',
    checked: true,
  },
  {
    title: t('page.baseinfo.notetype.column.title.create_time'),
    dataIndex: 'create_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('page.baseinfo.notetype.column.title.update_time'),
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('page.baseinfo.notetype.column.title.is_active'),
    dataIndex: 'is_active',
    checked: true,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('page.baseinfo.notetype.column.title.action'),
    width: 120,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  // slotName: 'is_active',
  field_true: '可用',
  field_false: '禁用',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_note_type_list,
    addData: create_note_type,
    updateData: update_note_type,
    removeData: delete_note_type,
    recoverData: recover_note_type,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
