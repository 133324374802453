import {
  create_page_info,
  delete_page_info,
  query_page_info_list,
  recover_page_info,
  update_page_info,
} from '@/api/apidoc-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/apidoc/apidoc-pageinfo-config';

import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '菜单名称',
      label_i18n: '菜单名称',
      placeholder_i18n: '请填写菜单名称',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写菜单名称',
    },
    {
      type: 'input',
      name: 'flag',
      label: '页面组件标记',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写页面组件标记',
    },
    {
      type: 'remoteselect',
      name: 'app_id',
      label: '隶属app',
      disabled: false,
      placeholder: '请选择隶属app',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'apidoc-app',
    },
    {
      type: 'input',
      name: 'model_class',
      label: '对应的model',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写对应的model',
    },
    {
      type: 'input',
      name: 'create_api',
      label: '创建接口',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写创建接口',
    },
    {
      type: 'input',
      name: 'update_api',
      label: '修改接口',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写修改接口',
    },
    {
      type: 'input',
      name: 'delete_api',
      label: '删除接口',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写删除接口',
    },
    {
      type: 'input',
      name: 'recover_api',
      label: '恢复接口',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写恢复接口',
    },
    {
      type: 'input',
      name: 'get_api',
      label: '详情接口',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写详情接口',
    },
    {
      type: 'input',
      name: 'table_api',
      label: '查询接口',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写查询接口',
    },
    {
      type: 'input',
      name: 'm2m_api',
      label: '多对多属性修改接口',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写多对多属性修改接口',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    flag: null,
    app_id: null,
    model_class: null,
    create_api: null,
    update_api: null,
    delete_api: null,
    recover_api: null,
    get_api: null,
    table_api: null,
    m2m_api: null,
    is_modefy: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
  is_modefy: [
    { value: None, name: '全部' },
    { value: True, name: '允许代码修改' },
    { value: False, name: '不允许代码修改' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '菜单名称',
      label_i18n: '菜单名称',
      placeholder_i18n: '请填写菜单名称',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写菜单名称',
    },
    {
      type: 'input',
      name: 'flag',
      label: '页面组件标记',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写页面组件标记',
    },
    {
      type: 'remoteselect',
      name: 'app_id',
      label: '隶属app',
      disabled: false,
      placeholder: '请选择隶属app',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'rapidoc-app',
    },
    {
      type: 'remoteselect',
      name: 'git_branch_id',
      label: 'git的分支名',
      disabled: false,
      placeholder: '请选择git的分支名',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'apidoc-gitbranch',
    },
    {
      type: 'input',
      name: 'model_class',
      label: '对应的model',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写对应的model',
    },
    {
      type: 'input',
      name: 'create_api',
      label: '创建接口',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写创建接口',
    },
    {
      type: 'input',
      name: 'update_api',
      label: '修改接口',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写修改接口',
    },
    {
      type: 'input',
      name: 'delete_api',
      label: '删除接口',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写删除接口',
    },
    {
      type: 'input',
      name: 'recover_api',
      label: '恢复接口',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写恢复接口',
    },
    {
      type: 'input',
      name: 'get_api',
      label: '详情接口',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写详情接口',
    },
    {
      type: 'input',
      name: 'table_api',
      label: '查询接口',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写查询接口',
    },
    {
      type: 'input',
      name: 'm2m_api',
      label: '多对多属性修改接口',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写多对多属性修改接口',
    },
    {
      type: 'radio',
      name: 'is_modefy',
      label: '是否允许代码自动修改',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否允许代码自动修改',
    },
  ],
  rules: {
    name: [{ required: true, message: '请填输入菜单名称' }],
    flag: [{ required: true, message: '请填输入页面组件标记' }],
    app_id: [{ required: true, message: '请填输入隶属app' }],
    model_class: [{ required: true, message: '请填输入对应的model' }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    flag: null,
    app_id: null,
    git_branch_id: null,
    model_class: null,
    create_api: null,
    update_api: null,
    delete_api: null,
    recover_api: null,
    get_api: null,
    table_api: null,
    m2m_api: null,
    is_modefy: null,
  }),
};
const edit_model_config_options = {
  is_modefy: [
    { value: True, name: '允许代码修改' },
    { value: False, name: '不允许代码修改' },
  ],
};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '菜单名称',
      label_i18n: '菜单名称',
      placeholder_i18n: '请填写菜单名称',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写菜单名称',
    },
    {
      type: 'input',
      name: 'flag',
      label: '页面组件标记',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写页面组件标记',
    },
    {
      type: 'remoteselect',
      name: 'app_id',
      label: '隶属app',
      disabled: false,
      placeholder: '请选择隶属app',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'apidoc-app',
    },
    {
      type: 'remoteselect',
      name: 'git_branch_id',
      label: 'git的分支名',
      disabled: false,
      placeholder: '请选择git的分支名',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'apidoc-gitbranch',
    },
    {
      type: 'input',
      name: 'model_class',
      label: '对应的model',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写对应的model',
    },
    {
      type: 'input',
      name: 'create_api',
      label: '创建接口',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写创建接口',
    },
    {
      type: 'input',
      name: 'update_api',
      label: '修改接口',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写修改接口',
    },
    {
      type: 'input',
      name: 'delete_api',
      label: '删除接口',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写删除接口',
    },
    {
      type: 'input',
      name: 'recover_api',
      label: '恢复接口',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写恢复接口',
    },
    {
      type: 'input',
      name: 'get_api',
      label: '详情接口',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写详情接口',
    },
    {
      type: 'input',
      name: 'table_api',
      label: '查询接口',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写查询接口',
    },
    {
      type: 'input',
      name: 'm2m_api',
      label: '多对多属性修改接口',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写多对多属性修改接口',
    },
    {
      type: 'radio',
      name: 'is_modefy',
      label: '是否允许代码自动修改',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否允许代码自动修改',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    flag: null,
    app_id: null,
    git_branch_id: null,
    model_class: null,
    create_api: null,
    update_api: null,
    delete_api: null,
    recover_api: null,
    get_api: null,
    table_api: null,
    m2m_api: null,
    is_modefy: null,
  }),
};
const update_model_config_options = {
  is_modefy: [
    { value: True, name: '允许代码修改' },
    { value: False, name: '不允许代码修改' },
  ],
};

const titleList = {
  title: 'Antd页面组件管理',
  addModalTitle: '新建Antd页面组件',
  updateTitle: '修改Antd页面组件',
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    pageinfo_id: 'id',
  },
  delete: {
    pageinfo_id: 'id',
  },
  recover: {
    pageinfo_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    pageinfo_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    width: 50,
    slots: { customRender: 'index' },
  },
  {
    title: '项目主键',
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: '菜单名称',
    width: 150,
    dataIndex: 'name',
    checked: true,
  },
  {
    title: '页面组件标记',
    width: 150,
    dataIndex: 'flag',
    checked: true,
  },
  {
    title: '隶属app',
    width: 150,
    dataIndex: 'app_id',
    checked: true,
  },
  {
    title: '应用名称',
    width: 150,
    dataIndex: 'app__name',
    checked: true,
  },
  {
    title: 'git的分支名',
    width: 150,
    dataIndex: 'git_branch_id',
    checked: true,
  },
  {
    title: '分支名称',
    width: 150,
    dataIndex: 'git_branch__name',
    checked: true,
  },
  {
    title: '对应的model',
    width: 150,
    dataIndex: 'model_class',
    checked: true,
  },
  {
    title: '创建接口',
    width: 150,
    dataIndex: 'create_api',
    checked: true,
  },
  {
    title: '修改接口',
    width: 150,
    dataIndex: 'update_api',
    checked: true,
  },
  {
    title: '删除接口',
    width: 150,
    dataIndex: 'delete_api',
    checked: true,
  },
  {
    title: '恢复接口',
    width: 150,
    dataIndex: 'recover_api',
    checked: true,
  },
  {
    title: '详情接口',
    width: 150,
    dataIndex: 'get_api',
    checked: true,
  },
  {
    title: '查询接口',
    width: 150,
    dataIndex: 'table_api',
    checked: true,
  },
  {
    title: '多对多属性修改接口',
    width: 150,
    dataIndex: 'm2m_api',
    checked: true,
  },
  {
    title: '是否允许代码自动修改',
    width: 150,
    dataIndex: 'is_modefy',
    checked: true,

    slots: { customRender: 'is_modefy' },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: '允许代码修改', value: true },
      { text: '不允许代码修改', value: false },
    ],
  },
  {
    title: t('创建时间'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,

    slots: { customRender: 'datetime' },
  },
  {
    title: '更新日期',
    width: 150,
    dataIndex: 'update_time',
    checked: false,

    slots: { customRender: 'datetime' },
  },
  {
    title: t('是否可用'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,

    slots: { customRender: 'is_active' },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('操作'),
    width: 200,
    dataIndex: 'action',
    checked: false,
    slots: { customRender: 'action' },
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push(
  {
    type: 'custom',
    slotName: 'is_modefy',
    field_true: '允许代码修改',
    field_false: '不允许代码修改',
  },
  { type: 'custom', field_true: '可用', field_false: '禁用' },
);

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_page_info_list,
    addData: create_page_info,
    updateData: update_page_info,
    removeData: delete_page_info,
    recoverData: recover_page_info,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
