import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';

import modalBox from '@/components/form-modal/modal-tools';
import { reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { set_global_custom_data_by_flag } from '@/api/sys-model';
import { get_file_url } from '@/api/nsbcs-modal';

export const event_obj = mitt();
export const btn_list = [
  {
    name: '更换图片',
    clazz: 'primary',
    type: 'dropdown',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('更换图片'),
        group: '微信小程序主页',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;
            get_file_url({
              fileid: payload,
            }).then((tempRes: any) => {
              data.img_url_a = `https://erp-cc.oss-cn-hongkong.aliyuncs.com/public${tempRes?.split('/public')[1]}`;
              const newData: any = {
                data: [data],
                current: 1,
                success: true,
                pageSize: 20,
                total: 1,
              };
              set_global_custom_data_by_flag({
                org_id: current_org?.id,
                flag: 'wechat_mini_main_page',
                data: newData,
              })
                .then(() => {
                  message.success(t('修改成功'));
                  event_obj.emit('refresh');
                  resolve(null);
                })
                .finally(() => {
                  loading.value = false;
                });
            });

          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '修改文字',
    clazz: 'primary',
    type: 'dropdown',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const editModal = reactive({
        visible: true,
        title: t('修改文字'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
          },
          fields: [
            {
              type: 'input',
              name: 'img_url_a',
              label: '图片',
              label_i18n: '图片',
              disabled: true,
              placeholder: '请图片',
              datasourceType: 'remote',
              allowClear: false,
            },
            {
              type: 'textarea',
              name: 'text_a',
              label: '文字',
              label_i18n: '文字',
              disabled: false,
              placeholder: '请填写文字',
              placeholder_i18n: '请填写文字',
              allowClear: true,
            },
          ],
          rules: {},
          model: reactive({
            ...data,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            data.text_a = payload.text_a;
            const newData: any = {
              data: [data],
              current: 1,
              success: true,
              pageSize: 20,
              total: 1,
            };
            set_global_custom_data_by_flag({
              org_id: current_org?.id,
              flag: 'wechat_mini_main_page',
              data: newData,
            })
              .then(() => {
                message.success(t('修改成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
