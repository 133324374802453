import {
  create_insurance_type,
  delete_insurance_type,
  query_insurance_type_list,
  recover_insurance_type,
  update_insurance_type,
} from '@/api/baseinfo-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/staticinfo/staticinfo-insurance_type-config';

import { reactive } from 'vue';
import { getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: '名称',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: '请填写名称',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
  }),
};
const search_options = {};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: '名称',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: '请填写名称',
    },
    {
      type: 'money',
      name: 'month_insurance_fee',
      label: '每月保费',
      label_i18n: '每月保费',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写每月保费',
      placeholder_i18n: '请填写每月保费',
    },
    {
      type: 'money',
      name: 'insurance_fee',
      label: '保费成本',
      label_i18n: '保费成本',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写保费成本',
      placeholder_i18n: '请填写保费成本',
    },
    {
      type: 'textarea',
      name: 'remark',
      label: '备注',
      label_i18n: '备注',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填写备注',
      placeholder_i18n: '请填写备注',
    },
  ],
  rules: {
    name: [{ required: true, message: t('请填写名称') }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    remark: null,
    month_insurance_fee: null,
    insurance_fee: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: '名称',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: '请填写名称',
    },
    {
      type: 'money',
      name: 'month_insurance_fee',
      label: '每月保费',
      label_i18n: '每月保费',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写每月保费',
      placeholder_i18n: '请填写每月保费',
    },
    {
      type: 'money',
      name: 'insurance_fee',
      label: '保费成本',
      label_i18n: '保费成本',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写保费成本',
      placeholder_i18n: '请填写保费成本',
    },
    {
      type: 'textarea',
      name: 'remark',
      label: '备注',
      label_i18n: '备注',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填写备注',
      placeholder_i18n: '请填写备注',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    remark: null,
    month_insurance_fee: null,
    insurance_fee: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('保险类型管理'),
  addModalTitle: t('新建保险类型'),
  updateTitle: t('修改'),
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    insurance_type_id: 'id',
  },
  delete: {
    insurance_type_id: 'id',
  },
  recover: {
    insurance_type_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    insurance_type_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: 'ID',
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('名称'),
    width: 150,
    dataIndex: 'name',
    checked: true,
  },
  {
    title: t('每月保费'),
    minWidth: 150,
    dataIndex: 'month_insurance_fee',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('保费成本'),
    minWidth: 150,
    dataIndex: 'insurance_fee',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('备注'),
    minWidth: 150,
    dataIndex: 'remark',
    checked: true,
  },
  {
    title: t('创建时间'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('修改时间'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('是否可用'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,

    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('操作'),
    width: 200,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  // slotName: 'is_active',
  field_true: '可用',
  field_false: '禁用',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_insurance_type_list,
    addData: create_insurance_type,
    updateData: update_insurance_type,
    removeData: delete_insurance_type,
    recoverData: recover_insurance_type,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};