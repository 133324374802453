import { addPerson, queryPerson, recoverPerson, removePerson, updatePerson } from '@/api/permission/user-manage';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const search_config: SearchConfig = {
  settings: {
    name: '表单名称',
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'remoteselect',
      name: 'aim_org_id',
      label: '目标组织',
      label_i18n: '目标组织',
      placeholder_i18n: '请选择目标组织',
      disabled: false,
      labelKey: 'name',
      valueKey: 'id',
      placeholder: '请填组织id',
      datasourceType: 'remote',
      datasource: 'aim_org_id',
      mode: 'default',
      modalType: 'select-org-id',
    },
    {
      type: 'input',
      name: 'name',
      label: '角色名称',
      label_i18n: '角色名称',
      placeholder_i18n: '请填写角色名称',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填写角色名称',
    },
  ],
  rules: {},
  model: {
    org_id: current_org?.id,
    aim_org_id: current_org?.id,
    is_active: true,
    name: '',
  },
};
const search_options = {};

const edit_model_config: SearchConfig = {
  settings: {
    name: '表单名称',
    labelAlign: 'right',
    layout: 'vertical',
  },
  fields: [
    {
      type: 'input',
      name: 'username',
      label: '手机号',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填手机号',
    },
    {
      type: 'input',
      name: 'realname',
      label: '真实姓名',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填真实姓名',
    },
    {
      type: 'input',
      name: 'title',
      label: '职务',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填职务',
    },
    {
      type: 'remoteselect',
      name: 'aim_org_id',
      label: '目标组织',
      label_i18n: '目标组织',
      placeholder_i18n: '请选择目标组织',
      disabled: false,
      labelKey: 'name',
      valueKey: 'id',
      placeholder: '请填组织id',
      datasourceType: 'remote',
      datasource: 'aim_org_id',
      mode: 'default',
      modalType: 'select-org-id',
    },
    {
      type: 'input',
      name: 'email',
      label: '电子邮箱',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填电子邮箱',
    },
  ],
  rules: {
    username: [{ required: true, message: '请输入手机号' }],
    realname: [{ required: true, message: '请输入真实姓名' }],
    title: [{ required: true, message: '请输入职务' }],
  },
  model: {
    id: null,
    username: '',
    realname: '',
    title: '',
    aim_org_id: '',
    email: '',
  },
};
const edit_model_config_options = {};
const update_edit_model_config: SearchConfig = {
  settings: {
    name: '表单名称',
    labelAlign: 'right',
    layout: 'vertical',
  },
  fields: [
    {
      type: 'input',
      name: 'username',
      label: '手机号',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填手机号',
    },
    {
      type: 'input',
      name: 'realname',
      label: '真实姓名',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填真实姓名',
    },
    {
      type: 'input',
      name: 'title',
      label: '职务',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填职务',
    },
    {
      type: 'remoteselect',
      name: 'aim_org_id',
      label: '目标组织',
      label_i18n: '目标组织',
      placeholder_i18n: '请选择目标组织',
      disabled: false,
      labelKey: 'name',
      valueKey: 'id',
      placeholder: '请填组织id',
      datasourceType: 'remote',
      datasource: 'aim_org_id',
      mode: 'default',
      modalType: 'select-org-id',
    },
    {
      type: 'input',
      name: 'email',
      label: '电子邮箱',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填电子邮箱',
    },
  ],
  rules: {
    username: [{ required: true, message: '请输入手机号' }],
    realname: [{ required: true, message: '请输入真实姓名' }],
    title: [{ required: true, message: '请输入职务' }],
  },
  model: {
    id: null,
    username: '',
    realname: '',
    title: '',
    aim_org_id: '',
    email: '',
  },
};
const update_edit_model_config_options = {};
const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    width: 50,
    slots: { customRender: 'index' },
  },
  {
    title: '名称',
    width: 90,
    dataIndex: 'realname',
  },
  {
    title: '手机号',
    width: 110,
    dataIndex: 'user__username',
  },
  {
    title: t('创建时间'),
    width: 150,
    dataIndex: 'create_time',
    slots: { customRender: 'time' },
  },
  {
    title: '微信',
    width: 70,
    dataIndex: 'user__lyuserinfo__wechat_username',
    slots: { customRender: 'wei' },
  },
  {
    title: '职务',
    width: 150,
    dataIndex: 'title',
  },
  {
    title: '隶属部门',
    // width: 200,
    dataIndex: 'group_list',
    slots: { customRender: 'group' },
  },
  {
    title: '状态',
    width: 70,
    dataIndex: 'is_active',
    slots: { customRender: 'is_active' },
  },
  {
    title: t('操作'),
    width: 200,
    dataIndex: 'action',
    slots: { customRender: 'action' },
  },
];
const slotList = [
  {
    slotName: 'index', // 槽名
  },
  {
    slotName: 'wei',
    type: 'boolean', // 类型：'boolean' - text字段有无、(true/false)
    name1: '已绑定', // text字段有或true 展示
    name2: '未绑定', // text字段无或false 展示
  },
  {
    slotName: 'group',
    type: 'list', // 类型：'list' - text字段为数组
  },
  {
    slotName: 'time',
    type: 'time', // 类型：'time' - text字段为时间
  },
];
const titleList = {
  title: '用户管理',
  addModalTitle: '新建用户',
  updateTitle: '修改用户',
  is_update: true, // 是否有【修改】按钮
  is_create: true, // 是否有【新建】按钮
  is_remove: true, // 是否有【删除】按钮
};

const api_fields: Record = {
  update: {
    username: 'user__username',
    aim_org_id: 'org_id',
    person_id: 'id',
  },
  delete: {
    person_id: 'id',
  },
  recover: {
    person_id: 'id',
  },
  updateData: {
    aim_org_id: 'org_id',
    role_id: 'id',
  },
};

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_edit_model_config,
    updateFormModalConfigOptions: update_edit_model_config_options,
    queryData: queryPerson,
    addData: addPerson,
    updateData: updatePerson,
    removeData: removePerson,
    recoverData: recoverPerson,
    baseColumns: baseColumns,
    slotList: slotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: null,
    head_btn_list: null,
    event_obj: null,
  };
};
