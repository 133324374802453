import { query_change_history_list } from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/cerp/cerp-changehistory-config';

import { reactive, ref } from 'vue';
import { getDateTime } from '@/utils/function';
import modalBox from '@/components/form-modal/modal-tools';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '隶属顾问',
      label_i18n: t('隶属顾问'),
      disabled: false,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      placeholder_i18n: t('隶属顾问'),
      show_search: false,
      maxTagCount: 5,
      allowClear: true,
      mode: 'default',
      title: t('选择隶属顾问'),
      modalType: 'cerp-adviser',
    },
    {
      type: 'input',
      name: 'model_str',
      label: 'model标记',
      label_i18n: t('page.cerp.changehistory.form.label.model_str'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写model标记',
      placeholder_i18n: t('page.cerp.changehistory.form.placeholder.model_str'),
    },
    {
      type: 'input',
      name: 'model_name',
      label: 'model名称',
      label_i18n: t('page.cerp.changehistory.form.label.model_name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写model名称',
      placeholder_i18n: t('page.cerp.changehistory.form.placeholder.model_name'),
    },
    {
      type: 'input',
      name: 'object_id',
      label: '数据ID',
      label_i18n: t('数据ID'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写数据ID',
      placeholder_i18n: t('请填写数据ID'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: null,
    is_active: true,
    code: null,
    name: null,
    currency: null,
    nationality: null,
    adviser_id: null,
    model_name: null,
    model_str: null,
    object_id: null,
  }),
};
const search_options = {};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    code: null,
    currency: null,
    nationality: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    code: null,
    currency: null,
    nationality: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('数据变动管理'),
  addModalTitle: t('数据变动'),
  updateTitle: t('数据变动'),
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: false, // 是否有【删除】按钮
  is_recover: false, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {},
  delete: {},
  recover: {},
  updateData: {},
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.cerp.changehistory.column.title.index'),
    dataIndex: 'index',
    width: 50,
    fixed: 'left',
  },
  {
    title: t('page.cerp.changehistory.column.title.id'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.cerp.changehistory.column.title.model_str'),
    width: 150,
    dataIndex: 'model_str',
    checked: true,
  },
  {
    title: t('page.cerp.changehistory.column.title.model_name'),
    width: 150,
    dataIndex: 'model_name',
    checked: true,
  },
  {
    title: t('page.cerp.beneficiary.column.title.adviser__full_name'),
    width: 150,
    dataIndex: 'adviser__full_name',
    checked: true,
  },
  {
    title: t('page.cerp.beneficiary.column.title.adviser__employee_number'),
    width: 150,
    dataIndex: 'adviser__employee_number',
    checked: true,
  },
  {
    title: t('page.cerp.changehistory.column.title.object_id'),
    width: 150,
    dataIndex: 'object_id',
    checked: true,
  },
  {
    title: t('page.cerp.changehistory.column.title.old_json'),
    minWidth: 150,
    dataIndex: 'old_json',
    checked: true,
    cellComponent: 'click',
    cb: (record: any) => {
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: 'old_json',
        component: 'json-view-modal',
        loading: loading,
        mode: 'javascript',
        params: record.old_json,
        okText: t('确定'),
        hold: () => {},
      });
      modalBox(editModal);
    },
  },
  {
    title: t('page.cerp.changehistory.column.title.new_json'),
    minWidth: 150,
    dataIndex: 'new_json',
    checked: true,
    cellComponent: 'click',
    cb: (record: any) => {
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: 'new_json',
        component: 'json-view-modal',
        loading: loading,
        mode: 'javascript',
        params: record.new_json,
        okText: t('确定'),
        hold: () => {},
      });
      modalBox(editModal);
    },
  },
  {
    title: t('page.cerp.changehistory.column.title.user_name'),
    width: 150,
    dataIndex: 'user_name',
    checked: true,
  },
  {
    title: t('page.cerp.changehistory.column.title.user_phone'),
    width: 150,
    dataIndex: 'user_phone',
    checked: true,
  },
  {
    title: t('page.cerp.changehistory.column.title.create_time'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('page.cerp.changehistory.column.title.update_time'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('page.cerp.changehistory.column.title.is_active'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('page.cerp.changehistory.column.title.action'),
    width: 200,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  // slotName: 'is_active',
  field_true: '可用',
  field_false: '禁用',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_change_history_list,
    addData: None,
    updateData: None,
    removeData: None,
    recoverData: None,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
