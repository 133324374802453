import {
  create_address,
  delete_address,
  query_error_custom_field_list,
  recover_address,
  update_address,
} from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/cerp/cerp-additional_item_abnormal_data-config';

import { reactive } from 'vue';
import moment from 'moment';

export default () => {
  const None = null;
  const True = true;
  const False = false;

  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

  const { t } = useI18n();
  const search_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
      {
        type: 'rangepicker',
        name: 'time_interval',
        label: '时间范围',
        label_i18n: '时间范围',
        placeholder_i18n: '时间范围',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        showTime: true,
        valueFormat: 'YYYY-MM-DD HH:mm:ss',
        placeholder: ['开始时间', '结束时间'],
      },
    ],
    rules: {},
    model: reactive({
      org_id: current_org?.id,
      is_active: true,
      time_interval: [
        moment().subtract(7, 'day').format('YYYY-MM-DD HH:mm:ss'),
        moment().format('YYYY-MM-DD HH:mm:ss'),
      ],
    }),
  };
  const search_options = {};

  const edit_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [],
    rules: {},
    model: reactive({}),
  };
  const edit_model_config_options = {};

  const update_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [],
    rules: {},
    model: reactive({}),
  };
  const update_model_config_options = {};

  const titleList = {
    title: t('额外项异常数据'),
    addModalTitle: '新建地址',
    updateTitle: '修改地址',
    is_create: false, // 是否有【新建】按钮
    is_update: false, // 是否有【修改】按钮
    is_remove: false, // 是否有【删除】按钮
    is_recover: false, // 是否有【恢复】按钮
  };

  const api_fields: Record = {
    update: {
      address_id: 'id',
    },
    delete: {
      address_id: 'id',
    },
    recover: {
      address_id: 'id',
    },
    updateData: {
      org_id: 'org_id',
      address_id: 'id',
    },
  };

  const baseColumns: TableColumn[] = [
    {
      title: t('序号'),
      dataIndex: 'index',
      width: 50,
    },
    {
      title: t('顾问'),
      width: 200,
      dataIndex: 'adviser',
      checked: true,
    },
    {
      title: t('工号'),
      width: 80,
      dataIndex: 'employee_number',
      checked: true,
    },
    {
      title: t('请款单号'),
      minWidth: 150,
      dataIndex: 'invoice_no',
      checked: true,
    },
    {
      title: t('工资单号'),
      minWidth: 150,
      dataIndex: 'payslip_no',
      checked: true,
    },
    {
      title: t('错误类型'),
      width: 80,
      dataIndex: 'error_type',
      checked: true,
      cellComponent: 'tag',
      content: (record: any, text: any) => {
        return text == 'invoice' ? t('请款单') : text == 'payslip' ? t('工资单') : '';
      },
    },
    {
      title: t('额外项名称'),
      width: 150,
      dataIndex: 'extra_name',
      checked: true,
    },
    {
      title: t('额外项数值'),
      width: 120,
      dataIndex: 'extra_value',
      checked: true,
    },
    {
      title: t('额外项隶属顾问'),
      width: 200,
      dataIndex: 'adviser_of_extra',
      checked: true,
    },
    {
      title: t('额外项隶属工号'),
      width: 120,
      dataIndex: 'employee_number_of_extra',
      checked: true,
    },
  ];
  const newSlotList: any = [];
  Object.assign(newSlotList, slotList);
  newSlotList.push(
    {
      type: 'custom',
      // slotName: 'is_active',
      field_true: '可用',
      field_false: '禁用',
    },
    {
      type: 'custom',
      slotName: 'address_type',
      field_0: '家庭地址',
      field_1: '工作地址',
    },
  );

  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_error_custom_field_list,
    addData: create_address,
    updateData: update_address,
    removeData: delete_address,
    recoverData: recover_address,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
