import {
  create_work_hour_template,
  delete_work_hour_template,
  query_work_hour_template_list,
  recover_work_hour_template,
  update_work_hour_template,
} from '@/api/baseinfo-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_COMPANY, STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/baseinfo/baseinfo-time_calendar-config';

import { reactive } from 'vue';
import { getDateTime } from '@/utils/function';
import moment from 'moment';

const None = null;
const True = true;
const False = false;
export default () => {
  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  const current_company = ls.get(STORAGE_CURRENT_COMPANY); // 当前cc公司

  const { t } = useI18n();
  const search_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
      {
        type: 'input',
        name: 'work_year',
        label: '日历年份',
        label_i18n: t('日历年份'),
        disabled: false,
        allowClear: true,
        inputType: 'number',
        defaultValue: '',
        placeholder: '请填写日历年份',
        placeholder_i18n: t('请填写日历年份'),
      },
      // {
      //   type: 'remoteselect',
      //   name: 'cc_company_id',
      //   label: 'CC公司',
      //   disabled: false,
      //   placeholder: '请选择CC公司',
      //   datasourceType: 'remote',
      //   mode: 'default',
      //   allowClear: true,
      //   modalType: 'baseinfo-cccompany',
      // },
      {
        type: 'remoteselect',
        name: 'country_id',
        label: '国家',
        label_i18n: t('page.baseinfo.cccompany.form.label.country_id'),
        disabled: false,
        placeholder: '请选择国家',
        placeholder_i18n: t('page.baseinfo.cccompany.form.placeholder.country_id'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        labelKey: 'name',
        valueKey: 'id',
        modalType: 'baseinfo-country',
      },
      {
        type: 'input',
        name: 'cc_company_name',
        label: 'CC公司',
        label_i18n: t('CC公司'),
        disabled: true,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写CC公司',
        placeholder_i18n: t('请填写CC公司'),
      },
    ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    work_year: Number(moment().format('YYYY')),
    cc_company_id: current_company?.id,
    cc_company_name: current_company?.name !== '全部公司' ? current_company?.name : '',
    country_id: null,
  }),
};
const search_options = {};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [],
  rules: {
    work_year: [{ required: true, message: t('请填写日历年份') }],
    cc_company_id: [{ required: true, message: t('请填写CC公司') }],
    country_id: [{ required: true, message: t('请选择国家') }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    work_year: Number(moment().format('YYYY')),
    cc_company_id: null,
    country_id: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'work_year',
      label: '日历年份',
      label_i18n: t('日历年份'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写日历年份',
      placeholder_i18n: t('请填写日历年份'),
    },
    {
      type: 'remoteselect',
      name: 'cc_company_id',
      label: 'CC公司',
      label_i18n: t('CC公司'),
      disabled: false,
      placeholder: '请选择CC公司',
      placeholder_i18n: t('请选择CC公司'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-cccompany',
    },
    {
      type: 'remoteselect',
      name: 'country_id',
      label: '国家',
      label_i18n: t('page.baseinfo.cccompany.form.label.country_id'),
      disabled: false,
      placeholder: '请选择国家',
      placeholder_i18n: t('page.baseinfo.cccompany.form.placeholder.country_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'name',
      valueKey: 'id',
      modalType: 'baseinfo-country',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    work_year: null,
    cc_company_id: null,
    country_id: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('工时年历'),
  addModalTitle: '',
  updateTitle: t('修改工时模板'),
  is_create: false, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    work_hour_template_id: 'id',
  },
  delete: {
    work_hour_template_id: 'id',
  },
  recover: {
    work_hour_template_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    work_hour_template_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('ID'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('年份'),
    width: 80,
    dataIndex: 'work_year',
    checked: true,
  },
  {
    title: t('国家'),
    minWidth: 150,
    dataIndex: 'country__name',
    checked: true,
  },
  {
    title: t('CC公司'),
    minWidth: 150,
    dataIndex: 'cc_company__name',
    checked: true,
  },
  {
    title: t('创建时间'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('修改时间'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('是否可用'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('操作'),
    width: 150,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
  const newSlotList: any = [];
  Object.assign(newSlotList, slotList);
  newSlotList.push({
    type: 'custom',
    // slotName: 'is_active',
    field_true: '可用',
    field_false: '禁用',
  });

  const route = useRoute();
  search_config.model = reactive({ ...search_config.model });
  search_config.model.cc_company_id = route.query.cc_company_id ? route.query.cc_company_id : current_company?.id;
  search_config.model.cc_company_name = route.query.cc_company_name ? (route.query.cc_company_name !== '全部公司' ? route.query.cc_company_name : '') : (current_company?.name !== '全部公司' ? current_company?.name : '');
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_work_hour_template_list,
    addData: create_work_hour_template,
    updateData: update_work_hour_template,
    removeData: delete_work_hour_template,
    recoverData: recover_work_hour_template,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};