import { addPerson, queryPerson, recoverPerson, removePerson, updatePerson } from '@/api/permission/user-manage';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/sys/sys-user_list-config';

import { reactive } from 'vue';
import { getDateTime } from '@/utils/function';

export default () => {
  const None = null;
  const True = true;
  const False = false;

  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

  const { t } = useI18n();
  const search_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
      {
        type: 'remoteselect',
        name: 'aim_org_id',
        label: '目标组织',
        label_i18n: '目标组织',
        placeholder_i18n: '请选择目标组织',
        disabled: false,
        labelKey: 'name',
        valueKey: 'id',
        placeholder: '请填组织id',
        datasourceType: 'remote',
        datasource: 'aim_org_id',
        mode: 'default',
        modalType: 'select-org-id',
      },
      {
        type: 'input',
        name: 'name',
        label: '名称',
        label_i18n: '名称',
        placeholder_i18n: '请填写名称',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        maxlength: 100,
        defaultValue: '',
        placeholder: '请填写名称',
      },
      {
        type: 'input',
        name: 'username',
        label: '手机号（用户名）',
        label_i18n: '手机号（用户名）',
        placeholder_i18n: '请填写手机号（用户名）',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        maxlength: 100,
        defaultValue: '',
        placeholder: '请填写手机号（用户名）',
      },
      {
        type: 'remoteselect',
        name: 'role_id',
        label: '角色',
        label_i18n: '角色',
        placeholder_i18n: '请选择角色',
        disabled: false,
        labelKey: 'name',
        allowClear: true,
        valueKey: 'id',
        placeholder: '请选择角色',
        datasource: '',
        mode: 'default',
        modalType: 'sys-query_role_list',
      },
    ],
    rules: {},
    model: reactive({
      org_id: current_org?.id,
      aim_org_id: current_org?.id,
      is_active: true,
      name: null,
      username: null,
      get_role: true,
      role_id: 2,
    }),
  };
  const search_options = {};

  const edit_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [
      {
        type: 'remoteselect',
        name: 'aim_org_id',
        label: '目标组织',
        label_i18n: '目标组织',
        placeholder_i18n: '请选择目标组织',
        disabled: false,
        labelKey: 'name',
        valueKey: 'id',
        placeholder: '请填组织id',
        datasourceType: 'remote',
        datasource: 'aim_org_id',
        mode: 'default',
        modalType: 'select-org-id',
      },
      {
        type: 'input',
        name: 'username',
        label: '手机号',
        label_i18n: '手机号',
        placeholder_i18n: '请填手机号',
        disabled: false,
        allowClear: true,
        inputType: 'number',
        maxlength: 100,
        defaultValue: '',
        placeholder: '请填手机号',
      },
      {
        type: 'input',
        name: 'realname',
        label: '真实姓名',
        label_i18n: '真实姓名',
        placeholder_i18n: '请填真实姓名',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        maxlength: 100,
        defaultValue: '',
        placeholder: '请填真实姓名',
      },
      {
        type: 'input',
        name: 'title',
        label: '职务',
        label_i18n: '职务',
        placeholder_i18n: '请填职务',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        maxlength: 100,
        defaultValue: '',
        placeholder: '请填职务',
      },
      {
        type: 'input',
        name: 'email',
        label: '电子邮箱',
        label_i18n: '电子邮箱',
        placeholder_i18n: '请填电子邮箱',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        maxlength: 100,
        defaultValue: '',
        placeholder: '请填电子邮箱',
      },
      {
        type: 'remoteselect',
        name: 'group_ids',
        label: '所属部门',
        label_i18n: '所属部门',
        placeholder_i18n: '请选择所属部门',
        disabled: false,
        labelKey: 'name',
        valueKey: 'id',
        placeholder: '请选择所属部门',
        datasource: '',
        mode: 'multiple',
        modalType: 'sys-query_group_by_org_list',
      },
      {
        type: 'remoteselect',
        name: 'role_ids',
        label: '角色',
        label_i18n: '角色',
        placeholder_i18n: '请选择角色',
        disabled: false,
        labelKey: 'name',
        valueKey: 'id',
        placeholder: '请选择角色',
        datasource: '',
        mode: 'multiple',
        modalType: 'sys-query_role_list',
      },
    ],
    rules: {
      username: [{ required: true, message: t('请输入手机号') }],
      realname: [{ required: true, message: t('请输入真实姓名') }],
      title: [{ required: true, message: t('请填职务') }],
    },
    model: reactive({
      org_id: current_org?.id,
      is_active: true,
      username: null,
      realname: null,
      title: null,
      aim_org_id: current_org?.id,
      email: null,
      role_ids: null,
      group_ids: null,
    }),
  };
  const edit_model_config_options = {};

  const update_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [],
    rules: {},
    model: reactive({}),
  };
  const update_model_config_options = {};

  const titleList = {
    title: t('用户管理'),
    addModalTitle: t('新建'),
    updateTitle: t('修改'),
    is_create: true, // 是否有【新建】按钮
    is_update: false, // 是否有【修改】按钮
    is_remove: true, // 是否有【删除】按钮
    is_recover: true, // 是否有【恢复】按钮
  };

  const api_fields: Record = {
    update: {
      username: 'user__username',
      aim_org_id: 'org_id',
      person_id: 'id',
    },
    delete: {
      person_id: 'id',
    },
    recover: {
      person_id: 'id',
    },
    updateData: {
      aim_org_id: 'org_id',
      role_id: 'id',
    },
  };

  const baseColumns: TableColumn[] = [
    {
      title: t('序号'),
      dataIndex: 'index',
      width: 50,
      checked: true,
      fixed: 'left',
    },
    {
      title: 'ID',
      dataIndex: 'id',
      width: 50,
      checked: false,
    },
    {
      title: t('用户ID'),
      dataIndex: 'user_id',
      width: 80,
      checked: false,
    },
    {
      title: t('名称'),
      width: 150,
      checked: true,
      dataIndex: 'realname',
    },
    {
      title: t('手机号'),
      width: 150,
      checked: true,
      dataIndex: 'user__username',
    },
    // {
    //   title: '微信',
    //   width: 70,
    //   dataIndex: 'user__lyuserinfo__wechat_username',
    //   checked: false,
    //   slots: { customRender: 'wei' },
    // },
    {
      title: t('职务'),
      width: 150,
      checked: true,
      dataIndex: 'title',
    },
    {
      title: t('所属部门'),
      minWidth: 200,
      dataIndex: 'group_list',
      cellComponent: 'list',
    },
    {
      title: t('角色'),
      minWidth: 200,
      dataIndex: 'role_list',
      cellComponent: 'list',
    },
    {
      title: t('状态'),
      width: 150,
      dataIndex: 'is_active',
      checked: false,
      cellComponent: 'boolean',
      name1: t('可用'),
      name2: t('禁用'),
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('可用'), value: true },
        { text: t('禁用'), value: false },
      ],
    },
    {
      title: t('创建时间'),
      width: 150,
      checked: false,
      dataIndex: 'create_time',
      content: (record: any) => {
        return getDateTime(record.create_time);
      },
    },
    {
      title: t('操作'),
      width: 200,
      checked: false,
      dataIndex: 'action',
      cellComponent: 'action',
      fixed: 'right',
    },
  ];
  const newSlotList: any = [];
  Object.assign(newSlotList, slotList);
  newSlotList.push(
    {
      type: 'custom',
      // slotName: 'is_active',
      field_true: '可用',
      field_false: '禁用',
    },
    {
      type: 'custom',
      slotName: 'address_type',
      field_0: '家庭地址',
      field_1: '工作地址',
    },
  );

  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: queryPerson,
    addData: addPerson,
    updateData: updatePerson,
    removeData: removePerson,
    recoverData: recoverPerson,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};