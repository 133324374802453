import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';

import modalBox from '@/components/form-modal/modal-tools';
import { reactive } from 'vue';
import { create_work_hour_template, create_work_hour_template_month_data } from '@/api/baseinfo-model';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { message } from 'ant-design-vue';

const { t } = useI18n();
export const event_obj = mitt();
export const btn_list = [
  {
    name: t('工时年历详情'),
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'TimeCalendarModal',
        record: record,
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {
      };
      modalBox(modifyModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list = [
  {
    name: t('新建工时模板'),
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const editModal = reactive({
        visible: true,
        loading: false,
        title: t('新建工时模板'),
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'work_year',
              label: '日历年份',
              label_i18n: t('日历年份'),
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              placeholder: '请填写日历年份',
              placeholder_i18n: t('请填写日历年份'),
            },
            {
              type: 'remoteselect',
              name: 'cc_company_id',
              label: 'CC公司',
              label_i18n: t('CC公司'),
              disabled: false,
              placeholder: '请选择CC公司',
              placeholder_i18n: t('请选择CC公司'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-cccompany',
            },
            {
              type: 'remoteselect',
              name: 'country_id',
              label: '国家',
              label_i18n: t('国家'),
              disabled: false,
              placeholder: '请选择国家',
              placeholder_i18n: t('请选择国家'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'name',
              valueKey: 'id',
              modalType: 'baseinfo-country',
            },
          ],
          rules: {
            work_year: [{ required: true, message: t('请填写日历年份') }],
            cc_company_id: [{ required: true, message: t('请选择CC公司') }],
            country_id: [{ required: true, message: t('请选择国家') }],
          },
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            work_year: Number(moment().format('YYYY')),
            cc_company_id: null,
            country_id: null,
          }),
        },
        options: {},
        ok: (data: any) => {
          return new Promise(function (resolve) {
            editModal.loading = true;
            create_work_hour_template({
              ...data,
            })
              .then((res: any) => {
                message.success(t('操作成功'));
                event_obj.emit('refresh');
                if (res?.id) {
                  create_work_hour_template_month_data({
                    org_id: current_org?.id,
                    work_hour_template_id: res?.id,
                  });
                }
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
];
