import {
  create_person_to_company,
  delete_person_to_company,
  query_person_to_company_list,
  recover_person_to_company,
  update_person_to_company,
} from '@/api/baseinfo-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/cerp/cerp-person_to_company-config';

import { reactive } from 'vue';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'remoteselect',
      name: 'user_id',
      label: 'CC用户',
      label_i18n: 'CC用户',
      placeholder_i18n: '请选择CC用户',
      disabled: false,
      placeholder: '请选择CC用户',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'realname',
      valueKey: 'user_id',
      modalType: 'sys-query_person_for_cmes_list',
    },
    {
      type: 'input',
      name: 'user_name',
      label: '用户名称',
      label_i18n: '用户名称',
      placeholder_i18n: '请填写用户名称',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填写用户名称',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    note_type_id: null,
    cc_company_id: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'remoteselect',
      name: 'user_id',
      label: 'CC用户',
      disabled: false,
      placeholder: '请选择CC用户',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'realname',
      valueKey: 'user_id',
      modalType: 'sys-query_person_for_cmes_list',
    },
    {
      type: 'remoteselect',
      name: 'cc_company_ids',
      label: 'CC公司',
      label_i18n: 'CC公司',
      placeholder_i18n: '请选择CC公司',
      disabled: false,
      placeholder: '请选择CC公司',
      datasourceType: 'remote',
      mode: 'multiple',
      allowClear: true,
      modalType: 'baseinfo-cccompany',
    },
  ],
  rules: {
    user_id: [{ required: true, message: '请选择CC用户' }],
    cc_company_ids: [{ required: true, message: t('请选择CC公司') }],
  },
  model: reactive({
    org_id: current_org?.id,
    user_id: null,
    cc_company_ids: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    note: null,
    date: null,
    note_type_id: null,
    object_id: null,
    cc_company_id: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('CC公司权限'),
  addModalTitle: t('新建'),
  updateTitle: t('修改'),
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    person_to_company_id: 'id',
  },
  delete: {
    person_to_company_id: 'id',
  },
  recover: {
    person_to_company_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    person_to_company_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.cerp.note.column.title.index'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('page.cerp.note.column.title.id'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('CC用户'),
    width: 200,
    dataIndex: 'user_name',
    checked: true,
  },
  {
    title: t('CC公司权限'),
    minWidth: 200,
    dataIndex: 'cc_companys',
    checked: true,
    cellComponent: 'list',
  },

  {
    title: t('page.cerp.note.column.title.action'),
    width: 200,
    dataIndex: 'action',
    checked: true,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  // slotName: 'is_active',
  field_true: '可用',
  field_false: '禁用',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_person_to_company_list,
    addData: create_person_to_company,
    updateData: update_person_to_company,
    removeData: delete_person_to_company,
    recoverData: recover_person_to_company,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
