import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export async function create_custom_panel(params: Record<string, any>) {
  return request.post('/sandbox/create_custom_panel', params);
}

export async function update_custom_panel(params: Record<string, any>) {
  return request.post('/sandbox/update_custom_panel', params);
}

export async function delete_custom_panel(params: Record<string, any>) {
  return request.post('/sandbox/delete_custom_panel', params);
}

export async function recover_custom_panel(params: Record<string, any>) {
  return request.post('/sandbox/recover_custom_panel', params);
}
export async function update_custom_panel_data(params: Record<string, any>) {
  return request.post('/sandbox/update_custom_panel_data', params);
}

export async function query_custom_panel_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/sandbox/query_custom_panel_list', params);
}

export async function query_custom_data_view(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/sandboxread/query_custom_data_view', params);
}
