import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import { useRouter } from 'vue-router';
import modalBox from '@/components/form-modal/modal-tools';
import { createVNode, reactive, ref } from 'vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  create_adviser,
  create_adviser_user_account,
  create_contract,
  delete_adviser_end_date,
  delete_adviser_user_account,
  delete_person_to_adviser_relation,
  enable_adviser_account,
  query_insurance_list,
  reset_adviser_password,
  send_adviser_user_account_email,
  update_address,
  update_adviser,
} from '@/api/cerp-model';
import { query_person_for_cmes_list } from '@/api/sys-model';
import { message, Modal, notification } from 'ant-design-vue';
import cerp_adviser_editModal from '@/components/page-model/cerp/cerp-adviser-editModal.ts';
import { get_fields, options, settings } from '@/components/page-model/cerp/cerp-contract-editModal.ts';
import { useI18n } from 'vue-i18n';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import {
  query_c_c_company_template_list,
  query_country_list,
  query_managementfeeformula_list,
} from '@/api/baseinfo-model';
import { isNumber } from 'lodash';

const router = useRouter();
const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const True = true;
const False = false;
const { t } = useI18n();
const trim = (str: any) => {
  return str.replace(/(^\s*)|(\s*$)/g, "");
}
export const event_obj = mitt();
export const btn_list = [
  {
    name: '详情',
    type: 'dropdown',
    clazz: 'default',
    cb: (data: any) => {
      router.push({
        path:
          '/profile/basic/' +
          data.id +
          '/' +
          data.full_name +
          '/' +
          data.employee_number +
          '/' +
          data.contract_id,
      });
    },
  },
  {
    name: '修改',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const loading = ref(false);
      const {
        settings_adviser,
        fields_adviser,
        fields_adviser_update,
        options_adviser,
        baseColumns,
      } = cerp_adviser_editModal(t, record);
      let is_update = false;
      const tag_ids: any = [];
      if(record?.tags && record?.tags.length > 0){
        record?.tags.map((item: any) => {
          tag_ids.push(item.id);
        })
      }
      const editModal = {
        visible: true,
        title: t('修改顾问'),
        loading: loading,
        form: {
          settings: settings_adviser,
          fields: fields_adviser_update,
          rules: {},
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            adviser_id: record?.id,
            employee_number: record?.employee_number,
            title: record?.title,
            first_name: record?.first_name,
            last_name: record?.last_name,
            other_name: record?.other_name,
            birth_date: record?.birth_date,
            nationality: record?.nationality,
            pay_currency_id: record?.pay_currency_id,
            person_type_id: record?.person_type_id,
            mobile: record?.mobile,
            phone: record?.phone,
            personal_email: record?.personal_email,
            work_email: record?.work_email,
            work_address_id: record?.work_address_id,
            creater_id: record?.creater_id,
            status: record?.status,
            wechat: record?.wechat,
            qq: record?.qq,
            end_date: record?.end_date,
            user_email_template_id: record?.user_email_template_id,
            tag_ids: tag_ids,
            skypelid: record?.skypelid,
            whats_app: record?.whats_app,
            home_address_id: record?.home_address_id,
            person_note: record?.person_note,
            creater: record?.creater,
            cc_bank_account_id: record?.cc_bank_account_id,
          }),
          watch: {
            creater_id: (data: any) => {
              query_person_for_cmes_list({
                org_id: current_org?.id,
                ids: [data.creater_id],
              }).then((res: any) => {
                data['creater'] = res.data[0].realname;
              });
            },
            status: (data: any) => {
              is_update = true;
            },
            mobile: (data: any) => {
              if (data?.mobile && data.mobile.includes('+')) {
                data.mobile = data.mobile.replace(/\+/g, '00');
              }
            },
          },
        },
        options: options_adviser,
        ok: (payload: any) => {
          return new Promise(resolve => {
            if(is_update && record.end_date){
              notification.error({
                message: t('提示'),
                description: t('请先删除顾问结束日期'),
              });
              return false;
            }
            loading.value = true;
            update_adviser({
              ...payload,
              work_address_id: payload.work_address_id ? payload.work_address_id : '',
              first_name: trim(payload.first_name),
              last_name: trim(payload.last_name),
              phone: payload.phone?.trim(),
              mobile: payload.mobile?.trim(),
            })
              .then((param: any) => {
                message.success(t('修改成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      };
      modalBox(editModal);
    },
  },
  {
    name: '删除顾问结束日期',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.end_date;
    },
    cb: (record: any) => {
      Modal.confirm({
        title: t('是否删除顾问结束日期') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            delete_adviser_end_date({
              org_id: current_org.id,
              adviser_id: record.id,
            }).then((res: any) => {
              message.success(t('删除成功'));
              Object.assign(record, res);
              resolve(null);
            });
          }).catch(() => console.log('Oops errors!'));
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCancel() {},
      });
    },
  },
  {
    name: '小程序解绑',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.wx_bind;
    },
    cb: (record: any) => {
      Modal.confirm({
        title: t('是否小程序解绑') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            delete_person_to_adviser_relation({
              org_id: current_org.id,
              adviser_id: record.id,
            }).then((res: any) => {
              message.success(t('删除成功'));
              Object.assign(record, res);
              resolve(null);
            });
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
  },
  {
    name: '创建账号',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return !record.adviser_user_id;
    },
    cb: (data: any) => {
      const tag_ids: any = [];
      if(data.tags && data.tags.length > 0){
        data.tags.map((item: any) => {
          tag_ids.push(item.id);
        })
      }
      const editModal = reactive({
        visible: true,
        title: t('创建账号'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'remoteselect',
              name: 'tag_ids',
              label: '标签',
              label_i18n: '标签',
              disabled: false,
              placeholder: '请选择标签',
              placeholder_i18n: '请选择标签',
              datasourceType: 'remote',
              mode: 'multiple',
              allowClear: true,
              labelKey: 'name',
              valueKey: 'id',
              modalType: 'baseinfo-tag',
            },
            {
              type: 'remoteselect',
              name: 'user_email_template_id',
              label: '用户邮箱模板',
              label_i18n: '用户邮箱模板',
              disabled: false,
              placeholder: '请选择用户邮箱模板',
              placeholder_i18n: '请选择用户邮箱模板',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-emailtemplate',
            },
            {
              type: 'input',
              name: 'username',
              label: '用户名',
              label_i18n: '用户名',
              disabled: false,
              placeholder: '请填写用户名',
              placeholder_i18n: '请填写用户名',
              datasourceType: 'remote',
              allowClear: false,
            },
          ],
          rules: {
            username: [{ required: true, message: t('请填写用户名') }],
            tag_ids: [{ required: true, message: t('请选择标签') }],
            user_email_template_id: [{ required: true, message: t('请选择用户邮箱模板') }],
          },
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            tag_id: tag_ids,
            user_email_template_id: data.user_email_template_id,
            username: data.personal_email ? data.personal_email : data.work_email,
            adviser_id: Number(data.id),
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            update_adviser({
              ...payload,
            }).then(() => {
              create_adviser_user_account({
                ...payload,
              })
                .then(() => {
                  message.success(t('创建成功'));
                  event_obj.emit('refresh');
                  resolve(null);
                })
                .finally(() => {
                  editModal.loading = false;
                });
            });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '删除账号',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.adviser_user_id;
    },
    cb: (record: any) => {
      Modal.confirm({
        title: t('是否删除账号') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            delete_adviser_user_account({
              org_id: current_org.id,
              adviser_id: record.id,
            }).then((res: any) => {
              message.success(t('删除成功'));
              Object.assign(record, res);
              resolve(null);
            });
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
  },
  {
    name: '发送邮件',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.adviser_user_id;
    },
    cb: (record: any) => {
      Modal.confirm({
        title: t('确认发送邮件') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            send_adviser_user_account_email({
              org_id: current_org.id,
              adviser_id: record.id,
            }).then((res: any) => {
              message.success(t('发送成功'));
              resolve(null);
            });
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
  },
  {
    name: '恢复账号',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return !record.is_account_enable && record.status == 2;
    },
    cb: (record: any) => {
      Modal.confirm({
        title: t('恢复账号') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            enable_adviser_account({
              org_id: current_org.id,
              adviser_id: record.id,
            }).then((res: any) => {
              message.success(t('恢复账号成功'));
              Object.assign(record, res);
              resolve(null);
            });
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
  },
  {
    name: '重置密码',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.adviser_user_id;
    },
    cb: (record: any) => {
      Modal.confirm({
        title: t('是否重置密码') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            reset_adviser_password({
              org_id: current_org.id,
              adviser_id: record.id,
            }).then((res: any) => {
              message.success(t('重置成功'));
              resolve(null);
            });
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
  },
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        params: {
          object_id: record.id,
          note_type_flag: 'cerp.adviser',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
];

export const slotList = defaultSlotList;
export const config = {
  creatCol: [6, 6, 6],
  updateCol: [6, 5, 5],
};

export const head_btn_list: btnTd[] = [
  {
    name: '新顾问',
    // select_mode: 'radio',
    clazz: 'primary',
    cb: (rowSelection?: any, selectedRowKeys?: any, selectedRows?: any) => {
      //新顾问 按钮被点击
      const {
        settings_adviser,
        fields_adviser,
        fields_adviser_update,
        options_adviser,
        baseColumns,
      } = cerp_adviser_editModal(t, {});
      const editModal = {
        visible: true,
        title: t('新建顾问'),
        loading: false,
        form: {
          settings: settings_adviser,
          fields: fields_adviser,
          rules: {
            // personal_email: [{ required: true, message: '请输入个人邮箱' }],
            first_name: [{ required: true, message: '请填输入名字' }],
            last_name: [{ required: true, message: '请填输入姓' }],
            person_type_id: [{ required: true, message: '请选择个人类别' }],
            status: [{ required: true, message: '请选择个人状态' }],
          },
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            title: '',
            first_name: null,
            last_name: null,
            birth_date: null,
            nationality: null,
            phone: null,
            mobile: null,
            personal_email: null,
            work_email: null,
            wechat: null,
            whats_app: null,
            qq: null,
            skypelid: null,
            end_date: null,
            tag_ids: null,
            user_email_template_id: null,
            person_type_id: null,
            status: null,
            home_address_id: null,
            person_note: null,
            work_address_id: null,
            employee_number: null,
            creater: null,
          }),
          watch: {
            creater_id: (data: any) => {
              query_person_for_cmes_list({
                org_id: current_org?.id,
                ids: [data.creater_id],
              }).then((res: any) => {
                data['creater'] = res.data[0].realname;
              });
            },
            mobile: (data: any) => {
              if (data?.mobile && data.mobile.includes('+')) {
                data.mobile = data.mobile.replace(/\+/g, '00');
              }
            },
          },
        },
        options: options_adviser,
        ok: (payload: any) => {
          // 模态框，确认按钮点击。
          // todo: 创建新顾问，并询问是否录入 合同。
          if (!payload.personal_email && !payload.work_email) {
            notification.error({
              message: t('提示'),
              description: '个人邮箱、工作邮箱 至少要填写一个！',
            });
            return false;
          }
          editModal.loading = true;
          create_adviser({
            ...payload,
            first_name: trim(payload.first_name),
            last_name: trim(payload.last_name),
            phone: payload.phone?.trim(),
            mobile: payload.mobile?.trim(),
          }).then(function (param: any) {
            editModal.loading = false;
            event_obj.emit('refresh');
            if (payload.home_address_id) {
              update_address({
                org_id: current_org?.id,
                adviser_id: Number(param.id),
                address_id: Number(payload.home_address_id),
              });
            }
            if (payload.work_address_id) {
              update_address({
                org_id: current_org?.id,
                adviser_id: Number(param.id),
                address_id: Number(payload.work_address_id),
              });
            }

            // todo: 顾问已经创建成功。
            if (!param.end_date) {
              Modal.confirm({
                title: t('提示'),
                content: '是否创建顾问的合同？',
                onOk() {
                  // todo: 创建合同的模态框
                  // todo: 此处创建合同时，顾问要用input，防止多查数据，导致网络缓慢
                  const insurance_fee = ref(false);
                  const is_insurance_fee = ref(false);
                  const is_c_insurance_fee = ref(false);
                  const is_adviser_fee = ref(false);
                  const is_c_adviser_fee = ref(false);
                  let description = '';
                  const editContractModal = reactive({
                    visible: true,
                    title: '创建合同',
                    loading: false,
                    form: {
                      settings: settings,
                      fields: get_fields({
                        is_current: false,
                        insurance_fee: insurance_fee,
                        is_insurance_fee: is_insurance_fee,
                        is_c_insurance_fee: is_c_insurance_fee,
                        is_adviser_fee: is_adviser_fee,
                        is_c_adviser_fee: is_c_adviser_fee,
                      }, t),
                      rules: {
                        recuitment_company_id: [{ required: true, message: t('请选择顾问公司') }],
                        recuitment_company_person_id: [
                          { required: true, message: t('请选择请款邮箱地址') },
                        ],
                        consultant: [{ required: true, message: t('请填写职务') }],
                        work_location_id: [{ required: true, message: t('请选择工作地点') }],
                        start_end_date: [{ required: true, message: t('请填写合同起始结束日期') }],
                        rate_type: [{ required: true, message: t('请选择薪资种类') }],
                        currency_id: [{ required: true, message: t('请选择薪资币种') }],
                        basic_rate: [{ required: true, message: t('请填写基本单位薪资') }],
                        is_current: [{ required: true, message: t('是否当前合同') }],
                        contract_email_template_id: [{ required: true, message: t('请选择合同邮箱模板') }],
                        payslip_email_template_id: [
                          { required: true, message: t('请选择工资单邮箱模板') },
                        ],
                        invoice_email_template_id: [
                          { required: true, message: t('请选择请款单邮箱模板') },
                        ],
                        cc_email_id: [{ required: true, message: t('请选择CC邮箱') }],
                        end_client_id: [{ required: true, message: t('请选择终端用户') }],
                        manage_fee: [{ required: true, message: t('请填写管理费描述') }],
                        contract_template_id: [{ required: true, message: t('请选择合同模板') }],
                        cc_company_id: [{ required: true, message: t('请选择CC公司') }],
                        adviser_email_template_id: [
                          { required: true, message: t('请选择请款时顾问提示邮箱模板') },
                        ],
                        bank_charge: [{ required: true, message: t('请填写银行转账费') }],
                        management_fee_formula_id: [
                          { required: true, message: t('请选择管理费计算方式') },
                        ],
                        expense_email_template_id: [{ required: true, message: t('请选择报销请款单邮箱模板') }],
                        overtime_email_template_id: [{ required: true, message: t('请选择加班请款单邮箱模板') }],
                        cn_email_template_id: [{ required: true, message: t('请选择CN请款单邮箱模板') }],
                        expiration_email_template_id: [{ required: true, message: t('请选择合同到期提醒邮箱模板') }],
                        sign_email_template_id: [{ required: true, message: t('请选择签署合同确认邮箱模板') }],
                        solution_type: [{ required: true, message: t('请选择是否运用本地解决方案') }],
                        cc_expense_email_template_id: [{ required: true, message: t('请选择CC报销请款单邮箱模板') }],
                      },
                      model: reactive({
                        org_id: current_org?.id,
                        is_active: true,
                        adviser_id: Number(param.id),
                        adviser__full_name: param.full_name,
                        recuitment_company_id: null,
                        recuitment_company_person_id: null,
                        work_location_id: null,
                        start_date: null,
                        end_date: null,
                        termination_date: null,
                        rate_type: null,
                        currency_id: null,
                        contact_person_id: null,
                        pay_currency_id: null,
                        basic_rate: null,
                        cc_management_fee: null,
                        bank_charge: null,
                        accommodation_rate: null,
                        overtime_rate: null,
                        remark: null,
                        nsfile_id: null,
                        cc_management_fee_rate: null,
                        local_management_fee: null,
                        local_management_fee_rate: null,
                        is_bank_charge_payment: null,
                        insurance_payment_flag: null,
                        management_fee_payment_flag: null,
                        management_fee_formula_id: null,
                        income_tax: null,
                        social_insurance: null,
                        local_management_fee_fixed: null,
                        exchange_rate_id: null,
                        is_current: null,
                        end_client_id: null,
                        local_deduct_fee: null,
                        email_address_ids: null,
                        contract_email_template_id: null,
                        creater_id: null,
                        creater: null,
                        consultant_bank_charge: null,
                        company_bank_charge: null,
                        extra_info: null,
                        project_name: null,
                        local_salary: null,
                        cc_expense_email_template_id: 18,
                        insurance_type: 0,
                        solution_type: 1,
                        c_insurance_fee: 0,
                        insurance_fee: 0,
                        cc_email_id: 4,
                        adviser_email_template_id: 6,
                        invoice_email_template_id: 7,
                        payslip_email_template_id: 8,
                        expense_email_template_id: 15,
                        overtime_email_template_id: 16,
                        cn_email_template_id: 17,
                        contract_template_id: 3,
                        tip_day: 10,
                        expiration_email_template_id: 22,
                        sign_email_template_id: 26,
                        work_hour_template_id: null,
                        fte: 1,
                        annual_leave: null,
                      }),
                      watch: {
                        management_fee_formula_id: (data1: any) => {
                          if(data1.management_fee_payment_flag == 0){
                            if(data1.management_fee_formula_id){
                              query_managementfeeformula_list({
                                org_id: current_org?.id,
                                ids: [data1.management_fee_formula_id],
                              }).then((res: any) => {
                                if(res && res.data){
                                  data1.manage_fee = res.data[0].description;
                                  data1.manage_fee = data1.manage_fee
                                    .replace(/<local_rate>/g, data1.local_management_fee_rate ? data1.local_management_fee_rate?.toString() : '0')
                                    .replace(/<cc_rate>/g, data1.cc_management_fee_rate ? data1.cc_management_fee_rate?.toString() : '0')
                                    .replace(/<basic_rate>/g, data1.basic_rate ? data1.basic_rate?.toString() : '0')
                                    .replace(/<over_rate>/g, data1.overtime_rate ? data1.overtime_rate?.toString() : '0')
                                    .replace(/<cc_fee>/g, data1.cc_management_fee ? data1.cc_management_fee?.toString() : '0')
                                    .replace(/<local_fee>/g, data1.local_management_fee ? data1.local_management_fee?.toString() : '0')
                                    .replace(/<local_fixed>/g, data1.local_management_fee_fixed ? data1.local_management_fee_fixed?.toString() : '0')
                                    .replace(/<local_deduct>/g, data1.local_deduct_fee ? data1.local_deduct_fee?.toString() : '0')
                                    .replace(/<local_salaryvalue>/g, data1.local_salary ? data1.local_salary?.toString() : '0')
                                    .replace(/<rate_type>/g, data1.rate_type == 0 ? 'DAY' : data1.rate_type == 1 ? 'MONTH' : data1.rate_type == 2 ? 'YEAR' : data1.rate_type == 3 ? 'HOUR' : data1.rate_type == 4 ? 'WEEK' : '')
                                    .replace(/<currency>/g, data1.currency__currency ? data1.currency__currency?.toString() : '')
                                    .replace(/<local_pay_currency>/g, data1.local_pay_currency__currency ? data1.local_pay_currency__currency?.toString() : '')
                                    .replace(/<accommodation_rate>/g, data1.accommodation_rate ? data1.accommodation_rate?.toString() : '0');
                                  description = res.data[0].description;
                                }
                              })
                            }
                          }else{
                            if(data1.currency_id){
                              query_country_list({
                                org_id: current_org?.id,
                                ids: [data1.currency_id],
                              }).then((res: any) => {
                                if(res && res.data){
                                  data1.manage_fee = res.data[0].currency + '0.00';
                                }
                              })
                            }
                          }
                        },
                        management_fee_payment_flag: (data1: any) => {
                          if(data1.management_fee_payment_flag == 0){
                            data1.manage_fee = description
                              .replace(/<local_rate>/g, data1.local_management_fee_rate ? data1.local_management_fee_rate?.toString() : '0')
                              .replace(/<cc_rate>/g, data1.cc_management_fee_rate ? data1.cc_management_fee_rate?.toString() : '0')
                              .replace(/<basic_rate>/g, data1.basic_rate ? data1.basic_rate?.toString() : '0')
                              .replace(/<over_rate>/g, data1.overtime_rate ? data1.overtime_rate?.toString() : '0')
                              .replace(/<cc_fee>/g, data1.cc_management_fee ? data1.cc_management_fee?.toString() : '0')
                              .replace(/<local_fee>/g, data1.local_management_fee ? data1.local_management_fee?.toString() : '0')
                              .replace(/<local_fixed>/g, data1.local_management_fee_fixed ? data1.local_management_fee_fixed?.toString() : '0')
                              .replace(/<local_deduct>/g, data1.local_deduct_fee ? data1.local_deduct_fee?.toString() : '0')
                              .replace(/<local_salaryvalue>/g, data1.local_salary ? data1.local_salary?.toString() : '0')
                              .replace(/<rate_type>/g, data1.rate_type == 0 ? 'DAY' : data1.rate_type == 1 ? 'MONTH' : data1.rate_type == 2 ? 'YEAR' : data1.rate_type == 3 ? 'HOUR' : data1.rate_type == 4 ? 'WEEK' : '')
                              .replace(/<currency>/g, data1.currency__currency ? data1.currency__currency?.toString() : '')
                              .replace(/<local_pay_currency>/g, data1.local_pay_currency__currency ? data1.local_pay_currency__currency?.toString() : '')
                              .replace(/<accommodation_rate>/g, data1.accommodation_rate ? data1.accommodation_rate?.toString() : '0');
                          }else{
                            if(data1.currency_id){
                              query_country_list({
                                org_id: current_org?.id,
                                ids: [data1.currency_id],
                              }).then((res: any) => {
                                if(res && res.data){
                                  data1.manage_fee = res.data[0].currency + '0.00';
                                }
                              })
                            }
                          }
                        },
                        bank_charge: (data1: any) => {
                          if (data1['is_bank_charge_payment']) {
                            if (isNumber(data1.bank_charge) && isNumber(data1.company_bank_charge)) {
                              data1.consultant_bank_charge = data1.bank_charge - data1.company_bank_charge;
                            }
                          } else {
                            if (isNumber(data1.bank_charge) && isNumber(data1.consultant_bank_charge)) {
                              data1.company_bank_charge = data1.bank_charge - data1.consultant_bank_charge;
                            }
                          }
                        },
                        is_bank_charge_payment: (data1: any) => {
                          if (data1['is_bank_charge_payment']) {
                            is_adviser_fee.value = true;
                            is_c_adviser_fee.value = false;
                            data1.consultant_bank_charge = data1.bank_charge;
                            data1.company_bank_charge = data1.bank_charge - data1.consultant_bank_charge;
                          } else {
                            is_c_adviser_fee.value = true;
                            is_adviser_fee.value = false;
                            data1.company_bank_charge = data1.bank_charge;
                            data1.consultant_bank_charge = data1.bank_charge - data1.company_bank_charge;
                          }
                        },
                        consultant_bank_charge: (data1: any) => {
                          if (isNumber(data1.bank_charge) && isNumber(data1.consultant_bank_charge)) {
                            data1.company_bank_charge = data1.bank_charge - data1.consultant_bank_charge;
                          }
                        },
                        company_bank_charge: (data1: any) => {
                          if (isNumber(data1.bank_charge) && isNumber(data1.company_bank_charge)) {
                            data1.consultant_bank_charge = data1.bank_charge - data1.company_bank_charge;
                          }
                        },
                        insurance_type: (data1: any) => {
                          if (data1['insurance_type'] != 0) {
                            insurance_fee.value = true;
                            data1['insurance_payment_flag'] = -1;
                          } else {
                            insurance_fee.value = false;
                          }
                        },
                        creater_id: (data: any) => {
                          query_person_for_cmes_list({
                            org_id: current_org?.id,
                            ids: [data.creater_id],
                          }).then((res: any) => {
                            data['creater'] = res.data[0].realname;
                          });
                        },
                        insurance_payment_flag: (data: any) => {
                          if (data['insurance_payment_flag'] == -1) {
                            is_insurance_fee.value = true;
                            is_c_insurance_fee.value = true;
                            data['c_insurance_fee'] = 0;
                            data['insurance_fee'] = 0;
                          } else if (data['insurance_payment_flag'] == 0) {
                            is_insurance_fee.value = false;
                            is_c_insurance_fee.value = true;
                            query_insurance_list({
                              org_id: current_org.id,
                              is_current: true,
                              adviser_id: data.adviser_id,
                            }).then((res: any) => {
                              data['insurance_fee'] = res.data[0].month_insurance_fee;
                            });
                          } else if (data['insurance_payment_flag'] == 1) {
                            is_insurance_fee.value = true;
                            is_c_insurance_fee.value = false;
                            query_insurance_list({
                              org_id: current_org.id,
                              is_current: true,
                              adviser_id: data.adviser_id,
                            }).then((res: any) => {
                              data['c_insurance_fee'] = res.data[0].month_insurance_fee;
                            });
                          }
                        },
                        insurance_fee: (data: any) => {
                          if(data['insurance_payment_flag'] == 0){
                            query_insurance_list({
                              org_id: current_org.id,
                              is_current: true,
                              adviser_id: data.adviser_id,
                            }).then((res: any) => {
                              data['c_insurance_fee'] = res.data[0].month_insurance_fee - (data['insurance_fee'] ? data['insurance_fee'] : 0);
                            })
                          }
                        },
                        c_insurance_fee: (data: any) => {
                          if (data['insurance_payment_flag'] == 1) {
                            query_insurance_list({
                              org_id: current_org.id,
                              is_current: true,
                              adviser_id: data.adviser_id,
                            }).then((res: any) => {
                              data['insurance_fee'] = res.data[0].month_insurance_fee - (data['c_insurance_fee'] ? data['c_insurance_fee'] : 0);
                            });
                          }
                        },
                        cc_company_id: (data: any) => {
                          query_c_c_company_template_list({
                            org_id: current_org.id,
                            cc_company_id: data.cc_company_id,
                          }).then((res: any) => {
                            if (res && res.data && res.data.length > 0) {
                              data.recuitment_company_person_id = res.data[0].recuitment_company_person_id;
                              data.contract_template_id = res.data[0].contract_template_id;
                              data.cc_email_id = res.data[0].cc_email_id;
                              data.cc_expense_email_template_id = res.data[0].cc_expense_email_template_id;
                              data.invoice_email_template_id = res.data[0].invoice_email_template_id;
                              data.contract_email_template_id = res.data[0].contract_email_template_id;
                              data.expense_email_template_id = res.data[0].expense_email_template_id;
                              data.expiration_email_template_id = res.data[0].expiration_email_template_id;
                              data.adviser_email_template_id = res.data[0].adviser_email_template_id;
                              data.overtime_email_template_id = res.data[0].overtime_email_template_id;
                              data.sign_email_template_id = res.data[0].sign_email_template_id;
                              data.payslip_email_template_id = res.data[0].payslip_email_template_id;
                              data.cn_email_template_id = res.data[0].cn_email_template_id;
                            }
                          });
                        },
                      },
                    },
                    options: options(t),
                    ok: (contract_payload: any) => {
                      return new Promise(resolve => {
                        editModal.loading = true;
                        create_contract({
                          ...contract_payload,
                          start_date: contract_payload.start_end_date[0],
                          end_date: contract_payload.start_end_date[1],
                        })
                          .then(() => {
                            message.success('创建合同成功');
                            event_obj.emit('refresh');
                            resolve(null);
                          })
                          .finally(() => {
                            editModal.loading = false;
                          });
                      });
                    },
                  });
                  modalBox(editContractModal);
                },
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onCancel() {},
              });
            }else{
                notification.error({
                  message: t('提示'),
                  description: '请先删除顾问结束日期',
                });
            }
          });
        },
      };
      modalBox(editModal);
    },
  },
];
