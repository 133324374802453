import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import { reactive, ref } from 'vue';
import { delete_email_template_image, update_email_template } from '@/api/baseinfo-model';
import { message, Modal } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';

export const event_obj = mitt();
export const btn_list = [
  {
    name: '上传图片',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('上传图片'),
        group: '邮箱模板',
        type: 'picture-card',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;
            if (payload.length > 1) {
              message.error(t('只能上传一张图片，默认上传第一张'));
            }
            update_email_template({
              emailtemplate_id: data.id,
              nsfile_id: payload[0],
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('添加成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '上传附件',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('上传附件'),
        group: '邮箱模板',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;
            if (data.appendix_ids) {
              payload.push(...data.appendix_ids.split(','));
            }
            update_email_template({
              emailtemplate_id: data.id,
              appendix_ids: payload,
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('添加成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        params: {
          object_id: record.id,
          note_type_flag: 'baseinfo.emailtemplate',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
  {
    name: '删除图片',
    type: 'dropdown',
    clazz: 'danger',
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      Modal.confirm({
        title: t('提示'),
        content: t('确认删除图片'),
        onOk() {
          return new Promise(resolve => {
            delete_email_template_image({ org_id: current_org?.id, emailtemplate_id: record.id })
              .then(() => {
                message.success(t('操作成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
