import {
  create_receipt,
  delete_receipt,
  query_receipt_list,
  recover_receipt,
  update_receipt,
  upload_receipt_files,
} from '@/api/receipt-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_COMPANY, STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/receipt/receipt-receipt-config';
import { useRoute } from 'vue-router';
import { getDate, getDateTime, getMonth } from '@/utils/function';
import { Modal, notification } from 'ant-design-vue';
import { createVNode, reactive } from 'vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue/es';
import router from '@/router';

const None = null;
const True = true;
const False = false;
export default () => {
  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  const current_company = ls.get(STORAGE_CURRENT_COMPANY); // 当前cc公司

  const { t } = useI18n();
  const search_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
      {
        type: 'remoteselect',
        name: 'receipt_type_id',
        label: '票据种类',
        label_i18n: t('票据种类'),
        disabled: false,
        placeholder: '请选择票据种类',
        placeholder_i18n: t('请选择票据种类'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        labelKey: 'name',
        valueKey: 'id',
        modalType: 'receipt-query_receipt_type_list',
      },
      {
        type: 'remoteselect',
        name: 'customer_id',
        label: '客户',
        label_i18n: t('客户'),
        disabled: false,
        placeholder: '请选择客户',
        placeholder_i18n: t('请选择客户'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        labelKey: 'name',
        valueKey: 'id',
        modalType: 'receipt-query_customer_list',
      },
      // {
      //   type: 'remoteselect',
      //   name: 'cc_company_id',
      //   label: 'CC公司',
      //   disabled: false,
      //   placeholder: '请选择CC公司',
      //   datasourceType: 'remote',
      //   mode: 'default',
      //   allowClear: true,
      //   modalType: 'baseinfo-cccompany',
      // },
      {
        type: 'rangepicker',
        name: 'receipt_date',
        label: '开票日期',
        label_i18n: t('开票日期'),
        disabled: false,
        allowClear: true,
        inputType: 'number',
        defaultValue: '',
        showTime: false,
        valueFormat: 'YYYY-MM-DD',
        format: 'YYYY-MM-DD',
        placeholder: '请选择开票日期',
        placeholder_i18n: t('请选择开票日期'),
      },
      {
        type: 'monthpicker',
        name: 'service_month',
        label: '服务月份',
        label_i18n: t('服务月份'),
        disabled: false,
        allowClear: true,
        inputType: 'number',
        defaultValue: '',
        showTime: false,
        valueFormat: 'YYYY-MM-DD',
        format: 'YYYY-MM',
        placeholder: '请选择服务月份',
        placeholder_i18n: t('请选择服务月份'),
      },
      {
        type: 'rangepicker',
        name: 'payment_date',
        label: '付款日期',
        label_i18n: t('付款日期'),
        disabled: false,
        allowClear: true,
        inputType: 'number',
        defaultValue: '',
        showTime: false,
        valueFormat: 'YYYY-MM-DD',
        format: 'YYYY-MM-DD',
        placeholder: '请选择付款日期',
        placeholder_i18n: t('请选择付款日期'),
      },
      {
        type: 'remoteselect',
        name: 'receipt_template_id',
        label: '票据模板',
        label_i18n: t('票据模板'),
        disabled: false,
        placeholder: '请选择票据模板',
        placeholder_i18n: t('请选择票据模板'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        labelKey: 'name',
        valueKey: 'id',
        modalType: 'receipt-query_receipt_template_list',
      },
      {
        type: 'remoteselect',
        name: 'cc_bank_account_id',
        label: 'CC银行账户',
        label_i18n: 'CC银行账户',
        placeholder_i18n: '请选择CC银行账户',
        disabled: false,
        placeholder: '请选择CC银行账户',
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        labelKey: 'account_flag',
        valueKey: 'id',
        modalType: 'baseinfo-ccbankaccountscc',
      },
      {
        type: 'input',
        name: 'cc_company_name',
        label: 'CC公司',
        label_i18n: t('CC公司'),
        disabled: true,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写CC公司',
        placeholder_i18n: t('请填写CC公司'),
      },
      {
        type: 'input',
        name: 'receipt_number',
        label: '发票号',
        label_i18n: t('发票号'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写发票号',
        placeholder_i18n: t('请填写发票号'),
      },
    ],
    rules: {},
    model: reactive({
      org_id: current_org?.id,
      is_active: true,
      name: null,
      receipt_date: null,
      customer_id: null,
      receipt_type_id: null,
      cc_company_id: current_company?.id,
      service_month: null,
      payment_date: null,
      cc_company_name: current_company?.name !== '全部公司' ? current_company?.name : '',
      receipt_template_id: null,
      cc_bank_account_id: null,
      receipt_number: null,
      sort_fields: ['-create_time'],
    }),
  };
  const search_options = {
    is_active: [
      { value: None, name: '全部' },
      { value: True, name: '可用' },
      { value: False, name: '禁用' },
    ],
  };

  const edit_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: [6, 6],
    },
    fields: [],
    rules: {
      receipt_date: [{ required: true, message: '请选择开票日期' }],
      receipt_type_id: [{ required: true, message: '请选择票据种类' }],
    },
    model: reactive({
      org_id: current_org?.id,
      receipt_type_id: null,
      receipt_contract_id: null,
      customer_id: null,
      receipt_kind: null,
      receipt_date: null,
      service_month: null,
      payment_date: null,
      amount: null,
      currency_id: null,
      data: null,
    }),
  };
  const edit_model_config_options = {
    receipt_kind: [
      { value: 0, name: '收取发票' },
      { value: 1, name: '开具发票' },
    ],
  };

  const update_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [],
    rules: {},
    model: reactive({
      org_id: current_org?.id,
      receipt_type_id: null,
      receipt_contract_id: null,
      customer_id: null,
      receipt_kind: null,
      receipt_date: null,
      service_month: null,
      payment_date: null,
      amount: null,
      currency_id: null,
      data: null,
    }),
  };
  const update_model_config_options = {};

  const titleList = {
    title: t('发票清单'),
    addModalTitle: '',
    updateTitle: '',
    is_create: false, // 是否有【新建】按钮
    is_update: false, // 是否有【修改】按钮
    is_remove: true, // 是否有【删除】按钮
    is_recover: true, // 是否有【恢复】按钮
  };

  const api_fields: Record = {
    update: {
      receipt_id: 'id',
    },
    delete: {
      receipt_id: 'id',
    },
    recover: {
      receipt_id: 'id',
    },
    updateData: {
      org_id: 'org_id',
      receipt_id: 'id',
    },
  };

  const baseColumns: TableColumn[] = [
    {
      title: t('序号'),
      dataIndex: 'index',
      fixed: 'left',
      width: 50,
    },
    {
      title: t('ID'),
      width: 150,
      dataIndex: 'id',
      checked: false,
    },
    {
      title: t('客户'),
      width: 200,
      dataIndex: 'customer__name',
      fixed: 'left',
      checked: true,
    },
    {
      title: t('客户属性'),
      width: 150,
      dataIndex: 'customer__customer_attr',
      checked: true,
      fixed: 'left',
      content: (record: any, text: any) => {
        let color = '';
        switch (text) {
          case 0:
            color = t('个人');
            break;
          case 1:
            color = t('公司');
            break;
          default:
            color = '';
            break;
        }
        return color;
      },
    },
    {
      title: t('客户类型'),
      width: 80,
      dataIndex: 'customer__customer_type',
      checked: true,
      content: (record: any, text: any) => {
        let color = '';
        switch (text) {
          case 0:
            color = t('顾问');
            break;
          case 1:
            color = t('顾问公司');
            break;
          case 2:
            color = t('CC公司');
            break;
          case 3:
            color = t('其他');
            break;
          default:
            color = '';
            break;
        }
        return color;
      },
    },
    {
      title: t('发票号'),
      width: 150,
      dataIndex: 'receipt_number',
      checked: true,
      fixed: 'left',
      sorter: {
        multiple: 1,
      },
      cellComponent: 'click',
      cb: (record: any) => {
        if (record.record_id) {
          router.push({
            path: '/receipt/receipt-payment',
            query: { ids: [parseInt(record.record_id)], t: new Date().getTime() },
          });
        } else {
          notification.error({
            message: t('提示'),
            description: t('无对应付款记录'),
          });
        }
      },
    },
    {
      title: t('CC银行账户'),
      width: 150,
      dataIndex: 'cc_bank_account__account_flag',
      checked: true,
    },
    {
      title: t('CC公司'),
      width: 200,
      dataIndex: 'cc_company__name',
      checked: true,
    },
    {
      title: t('CC邮箱'),
      width: 200,
      dataIndex: 'cc_email__name',
      checked: true,
    },
    {
      title: t('合同CC公司'),
      width: 200,
      dataIndex: 'receipt_contract__cc_company__name',
      checked: true,
    },
    {
      title: t('合同起始时间'),
      width: 105,
      dataIndex: 'receipt_contract__start_date',
      checked: true,
      sorter: {
        multiple: 1,
      },
    },
    {
      title: t('合同结束时间'),
      width: 105,
      dataIndex: 'receipt_contract__end_date',
      checked: true,
      sorter: {
        multiple: 1,
      },
    },
    {
      title: t('票据种类'),
      width: 75,
      dataIndex: 'receipt_type__name',
      checked: true,
    },
    {
      title: t('发票类型'),
      width: 90,
      dataIndex: 'receipt_kind',
      checked: true,
      content: (record: any) => {
        return record.receipt_kind == 0
          ? t('收取发票')
          : record.receipt_kind == 1
          ? t('开具发票')
          : '';
      },
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('收取发票'), value: '0' },
        { text: t('开具发票'), value: '1' },
      ],
    },
    {
      title: t('发票数据'),
      width: 150,
      dataIndex: 'data',
      checked: true,
      cellComponent: 'attributetable',
      content: (record: any, text: any) => {
        const list: any = [];
        if (record.data) {
          Object.keys(record.data).map((key: string) => {
            list.push({
              name: key,
              value: record.data[key],
            });
          });
        }
        return list;
      },
    },
    {
      title: t('发票模板'),
      width: 150,
      dataIndex: 'receipt_template__name',
      checked: true,
    },
    {
      title: t('发票邮件模板'),
      width: 150,
      dataIndex: 'receipt_email_template__name',
      checked: true,
    },
    {
      title: t('开票日期'),
      width: 95,
      dataIndex: 'receipt_date',
      checked: true,
      sorter: {
        multiple: 1,
      },
      content: (record: any) => {
        return getDate(record.receipt_date);
      },
    },
    {
      title: t('付款日期'),
      width: 95,
      dataIndex: 'payment_date',
      checked: true,
      sorter: {
        multiple: 1,
      },
      content: (record: any) => {
        return getDate(record.payment_date);
      },
    },
    {
      title: t('服务月份'),
      width: 80,
      dataIndex: 'service_month',
      checked: true,
      sorter: {
        multiple: 1,
      },
      content: (record: any) => {
        return getMonth(record.service_month);
      },
    },
    {
      title: t('总金额'),
      width: 90,
      dataIndex: 'amount',
      checked: true,
    },
    {
      title: t('币种'),
      width: 70,
      dataIndex: 'currency__currency',
      checked: true,
    },
    {
      title: t('付款状态'),
      width: 90,
      dataIndex: 'payment_status',
      checked: true,
      content: (record: any) => {
        return record.payment_status == 0
          ? t('未付款')
          : record.payment_status == 1
          ? t('已付款')
          : '';
      },
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('未付款'), value: '0' },
        { text: t('已付款'), value: '1' },
      ],
    },
    {
      title: t('附件'),
      width: 200,
      dataIndex: 'appendix_json',
      checked: true,
      cellComponent: 'clicklist',
      cb: (record: any) => {
        if (record && record.file_url) {
          const url: string =
            'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.file_url);
          window.open(url, '_blank');
        } else {
          notification.error({
            message: t('提示'),
            description: t('无对应附件'),
          });
        }
      },
      del: (record: any, text: any, file: any) => {
        if (file.file_id) {
          Modal.confirm({
            title: t('提示'),
            content: t('是否删除') + '[' + file.name + ']',
            icon: createVNode(ExclamationCircleOutlined),
            onOk() {
              return new Promise(resolve => {
                const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
                let ids: any = [];
                text.forEach((itme: any) => {
                  if (itme.file_id != file.file_id) {
                    ids.push(itme.file_id);
                  }
                });
                if (ids.length == 0) {
                  ids = 0;
                }
                upload_receipt_files({
                  receipt_id: record.id,
                  appendix_ids: ids,
                  org_id: current_org?.id,
                })
                  .then(() => {
                    message.success(t('操作成功'));
                    event_obj.emit('refresh');
                  })
                  .finally(() => {
                    resolve(null);
                  });
              });
            },
            onCancel() {},
          });
        }
      },
    },
    {
      title: t('增扣项类型'),
      width: 80,
      dataIndex: 'extra__type',
      checked: true,
      content: (record: any) => {
        if (record.extra__type == 0) {
          return t('请款单');
        } else if (record.extra__type == 1) {
          return t('工资单');
        }
      },
    },
    {
      title: t('增扣项'),
      width: 80,
      dataIndex: 'extra__custom_field_name',
      checked: true,
    },
    {
      title: t('增扣项值'),
      width: 80,
      dataIndex: 'extra__custom_field_value',
      checked: true,
    },
    {
      title: t('增扣项状态'),
      width: 90,
      dataIndex: 'extra__is_finished',
      checked: true,
      content: (record: any) => {
        if (record.extra__is_finished == false) {
          return t('未完成');
        } else if (record.extra__is_finished == true) {
          return t('完成');
        }
      },
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('完成'), value: true },
        { text: t('未完成'), value: false },
      ],
    },
    {
      title: t('请款单号'),
      minWidth: 150,
      dataIndex: 'invoice__invoice_no',
      checked: true,
      cellComponent: 'click',
      cb: (record: any) => {
        router.push({
          path: '/adviser-manage/cerp-invoice',
          query: { id: parseInt(record.invoice_id), t: new Date().getTime() },
        });
      },
    },
    {
      title: t('汇款凭证'),
      width: 200,
      dataIndex: 'invoice__remittance__remittance_set__remittance_name',
      checked: true,
      cellComponent: 'click',
      content: (record: any) => {
        if (record.invoice__remittance__remittance_set__remittance_name) {
          return record.invoice__remittance__remittance_set__remittance_name;
        } else {
          return '';
        }
      },
      cb: (record: any) => {
        if (record.invoice__remittance__remittance_set_id) {
          router.push({
            path: '/adviser-manage/cerp-remittanceset',
            query: {
              ids: [parseInt(record.invoice__remittance__remittance_set_id)],
              t: new Date().getTime(),
            },
          });
        }
      },
    },
    {
      title: t('工资单号'),
      minWidth: 150,
      dataIndex: 'payslip__payslip_no',
      checked: true,
      cellComponent: 'click',
      cb: (record: any) => {
        router.push({
          path: '/adviser-manage/cerp-payslip',
          query: { ids: [parseInt(record.payslip_id)], t: new Date().getTime() },
        });
      },
    },
    {
      title: t('工资单银行流水'),
      width: 200,
      dataIndex: 'payslip__transaction__name',
      checked: true,
      cellComponent: 'click',
      cb: (record: any) => {
        router.push({
          path: '/adviser-manage/cerp-banktransaction',
          query: { id: record.payslip__transaction_id, t: new Date().getTime() },
        });
      },
    },
    {
      title: t('邮件发送记录'),
      width: 150,
      dataIndex: 'email_record__log',
      checked: true,
    },
    {
      title: t('邮件发送状态'),
      width: 105,
      dataIndex: 'email_record__status',
      checked: true,
      content: (record: any) => {
        if (record.email_record__status == 1) {
          return t('发送成功');
        } else if (record.email_record__status == 2) {
          return t('发送失败');
        } else if (record.email_record__status == 0) {
          return t('已发送');
        }
      },
    },
    {
      title: t('邮件发送时间'),
      width: 120,
      dataIndex: 'email_record__update_time',
      checked: true,
      content: (record: any) => {
        return getDateTime(record.email_record__update_time);
      },
    },
    {
      title: t('付款凭证邮件发送记录'),
      width: 150,
      dataIndex: 'payment_email_record__log',
      checked: true,
    },
    {
      title: t('付款凭证邮件发送状态'),
      width: 105,
      dataIndex: 'payment_email_record__status',
      checked: true,
      content: (record: any) => {
        if (record.payment_email_record__status == 1) {
          return t('发送成功');
        } else if (record.payment_email_record__status == 2) {
          return t('发送失败');
        } else if (record.payment_email_record__status == 0) {
          return t('已发送');
        }
      },
    },
    {
      title: t('付款凭证邮件发送时间'),
      width: 120,
      dataIndex: 'payment_email_record__update_time',
      checked: true,
      content: (record: any) => {
        return getDateTime(record.payment_email_record__update_time);
      },
    },
    {
      title: t('创建时间'),
      width: 150,
      dataIndex: 'create_time',
      checked: true,
      sorter: {
        multiple: 1,
      },
      content: (record: any) => {
        return getDateTime(record.create_time);
      },
    },
    {
      title: t('修改时间'),
      width: 150,
      dataIndex: 'update_time',
      checked: true,
      sorter: {
        multiple: 1,
      },
      content: (record: any) => {
        return getDateTime(record.update_time);
      },
    },
    {
      title: t('是否可用'),
      width: 150,
      dataIndex: 'is_active',
      checked: true,
      cellComponent: 'boolean',
      name1: t('可用'),
      name2: t('禁用'),
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('可用'), value: true },
        { text: t('禁用'), value: false },
      ],
    },

    {
      title: t('操作'),
      width: 150,
      dataIndex: 'action',
      checked: true,
      cellComponent: 'action',
      fixed: 'right',
    },
  ];
  const newSlotList: any = [];
  Object.assign(newSlotList, slotList);
  newSlotList.push();

  const route = useRoute();
  search_config.model = reactive({ ...search_config.model });
  search_config.model.cc_company_id = route.query.cc_company_id
    ? route.query.cc_company_id
    : current_company?.id;
  search_config.model.cc_company_name = route.query.cc_company_name
    ? route.query.cc_company_name !== '全部公司'
      ? route.query.cc_company_name
      : ''
    : current_company?.name !== '全部公司'
    ? current_company?.name
    : '';
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_receipt_list,
    addData: create_receipt,
    updateData: update_receipt,
    removeData: delete_receipt,
    recoverData: recover_receipt,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
