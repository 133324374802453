import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';

import { update_bank_account, upload_bank_account_appendix } from '@/api/cerp-model';
import { message } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import { reactive, ref } from 'vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { query_bank_manage_list } from '@/api/baseinfo-model';
import locales from '@/locales';

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
export const event_obj = mitt();
export const btn_list = [
  {
    name: '修改',
    clazz: 'primary',
    type: 'dropdown',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const disabled = ref(true);
      const local = ref(locales.global.locale);
      query_bank_manage_list({
        org_id: current_org?.id,
        ids: [data.bankmanage_id].join(','),
      }).then((res: any) => {
        if (res.data[0].name == '其它') {
          disabled.value = false;
        } else {
          disabled.value = true;
        }
      });
      const editModal = reactive({
        visible: true,
        title: t('修改银行账户'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [7, 7],
          },
          fields: [
            {
              type: 'input',
              name: 'adviser__full_name',
              label: '顾问',
              label_i18n: '顾问',
              placeholder_i18n: '请选择隶属顾问',
              disabled: true,
              placeholder: '请选择隶属顾问',
              datasourceType: 'remote',
              allowClear: false,
            },
            {
              type: 'remoteselect',
              name: 'bankmanage_id',
              label: '选择银行',
              label_i18n: '选择银行',
              placeholder_i18n: '请选择银行',
              disabled: false,
              placeholder: '请选择银行',
              datasourceType: 'remote',
              show_search: true,
              mode: 'default',
              allowClear: true,
              labelKey: local.value == 'en-US' ? 'english_name' : 'name',
              valueKey: 'id',
              modalType: 'baseinfo-bankmanage',
            },
            {
              type: 'input',
              name: 'bank_name',
              label: '银行名称',
              label_i18n: '银行名称',
              placeholder_i18n: '请选择银行名称',
              disabled: disabled,
              placeholder: '请选择银行名称',
              datasourceType: 'remote',
              allowClear: false,
            },
            {
              type: 'input',
              name: 'english_name',
              label: '银行英文名称',
              label_i18n: '银行英文名称',
              placeholder_i18n: '请选择银行英文名称',
              disabled: disabled,
              placeholder: '请选择银行英文名称',
              datasourceType: 'remote',
              allowClear: false,
            },
            {
              type: 'input',
              name: 'bic_or_swift_code',
              label: 'bic_or_swift_code',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: 'bic_or_swift_code',
            },
            {
              type: 'input',
              name: 'account_holder',
              label: '账户持有人',
              label_i18n: '账户持有人',
              placeholder_i18n: '请填写账户持有人',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写账户持有人',
            },
            {
              type: 'textarea',
              name: 'note',
              label: '备注',
              label_i18n: '备注',
              placeholder_i18n: '请填写备注',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 200,
              defaultValue: '',
              placeholder: '请填写备注',
            },
            {
              type: 'input',
              name: 'account_number',
              label: '账号',
              label_i18n: '账号',
              placeholder_i18n: '请填写账号',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写账号',
            },
            {
              type: 'input',
              name: 'bank_address',
              label: '开户行地址',
              label_i18n: '开户行地址',
              placeholder_i18n: '请填写开户行地址',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写开户行地址',
            },
            {
              type: 'input',
              name: 'iban',
              label: 'iban',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: 'iban',
            },
            {
              type: 'input',
              name: 'sort_code_or_branch_code',
              label: 'sort_code_or_branch_code',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: 'sort_code_or_branch_code',
            },
            {
              type: 'input',
              name: 'cnaps',
              label: 'cnaps',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: 'cnaps',
            },
            {
              type: 'radio',
              name: 'is_current',
              label: '是否当前账号',
              label_i18n: '是否当前账号',
              placeholder_i18n: '当前账号',
              disabled: false,
              allowClear: true,
              labelKey: 'name',
              valueKey: 'value',
              defaultValue: '',
            },
            {
              type: 'textarea',
              name: 'holder_address',
              label: '开户时向银行提供的住址',
              label_i18n: '开户时向银行提供的住址',
              placeholder_i18n: '请填写开户时向银行提供的住址',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 512,
              defaultValue: '',
              placeholder: '请填写开户时向银行提供的住址',
            },
          ],
          rules: {
            bank_name: [{ required: true, message: t('请填输入银行名称') }],
            account_holder: [{ required: true, message: t('请填输入账户持有人') }],
            account_number: [{ required: true, message: t('请填输入账号') }],
            bank_address: [{ required: true, message: t('请填输入开户行地址') }],
            is_current: [{ required: true, message: t('请填输入是否当前账号') }],
            bankmanage_id: [{ required: true, message: t('请选择银行') }],
          },
          model: reactive({
            org_id: current_org?.id,
            bank_account_id: Number(data.id),
            adviser_id: Number(data.adviser_id),
            adviser__full_name: data.adviser__full_name,
            is_active: true,
            bank_name: data.bank_name,
            english_name: data.english_name,
            bankmanage_id: data.bankmanage_id,
            account_holder: data.account_holder,
            account_number: data.account_number,
            bank_address: data.bank_address,
            iban: data.iban,
            note: data.note,
            bic_or_swift_code: data.bic_or_swift_code,
            sort_code_or_branch_code: data.sort_code_or_branch_code,
            cnaps: data.cnaps,
            holder_address: data.holder_address,
            kind: 0,
            is_current: data.is_current,
          }),
          watch: {
            bankmanage_id: (data1: any) => {
              query_bank_manage_list({
                org_id: current_org?.id,
                ids: [data1.bankmanage_id].join(','),
              }).then((res: any) => {
                if (res.data[0].name == '其它') {
                  disabled.value = false;
                  data1['bank_name'] = '';
                  data1['english_name'] = '';
                  data1['bic_or_swift_code'] = '';
                } else {
                  disabled.value = true;
                  data1['bank_name'] = local.value == 'en-US' ? res.data[0].english_name : res.data[0].name;
                  data1['english_name'] = res.data[0].english_name;
                  data1['bic_or_swift_code'] = res.data[0].swift_code;
                }
              });
            },
          },
        },
        options: {
          is_current: [
            { value: true, name: t('是') },
            { value: false, name: t('否') },
          ],
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            update_bank_account(payload)
              .then(() => {
                message.success(t('添加成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '其他附件上传',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('其他附件上传'),
        group: '其他附件',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          loading.value = true;
          if (data.appendix_ids) {
            payload.push(...data.appendix_ids.split(','));
          }
          return new Promise(resolve => {
            upload_bank_account_appendix({
              bank_account_id: data.id,
              appendix_ids: payload,
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('上传成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        params: {
          object_id: record.id,
          note_type_flag: 'cerp.bankaccount',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [6, 5],
  updateCol: [6, 6],
};

export const head_btn_list: btnTd[] = [];
