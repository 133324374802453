import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import { reactive } from 'vue';
import { create_custom_field } from '@/api/cerp-model';
import { message } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';

export const event_obj = mitt();
export const btn_list = [
  {
    name: '修改',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const editModal = reactive({
        visible: true,
        title: '修改自定义项',
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'invoice_no',
              label: '请款单号',
              label_i18n: '请款单号',
              placeholder_i18n: '请填写请款单号',
              disabled: true,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写请款单号',
            },
            {
              type: 'input',
              name: 'custom_field_name',
              label: '自定义项描述',
              label_i18n: '自定义项描述',
              placeholder_i18n: '请填写自定义项描述',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写自定义项描述',
            },
            {
              type: 'input',
              name: 'custom_field_value',
              label: '自定义项值',
              label_i18n: '自定义项值',
              placeholder_i18n: '请填写自定义项值',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写自定义项值',
            },
          ],
          rules: {
            custom_field_name: [{ required: true, message: '请填写自定义项描述' }],
          },
          model: {
            org_id: current_org?.id,
            is_active: true,
            custom_field_id: record.id,
            invoice_no: record.invoice__invoice_no,
            invoice_id: record.invoice_id,
            custom_field_name: record.custom_field_name,
            custom_field_value: record.custom_field_value,
          },
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            create_custom_field(payload)
              .then(() => {
                message.success('修改自定义项成功');
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        params: {
          object_id: record.id,
          note_type_flag: 'cerp.customfield',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
