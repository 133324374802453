import { SearchConfig } from '@/components/page-model/typing';
import { reactive } from 'vue';
import { config } from '@/components/page-model/service/service-service-config';
import { useI18n } from 'vue-i18n';

export default (current_org: any) => {
  const { t } = useI18n();

  const None = null;
  const True = true;
  const False = false;

  const edit_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [
      {
        type: 'input',
        name: 'name',
        label: '名称',
        label_i18n: t('名称'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写名称',
        placeholder_i18n: t('请填写名称'),
      },
      {
        type: 'select',
        name: 'modal_type',
        label: '查询表类型',
        label_i18n: t('查询表类型'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        labelKey: 'name',
        valueKey: 'value',
        defaultValue: '',
        placeholder: '请填写查询表类型',
        placeholder_i18n: t('请填写查询表类型'),
        datasource: 'modal_type',
      },
      {
        type: 'input',
        name: 'sorted_index',
        label: '排序',
        label_i18n: t('排序'),
        disabled: false,
        allowClear: true,
        inputType: 'number',
        defaultValue: '',
        placeholder: '请填写排序',
        placeholder_i18n: t('请填写排序'),
      },
      {
        type: 'number',
        name: 'tip_day',
        label: '自动发送邮件频率（天）',
        label_i18n: t('自动发送邮件频率（天）'),
        disabled: false,
        allowClear: true,
        inputType: 'number',
        defaultValue: '',
        placeholder: '自动发送邮件频率（天）',
        placeholder_i18n: t('自动发送邮件频率（天）'),
      },
      {
        type: 'radio',
        name: 'is_send',
        label: '是否定时发送邮件',
        label_i18n: t('是否定时发送邮件'),
        disabled: false,
        labelKey: 'name',
        valueKey: 'value',
        defaultValue: '',
      },
      {
        type: 'remoteselect',
        name: 'email_template_id',
        label: '邮箱模板',
        label_i18n: t('邮箱模板'),
        disabled: false,
        placeholder: '请选择请邮箱模板',
        placeholder_i18n: t('请选择请邮箱模板'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        modalType: 'baseinfo-emailtemplate',
      },
    ],
    rules: {
      name: [{ required: true, message: t('请填写名称') }],
      modal_type: [{ required: true, message: t('请填写查询表类型') }],
    },
    model: reactive({
      org_id: current_org?.id,
      is_active: true,
      name: null,
      modal_type: null,
      sorted_index: null,
      email_template_id: null,
      tip_day: 10,
      is_send: false,
    }),
  };
  const edit_model_config_options = {
    modal_type: [
      { value: 'cerp-adviser', name: '顾问' },
      { value: 'cerp-todoevent', name: '待办' },
      { value: 'cerp-contract', name: '合同' },
      { value: 'cerp-contract1', name: '合同1' },
      { value: 'cerp-insurance', name: '保险' },
      { value: 'cerp-wechatupdaterecord', name: '微信小程序数据' },
      { value: 'cerp-invoice', name: '请款单' },
    ],
    is_send: [
      { value: True, name: t('是') },
      { value: False, name: t('否') },
    ],
  };

  return {
    edit_model_config: edit_model_config,
    edit_model_config_options: edit_model_config_options,
  };
};
