import {
  create_git_project,
  delete_git_project,
  query_git_project_list,
  recover_git_project,
  update_git_project,
} from '@/api/apidoc-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/apidoc/apidoc-gitproject-config';

import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: 'git项目名',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写git项目名',
    },
    {
      type: 'input',
      name: 'url',
      label: 'git项目URL',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写git项目URL',
    },
    {},
    {
      type: 'remoteselect',
      name: 'project_id',
      label: '项目',
      disabled: false,
      placeholder: '请选择项目',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'apidoc-project',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    url: null,
    app_id: null,
    project_id: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: 'git项目名',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写git项目名',
    },
    {
      type: 'input',
      name: 'url',
      label: 'git项目URL',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写git项目URL',
    },
    {
      type: 'remoteselect',
      name: 'project_id',
      label: '项目',
      disabled: false,
      placeholder: '请选择项目',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'apidoc-project',
    },
  ],
  rules: { name: [{ required: true, message: '请填输入git项目名' }] },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    url: null,
    project_id: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: 'git项目名',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写git项目名',
    },
    {
      type: 'input',
      name: 'url',
      label: 'git项目URL',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写git项目URL',
    },
    {
      type: 'remoteselect',
      name: 'project_id',
      label: '项目',
      disabled: false,
      placeholder: '请选择项目',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'apidoc-project',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    url: null,
    project_id: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: 'Git项目管理',
  addModalTitle: '新建Git项目',
  updateTitle: '修改Git项目',
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    gitproject_id: 'id',
  },
  delete: {
    gitproject_id: 'id',
  },
  recover: {
    gitproject_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    gitproject_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    width: 50,
    slots: { customRender: 'index' },
  },
  {
    title: '项目主键',
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: 'git项目名',
    width: 150,
    dataIndex: 'name',
    checked: true,
  },
  {
    title: 'git项目URL',
    width: 150,
    dataIndex: 'url',
    checked: true,
  },
  {
    title: '项目',
    width: 150,
    dataIndex: 'project_id',
    checked: true,
  },
  {
    title: '项目名称',
    width: 150,
    dataIndex: 'project__name',
    checked: true,
  },
  {
    title: t('创建时间'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,

    slots: { customRender: 'datetime' },
  },
  {
    title: '更新日期',
    width: 150,
    dataIndex: 'update_time',
    checked: false,

    slots: { customRender: 'datetime' },
  },
  {
    title: t('是否可用'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,

    slots: { customRender: 'is_active' },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('操作'),
    width: 200,
    dataIndex: 'action',
    checked: false,
    slots: { customRender: 'action' },
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  // slotName: 'is_active',
  field_true: '可用',
  field_false: '禁用',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_git_project_list,
    addData: create_git_project,
    updateData: update_git_project,
    removeData: delete_git_project,
    recoverData: recover_git_project,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
