import { create_note, delete_note, query_note_list, recover_note, update_note } from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { btn_list, config, event_obj, head_btn_list, slotList } from '@/components/page-model/cerp/cerp-note-config';

import { reactive } from 'vue';
import { getDate, getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'remoteselect',
      name: 'note_type_id',
      label: '记录类型',
      label_i18n: t('page.cerp.note.form.label.note_type_id'),
      disabled: false,
      placeholder: '请选择记录类型',
      placeholder_i18n: t('page.cerp.note.form.placeholder.note_type_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-notetype',
    },
    {
      type: 'remoteselect',
      name: 'cc_company_id',
      label: 'CC公司',
      label_i18n: 'CC公司',
      placeholder_i18n: '请选择CC公司',
      disabled: false,
      placeholder: '请选择CC公司',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-cccompany',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    note_type_id: null,
    cc_company_id: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'note',
      label: '记录',
      label_i18n: t('page.cerp.note.form.label.note'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写记录',
      placeholder_i18n: t('page.cerp.note.form.placeholder.note'),
    },
    {
      type: 'datepicker',
      name: 'date',
      label: '日期',
      label_i18n: t('page.cerp.note.form.label.date'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: true,
      valueFormat: 'YYYY-MM-DD HH:mm:ss',
      placeholder: '请填写日期',
      placeholder_i18n: t('page.cerp.note.form.placeholder.date'),
    },
    {
      type: 'remoteselect',
      name: 'note_type_id',
      label: '记录类型',
      label_i18n: t('page.cerp.note.form.label.note_type_id'),
      disabled: false,
      placeholder: '请选择记录类型',
      placeholder_i18n: t('page.cerp.note.form.placeholder.note_type_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-notetype',
    },
    {
      type: 'input',
      name: 'object_id',
      label: '描述',
      label_i18n: t('page.cerp.note.form.label.object_id'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写描述',
      placeholder_i18n: t('page.cerp.note.form.placeholder.object_id'),
    },
  ],
  rules: {
    note: [{ required: true, message: t('请填输入记录') }],
    object_id: [{ required: true, message: t('请填输入描述') }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    note: null,
    date: null,
    note_type_id: null,
    object_id: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'note',
      label: '记录',
      label_i18n: t('page.cerp.note.form.label.note'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写记录',
      placeholder_i18n: t('page.cerp.note.form.placeholder.note'),
    },
    {
      type: 'datepicker',
      name: 'date',
      label: '日期',
      label_i18n: t('page.cerp.note.form.label.date'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: true,
      valueFormat: 'YYYY-MM-DD HH:mm:ss',
      placeholder: '请填写日期',
      placeholder_i18n: t('page.cerp.note.form.placeholder.date'),
    },
    {
      type: 'remoteselect',
      name: 'note_type_id',
      label: '记录类型',
      label_i18n: t('page.cerp.note.form.label.note_type_id'),
      disabled: false,
      placeholder: '请选择记录类型',
      placeholder_i18n: t('page.cerp.note.form.placeholder.note_type_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-notetype',
    },
    {
      type: 'input',
      name: 'object_id',
      label: '描述',
      label_i18n: t('page.cerp.note.form.label.object_id'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写描述',
      placeholder_i18n: t('page.cerp.note.form.placeholder.object_id'),
    },
    {
      type: 'remoteselect',
      name: 'cc_company_id',
      label: 'CC公司',
      label_i18n: 'CC公司',
      placeholder_i18n: '请选择CC公司',
      disabled: false,
      placeholder: '请选择CC公司',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-cccompany',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    note: null,
    date: null,
    note_type_id: null,
    object_id: null,
    cc_company_id: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('记录管理'),
  addModalTitle: t('新建记录'),
  updateTitle: t('修改记录'),
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    note_id: 'id',
  },
  delete: {
    note_id: 'id',
  },
  recover: {
    note_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    note_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.cerp.note.column.title.index'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('page.cerp.note.column.title.id'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.cerp.note.column.title.note'),
    minWidth: 350,
    dataIndex: 'note',
    checked: true,
  },
  {
    title: t('page.cerp.note.column.title.is_active'),
    width: 100,
    dataIndex: 'is_active',
    checked: true,

    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },
  {
    title: t('page.cerp.note.column.title.date'),
    width: 150,
    dataIndex: 'date',
    checked: true,
    content: (record: any) => {
      return getDate(record.date);
    },
  },
  {
    title: t('page.cerp.note.column.title.note_type__name'),
    width: 150,
    dataIndex: 'note_type__name',
    checked: true,
  },
  {
    title: t('顾问'),
    width: 200,
    dataIndex: 'adviser__full_name',
    checked: true,
  },
  {
    title: t('创建人'),
    width: 80,
    dataIndex: 'person_name',
    checked: true,
  },
  {
    title: t('CC公司'),
    width: 150,
    dataIndex: 'cc_company__name',
    checked: true,
  },
  {
    title: t('page.cerp.note.column.title.create_time'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('page.cerp.note.column.title.update_time'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },

  {
    title: t('page.cerp.note.column.title.action'),
    width: 200,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  // slotName: 'is_active',
  field_true: '可用',
  field_false: '禁用',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_note_list,
    addData: create_note,
    updateData: update_note,
    removeData: delete_note,
    recoverData: recover_note,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
