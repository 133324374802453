import {
  create_remittance_set,
  delete_remittance_set,
  query_remittance_set_list,
  recover_remittance_set,
  update_remittance_set,
} from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/cerp/cerp-remittanceset-config';

import { reactive } from 'vue';
import numerals from '@/utils/numeral';
import { useRoute } from 'vue-router';
import router from '@/router';
import { getDate, getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'remittance_name',
      label: '名称',
      label_i18n: t('page.cerp.remittanceset.form.label.remittance_name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.cerp.remittanceset.form.placeholder.remittance_name'),
    },
    {
      type: 'remoteselect',
      name: 'transaction_id',
      label: '银行流水',
      label_i18n: t('page.cerp.remittanceset.form.label.transaction_id'),
      disabled: false,
      placeholder: '请选择银行流水',
      placeholder_i18n: t('page.cerp.remittanceset.form.placeholder.transaction_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'cerp-banktransaction',
    },
    {
      type: 'remoteselect',
      name: 'recuitment_company_id',
      label: '顾问公司',
      label_i18n: '顾问公司',
      placeholder_i18n: '请选择顾问公司',
      disabled: false,
      placeholder: '请选择顾问公司',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'company_name',
      valueKey: 'id',
      modalType: 'baseinfo-recuitmentcompany',
    },
    {
      type: 'rangepicker',
      name: 'time_interval',
      label: '时间区间',
      label_i18n: '时间区间',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: true,
      valueFormat: 'YYYY-MM-DD HH:mm:ss',
      placeholder: '请填写时间区间',
    },
    {
      type: 'number',
      name: 'total_amount',
      label: '总金额',
      label_i18n: '总金额',
      placeholder_i18n: '请填写总金额',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写总金额',
    },
    {
      type: 'remoteselect',
      name: 'ids',
      label: '汇款凭证',
      label_i18n: '汇款凭证',
      placeholder_i18n: '请选择汇款凭证',
      disabled: false,
      placeholder: '请选择汇款凭证',
      datasourceType: 'remote',
      mode: 'multiple',
      allowClear: true,
      labelKey: 'remittance_name',
      valueKey: 'id',
      modalType: 'cerp-remittanceset',
    },
    {
      type: 'select',
      name: 'is_active',
      label: '状态',
      label_i18n: t('page.cerp.remittanceset.form.label.is_active'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择状态',
      placeholder_i18n: t('page.cerp.remittanceset.form.placeholder.is_active'),
      datasource: 'is_active',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    remittance_name: null,
    transaction_id: null,
    time_interval: null,
    total_amount: null,
    ids: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: t('全部') },
    { value: True, name: t('可用') },
    { value: False, name: t('禁用') },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'remittance_name',
      label: '名称',
      label_i18n: t('page.cerp.remittanceset.form.label.remittance_name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.cerp.remittanceset.form.placeholder.remittance_name'),
    },
    {
      type: 'textarea',
      name: 'description',
      label: '描述',
      label_i18n: t('page.cerp.remittanceset.form.label.description'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填描述',
      placeholder_i18n: t('page.cerp.remittanceset.form.placeholder.description'),
    },
    {
      type: 'money',
      name: 'total_amount',
      label: '总金额',
      label_i18n: t('page.cerp.remittanceset.form.label.total_amount'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填总金额',
      placeholder_i18n: t('page.cerp.remittanceset.form.placeholder.total_amount'),
    },
    {
      type: 'remoteselect',
      name: 'transaction_id',
      label: '银行流水',
      label_i18n: t('page.cerp.remittanceset.form.label.transaction_id'),
      disabled: false,
      placeholder: '请选择银行流水',
      placeholder_i18n: t('page.cerp.remittanceset.form.placeholder.transaction_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'cerp-banktransaction',
    },
  ],
  rules: {
    remittance_name: [{ required: true, message: '请填输入名称' }],
    description: [{ required: true, message: '请填输入描述' }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    remittance_name: null,
    description: null,
    total_amount: null,
    transaction_id: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'remittance_name',
      label: '名称',
      label_i18n: t('page.cerp.remittanceset.form.label.remittance_name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.cerp.remittanceset.form.placeholder.remittance_name'),
    },
    {
      type: 'textarea',
      name: 'description',
      label: '描述',
      label_i18n: t('page.cerp.remittanceset.form.label.description'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填描述',
      placeholder_i18n: t('page.cerp.remittanceset.form.placeholder.description'),
    },
    {
      type: 'money',
      name: 'total_amount',
      label: '总金额',
      label_i18n: t('page.cerp.remittanceset.form.label.total_amount'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      maxlength: 100,
      defaultValue: '',
      placeholder: '请填总金额',
      placeholder_i18n: t('page.cerp.remittanceset.form.placeholder.total_amount'),
    },
    {
      type: 'remoteselect',
      name: 'transaction_id',
      label: '银行流水',
      label_i18n: t('page.cerp.remittanceset.form.label.transaction_id'),
      disabled: true,
      placeholder: '请选择银行流水',
      placeholder_i18n: t('page.cerp.remittanceset.form.placeholder.transaction_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'cerp-banktransaction',
    },
    {
      type: 'remoteselect',
      name: 'bank_charge_id',
      label: '银行手续费流水',
      label_i18n: t('page.cerp.remittanceset.form.label.transaction_id'),
      disabled: false,
      placeholder: '请选择银行手续费流水',
      placeholder_i18n: t('page.cerp.remittanceset.form.placeholder.transaction_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'cerp-banktransaction',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    remittance_name: null,
    description: null,
    total_amount: null,
    transaction_id: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('汇款凭证'),
  addModalTitle: t('新建汇款凭证'),
  updateTitle: t('修改汇款凭证'),
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: false, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    remittance_set_id: 'id',
  },
  delete: {
    remittance_set_id: 'id',
  },
  recover: {
    remittance_set_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    remittance_set_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.cerp.remittanceset.column.title.index'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('page.cerp.remittanceset.column.title.id'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.cerp.remittanceset.column.title.remittance_name'),
    width: 150,
    dataIndex: 'remittance_name',
    checked: true,
  },
  {
    title: t('page.cerp.remittanceset.column.title.description'),
    width: 300,
    dataIndex: 'description',
    checked: true,
  },
  {
    title: t('page.cerp.remittanceset.column.title.date_received'),
    width: 150,
    dataIndex: 'date_received',
    checked: true,
    content: (record: any) => {
      return getDate(record.date_received);
    },
  },
  {
    title: t('page.cerp.remittanceset.column.title.current_amount'),
    width: 150,
    dataIndex: 'current_amount',
    checked: true,
    cellComponent: 'color',
    cb: (record: any) => {
      if (!record.current_amount) {
        return '';
      }
      if (record.invoice_total_amount || record.invoice_total_amount == 0) {
        if (record.invoice_total_amount.toFixed(2) > record.current_amount.toFixed(2)) {
          return 'color: #00BBFF;font-weight: bolder';
        } else if (record.invoice_total_amount.toFixed(2) == record.current_amount.toFixed(2)) {
          return 'color: #00DD00;font-weight: bolder';
        } else if (record.invoice_total_amount.toFixed(2) < record.current_amount.toFixed(2)) {
          return 'color: #FF0000;font-weight: bolder';
        } else {
          return '';
        }
      } else {
        return '';
      }
    },
    content: (record: any) => {
      if (record.current_amount) {
        return numerals.money(record.current_amount);
      } else {
        return '';
      }
    },
  },
  {
    title: t('page.cerp.remittanceset.column.title.invoice_total_amount'),
    width: 150,
    dataIndex: 'invoice_total_amount',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.remittanceset.column.title.total_amount'),
    width: 150,
    dataIndex: 'total_amount',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.remittanceset.column.title.currency__currency'),
    width: 150,
    dataIndex: 'currency__currency',
    checked: true,
  },
  {
    title: t('page.cerp.remittanceset.column.title.transaction__name'),
    width: 150,
    dataIndex: 'transaction__name',
    checked: true,
    cellComponent: 'click',
    cb: (record: any) => {
      router.push({
        path: '/adviser-manage/cerp-banktransaction',
        query: { id: record.transaction_id, t: new Date().getTime() },
      });
    },
  },
  {
    title: t('page.cerp.remittanceset.column.title.transaction__currency__currency'),
    width: 150,
    dataIndex: 'transaction__currency__currency',
    checked: true,
  },
  {
    title: t('page.cerp.remittanceset.column.title.transaction__amount'),
    width: 150,
    dataIndex: 'transaction__amount',
    checked: true,
    cellComponent: 'money',
  },
  {
    title: t('page.cerp.remittanceset.column.title.recuitment_company__company_name'),
    width: 150,
    dataIndex: 'recuitment_company__company_name',
    checked: true,
  },
  {
    title: t('page.cerp.remittanceset.column.title.bank_charge__name'),
    width: 150,
    dataIndex: 'bank_charge__name',
    checked: true,
  },
  {
    title: t('page.cerp.remittanceset.column.title.bank_charge__amount'),
    width: 150,
    dataIndex: 'bank_charge__amount',
    checked: true,
    cellComponent: 'money',
    color: (record: any, text: any) => {
      if(text && text >= 0) {
        return '';
      }else {
        return 'color: #FF0000;font-weight: bolder';
      }
    }
  },
  {
    title: t('page.cerp.remittanceset.column.title.create_time'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('page.cerp.remittanceset.column.title.update_time'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('page.cerp.remittanceset.column.title.is_active'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('page.cerp.remittanceset.column.title.action'),
    width: 320,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push(
  {
    type: 'custom',
    // slotName: 'is_active',
    field_true: '可用',
    field_false: '禁用',
  },

  {
    type: 'color',
    slotName: 'current_amount',
    cb: (record: any) => {
      if (!record.current_amount) {
        return '';
      }
      if (record.invoice_total_amount || record.invoice_total_amount == 0) {
        if (record.invoice_total_amount.toFixed(2) > record.current_amount.toFixed(2)) {
          return 'color: #00BBFF;font-weight: bolder';
        } else if (record.invoice_total_amount.toFixed(2) == record.current_amount.toFixed(2)) {
          return 'color: #00DD00;font-weight: bolder';
        } else if (record.invoice_total_amount.toFixed(2) < record.current_amount.toFixed(2)) {
          return 'color: #FF0000;font-weight: bolder';
        } else {
          return '';
        }
      } else {
        return '';
      }
    },
    content: (record: any) => {
      if (record.current_amount) {
        return numerals.money(record.current_amount);
      } else {
        return '';
      }
    },
  },
  {
    type: 'color_btn',
    slotName: 'transaction__name',
    cb: (record: any) => {
      router.push({
        path: '/adviser-manage/cerp-banktransaction',
        query: { id: record.transaction_id, t: new Date().getTime() },
      });
    },
    rt: (record: any) => {
      return '';
    },
  },
);

export default () => {
  const route = useRoute();
  search_config.model = reactive({ ...search_config.model });
  if (route.query.ids) {
    search_config.model.ids = route.query.ids;
  }
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_remittance_set_list,
    addData: create_remittance_set,
    updateData: update_remittance_set,
    removeData: delete_remittance_set,
    recoverData: recover_remittance_set,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
