import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';
import { useRouter } from 'vue-router';
import { createVNode, reactive, ref } from 'vue';
import { query_country_list, query_recuitment_company_list } from '@/api/baseinfo-model';
import {
  create_bank_transaction,
  create_remittance,
  create_remittance_set,
  delete_remittance_set,
  set_remittance_set_transaction,
  update_remittance_set,
} from '@/api/cerp-model';
import { message, Modal } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import moment from 'moment';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';

const router = useRouter();
export const event_obj = mitt();
const getDate = (time: any) => {
  if (time) {
    time = time.substring(0, 10);
  }
  return time;
};
export const btn_list = [
  {
    name: '详情',
    type: 'dropdown',
    clazz: 'default',
    cb: (data: any) => {
      router.push({
        path: '/adviser-manage/basic-remittance/' + data.id + '/' + data.remittance_name,
      });
    },
  },
  {
    name: '修改',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const editModal = reactive({
        visible: true,
        title: t('修改汇款凭证'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'remittance_name',
              label: '名称',
              label_i18n: '名称',
              placeholder_i18n: '请填写名称',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 100,
              defaultValue: '',
              placeholder: '请填写名称',
            },
            {
              type: 'textarea',
              name: 'description',
              label: '描述',
              label_i18n: '描述',
              placeholder_i18n: '请填描述',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 100,
              defaultValue: '',
              placeholder: '请填描述',
            },
            {
              type: 'datepicker',
              name: 'date_received',
              label: '汇款收到日期',
              label_i18n: '汇款收到日期',
              placeholder_i18n: '请填写汇款收到日期',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: true,
              valueFormat: 'YYYY-MM-DD HH:mm',
              format: 'YYYY-MM-DD HH:mm',
              placeholder: '请填写汇款收到日期',
            },
            {
              type: 'money',
              name: 'total_amount',
              label: '总金额',
              label_i18n: '总金额',
              placeholder_i18n: '请填写总金额',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              maxlength: 100,
              defaultValue: '',
              placeholder: '请填总金额',
            },
            {
              type: 'remoteselect',
              name: 'currency_id',
              labelKeyList: ['code', 'currency'],
              label: '币种',
              label_i18n: t('币种'),
              disabled: false,
              placeholder: '请选择币种',
              placeholder_i18n: t('请选择币种'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-country',
            },
            {
              type: 'input',
              name: 'transaction__name',
              label: '银行流水',
              label_i18n: '银行流水',
              placeholder_i18n: '请填写银行流水',
              disabled: true,
              allowClear: true,
              inputType: 'text',
              maxlength: 100,
              defaultValue: '',
              placeholder: '请填写银行流水',
            },
            {
              type: 'input',
              name: 'bank_charge__name',
              label: '银行手续费流水',
              label_i18n: '银行手续费流水',
              placeholder_i18n: '请填写银行手续费流水',
              disabled: true,
              allowClear: true,
              inputType: 'text',
              maxlength: 100,
              defaultValue: '',
              placeholder: '请填写银行手续费流水',
            },
          ],
          rules: {},
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            remittance_name: record.remittance_name,
            description: record.description,
            total_amount: record.total_amount,
            transaction_id: record.transaction_id,
            bank_charge__name: record.bank_charge__name,
            transaction__name: record.transaction__name,
            date_received: record.date_received,
            remittance_set_id: record.id,
            bank_charge_id: record.bank_charge_id,
            currency_id: record.currency_id,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            update_remittance_set(payload)
              .then(() => {
                message.success(t('修改成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '添加银行流水',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.transaction_id == null;
    },
    type: 'dropdown',
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const editModal = reactive({
        visible: true,
        title: t('添加银行流水'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'name',
              label: '名称',
              label_i18n: '名称',
              placeholder_i18n: '请填写名称',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写名称',
            },
            {
              type: 'input',
              name: 'description',
              label: '描述',
              label_i18n: '描述',
              placeholder_i18n: '请填描述',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写描述',
            },
            {
              type: 'money',
              name: 'amount',
              label: '总金额',
              label_i18n: '总金额',
              placeholder_i18n: '请填写总金额',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              placeholder: '请填写总金额',
            },
            {
              type: 'datepicker',
              name: 'date',
              label: '日期',
              label_i18n: '日期',
              placeholder_i18n: '请填写日期',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请填写日期',
            },
            {
              type: 'select',
              name: 'transaction_type',
              label: '流水种类',
              label_i18n: '流水种类',
              placeholder_i18n: '请选择流水种类',
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              placeholder: '请选择流水种类',
              datasource: 'transaction_type',
            },
            {
              type: 'remoteselect',
              name: 'cc_bank_account_id',
              label: 'CC银行账户',
              label_i18n: 'CC银行账户',
              placeholder_i18n: '请选择CC银行账户',
              disabled: false,
              placeholder: '请选择CC银行账户',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'account_flag',
              valueKey: 'id',
              modalType: 'baseinfo-ccbankaccountscc',
            },
            {
              type: 'remoteselect',
              name: 'currency_id',
              labelKeyList: ['code', 'currency'],
              label: '币种',
              label_i18n: t('币种'),
              default: true,
              disabled: false,
              placeholder: '请选择薪资币种',
              placeholder_i18n: t('请选择薪资币种'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-country1',
            },
          ],
          rules: {
            name: [{ required: true, message: t('请填输入名称') }],
            amount: [{ required: true, message: t('请填输入总金额') }],
            transaction_type: [{ required: true, message: t('请填输入流水种类') }],
          },
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            name: record.remittance_name,
            description: null,
            amount: record.total_amount,
            currency_id: record.transaction__currency_id,
            date: moment().format('YYYY-MM-DD'),
            transaction_type: '收入',
            cc_bank_account_id: null,
          }),
        },
        options: {
          transaction_type: [
            { value: '收入', name: t('收入') },
            { value: '支出', name: t('支出') },
          ],
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            if(record.total_amount - payload.amount < 0){
              Modal.confirm({
                title: t('提示'),
                content: t('银行手续费是负数，是否继续创建'),
                onOk() {
                  create_bank_transaction(payload).then((res: any) => {
                    set_remittance_set_transaction({
                      org_id: current_org?.id,
                      transaction_id: res?.id,
                      remittance_set_id: record.id,
                    })
                      .then((res: any) => {
                        message.success(t('操作成功'));
                        create_bank_transaction({
                          ...payload,
                          amount: Number(record.total_amount - payload.amount),
                          name: record.remittance_name + '-bank charge',
                          date: moment().format('YYYY-MM-DD'),
                          transaction_type: '支出',
                          description: '',
                        }).then((res1: any) => {
                          update_remittance_set({
                            org_id: current_org?.id,
                            bank_charge_id: res1?.id,
                            remittance_set_id: record.id,
                          })
                            .then((res2: any) => {
                              resolve(null);
                              Object.assign(record, res2)
                            })
                        });

                      })
                      .finally(() => {
                        editModal.loading = false;
                      });
                  });
                },
                onCancel() {},
              });
            }else {
              create_bank_transaction(payload).then((res: any) => {
                set_remittance_set_transaction({
                  org_id: current_org?.id,
                  transaction_id: res?.id,
                  remittance_set_id: record.id,
                })
                  .then((res: any) => {
                    message.success(t('操作成功'));
                    create_bank_transaction({
                      ...payload,
                      amount: Number(record.total_amount - payload.amount),
                      name: record.remittance_name + '-bank charge',
                      date: moment().format('YYYY-MM-DD'),
                      transaction_type: '支出',
                      description: '',
                    }).then((res1: any) => {
                      update_remittance_set({
                        org_id: current_org?.id,
                        bank_charge_id: res1?.id,
                        remittance_set_id: record.id,
                      })
                        .then((res2: any) => {
                          resolve(null);
                          Object.assign(record, res2)
                        })
                    });
                  })
                  .finally(() => {
                    editModal.loading = false;
                  });
              });
            }


          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '添加手续费银行流水',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.bank_charge_id == null;
    },
    type: 'dropdown',
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const editModal = reactive({
        visible: true,
        title: t('添加手续费银行流水'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'name',
              label: '名称',
              label_i18n: '名称',
              placeholder_i18n: '请填写名称',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写名称',
            },
            {
              type: 'input',
              name: 'description',
              label: '描述',
              label_i18n: '描述',
              placeholder_i18n: '请填描述',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写描述',
            },
            {
              type: 'money',
              name: 'amount',
              label: '总金额',
              label_i18n: '总金额',
              placeholder_i18n: '请填写总金额',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              placeholder: '请填写总金额',
            },
            {
              type: 'datepicker',
              name: 'date',
              label: '日期',
              label_i18n: '日期',
              placeholder_i18n: '请填写日期',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请填写日期',
            },
            {
              type: 'select',
              name: 'transaction_type',
              label: '流水种类',
              label_i18n: '流水种类',
              placeholder_i18n: '请选择流水种类',
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              placeholder: '请选择流水种类',
              datasource: 'transaction_type',
            },
            {
              type: 'remoteselect',
              name: 'cc_bank_account_id',
              label: 'CC银行账户',
              label_i18n: 'CC银行账户',
              placeholder_i18n: '请选择CC银行账户',
              disabled: false,
              placeholder: '请选择CC银行账户',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'account_flag',
              valueKey: 'id',
              modalType: 'baseinfo-ccbankaccountscc',
            },
            {
              type: 'remoteselect',
              name: 'currency_id',
              labelKeyList: ['code', 'currency'],
              label: '币种',
              label_i18n: t('币种'),
              disabled: false,
              default: true,
              placeholder: '请选择薪资币种',
              placeholder_i18n: t('请选择薪资币种'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-country1',
            },
          ],
          rules: {
            name: [{ required: true, message: t('请填输入名称') }],
            amount: [{ required: true, message: t('请填输入总金额') }],
            transaction_type: [{ required: true, message: t('请填输入流水种类') }],
          },
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            name: record.remittance_name + '-bank charge',
            description: null,
            amount: null,
            currency_id: null,
            date: moment().format('YYYY-MM-DD'),
            transaction_type: '支出',
            cc_bank_account_id: null,
          }),
        },
        options: {
          transaction_type: [
            { value: '收入', name: t('收入') },
            { value: '支出', name: t('支出') },
          ],
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            create_bank_transaction(payload).then((res: any) => {
              update_remittance_set({
                org_id: current_org?.id,
                bank_charge_id: res?.id,
                remittance_set_id: record.id,
              })
                .then((res: any) => {
                  resolve(null);
                  message.success(t('操作成功'));
                  Object.assign(record, res);
                })
                .finally(() => {
                  editModal.loading = false;
                });
            });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '跳转工资单',
    clazz: 'primary',
    type: 'dropdown',
    cb: (record: any) => {
      router.push({ path: '/adviser-manage/cerp-payslip', query: { remittance_set_id: record.id, t: (new Date()).getTime() } });
    },
  },
  {
    name: '新增汇款凭证项',
    clazz: 'primary',
    type: 'dropdown',
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const editModal = reactive({
        visible: true,
        title: t('新增汇款凭证项'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'remittance_name',
              label: '汇款凭证',
              label_i18n: '汇款凭证',
              placeholder_i18n: '请选择汇款凭证',
              disabled: true,
              allowClear: true,
              inputType: 'text',
              maxlength: 100,
              defaultValue: '',
            },
            {
              type: 'remoteselect',
              name: 'invoice_id',
              label: '请款单',
              label_i18n: '请款单',
              placeholder_i18n: '请款单',
              disabled: false,
              placeholder: '请选择请款单',
              datasourceType: 'remote',
              mode: 'default',
              init_model: { is_remittance: true, status: [2, 7] },
              labelKeyList: ['invoice_no', 'total_amount'],
              allowClear: true,
              modalType: 'cerp-invoice',
            },
            {
              type: 'textarea',
              name: 'description',
              label: '描述',
              label_i18n: '描述',
              placeholder_i18n: '请填描述',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 100,
              defaultValue: '',
              placeholder: '请填描述',
            },
            {
              type: 'money',
              name: 'amount',
              label: '金额',
              label_i18n: '金额',
              placeholder_i18n: '请填总金额',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              maxlength: 100,
              defaultValue: '',
              placeholder: '请填总金额',
            },
          ],
          rules: {
            amount: [{ required: true, message: t('请填总金额') }],
          },
          model: {
            org_id: current_org?.id,
            is_active: true,
            remittance_set_id: record.id,
            remittance_name: record.remittance_name,
            invoice_id: null,
            description: null,
            amount: null,
          },
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            create_remittance(payload)
              .then(() => {
                message.success(t('新增成功'));
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '汇款凭证项批量导入',
    clazz: 'primary',
    type: 'dropdown',
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('汇款凭证项批量导入'),
        group: '汇款凭证',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;
            router.push({
              path:
                '/profile/basic-remittanceset/' +
                payload[0] +
                '/' +
                record.remittance_name +
                '/' +
                record.date_received +
                '/' +
                record.total_amount +
                '/' +
                record.id,
            });
            resolve(null);
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        params: {
          object_id: record.id,
          note_type_flag: 'cerp.remittanceset',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
  {
    name: '删除',
    type: 'dropdown',
    clazz: 'danger',
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      Modal.confirm({
        title: t('是否删除此项记录') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            delete_remittance_set({
              org_id: current_org.id,
              remittance_set_id: record.id,
            }).then((res: any) => {
              message.success(t('删除成功'));
              Object.assign(record, res);
              resolve(null);
            }).finally(() => {
              resolve(null);
            })
          })
        },
        onCancel() {},
      });
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};
const cc_id: any = ref(null);
const is_cc: any = ref(false);
export const head_btn_list = [
  {
    name: '新建',
    clazz: 'primary',
    cb: (record: any, dataSource: any, selectedRowKeys: any, searchConfigOptions: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const date_received: any = ref('');
      const company_name: any = ref('');
      const currency_name: any = ref('');
      const tempModel = reactive({
        org_id: current_org?.id,
        is_active: true,
        remittance_name: null,
        description: null,
        currency_id: null,
        total_amount: null,
        date_received: null,
        transaction_id: null,
      });
      const editModal = reactive({
        visible: true,
        title: t('新增汇款凭证'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'remittance_name',
              label: '名称',
              label_i18n: '名称',
              placeholder_i18n: '请填写名称',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 100,
              defaultValue: '',
              placeholder: '请填写名称',
            },
            {
              type: 'textarea',
              name: 'description',
              label: '描述',
              label_i18n: '描述',
              placeholder_i18n: '请填描述',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 100,
              defaultValue: '',
              placeholder: '请填描述',
            },
            {
              type: 'remoteselect',
              name: 'recuitment_company_id',
              label: '顾问公司',
              label_i18n: '顾问公司',
              placeholder_i18n: '请选择顾问公司',
              disabled: false,
              placeholder: '请选择顾问公司',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'company_name',
              valueKey: 'id',
              modalType: 'baseinfo-recuitmentcompany',
            },
            {
              type: 'datepicker',
              name: 'date_received',
              label: '汇款收到日期',
              label_i18n: '汇款收到日期',
              placeholder_i18n: '请填写汇款收到日期',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: true,
              valueFormat: 'YYYY-MM-DD HH:mm',
              format: 'YYYY-MM-DD HH:mm',
              placeholder: '请填写汇款收到日期',
            },
            {
              type: 'money',
              name: 'total_amount',
              label: '总金额',
              label_i18n: '总金额',
              placeholder_i18n: '请填写总金额',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              maxlength: 100,
              defaultValue: '',
              placeholder: '请填总金额',
            },
            {
              type: 'remoteselect',
              name: 'currency_id',
              labelKeyList: ['code', 'currency'],
              label: '币种',
              label_i18n: t('币种'),
              disabled: false,
              placeholder: '请选择币种',
              placeholder_i18n: t('请选择币种'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-country',
            },
            {
              type: 'remoteselect',
              name: 'transaction_id',
              label: '银行流水',
              label_i18n: '银行流水',
              placeholder_i18n: '请填写银行流水',
              disabled: false,
              placeholder: '请选择银行流水',
              datasourceType: 'remote',
              init_model: { pageSize: 200 },
              mode: 'default',
              allowClear: true,
              modalType: 'cerp-banktransaction',
              cb: () => {
                console.error(1);
                const p = new Promise(function (resolve, reject) {
                  const editModal1 = reactive({
                    visible: true,
                    title: t('新建银行流水'),
                    loading: false,
                    form: {
                      settings: {
                        labelAlign: 'right',
                        layout: 'vertical',
                        col: [],
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'name',
                          label: '名称',
                          label_i18n: '名称',
                          placeholder_i18n: '请填写名称',
                          disabled: false,
                          allowClear: true,
                          inputType: 'text',
                          defaultValue: '',
                          placeholder: '请填写名称',
                        },
                        {
                          type: 'input',
                          name: 'description',
                          label: '描述',
                          label_i18n: '描述',
                          placeholder_i18n: '请填描述',
                          disabled: false,
                          allowClear: true,
                          inputType: 'text',
                          defaultValue: '',
                          placeholder: '请填写描述',
                        },
                        {
                          type: 'money',
                          name: 'amount',
                          label: '总金额',
                          label_i18n: '总金额',
                          placeholder_i18n: '请填写总金额',
                          disabled: false,
                          allowClear: true,
                          inputType: 'number',
                          defaultValue: '',
                          placeholder: '请填写总金额',
                        },
                        {
                          type: 'datepicker',
                          name: 'date',
                          label: '收到日期',
                          label_i18n: '收到日期',
                          placeholder_i18n: '请填写收到日期',
                          disabled: false,
                          allowClear: true,
                          inputType: 'number',
                          defaultValue: '',
                          showTime: false,
                          valueFormat: 'YYYY-MM-DD',
                          placeholder: '请填写收到日期',
                        },
                        {
                          type: 'select',
                          name: 'transaction_type',
                          label: '流水种类',
                          label_i18n: '流水种类',
                          placeholder_i18n: '请选择流水种类',
                          mode: 'default',
                          disabled: false,
                          defaultValue: '',
                          labelKey: 'name',
                          valueKey: 'value',
                          placeholder: '请选择流水种类',
                          datasource: 'transaction_type',
                        },
                        {
                          type: 'remoteselect',
                          name: 'cc_bank_account_id',
                          label: 'CC银行账户',
                          label_i18n: 'CC银行账户',
                          placeholder_i18n: '请选择CC银行账户',
                          disabled: false,
                          placeholder: '请选择CC银行账户',
                          datasourceType: 'remote',
                          mode: 'default',
                          allowClear: true,
                          labelKey: 'account_flag',
                          valueKey: 'id',
                          modalType: 'baseinfo-ccbankaccountscc',
                        },
                        {
                          type: 'remoteselect',
                          name: 'currency_id',
                          labelKey: 'currency',
                          label: '币种',
                          label_i18n: t('币种'),
                          default: true,
                          disabled: false,
                          placeholder: '请选择薪资币种',
                          placeholder_i18n: t('请选择薪资币种'),
                          datasourceType: 'remote',
                          mode: 'default',
                          allowClear: true,
                          modalType: 'baseinfo-country1',
                        },
                      ],
                      rules: {
                        name: [{ required: true, message: t('请填输入名称') }],
                        amount: [{ required: true, message: t('请填输入总金额') }],
                        transaction_type: [{ required: true, message: t('请填输入流水种类') }],
                      },
                      model: reactive({
                        org_id: current_org?.id,
                        is_active: true,
                        name: tempModel.remittance_name,
                        description: null,
                        amount: null,
                        currency_id: null,
                        date: date_received ? date_received : moment().format('YYYY-MM-DD HH:mm:ss'),
                        recuitment_company_id: null,
                        transaction_type: '收入',
                        cc_bank_account_id: null,
                      }),
                    },
                    options: {
                      transaction_type: [
                        { value: '收入', name: t('收入') },
                        { value: '支出', name: t('支出') },
                      ],
                    },
                    ok: (payload: any) => {
                      return new Promise(resolve1 => {
                        editModal1.loading = true;
                        create_bank_transaction(payload)
                          .then((res: any) => {
                            message.success(t('创建成功'));
                            cc_id.value = payload.cc_bank_account_id;
                            resolve(res.id as number);
                            resolve1(null);
                          })
                          .finally(() => {
                            editModal1.loading = false;
                          });
                      });
                    },
                  });
                  modalBox(editModal1);
                });
                return p;
              },
            },
            {
              type: 'remoteselect',
              name: 'bank_charge_id',
              label: '银行手续费流水',
              label_i18n: '银行手续费流水',
              placeholder_i18n: '请填写银行手续费流水',
              disabled: false,
              placeholder: '请选择银行手续费流水',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'cerp-banktransaction',
              cb: () => {
                const p = new Promise(function (resolve, reject) {
                  const editModal1 = reactive({
                    visible: true,
                    title: t('新建银行流水'),
                    loading: false,
                    form: {
                      settings: {
                        labelAlign: 'right',
                        layout: 'vertical',
                        col: [],
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'name',
                          label: '名称',
                          label_i18n: '名称',
                          placeholder_i18n: '请填写名称',
                          disabled: false,
                          allowClear: true,
                          inputType: 'text',
                          defaultValue: '',
                          placeholder: '请填写名称',
                        },
                        {
                          type: 'input',
                          name: 'description',
                          label: '描述',
                          label_i18n: '描述',
                          placeholder_i18n: '请填描述',
                          disabled: false,
                          allowClear: true,
                          inputType: 'text',
                          defaultValue: '',
                          placeholder: '请填写描述',
                        },
                        {
                          type: 'money',
                          name: 'amount',
                          label: '总金额',
                          label_i18n: '总金额',
                          placeholder_i18n: '请填写总金额',
                          disabled: false,
                          allowClear: true,
                          inputType: 'number',
                          defaultValue: '',
                          placeholder: '请填写总金额',
                        },
                        {
                          type: 'datepicker',
                          name: 'date',
                          label: '收到日期',
                          label_i18n: '收到日期',
                          placeholder_i18n: '请填写收到日期',
                          disabled: false,
                          allowClear: true,
                          inputType: 'number',
                          defaultValue: '',
                          showTime: false,
                          valueFormat: 'YYYY-MM-DD',
                          placeholder: '请填写收到日期',
                        },
                        {
                          type: 'select',
                          name: 'transaction_type',
                          label: '流水种类',
                          label_i18n: '流水种类',
                          placeholder_i18n: '请选择流水种类',
                          mode: 'default',
                          disabled: false,
                          defaultValue: '',
                          labelKey: 'name',
                          valueKey: 'value',
                          placeholder: '请选择流水种类',
                          datasource: 'transaction_type',
                        },
                        {
                          type: 'remoteselect',
                          name: 'cc_bank_account_id',
                          label: 'CC银行账户',
                          label_i18n: 'CC银行账户',
                          placeholder_i18n: '请选择CC银行账户',
                          disabled: false,
                          placeholder: '请选择CC银行账户',
                          datasourceType: 'remote',
                          mode: 'default',
                          allowClear: true,
                          labelKey: 'account_flag',
                          valueKey: 'id',
                          modalType: 'baseinfo-ccbankaccountscc',
                        },
                        {
                          type: 'remoteselect',
                          name: 'currency_id',
                          labelKey: 'currency',
                          label: '币种',
                          label_i18n: t('币种'),
                          default: true,
                          disabled: false,
                          placeholder: '请选择薪资币种',
                          placeholder_i18n: t('请选择薪资币种'),
                          datasourceType: 'remote',
                          mode: 'default',
                          allowClear: true,
                          modalType: 'baseinfo-country1',
                        },
                      ],
                      rules: {
                        name: [{ required: true, message: t('请填输入名称') }],
                        amount: [{ required: true, message: t('请填输入总金额') }],
                        transaction_type: [{ required: true, message: t('请填输入流水种类') }],
                      },
                      model: reactive({
                        org_id: current_org?.id,
                        is_active: true,
                        name: tempModel.remittance_name + '-bank charge',
                        description: null,
                        amount: null,
                        currency_id: null,
                        date: date_received ? date_received : moment().format('YYYY-MM-DD HH:mm:ss'),
                        recuitment_company_id: null,
                        transaction_type: '支出',
                        cc_bank_account_id: is_cc.value ? cc_id.value : null,
                      }),
                    },
                    options: {
                      transaction_type: [
                        { value: '收入', name: t('收入') },
                        { value: '支出', name: t('支出') },
                      ],
                    },
                    ok: (payload: any) => {
                      return new Promise(resolve1 => {
                        editModal1.loading = true;
                        create_bank_transaction(payload)
                          .then((res: any) => {
                            message.success(t('创建成功'));
                            is_cc.value = false;
                            resolve(res.id as number);
                            resolve1(null);
                          })
                          .finally(() => {
                            editModal1.loading = false;
                          });
                      });
                    },
                  });
                  modalBox(editModal1);
                });
                return p;
              },
            },
          ],
          rules: {
            recuitment_company_id: [{ required: true, message: t('请选择顾问公司') }],
            date_received: [{ required: true, message: t('请填写汇款收到日期') }],
            remittance_name: [{ required: true, message: t('请填输入名称') }],
            total_amount: [{ required: true, message: t('总金额') }],
            currency_id: [{ required: true, message: t('请选择币种') }],
          },
          model: tempModel,
          watch: {
            remittance_name: (data: any) => {
              tempModel['remittance_name'] = data['remittance_name'];
            },
            transaction_id: (data: any) => {
              if(data['transaction_id']){
                is_cc.value = true;
              }
            },
            date_received: (data: any) => {
              if(data['date_received']){
                date_received.value = data['date_received'];
                data['remittance_name'] = company_name.value + '-' + getDate(data['date_received']) + '-' + currency_name.value + ' ' + data['total_amount'] ;
              }
            },
            recuitment_company_id: (data: any) => {
              if(data['recuitment_company_id']){
                query_recuitment_company_list({
                  org_id: current_org.id,
                  ids: [data['recuitment_company_id']],
                }).then((res: any) => {
                  company_name.value = res.data[0].client_id;
                  data['remittance_name'] = company_name.value + '-' + getDate(data['date_received']) + '-' + currency_name.value + ' ' + data['total_amount'] ;
                })
              }
            },
            currency_id: (data: any) => {
              if(data['currency_id']){
                query_country_list({
                  org_id: current_org.id,
                  ids: [data['currency_id']],
                }).then((res: any) => {
                  currency_name.value = res.data[0].currency;
                  data['remittance_name'] = company_name.value + '-' + getDate(data['date_received']) + '-' + currency_name.value + ' ' + data['total_amount'] ;
                })
              }
            },
            total_amount: (data: any) => {
              if(data['total_amount']){
                data['remittance_name'] = company_name.value + '-' + getDate(data['date_received']) + '-' + currency_name.value + ' ' + data['total_amount'] ;
              }
            },
          },
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            create_remittance_set(payload)
              .then(() => {
                message.success(t('新建成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
];
