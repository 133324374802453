import {
  create_payment_record,
  delete_payment_record,
  query_payment_record_list,
  recover_payment_record,
  update_payment_record,
} from '@/api/receipt-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_COMPANY, STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/receipt/receipt-payment-config';
import { reactive } from 'vue';
import { useRoute } from 'vue-router';
import { getDate, getDateTime, getMonth } from '@/utils/function';
import router from '@/router';

const None = null;
const True = true;
const False = false;
export default () => {
  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  const current_company = ls.get(STORAGE_CURRENT_COMPANY); // 当前cc公司

  const { t } = useI18n();
  const search_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
      {
        type: 'remoteselect',
        name: 'receipt_id',
        label: '发票',
        label_i18n: t('发票'),
        disabled: false,
        placeholder: '请选择发票',
        placeholder_i18n: t('请选择发票'),
        datasourceType: 'remote',
        mode: 'multiple',
        allowClear: true,
        labelKeyList: [
          'receipt_contract__start_date',
          'receipt_contract__end_date',
          'customer__name',
        ],
        valueKey: 'id',
        modalType: 'receipt-query_receipt_list',
      },
      {
        type: 'remoteselect',
        name: 'cc_bank_account_id',
        label: 'CC银行账户',
        label_i18n: t('CC银行账户'),
        disabled: false,
        placeholder: '请选择CC银行账户',
        placeholder_i18n: t('请选择CC银行账户'),
        datasourceType: 'remote',
        mode: 'multiple',
        allowClear: true,
        labelKey: 'account_flag',
        valueKey: 'id',
        modalType: 'baseinfo-ccbankaccountscc',
      },
      {
        type: 'rangepicker',
        name: 'payment_date',
        label: '付款日期',
        label_i18n: t('付款日期'),
        disabled: false,
        allowClear: true,
        inputType: 'number',
        defaultValue: '',
        showTime: false,
        valueFormat: 'YYYY-MM-DD',
        format: 'YYYY-MM-DD',
        placeholder: '请选择付款日期',
        placeholder_i18n: t('请选择付款日期'),
      },
      {
        type: 'remoteselect',
        name: 'currency_id',
        labelKeyList: ['code', 'currency'],
        label: '币种',
        label_i18n: t('币种'),
        disabled: false,
        placeholder: '请选择币种',
        placeholder_i18n: t('请选择币种'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        modalType: 'baseinfo-country',
      },
      // {
      //   type: 'remoteselect',
      //   name: 'cc_company_id',
      //   label: 'CC公司',
      //   disabled: false,
      //   placeholder: '请选择CC公司',
      //   datasourceType: 'remote',
      //   mode: 'default',
      //   allowClear: true,
      //   modalType: 'baseinfo-cccompany',
      // },
      {
        type: 'input',
        name: 'cc_company_name',
        label: 'CC公司',
        label_i18n: t('CC公司'),
        disabled: true,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写CC公司',
        placeholder_i18n: t('请填写CC公司'),
      },
    ],
    rules: {},
    model: reactive({
      org_id: current_org?.id,
      is_active: true,
      name: null,
      receipt_id: null,
      cc_bank_account_id: null,
      payment_date: null,
      currency_id: null,
      cc_company_id: current_company?.id,
      cc_company_name: current_company?.name !== '全部公司' ? current_company?.name : '',
      sort_fields: ['-create_time'],
    }),
  };
  const search_options = {
    is_active: [
      { value: None, name: '全部' },
      { value: True, name: '可用' },
      { value: False, name: '禁用' },
    ],
  };

  const edit_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [],
    rules: {
      name: [{ required: true, message: '请填写票据种类名称' }],
    },
    model: reactive({
      org_id: current_org?.id,
      name: null,
      description: null,
    }),
  };
  const edit_model_config_options = {};

  const update_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [],
    rules: {},
    model: reactive({
      org_id: current_org?.id,
      name: null,
      description: null,
    }),
  };
  const update_model_config_options = {};

  const titleList = {
    title: t('付款记录'),
    addModalTitle: '',
    updateTitle: '',
    is_create: false, // 是否有【新建】按钮
    is_update: false, // 是否有【修改】按钮
    is_remove: true, // 是否有【删除】按钮
    is_recover: true, // 是否有【恢复】按钮
  };

  const api_fields: Record = {
    update: {
      payment_record_id: 'id',
    },
    delete: {
      payment_record_id: 'id',
    },
    recover: {
      payment_record_id: 'id',
    },
    updateData: {
      org_id: 'org_id',
      payment_record_id: 'id',
    },
  };

  const baseColumns: TableColumn[] = [
    {
      title: t('序号'),
      dataIndex: 'index',
      width: 50,
      fixed: 'left',
    },
    {
      title: t('ID'),
      width: 150,
      dataIndex: 'id',
      checked: false,
    },
    {
      title: t('客户'),
      width: 200,
      dataIndex: 'receipt__customer__name',
      checked: true,
      fixed: 'left',
    },
    {
      title: t('发票号'),
      width: 150,
      dataIndex: 'receipt__receipt_number',
      checked: true,
      fixed: 'left',
      sorter: {
        multiple: 1,
      },
    },
    {
      title: t('客户类型'),
      width: 80,
      dataIndex: 'receipt__customer__customer_type',
      checked: true,
      content: (record: any, text: any) => {
        let color = '';
        switch (text) {
          case 0:
            color = t('顾问');
            break;
          case 1:
            color = t('顾问公司');
            break;
          case 2:
            color = t('CC公司');
            break;
          case 3:
            color = t('其他');
            break;
          default:
            color = '';
            break;
        }
        return color;
      },
    },
    {
      title: t('CC公司'),
      width: 200,
      dataIndex: 'receipt__cc_company__name',
      checked: true,
    },
    {
      title: t('服务月份'),
      width: 80,
      dataIndex: 'receipt__service_month',
      checked: true,
      content: (record: any) => {
        return getMonth(record.receipt__service_month);
      },
    },
    {
      title: t('票据种类'),
      width: 75,
      dataIndex: 'receipt__receipt_type__name',
      checked: true,
    },
    {
      title: t('发票类型'),
      width: 75,
      dataIndex: 'receipt__receipt_kind',
      checked: true,
      content: (record: any) => {
        return record.receipt__receipt_kind == 0
          ? t('收取发票')
          : record.receipt__receipt_kind == 1
          ? t('开具发票')
          : '';
      },
    },
    {
      title: t('开票日期'),
      width: 95,
      dataIndex: 'receipt__receipt_date',
      checked: true,
      content: (record: any) => {
        return getDate(record.receipt__receipt_date);
      },
    },
    {
      title: t('付款金额'),
      width: 80,
      dataIndex: 'amount',
      checked: true,
      cellComponent: 'money',
    },
    {
      title: t('付款币种'),
      width: 80,
      dataIndex: 'currency__currency',
      checked: true,
    },
    {
      title: t('付款时间'),
      width: 130,
      dataIndex: 'payment_date',
      checked: true,
      content: (record: any) => {
        return getDateTime(record.payment_date);
      },
    },
    {
      title: t('银行流水'),
      width: 200,
      dataIndex: 'transaction__name',
      checked: true,
      cellComponent: 'click',
      cb: (record: any) => {
        router.push({
          path: '/adviser-manage/cerp-banktransaction',
          query: { id: record.transaction_id, t: new Date().getTime() },
        });
      },
    },
    {
      title: t('流水金额'),
      width: 100,
      dataIndex: 'transaction__amount',
      checked: true,
      cellComponent: 'money',
    },
    {
      title: t('流水币种'),
      width: 80,
      dataIndex: 'transaction__currency__currency',
      checked: true,
    },
    {
      title: t('创建时间'),
      width: 150,
      dataIndex: 'create_time',
      checked: true,
      content: (record: any) => {
        return getDateTime(record.create_time);
      },
    },
    {
      title: t('修改时间'),
      width: 150,
      dataIndex: 'update_time',
      checked: true,
      content: (record: any) => {
        return getDateTime(record.update_time);
      },
    },
    {
      title: t('是否可用'),
      width: 150,
      dataIndex: 'is_active',
      checked: true,
      cellComponent: 'boolean',
      name1: t('可用'),
      name2: t('禁用'),
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('可用'), value: true },
        { text: t('禁用'), value: false },
      ],
    },

    {
      title: t('操作'),
      width: 150,
      dataIndex: 'action',
      checked: true,
      cellComponent: 'action',
      fixed: 'right',
    },
  ];
  const newSlotList: any = [];
  Object.assign(newSlotList, slotList);
  newSlotList.push();

  const route = useRoute();
  search_config.model = reactive({ ...search_config.model });
  search_config.model.cc_company_id = route.query.cc_company_id
    ? route.query.cc_company_id
    : current_company?.id;
  search_config.model.cc_company_name = route.query.cc_company_name
    ? route.query.cc_company_name !== '全部公司'
      ? route.query.cc_company_name
      : ''
    : current_company?.name !== '全部公司'
    ? current_company?.name
    : '';
  search_config.model.ids = Number(route.query.ids);
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_payment_record_list,
    addData: create_payment_record,
    updateData: update_payment_record,
    removeData: delete_payment_record,
    recoverData: recover_payment_record,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
