import {
  create_contract,
  delete_contract,
  query_contract_list,
  recover_contract,
  update_contract,
  upload_contract_appendix,
  upload_contract_wechat_appendix,
} from '@/api/cerp-model';
import { create_end_client } from '@/api/baseinfo-model';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_COMPANY, STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { btn_list, event_obj, head_btn_list } from '@/components/page-model/cerp/cerp-contract-config';
import { baseColumns, newSlotList } from '@/components/page-model/cerp/cerp-contract-colums.ts';
import { createVNode, reactive } from 'vue';
import { useRoute } from 'vue-router';
import { message, Modal, notification } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import modalBox from '@/components/form-modal/modal-tools';

const None = null;
const True = true;
const False = false;
export default () => {

  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  const current_company = ls.get(STORAGE_CURRENT_COMPANY); // 当前cc公司

  const { t } = useI18n();
  const search_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
      {
        type: 'input',
        name: 'search_text',
        label: '模糊搜索',
        label_i18n: t('page.cerp.contract.form.label.search_text'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写搜索内容',
        placeholder_i18n: t('page.cerp.contract.form.placeholder.search_text'),
      },
      {
        type: 'input',
        name: 'cc_company_name',
        label: 'CC公司',
        label_i18n: t('page.cerp.contract.form.label.cc_company_id'),
        disabled: true,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写CC公司',
      },
      {
        type: 'remotemodal',
        name: 'adviser_id',
        label: '隶属顾问',
        label_i18n: t('page.cerp.contract.form.label.adviser_id'),
        disabled: false,
        defaultValue: '',
        labelKey: 'full_name',
        valueKey: 'id',
        placeholder: '隶属顾问',
        placeholder_i18n: t('page.cerp.contract.form.placeholder.adviser_id'),
        show_search: false,
        maxTagCount: 5,
        mode: 'default',
        title: t('选择隶属顾问'),
        modalType: 'cerp-adviser',
      },
      {
        type: 'remoteselect',
        name: 'recuitment_company_id',
        label: '顾问公司',
        label_i18n: t('page.cerp.contract.form.label.recuitment_company_id'),
        disabled: false,
        placeholder: '请选择顾问公司',
        placeholder_i18n: t('page.cerp.contract.form.placeholder.recuitment_company_id'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        labelKey: 'company_name',
        valueKey: 'id',
        modalType: 'baseinfo-recuitmentcompany',
      },
      {
        type: 'remoteselect',
        name: 'contact_person_id',
        label: '顾问公司联系人',
        label_i18n: t('page.cerp.contract.form.label.contact_person_id'),
        disabled: false,
        placeholder: '请选择顾问公司联系人',
        placeholder_i18n: t('page.cerp.contract.form.placeholder.contact_person_id'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        labelKey: 'name',
        valueKey: 'id',
        modalType: 'baseinfo-recuitmentcompanyperson1',
      },
      {
        type: 'remoteselect',
        name: 'recuitment_company_person_id',
        label: '请款邮箱地址',
        label_i18n: t('page.cerp.contract.form.label.recuitment_company_person_id'),
        disabled: false,
        placeholder: '请选择请款邮箱地址',
        placeholder_i18n: t('page.cerp.contract.form.placeholder.recuitment_company_person_id'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        labelKey: 'name',
        valueKey: 'id',
        modalType: 'baseinfo-recuitmentcompanyperson1',
      },
      {
        type: 'remoteselect',
        name: 'work_location_id',
        label: '工作地点',
        label_i18n: t('page.cerp.contract.form.label.work_location_id'),
        disabled: false,
        placeholder: '请选择工作地点',
        placeholder_i18n: t('page.cerp.contract.form.placeholder.work_location_id'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        modalType: 'baseinfo-country',
      },
      {
        type: 'remoteselect',
        name: 'management_fee_formula_id',
        label: '管理费计算方式',
        label_i18n: t('page.cerp.contract.form.label.management_fee_formula_id'),
        disabled: false,
        placeholder: '请选择管理费计算方式',
        placeholder_i18n: t('page.cerp.contract.form.placeholder.management_fee_formula_id'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        modalType: 'baseinfo-managementfeeformula',
      },
      {
        type: 'remoteselect',
        name: 'cc_email_id',
        label: 'CC邮箱',
        label_i18n: t('page.cerp.contract.form.label.cc_email_id'),
        disabled: false,
        placeholder: '请选择CC邮箱',
        placeholder_i18n: t('page.cerp.contract.form.placeholder.cc_email_id'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        modalType: 'baseinfo-ccemail',
      },
      {
        type: 'remoteselect',
        name: 'invoice_email_template_id',
        label: '请款单邮箱模板',
        label_i18n: t('page.cerp.contract.form.label.invoice_email_template_id'),
        disabled: false,
        placeholder: '请选择请款单邮箱模板',
        placeholder_i18n: t('page.cerp.contract.form.placeholder.invoice_email_template_id'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        modalType: 'baseinfo-emailtemplate',
      },
      {
        type: 'remoteselect',
        name: 'payslip_email_template_id',
        label: '工资单邮箱模板',
        label_i18n: t('page.cerp.contract.form.label.payslip_email_template_id'),
        disabled: false,
        placeholder: '请选择工资单邮箱模板',
        placeholder_i18n: t('page.cerp.contract.form.placeholder.payslip_email_template_id'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        modalType: 'baseinfo-emailtemplate',
      },
      {
        type: 'remoteselect',
        name: 'contract_template_id',
        label: '合同模板',
        label_i18n: t('page.cerp.contract.form.label.contract_template_id'),
        disabled: false,
        placeholder: '请选择合同模板',
        placeholder_i18n: t('page.cerp.contract.form.placeholder.contract_template_id'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        modalType: 'baseinfo-contracttemplate',
      },
      // {
      //   type: 'remoteselect',
      //   name: 'cc_company_id',
      //   label: 'CC公司',
      //   label_i18n: t('page.cerp.contract.form.label.cc_company_id'),
      //   disabled: false,
      //   placeholder: '请选择CC公司',
      //   placeholder_i18n: t('page.cerp.contract.form.placeholder.cc_company_id'),
      //   datasourceType: 'remote',
      //   mode: 'default',
      //   allowClear: true,
      //   modalType: 'baseinfo-cccompany',
      // },
      {
        type: 'remoteselect',
        name: 'end_client_id',
        label: '终端用户',
        label_i18n: t('page.cerp.contract.form.label.end_client_id'),
        disabled: false,
        placeholder: '请选择终端用户',
        placeholder_i18n: t('page.cerp.contract.form.placeholder.end_client_id'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        labelKey: 'name',
        valueKey: 'id',
        modalType: 'baseinfo-endclient',
        cb: () => {
          const p = new Promise(function (resolve, reject) {
            const editModal1 = reactive({
              visible: true,
              title: '新建终端用户',
              loading: false,
              form: {
                settings: {
                  labelAlign: 'right',
                  layout: 'vertical',
                  col: [],
                },
                fields: [
                  {
                    type: 'input',
                    name: 'name',
                    label: '名称',
                    label_i18n: t('page.cerp.contract.form.label.name'),
                    disabled: false,
                    allowClear: true,
                    inputType: 'text',
                    defaultValue: '',
                    placeholder: '请填写名称',
                    placeholder_i18n: t('page.cerp.contract.form.placeholder.name'),
                  },
                  {
                    type: 'input',
                    name: 'address',
                    label: '地址',
                    label_i18n: t('page.cerp.contract.form.label.address'),
                    disabled: false,
                    allowClear: true,
                    inputType: 'text',
                    defaultValue: '',
                    placeholder: '请填写地址',
                    placeholder_i18n: t('page.cerp.contract.form.placeholder.address'),
                  },
                  {
                    type: 'input',
                    name: 'contact_number',
                    label: '联系方式',
                    label_i18n: t('page.cerp.contract.form.label.contact_number'),
                    disabled: false,
                    allowClear: true,
                    inputType: 'text',
                    defaultValue: '',
                    placeholder: '请填写联系方式',
                    placeholder_i18n: t('page.cerp.contract.form.placeholder.contact_number'),
                  },
                ],
                rules: {
                  name: [{ required: true, message: '请填输入名称' }],
                },
                model: reactive({
                  org_id: current_org?.id,
                  name: null,
                  address: null,
                  contact_number: null,
                }),
              },
              options: {
                gender: [
                  { value: 0, name: '男' },
                  { value: 1, name: '女' },
                ],
              },
              ok: (payload: any) => {
                return new Promise(resolve => {
                  editModal1.loading = true;
                  create_end_client(payload)
                    .then((res: any) => {
                      message.success(t('创建成功'));
                      resolve(res.id as number);
                    })
                    .finally(() => {
                      editModal1.loading = false;
                    });
                });
              },
            });
            modalBox(editModal1);
          });
          return p;
        },
      },
      {
        type: 'select',
        name: 'insurance_type',
        label: '保险类型',
        label_i18n: t('page.cerp.contract.form.label.insurance_type'),
        mode: 'default',
        disabled: false,
        defaultValue: '',
        labelKey: 'name',
        valueKey: 'value',
        placeholder: '请选择保险类型',
        placeholder_i18n: t('page.cerp.contract.form.placeholder.insurance_type'),
        datasource: 'insurance_type',
      },
      {
        type: 'select',
        name: 'rate_type',
        label: '薪资种类',
        label_i18n: t('page.cerp.contract.form.label.rate_type'),
        mode: 'default',
        disabled: false,
        defaultValue: '',
        labelKey: 'name',
        valueKey: 'value',
        placeholder: '请选择薪资种类',
        placeholder_i18n: t('page.cerp.contract.form.placeholder.rate_type'),
        datasource: 'rate_type',
      },
      {
        type: 'remoteselect',
        name: 'currency_id',
        labelKeyList: ['code', 'currency'],
        label: '薪资币种',
        label_i18n: t('page.cerp.contract.form.label.currency_id'),
        disabled: false,
        placeholder: '请选择薪资币种',
        placeholder_i18n: t('page.cerp.contract.form.placeholder.currency_id'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        modalType: 'baseinfo-country',
      },
      {
        type: 'input',
        name: 'remark',
        label: '备注',
        label_i18n: t('page.cerp.contract.form.label.remark'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写备注',
        placeholder_i18n: t('page.cerp.contract.form.placeholder.remark'),
      },
      {
        type: 'radio',
        name: 'is_bank_charge_payment',
        label: '银行转账费支付方式',
        label_i18n: t('page.cerp.contract.form.label.is_bank_charge_payment'),
        disabled: false,
        labelKey: 'name',
        valueKey: 'value',
        defaultValue: '',
      },
      {
        type: 'radio',
        name: 'insurance_payment_flag',
        label: '保险费支付方式',
        label_i18n: t('保险费支付方式'),
        disabled: false,
        labelKey: 'name',
        valueKey: 'value',
        defaultValue: '',
      },
      {
        type: 'radio',
        name: 'management_fee_payment_flag',
        label: '管理费支付方式',
        label_i18n: t('page.cerp.contract.form.label.management_fee_payment_flag'),
        disabled: false,
        labelKey: 'name',
        valueKey: 'value',
        defaultValue: '',
      },
      {
        type: 'radio',
        name: 'is_current',
        label: '是否当前合同',
        label_i18n: t('page.cerp.contract.form.label.is_current'),
        disabled: false,
        labelKey: 'name',
        valueKey: 'value',
        defaultValue: '',
      },
      {
        type: 'radio',
        name: 'solution_type',
        label: '是否有本地解决方案',
        label_i18n: t('page.cerp.contract.form.label.solution_type'),
        disabled: false,
        labelKey: 'name',
        valueKey: 'value',
        defaultValue: '',
      },
      {
        type: 'radio',
        name: 'is_active',
        label: '是否可用',
        label_i18n: t('page.cerp.contract.form.label.is_active'),
        disabled: false,
        labelKey: 'name',
        valueKey: 'value',
        defaultValue: '',
      },
    ],
    rules: {},
    model: reactive({
      org_id: current_org?.id,
      search_text: '',
      is_active: true,
      adviser_id: null,
      contact_person_id: null,
      management_fee_formula_id: null,
      income_tax: null,
      social_insurance: null,
      cc_email_id: null,
      invoice_email_template_id: null,
      payslip_email_template_id: null,
      contract_template_id: null,
      cc_company_name: current_company?.name !== '全部公司' ? current_company?.name : '',
      cc_company_id: current_company?.id,
      is_current: null,
      is_bank_charge_payment: null,
      insurance_payment_flag: null,
      recuitment_company_person_id: null,
      recuitment_company_id: null,
      work_location_id: null,
      rate_type: null,
      solution_type: null,
      end_client_id: null,
      currency_id: null,
      remark: null,
      management_fee_payment_flag: null,
    }),
    reset_model: {
      org_id: current_org?.id,
      search_text: '',
      is_active: true,
      adviser_id: null,
      contact_person_id: null,
      management_fee_formula_id: null,
      income_tax: null,
      social_insurance: null,
      cc_email_id: null,
      invoice_email_template_id: null,
      payslip_email_template_id: null,
      contract_template_id: null,
      cc_company_id: null,
      is_current: null,
      is_bank_charge_payment: null,
      insurance_payment_flag: null,
      recuitment_company_person_id: null,
      recuitment_company_id: null,
      work_location_id: null,
      rate_type: null,
      solution_type: null,
      end_client_id: null,
      currency_id: null,
      remark: null,
      management_fee_payment_flag: null,
    },
};
const search_options = {
  solution_type: [
    { value: None, name: t('全部') },
    { value: '0', name: t('是') },
    { value: '1', name: t('否') },
  ],
  is_active: [
    { value: None, name: t('全部') },
    { value: True, name: t('可用') },
    { value: False, name: t('禁用') },
  ],
  is_current: [
    { value: None, name: t('全部') },
    { value: True, name: t('是') },
    { value: False, name: t('否') },
  ],
  is_bank_charge_payment: [
    { value: None, name: t('全部') },
    { value: True, name: t('顾问支付') },
    { value: False, name: t('顾问公司支付') },
  ],
  insurance_payment_flag: [
    { value: None, name: t('全部') },
    { value: -1, name: t('无') },
    { value: 0, name: t('顾问支付') },
    { value: 1, name: t('顾问公司支付') },
  ],
  management_fee_payment_flag: [
    { value: None, name: t('全部') },
    { value: 0, name: t('顾问支付') },
    { value: 1, name: t('顾问公司支付') },
  ],
  rate_type: [
    { value: 0, name: t('日薪') },
    { value: 1, name: t('月薪') },
    { value: 2, name: t('年薪') },
    { value: 3, name: t('时薪') },
    { value: 4, name: t('周薪') },
  ],
  insurance_type: [
    { value: 0, name: t('需要') },
    { value: 1, name: t('不需要–顾问在本地') },
    { value: 2, name: t('不需要–顾问自己有保单') },
    { value: 3, name: t('不需要–顾问公司保险') },
  ],
};

  const edit_model_config: SearchConfig = {
    settings: {},
    fields: [],
    rules: {},
    model: reactive({}),
};
const edit_model_config_options = {};

  const update_model_config: SearchConfig = {
    settings: {},
    fields: [],
    rules: {},
    model: reactive({}),
};
const update_model_config_options = {};

  const titleList = {
    title: t('合同管理'),
    addModalTitle: t('新合同'),
    updateTitle: t('修改合同'),
    is_create: false, // 是否有【新建】按钮
    is_update: false, // 是否有【修改】按钮
    is_remove: true, // 是否有【删除】按钮
    is_recover: true, // 是否有【恢复】按钮
  };

  const api_fields: Record = {
    update: {
      contract_id: 'id',
    },
    delete: {
      contract_id: 'id',
    },
    recover: {
      contract_id: 'id',
    },
    updateData: {
      org_id: 'org_id',
      contract_id: 'id',
    },
};
newSlotList.push(
  {
    type: 'color',
    slotName: 'email_record__status',
    cb: (record: any) => {
      if (record.email_record__status == 0 || record.email_record__status == 1) {
        return 'font-weight: bolder';
      } else if (record.email_record__status == 2) {
        return 'color: #FF0000;font-weight: bolder';
      }
    },
    content: (record: any) => {
      if (record.email_record__status == 1) {
        return t('发送成功');
      } else if (record.email_record__status == 2) {
        return t('发送失败');
      } else if (record.email_record__status == 0) {
        return t('已发送');
      }
    },
  },
  {
    type: 'color',
    slotName: 'qiyuesuo_status',
    cb: (record: any) => {
      if (record.status == 1 || record.status == 2 || record.status == 3) {
        return 'font-weight: bolder';
      } else if (record.status == 4 || record.status == 5) {
        return 'color: #FF0000;font-weight: bolder';
      }
    },
    content: (record: any) => {
      if (record.status == 1) {
        return t('草稿');
      } else if (record.status == 2) {
        return t('发起合同');
      } else if (record.status == 3) {
        return t('签署完成');
      } else if (record.status == 4) {
        return '签署拒绝';
      } else if (record.status == 5) {
        return '签署失败';
      }
    },
  },
  {
    type: 'custom',
    slotName: 'rate_type',
    field_0: t('日薪'),
    field_1: t('月薪'),
    field_2: t('年薪'),
    field_3: t('时薪'),
    field_4: t('周薪'),
  },
  {
    type: 'custom',
    slotName: 'is_bank_charge_payment',
    field_true: t('顾问支付'),
    field_false: t('顾问公司支付'),
  },
  {
    type: 'custom1',
    slotName: 'insurance_payment_flag',
    content: (record: any) => {
      if (record.insurance_payment_flag == -1) {
        return t('无');
      } else if (record.insurance_payment_flag == 0) {
        return t('顾问支付');
      } else if (record.insurance_payment_flag == 1) {
        return t('顾问公司支付');
      } else {
        return '';
      }
    },
  },
  {
    type: 'custom',
    slotName: 'management_fee_payment_flag',
    field_0: t('顾问支付'),
    field_1: t('顾问公司支付'),
  },
  {
    type: 'clickList',
    slotName: 'appendix_json',
    cb: (record: any) => {
      if (record && record.file_url) {
        const url: string = 'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.file_url);
        window.open(url, '_blank');
        // axios.get('/' + record.file_url,{
        //   responseType: 'blob' }).then((res: any) => {
        //   var blob = res.data;
        //   // FileReader主要用于将文件内容读入内存
        //   var reader = new FileReader();
        //   reader.readAsDataURL(blob);
        //   // onload当读取操作成功完成时调用
        //   reader.onload = (e: any) => {
        //     var a = document.createElement('a');
        //     // 获取文件名fileName
        //     var fileName = res.config["url"].split("/");
        //     fileName = fileName[fileName.length - 1];
        //     // fileName = fileName.replace(/"/g, "");
        //     a.download = fileName;
        //     a.href = e.target.result;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        //   }
        // })
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
    del: (record: any, text: any, file: any) => {
      if (file.file_id) {
        Modal.confirm({
          title: t('提示'),
          content: t('是否删除') + '[' + file.name + ']',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            return new Promise(resolve => {
              const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
              let ids: any = [];
              text.forEach((itme: any) => {
                if (itme.file_id != file.file_id) {
                  ids.push(itme.file_id);
                }
              });
              if (ids.length == 0) {
                ids = 0;
              }
              upload_contract_appendix({
                contract_id: record.id,
                appendix_ids: ids,
                org_id: current_org?.id,
              })
                .then(() => {
                  message.success(t('删除成功'));
                  event_obj.emit('refresh');
                })
                .finally(() => {
                  resolve(null);
                });
            });
          },
          onCancel() {},
        });
      }
    },
  },
  {
    type: 'clickList',
    slotName: 'wechat_appendix_json_list',
    cb: (record: any) => {
      if (record && record.file_url) {
        const url: string = 'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.file_url);
        window.open(url, '_blank');
        // axios.get('/' + record.file_url,{
        //   responseType: 'blob' }).then((res: any) => {
        //   var blob = res.data;
        //   // FileReader主要用于将文件内容读入内存
        //   var reader = new FileReader();
        //   reader.readAsDataURL(blob);
        //   // onload当读取操作成功完成时调用
        //   reader.onload = (e: any) => {
        //     var a = document.createElement('a');
        //     // 获取文件名fileName
        //     var fileName = res.config["url"].split("/");
        //     fileName = fileName[fileName.length - 1];
        //     // fileName = fileName.replace(/"/g, "");
        //     a.download = fileName;
        //     a.href = e.target.result;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        //   }
        // })
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
    del: (record: any, text: any, file: any) => {
      if (file.file_id) {
        Modal.confirm({
          title: t('提示'),
          content: t('是否删除') + '[' + file.name + ']',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            return new Promise(resolve => {
              const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
              let ids: any = [];
              text.forEach((itme: any) => {
                if (itme.file_id != file.file_id) {
                  ids.push(itme.file_id);
                }
              });
              if (ids.length == 0) {
                ids = 0;
              }
              upload_contract_wechat_appendix({
                contract_id: record.id,
                appendix_ids: ids,
                org_id: current_org?.id,
              })
                .then(() => {
                  message.success(t('删除成功'));
                  event_obj.emit('refresh');
                })
                .finally(() => {
                  resolve(null);
                });
            });
          },
          onCancel() {},
        });
      }
    },
  },
)
  const route = useRoute();
  search_config.model = reactive({ ...search_config.model });
  search_config.model.search_text = route.query.q;
  search_config.model.cc_company_name = route.query.cc_company_name ? (route.query.cc_company_name !== '全部公司' ? route.query.cc_company_name : '') : (current_company?.name !== '全部公司' ? current_company?.name : '');
  search_config.model.cc_company_id = route.query.cc_company_id ? route.query.cc_company_id : current_company?.id;
  search_config.model.ids = Number(route.query.ids);
  // onMounted(() => {
  //   watch(
  //     () => route.query,
  //     () => {
  //       if (route.path == '/adviser-manage/cerp-contract' && route.query.q) {
  //         // search_config.model.search_text = route.query.q;
  //         event_obj.emit('refresh_search', { search_text: route.query.q });
  //       }
  //     },
  //   );
  // });
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_contract_list,
    addData: create_contract,
    updateData: update_contract,
    removeData: delete_contract,
    recoverData: recover_contract,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
