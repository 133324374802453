import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import { reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { useRouter } from 'vue-router';
import { update_receipt_template, upload_receipt_template_file } from '@/api/receipt-model';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const { t } = useI18n();

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
export const event_obj = mitt();
export const btn_list = [
  {
    name: t('修改'),
    clazz: 'primary',
    cb: (record: any) => {
      const editModal = reactive({
        visible: true,
        title: t('修改票据模板'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: config.creatCol,
          },
          fields: [
            {
              type: 'input',
              name: 'name',
              label: '票据模板名称',
              label_i18n: t('票据模板名称'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写票据模板名称',
              placeholder_i18n: t('请填写票据模板名称'),
            },
            {
              type: 'input',
              name: 'description',
              label: '描述',
              label_i18n: t('描述'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写描述',
              placeholder_i18n: t('请填写描述'),
            },
            {
              type: 'remoteselect',
              name: 'cc_company_id',
              label: 'CC公司',
              label_i18n: t('CC公司'),
              placeholder: '请选择CC公司',
              placeholder_i18n: t('请选择CC公司'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-cccompany',
            },
            {
              type: 'radio',
              name: 'is_vat',
              label: '是否VAT模板',
              label_i18n: t('是否VAT模板'),
              disabled: false,
              labelKey: 'name',
              valueKey: 'value',
              defaultValue: '',
            },
            {
              type: 'remoteselect',
              name: 'email_template_id',
              label: '发票邮件模板',
              label_i18n: t('发票邮件模板'),
              disabled: false,
              placeholder: '请选择发票邮件模板',
              placeholder_i18n: t('请选择发票邮件模板'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-emailtemplate',
            },
            {
              type: 'radio',
              name: 'is_default',
              label: '是否默认模板',
              label_i18n: t('是否默认模板'),
              disabled: false,
              labelKey: 'name',
              valueKey: 'value',
              defaultValue: '',
            },
            {
              type: 'number',
              name: 'customfield_num',
              label: '自定义项数量',
              label_i18n: t('自定义项数量'),
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              placeholder: '请填写自定义项数量',
              placeholder_i18n: t('请填写自定义项数量'),
            },
            {
              type: 'attributetable',
              name: 'data',
              label: '模板数据',
              label_i18n: t('模板数据'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写模板数据',
              placeholder_i18n: t('请填写模板数据'),
            },
          ],
          rules: {},
          model: reactive({
            org_id: current_org?.id,
            receipt_template_id: record.id,
            name: record.name,
            description: record.description,
            data: record.data,
            customfield_num: record.customfield_num,
            cc_company_id: record.cc_company_id,
            is_vat: record.is_vat,
            is_default: record.is_default,
            email_template_id: record.email_template_id,
          }),
        },
        options: {
          is_vat: [
            { value: true, name: t('是') },
            { value: false, name: t('否') },
          ],
          is_default: [
            { value: true, name: t('是') },
            { value: false, name: t('否') },
          ],
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            update_receipt_template({
              ...payload,
            })
              .then(() => {
                message.success(t('修改成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: t('上传附件'),
    clazz: 'primary',
    cb: (data: any) => {
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('上传票据模板附件'),
        group: '合同',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;
            upload_receipt_template_file({
              receipt_template_id: data.id,
              nsfile_id: payload[0],
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('上传成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
