import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import { reactive, ref } from 'vue';
import modalBox from '@/components/form-modal/modal-tools';
import { update_custom_panel_data } from '@/api/sandbox-model';
import { message } from 'ant-design-vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';

export const event_obj = mitt();
export const btn_list = [
  {
    name: '查询代码',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const code_ref = ref<string>('');
      const loading = ref(false);
      if (record.query_code) {
        code_ref.value = record.query_code;
      } else {
        code_ref.value = '';
      }
      const editModal = reactive({
        visible: true,
        title: t('代码内容'),
        component: 'code-editing-modal',
        loading: loading,
        mode: 'python',
        params: code_ref.value,
        hold: (code: any) => {
          loading.value = true;
          return new Promise(resolve => {
            update_custom_panel_data({
              org_id: current_org?.id,
              custompanel_id: record.id,
              query_code: code,
            })
              .then(function (res) {
                message.success(t('保存成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                loading.value = false;
                resolve(null);
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  // {
  //   name: '列表代码',
  //   type: 'dropdown',
  //   clazz: 'primary',
  //   cb: (record: any) => {
  //     const modifyModal: any = reactive({
  //       visible: false,
  //       component: 'NoteModel',
  //       params: {
  //         object_id: record.id,
  //         note_type_flag: 'baseinfo.country',
  //       },
  //     });
  //     modifyModal.visible = true;
  //     modifyModal.ok = () => {
  //       event_obj.emit('refresh');
  //     };
  //     modifyModal.cancel = () => {};
  //     modalBox(modifyModal);
  //   },
  // },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
