import {
  create_parameter,
  delete_parameter,
  query_parameter_list,
  recover_parameter,
  update_parameter,
} from '@/api/apidoc-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/apidoc/apidoc-parameter-config';

import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '参数名',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写参数名',
    },
    {
      type: 'remoteselect',
      name: 'api_id',
      label: '隶属接口',
      disabled: false,
      placeholder: '请选择隶属接口',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'apidoc-apiinfo',
    },
    {
      type: 'remoteselect',
      name: 'value_type',
      label: '参数类型',
      disabled: false,
      placeholder: '请选择参数类型',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'apidoc-valuetype',
    },
    {
      type: 'input',
      name: 'select_flag',
      label: 'RemoteSelect的flag',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写RemoteSelect的flag',
    },
    {
      type: 'input',
      name: 'modal_flag',
      label: 'RemoteSelect的flag',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写RemoteSelect的flag',
    },
    {
      type: 'input',
      name: 'sort',
      label: '排序',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写排序',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    api_id: null,
    is_required: null,
    is_array: null,
    value_type: null,
    is_select: null,
    select_flag: null,
    is_modal: null,
    modal_flag: null,
    is_hide: null,
    is_id: null,
    sort: null,
  }),
};
const search_options = {
  is_required: [
    { value: None, name: '全部' },
    {
      value: True,
      name: '允许代码修改',
    },
    { value: False, name: '不允许代码修改' },
  ],
  is_array: [
    { value: None, name: '全部' },
    { value: True, name: '数组型参数' },
    {
      value: False,
      name: '非数组型参数',
    },
  ],
  is_select: [
    { value: None, name: '全部' },
    { value: True, name: '需要RemoteSelect' },
    {
      value: False,
      name: '不需要RemoteSelect',
    },
  ],
  is_modal: [
    { value: None, name: '全部' },
    { value: True, name: '需要RemoteModal' },
    {
      value: False,
      name: '不需要RemoteModal',
    },
  ],
  is_hide: [
    { value: None, name: '全部' },
    { value: True, name: '需要RemoteModal' },
    {
      value: False,
      name: '不需要RemoteModal',
    },
  ],
  is_id: [
    { value: None, name: '全部' },
    { value: True, name: 'id参数' },
    { value: False, name: '非id参数' },
  ],
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '参数名',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写参数名',
    },
    {
      type: 'input',
      name: 'rule',
      label: '参数规则',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写参数规则',
    },
    {
      type: 'input',
      name: 'info',
      label: '参数中文',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写参数中文',
    },
    {
      type: 'radio',
      name: 'is_required',
      label: '是否必填参数',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否必填参数',
    },
    {
      type: 'radio',
      name: 'is_array',
      label: '是否必填参数',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否必填参数',
    },
    {
      type: 'remoteselect',
      name: 'value_type',
      label: '参数类型',
      disabled: false,
      placeholder: '请选择参数类型',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'apidoc-valuetype',
    },
    {
      type: 'input',
      name: 'default_value',
      label: '默认值',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写默认值',
    },
    {
      type: 'radio',
      name: 'is_select',
      label: '是否RemoteSelect',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否RemoteSelect',
    },
    {
      type: 'input',
      name: 'select_flag',
      label: 'RemoteSelect的flag',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写RemoteSelect的flag',
    },
    {
      type: 'radio',
      name: 'is_modal',
      label: '是否RemoteModal',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否RemoteModal',
    },
    {
      type: 'input',
      name: 'modal_flag',
      label: 'RemoteSelect的flag',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写RemoteSelect的flag',
    },
    {
      type: 'radio',
      name: 'is_hide',
      label: '是否在SearchForm表单中隐藏',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否在SearchForm表单中隐藏',
    },
    {
      type: 'radio',
      name: 'is_id',
      label: '是否ID参数',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否ID参数',
    },
    {
      type: 'input',
      name: 'sort',
      label: '排序',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写排序',
    },
  ],
  rules: {
    name: [{ required: true, message: '请填输入参数名' }],
    rule: [{ required: true, message: '请填输入参数规则' }],
    info: [{ required: true, message: '请填输入参数中文' }],
    value_type: [{ required: true, message: '请填输入参数类型' }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    rule: null,
    info: null,
    is_required: null,
    is_array: null,
    value_type: null,
    default_value: null,
    is_select: null,
    select_flag: null,
    is_modal: null,
    modal_flag: null,
    is_hide: null,
    is_id: null,
    sort: null,
  }),
};
const edit_model_config_options = {
  is_required: [
    { value: True, name: '允许代码修改' },
    {
      value: False,
      name: '不允许代码修改',
    },
  ],
  is_array: [
    { value: True, name: '数组型参数' },
    { value: False, name: '非数组型参数' },
  ],
  is_select: [
    { value: True, name: '需要RemoteSelect' },
    { value: False, name: '不需要RemoteSelect' },
  ],
  is_modal: [
    { value: True, name: '需要RemoteModal' },
    { value: False, name: '不需要RemoteModal' },
  ],
  is_hide: [
    { value: True, name: '需要RemoteModal' },
    { value: False, name: '不需要RemoteModal' },
  ],
  is_id: [
    { value: True, name: 'id参数' },
    { value: False, name: '非id参数' },
  ],
};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '参数名',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写参数名',
    },
    {
      type: 'input',
      name: 'rule',
      label: '参数规则',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写参数规则',
    },
    {
      type: 'input',
      name: 'info',
      label: '参数中文',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写参数中文',
    },
    {
      type: 'radio',
      name: 'is_required',
      label: '是否必填参数',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否必填参数',
    },
    {
      type: 'radio',
      name: 'is_array',
      label: '是否必填参数',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否必填参数',
    },
    {
      type: 'remoteselect',
      name: 'value_type',
      label: '参数类型',
      disabled: false,
      placeholder: '请选择参数类型',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'apidoc-valuetype',
    },
    {
      type: 'input',
      name: 'default_value',
      label: '默认值',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写默认值',
    },
    {
      type: 'radio',
      name: 'is_select',
      label: '是否RemoteSelect',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否RemoteSelect',
    },
    {
      type: 'input',
      name: 'select_flag',
      label: 'RemoteSelect的flag',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写RemoteSelect的flag',
    },
    {
      type: 'radio',
      name: 'is_modal',
      label: '是否RemoteModal',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否RemoteModal',
    },
    {
      type: 'input',
      name: 'modal_flag',
      label: 'RemoteSelect的flag',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写RemoteSelect的flag',
    },
    {
      type: 'radio',
      name: 'is_hide',
      label: '是否在SearchForm表单中隐藏',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否在SearchForm表单中隐藏',
    },
    {
      type: 'radio',
      name: 'is_id',
      label: '是否ID参数',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否ID参数',
    },
    {
      type: 'input',
      name: 'sort',
      label: '排序',
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写排序',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    rule: null,
    info: null,
    is_required: null,
    is_array: null,
    value_type: null,
    default_value: null,
    is_select: null,
    select_flag: null,
    is_modal: null,
    modal_flag: null,
    is_hide: null,
    is_id: null,
    sort: null,
  }),
};
const update_model_config_options = {
  is_required: [
    { value: True, name: '允许代码修改' },
    {
      value: False,
      name: '不允许代码修改',
    },
  ],
  is_array: [
    { value: True, name: '数组型参数' },
    { value: False, name: '非数组型参数' },
  ],
  is_select: [
    { value: True, name: '需要RemoteSelect' },
    { value: False, name: '不需要RemoteSelect' },
  ],
  is_modal: [
    { value: True, name: '需要RemoteModal' },
    { value: False, name: '不需要RemoteModal' },
  ],
  is_hide: [
    { value: True, name: '需要RemoteModal' },
    { value: False, name: '不需要RemoteModal' },
  ],
  is_id: [
    { value: True, name: 'id参数' },
    { value: False, name: '非id参数' },
  ],
};

const titleList = {
  title: '接口参数管理',
  addModalTitle: '新建接口参数',
  updateTitle: '修改接口参数',
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    parameter_id: 'id',
  },
  delete: {
    parameter_id: 'id',
  },
  recover: {
    parameter_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    parameter_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    width: 50,
    slots: { customRender: 'index' },
  },
  {
    title: '项目主键',
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: '参数名',
    width: 150,
    dataIndex: 'name',
    checked: true,
  },
  {
    title: '参数规则',
    width: 150,
    dataIndex: 'rule',
    checked: true,
  },
  {
    title: '是否必填参数',
    width: 150,
    dataIndex: 'is_required',
    checked: true,

    slots: { customRender: 'is_required' },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: '允许代码修改', value: true },
      { text: '不允许代码修改', value: false },
    ],
  },
  {
    title: '是否必填参数',
    width: 150,
    dataIndex: 'is_array',
    checked: true,

    slots: { customRender: 'is_array' },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: '数组型参数', value: true },
      { text: '非数组型参数', value: false },
    ],
  },
  {
    title: '参数类型',
    width: 150,
    dataIndex: 'value_type',
    checked: true,
  },
  {
    title: '可选项数据',
    width: 150,
    dataIndex: 'choices_list',
    checked: true,
  },
  {
    title: '默认值',
    width: 150,
    dataIndex: 'default_value',
    checked: true,
  },
  {
    title: '是否RemoteSelect',
    width: 150,
    dataIndex: 'is_select',
    checked: true,

    slots: { customRender: 'is_select' },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: '需要RemoteSelect', value: true },
      {
        text: '不需要RemoteSelect',
        value: false,
      },
    ],
  },
  {
    title: 'RemoteSelect的flag',
    width: 150,
    dataIndex: 'select_flag',
    checked: true,
  },
  {
    title: '是否RemoteModal',
    width: 150,
    dataIndex: 'is_modal',
    checked: true,

    slots: { customRender: 'is_modal' },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: '需要RemoteModal', value: true },
      {
        text: '不需要RemoteModal',
        value: false,
      },
    ],
  },
  {
    title: 'RemoteSelect的flag',
    width: 150,
    dataIndex: 'modal_flag',
    checked: true,
  },
  {
    title: '是否在SearchForm表单中隐藏',
    width: 150,
    dataIndex: 'is_hide',
    checked: true,

    slots: { customRender: 'is_hide' },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: '需要RemoteModal', value: true },
      {
        text: '不需要RemoteModal',
        value: false,
      },
    ],
  },
  {
    title: '是否ID参数',
    width: 150,
    dataIndex: 'is_id',
    checked: true,

    slots: { customRender: 'is_id' },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: 'id参数', value: true },
      { text: '非id参数', value: false },
    ],
  },
  {
    title: '排序',
    width: 150,
    dataIndex: 'sort',
    checked: true,
  },
  {
    title: t('创建时间'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,

    slots: { customRender: 'datetime' },
  },
  {
    title: '更新日期',
    width: 150,
    dataIndex: 'update_time',
    checked: false,

    slots: { customRender: 'datetime' },
  },
  {
    title: t('是否可用'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,

    slots: { customRender: 'is_active' },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },
  {
    title: '参数中文',
    width: 150,
    dataIndex: 'info',
    checked: true,
  },

  {
    title: t('操作'),
    width: 200,
    dataIndex: 'action',
    checked: false,
    slots: { customRender: 'action' },
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push(
  {
    type: 'custom',
    slotName: 'is_required',
    field_true: '允许代码修改',
    field_false: '不允许代码修改',
  },
  { type: 'custom', slotName: 'is_array', field_true: '数组型参数', field_false: '非数组型参数' },
  {
    type: 'custom',
    slotName: 'is_select',
    field_true: '需要RemoteSelect',
    field_false: '不需要RemoteSelect',
  },
  {
    type: 'custom',
    slotName: 'is_modal',
    field_true: '需要RemoteModal',
    field_false: '不需要RemoteModal',
  },
  {
    type: 'custom',
    slotName: 'is_hide',
    field_true: '需要RemoteModal',
    field_false: '不需要RemoteModal',
  },
  { type: 'custom', slotName: 'is_id', field_true: 'id参数', field_false: '非id参数' },
  {
    type: 'custom',
    // slotName: 'is_active',
    field_true: '可用',
    field_false: '禁用',
  },
);

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_parameter_list,
    addData: create_parameter,
    updateData: update_parameter,
    removeData: delete_parameter,
    recoverData: recover_parameter,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
