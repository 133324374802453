import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import { reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { useRouter } from 'vue-router';
import { upload_receipt_contract_template_file } from '@/api/receipt-model';
import { useI18n } from 'vue-i18n';

const router = useRouter();

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
export const event_obj = mitt();
const { t } = useI18n();
export const btn_list = [
  {
    name: t('上传附件'),
    clazz: 'primary',
    cb: (data: any) => {
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('上传票据合同模板附件'),
        group: '合同',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;
            upload_receipt_contract_template_file({
              receipt_contract_template_id: data.id,
              nsfile_id: payload[0],
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('上传成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
