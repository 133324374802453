import {
  create_end_client,
  delete_end_client,
  query_end_client_list,
  recover_end_client,
  update_end_client,
} from '@/api/baseinfo-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/baseinfo/baseinfo-endclient-config';

import { reactive } from 'vue';
import { getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('page.baseinfo.endclient.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.baseinfo.endclient.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'address',
      label: '地址',
      label_i18n: t('page.baseinfo.endclient.form.label.address'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写地址',
      placeholder_i18n: t('page.baseinfo.endclient.form.placeholder.address'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
  }),
};
const search_options = {};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('page.baseinfo.endclient.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.baseinfo.endclient.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'address',
      label: '地址',
      label_i18n: t('page.baseinfo.endclient.form.label.address'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写地址',
      placeholder_i18n: t('page.baseinfo.endclient.form.placeholder.address'),
    },
    {
      type: 'input',
      name: 'contact_number',
      label: '联系方式',
      label_i18n: t('page.baseinfo.endclient.form.label.contact_number'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写联系方式',
      placeholder_i18n: t('page.baseinfo.endclient.form.placeholder.contact_number'),
    },
  ],
  rules: {
    name: [{ required: true, message: t('page.baseinfo.endclient.form.placeholder.name') }],
  },
  model: reactive({
    org_id: current_org?.id,
    name: null,
    contact_number: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('page.baseinfo.endclient.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.baseinfo.endclient.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'address',
      label: '地址',
      label_i18n: t('page.baseinfo.endclient.form.label.address'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写地址',
      placeholder_i18n: t('page.baseinfo.endclient.form.placeholder.address'),
    },
    {
      type: 'input',
      name: 'contact_number',
      label: '联系方式',
      label_i18n: t('page.baseinfo.endclient.form.label.contact_number'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写联系方式',
      placeholder_i18n: t('page.baseinfo.endclient.form.placeholder.contact_number'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    address: null,
    contact_number: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('终端用户'),
  addModalTitle: t('新建'),
  updateTitle: t('修改'),
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    endclient_id: 'id',
  },
  delete: {
    endclient_id: 'id',
  },
  recover: {
    endclient_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    endclient_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.baseinfo.endclient.column.title.index'),
    width: 50,
    dataIndex: 'index',
  },
  {
    title: t('page.baseinfo.endclient.column.title.id'),
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.baseinfo.endclient.column.title.name'),
    dataIndex: 'name',
    checked: true,
  },
  {
    title: t('page.baseinfo.endclient.column.title.address'),
    dataIndex: 'address',
    checked: true,
  },
  {
    title: t('page.baseinfo.endclient.column.title.contact_number'),
    dataIndex: 'contact_number',
    checked: true,
  },
  {
    title: t('page.baseinfo.endclient.column.title.update_time'),
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('page.baseinfo.endclient.column.title.is_active'),
    dataIndex: 'is_active',
    checked: true,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('page.baseinfo.endclient.column.title.action'),
    width: 120,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  // slotName: 'is_active',
  field_true: '可用',
  field_false: '禁用',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_end_client_list,
    addData: create_end_client,
    updateData: update_end_client,
    removeData: delete_end_client,
    recoverData: recover_end_client,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
