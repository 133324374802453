import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { useRouter } from 'vue-router';
import { create_bank_transaction } from '@/api/cerp-model';
import modalBox from '@/components/form-modal/modal-tools';
import { update_payment_record } from '@/api/receipt-model';
import { reactive } from 'vue';
import { message } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const { t } = useI18n();
const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
export const event_obj = mitt();
export const btn_list = [
  {
    name: t('修改'),
    clazz: 'primary',
    type: 'dropdown',
    cb: (record: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const editModal = reactive({
        visible: true,
        title: t('修改付款记录'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'remoteselect',
              name: 'receipt_id',
              label: '发票',
              label_i18n: t('发票'),
              disabled: true,
              placeholder: '请选择发票',
              placeholder_i18n: t('请选择发票'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKeyList: ['receipt_number', 'customer__name'],
              valueKey: 'id',
              modalType: 'receipt-query_receipt_list',
            },
            {
              type: 'datepicker',
              name: 'payment_date',
              label: '付款日期',
              label_i18n: t('付款日期'),
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请填写付款日期',
              placeholder_i18n: t('请填写付款日期'),
            },
            {
              type: 'input',
              name: 'amount',
              label: '总金额',
              label_i18n: t('总金额'),
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              placeholder: '请填写总金额',
              placeholder_i18n: t('请填写总金额'),
            },
            {
              type: 'remoteselect',
              name: 'currency_id',
              labelKeyList: ['code', 'currency'],
              label: '币种',
              label_i18n: t('币种'),
              disabled: false,
              placeholder: '请选择币种',
              placeholder_i18n: t('请选择币种'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-country',
            },
            {
              type: 'remoteselect',
              name: 'cc_bank_account_id',
              label: 'CC银行账户',
              label_i18n: t('CC银行账户'),
              disabled: false,
              placeholder: '请选择CC银行账户',
              placeholder_i18n: t('请选择CC银行账户'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'account_flag',
              valueKey: 'id',
              modalType: 'baseinfo-ccbankaccountscc',
            },
            {
              type: 'input',
              name: 'transaction__name',
              label: '银行流水',
              label_i18n: t('银行流水'),
              disabled: true,
              allowClear: true,
              inputType: 'text',
              maxlength: 100,
              defaultValue: '',
              placeholder: '请填写银行流水',
              placeholder_i18n: t('请填写银行流水'),
            },
          ],
          rules: {
            payment_date: [{ required: true, message: t('请填写付款日期') }],
          },
          model: reactive({
            org_id: current_org?.id,
            payment_record_id: record.id,
            receipt_id: record.receipt_id,
            payment_date: record.payment_date,
            amount: record.amount,
            currency_id: record.currency_id,
            cc_bank_account_id: record.cc_bank_account_id,
            transaction_id: record.transaction_id,
            transaction__name: record.transaction__name,
          }),
          watch: {},
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            update_payment_record({
              ...payload,
            })
              .then(() => {
                message.success(t('修改成功'));
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: t('添加银行流水'),
    clazz: 'primary',
    type: 'dropdown',
    is_show: (record: any) => {
      return !record.transaction__name;
    },
    cb: (record: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const editModal1 = reactive({
        visible: true,
        title: t('添加银行流水'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'name',
              label: '名称',
              label_i18n: t('名称'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写名称',
              placeholder_i18n: t('请填写名称'),
            },
            {
              type: 'input',
              name: 'description',
              label: '描述',
              label_i18n: t('描述'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写描述',
              placeholder_i18n: t('请填写描述'),
            },
            {
              type: 'money',
              name: 'amount',
              label: '总金额',
              label_i18n: t('总金额'),
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              placeholder: '请填写总金额',
              placeholder_i18n: t('请填写总金额'),
            },
            {
              type: 'datepicker',
              name: 'date',
              label: '收到日期',
              label_i18n: t('收到日期'),
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请填写收到日期',
              placeholder_i18n: t('请填写收到日期'),
            },
            {
              type: 'select',
              name: 'transaction_type',
              label: '流水种类',
              label_i18n: t('流水种类'),
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              placeholder: '请选择流水种类',
              placeholder_i18n: t('请选择流水种类'),
              datasource: 'transaction_type',
            },
            {
              type: 'remoteselect',
              name: 'cc_bank_account_id',
              label: 'CC银行账户',
              label_i18n: t('CC银行账户'),
              disabled: false,
              placeholder: '请选择CC银行账户',
              placeholder_i18n: t('请选择CC银行账户'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'account_flag',
              valueKey: 'id',
              modalType: 'baseinfo-ccbankaccountscc',
            },
            {
              type: 'remoteselect',
              name: 'currency_id',
              labelKey: 'currency',
              label: '币种',
              label_i18n: t('币种'),
              default: true,
              disabled: false,
              placeholder: '请选择薪资币种',
              placeholder_i18n: t('请选择薪资币种'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-country1',
            },
          ],
          rules: {
            name: [{ required: true, message: t('请填写名称') }],
            amount: [{ required: true, message: t('请填写总金额') }],
            transaction_type: [{ required: true, message: t('请选择流水种类') }],
          },
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            name: record.receipt__receipt_number,
            description: null,
            amount: record.amount,
            currency_id: record.currency_id,
            date: record.payment_date,
            recuitment_company_id: null,
            transaction_type: '收入',
            cc_bank_account_id: null,
          }),
        },
        options: {
          transaction_type: [
            { value: '收入', name: '收入' },
            { value: '支出', name: '支出' },
          ],
        },
        ok: (payload: any) => {
          return new Promise(resolve1 => {
            editModal1.loading = true;
            create_bank_transaction(payload)
              .then((res: any) => {
                update_payment_record({
                  org_id: current_org?.id,
                  transaction_id: res.id,
                  payment_record_id: record.id,
                }).then(() => {
                  message.success(t('操作成功'));
                });
                resolve1(null);
              })
              .finally(() => {
                editModal1.loading = false;
              });
          });
        },
      });
      modalBox(editModal1);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
