import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import { reactive } from 'vue';
import modalBox from '@/components/form-modal/modal-tools';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import defaultSlotList from '@/components/page-model/slotList';

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

export const event_obj = mitt();
export const btn_list = null;
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

const editModal: any = reactive({
  visible: true,
  title: '导出页面组件',
  form: {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
    },
    fields: [
      {
        type: 'remoteselect',
        name: 'app_id',
        label: '应用',
        disabled: false,
        labelKey: 'name',
        valueKey: 'id',
        placeholder: '请选应用',
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        modalType: 'apidoc-app',
      },
      {
        type: 'remoteselect',
        name: 'project_id',
        label: '隶属Git项目',
        disabled: false,
        labelKey: 'name',
        valueKey: 'id',
        placeholder: '请选隶属Git项目',
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        modalType: 'apidoc-gitproject',
      },
      {
        type: 'remoteselect',
        name: 'git_branch_id',
        label: '隶属Git分支',
        disabled: false,
        labelKey: 'name',
        valueKey: 'id',
        placeholder: '请选隶属的Git分支',
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        modalType: 'apidoc-gitbranch',
      },
      {
        type: 'radio',
        name: 'is_config',
        label: '是否导出Config',
        disabled: false,
        labelKey: 'label',
        valueKey: 'value',
        placeholder: '是否导出Config',
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
      },
      {
        type: 'radio',
        name: 'is_router',
        label: '是否导出路由',
        disabled: false,
        labelKey: 'label',
        valueKey: 'value',
        placeholder: '是否导出路由',
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
      },
    ],
    rules: {
      app_id: [{ required: true, message: '请选应用' }],
      project_id: [{ required: true, message: '请选隶属的Git项目' }],
      git_branch_id: [{ required: true, message: '请选隶属的Git分支' }],
    },
    model: {
      org_id: current_org?.id,
      is_active: true,
      is_config: false,
      is_router: false,
    },
  },
  options: {
    is_config: [
      { value: true, label: '导出' },
      { value: false, label: '不导出' },
    ],
    is_router: [
      { value: true, label: '导出' },
      { value: false, label: '不导出' },
    ],
  },
});
export const head_btn_list: btnTd[] = [
  {
    name: '导出接口TypeScript',
    clazz: 'primary',
    cb: () => {
      editModal['title'] = '导出接口TypeScript';
      editModal['ok'] = (data: any) => {
        const url: string =
          'http://ccerp.8mu.com.cn/apidoc/export_api_info_by_type_script?app_id=' +
          data.app_id +
          '&git_branch_id=' +
          data.git_branch_id;
        window.open(url, '_blank');
      };
      modalBox(editModal);
    },
  },
  {
    name: '导出页面组件',
    clazz: 'primary',
    cb: () => {
      editModal['title'] = '导出页面组件';
      editModal['ok'] = (data: any) => {
        const url: string =
          'http://ccerp.8mu.com.cn/apidoc/export_page_info_by_type_script?app_id=' +
          data.app_id +
          '&git_branch_id=' +
          data.git_branch_id +
          '&is_config=' +
          data.is_config +
          '&is_router=' +
          data.is_router;
        window.open(url, '_blank');
      };
      modalBox(editModal);
    },
  },
  {
    name: 'RemoteSelect导出',
    clazz: 'primary',
    cb: () => {
      editModal['title'] = 'RemoteSelect导出';
      editModal['ok'] = (data: any) => {
        const url: string =
          'http://ccerp.8mu.com.cn/apidoc/export_app_all_select_ts_zip?app_id=' +
          data.app_id +
          '&git_branch_id=' +
          data.git_branch_id;
        window.open(url, '_blank');
      };
      modalBox(editModal);
    },
  },
  {
    name: 'RemoteModal导出',
    clazz: 'primary',
    cb: () => {
      editModal['title'] = 'RemoteModal导出';
      editModal['ok'] = (data: any) => {
        const url: string =
          'http://ccerp.8mu.com.cn/apidoc/export_app_all_select_modal_ts_zip?app_id=' +
          data.app_id +
          '&git_branch_id=' +
          data.git_branch_id;
        window.open(url, '_blank');
      };
      modalBox(editModal);
    },
  },
  {
    name: '接口JSON导出',
    clazz: 'primary',
    cb: () => {
      editModal['title'] = '接口JSON导出';
      editModal['ok'] = (data: any) => {
        const url: string =
          'http://ccerp.8mu.com.cn/apidoc/export_app_all_api_json_zip?app_id=' +
          data.app_id +
          '&git_branch_id=' +
          data.git_branch_id;
        window.open(url, '_blank');
      };
      modalBox(editModal);
    },
  },
];
