import { create_adviser, delete_adviser, query_adviser_list, recover_adviser, update_adviser } from '@/api/cerp-model';
import { Record, SearchConfig } from '@/components/page-model/typing';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { btn_list, event_obj, head_btn_list, slotList } from '@/components/page-model/cerp/cerp-adviser-config';
import { reactive } from 'vue';
import { useRoute } from 'vue-router';
import cerp_adviser_editModal from '@/components/page-model/cerp/cerp-adviser-editModal.ts';
import { useI18n } from 'vue-i18n';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'search_text',
      label: '模糊搜索',
      label_i18n: 'page.cerp.adviser.form.label.search_text',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写搜索内容',
      placeholder_i18n: 'page.cerp.adviser.form.placeholder.search_text',
    },
    {
      type: 'input',
      name: 'employee_number',
      label: '顾问工号',
      label_i18n: t('page.cerp.adviser.form.label.employee_number'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写顾问工号',
      placeholder_i18n: t('page.cerp.adviser.form.placeholder.employee_number'),
    },
    {
      type: 'select',
      name: 'title',
      label: '称谓',
      label_i18n: t('page.cerp.adviser.form.label.title'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择称谓',
      placeholder_i18n: t('page.cerp.adviser.form.placeholder.title'),
      datasource: 'title',
    },
    {
      type: 'input',
      name: 'first_name',
      label: '名字',
      label_i18n: t('page.cerp.adviser.form.label.first_name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名字',
      placeholder_i18n: t('page.cerp.adviser.form.placeholder.first_name'),
    },
    {
      type: 'input',
      name: 'last_name',
      label: '姓',
      label_i18n: t('page.cerp.adviser.form.label.last_name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写姓',
      placeholder_i18n: t('page.cerp.adviser.form.placeholder.last_name'),
    },
    {
      type: 'input',
      name: 'other_name',
      label: '其他名字',
      label_i18n: t('page.cerp.adviser.form.label.other_name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写其他名字',
      placeholder_i18n: t('page.cerp.adviser.form.placeholder.other_name'),
    },
    {
      type: 'remoteselect',
      name: 'nationality_id',
      label: '国籍',
      label_i18n: t('page.cerp.adviser.form.label.nationality_id'),
      disabled: false,
      placeholder: '请选择国籍',
      placeholder_i18n: t('page.cerp.adviser.form.placeholder.nationality_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-country',
    },
    {
      type: 'input',
      name: 'phone',
      label: '电话',
      label_i18n: t('page.cerp.adviser.form.label.phone'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写电话',
      placeholder_i18n: t('page.cerp.adviser.form.placeholder.phone'),
    },
    {
      type: 'input',
      name: 'mobile',
      label: '手机',
      label_i18n: t('page.cerp.adviser.form.label.mobile'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写手机',
      placeholder_i18n: t('page.cerp.adviser.form.placeholder.mobile'),
    },
    {
      type: 'input',
      name: 'personal_email',
      label: '个人邮箱',
      label_i18n: t('page.cerp.adviser.form.label.personal_email'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写个人邮箱',
      placeholder_i18n: t('page.cerp.adviser.form.placeholder.personal_email'),
    },
    {
      type: 'input',
      name: 'work_email',
      label: '工作邮箱',
      label_i18n: t('page.cerp.adviser.form.label.work_email'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写工作邮箱',
      placeholder_i18n: t('page.cerp.adviser.form.placeholder.work_email'),
    },
    {
      type: 'input',
      name: 'wechat',
      label: '微信账号',
      label_i18n: t('page.cerp.adviser.form.label.wechat'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写微信账号',
      placeholder_i18n: t('page.cerp.adviser.form.placeholder.wechat'),
    },
    {
      type: 'input',
      name: 'whats_app',
      label: 'WhatsApp账号',
      label_i18n: t('page.cerp.adviser.form.label.whats_app'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写WhatsApp账号',
      placeholder_i18n: t('page.cerp.adviser.form.placeholder.whats_app'),
    },
    {
      type: 'input',
      name: 'qq',
      label: 'QQ账号',
      label_i18n: t('page.cerp.adviser.form.label.qq'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写QQ账号',
      placeholder_i18n: t('page.cerp.adviser.form.placeholder.qq'),
    },
    {
      type: 'input',
      name: 'skypelid',
      label: 'Skype ID',
      label_i18n: t('page.cerp.adviser.form.label.skypelid'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写Skype ID',
      placeholder_i18n: t('page.cerp.adviser.form.placeholder.skypelid'),
    },
    {
      type: 'remoteselect',
      name: 'person_type_id',
      label: '个人类别',
      label_i18n: t('page.cerp.adviser.form.label.person_type_id'),
      disabled: false,
      placeholder: '请选择个人类别',
      placeholder_i18n: t('page.cerp.adviser.form.placeholder.person_type_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-persontype',
    },
    {
      type: 'select',
      name: 'status',
      label: '个人状态',
      label_i18n: t('page.cerp.adviser.form.label.status'),
      mode: 'default',
      disabled: false,
      allowClear: true,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择个人状态',
      placeholder_i18n: t('page.cerp.adviser.form.placeholder.status'),
      datasource: 'status',
    },
    // {
    //   type: 'remoteselect',
    //   name: 'creater_id',
    //   label: '创建人',
    //   label_i18n: t('page.cerp.adviser.form.label.creater_id'),
    //   disabled: false,
    //   placeholder: '请选择创建人',
    //   placeholder_i18n: t('page.cerp.adviser.form.placeholder.creater_id'),
    //   datasourceType: 'remote',
    //   mode: 'default',
    //   allowClear: true,
    //   labelKey: 'realname',
    //   valueKey: 'id',
    //   modalType: 'sys-userlist',
    // },
    {
      type: 'remoteselect',
      name: 'home_address_id',
      label: '家庭地址',
      label_i18n: t('page.cerp.adviser.form.label.home_address_id'),
      disabled: false,
      placeholder: '请选择家庭地址',
      init_model: { address_type: 0 },
      placeholder_i18n: t('page.cerp.adviser.form.placeholder.home_address_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'display_name',
      valueKey: 'id',
      modalType: 'cerp-address',
    },
    {
      type: 'remoteselect',
      name: 'work_address_id',
      label: '工作地址',
      label_i18n: t('page.cerp.adviser.form.label.work_address_id'),
      disabled: false,
      placeholder: '请选择工作地址',
      placeholder_i18n: t('page.cerp.adviser.form.placeholder.work_address_id'),
      datasourceType: 'remote',
      mode: 'default',
      init_model: { address_type: 1 },
      allowClear: true,
      labelKey: 'display_name',
      valueKey: 'id',
      modalType: 'cerp-address',
    },
    {
      type: 'remoteselect',
      name: 'tag_id',
      label: '标签',
      label_i18n: t('page.cerp.adviser.form.label.tag_id'),
      disabled: false,
      placeholder: '请选择标签',
      placeholder_i18n: t('page.cerp.adviser.form.placeholder.tag_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'name',
      valueKey: 'id',
      modalType: 'baseinfo-tag',
    },
    // {
    //   type: 'radio',
    //   name: 'is_active',
    //   label: '是否可用',
    //   label_i18n: t('page.cerp.adviser.form.label.is_active'),
    //   disabled: false,
    //   allowClear: true,
    //   labelKey: 'name',
    //   valueKey: 'value',
    //   defaultValue: '',
    // },
    {
      type: 'radio',
      name: 'is_asc',
      label: '排序',
      label_i18n: t('page.cerp.adviser.form.label.is_asc'),
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    search_text: '',
    title: '',
    first_name: null,
    last_name: null,
    other_name: null,
    nationality_id: null,
    phone: null,
    mobile: null,
    personal_email: null,
    work_email: null,
    wechat: null,
    whats_app: null,
    qq: null,
    skypelid: null,
    person_type_id: null,
    home_address_id: null,
    work_address_id: null,
    status: null,
    is_asc: false,
  }),
  reset_model: {
    org_id: current_org?.id,
    is_active: true,
    search_text: '',
    employee_number: '',
    title: '',
    first_name: null,
    last_name: null,
    other_name: null,
    nationality_id: null,
    phone: null,
    mobile: null,
    personal_email: null,
    work_email: null,
    wechat: null,
    whats_app: null,
    qq: null,
    skypelid: null,
    person_type_id: null,
    home_address_id: null,
    work_address_id: null,
    status: null,
    is_asc: false,
  },
};
const search_options = {
  is_asc: [
    { value: True, name: t('正序') },
    { value: False, name: t('倒序') },
  ],
  is_active: [
    { value: None, name: t('全部') },
    { value: True, name: t('可用') },
    { value: False, name: t('禁用') },
  ],
  title: [
    { value: '', name: '-None-' },
    { value: 'Mr.', name: 'Mr.' },
    { value: 'Mrs.', name: 'Mrs.' },
    { value: 'Ms.', name: 'Ms.' },
    { value: 'Dr.', name: 'Dr.' },
    { value: 'Prof.', name: 'Prof.' },
  ],
  status: [
    { value: 1, name: t('-无-') },
    { value: 2, name: t('工作中') },
    { value: 3, name: t('结束') },
    { value: 4, name: t('申请签证中') },
    { value: 5, name: t('尚未开始') },
    { value: 6, name: t('潜在雇员') },
    { value: 7, name: t('离职') },
    { value: 8, name: t('待定') },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {},
  fields: [],
  rules: {},
  model: reactive({}),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {},
  fields: [],
  rules: {},
  model: reactive({}),
};
const update_model_config_options = {};

const titleList = {
  title: t('顾问管理'),
  addModalTitle: t('新建顾问'),
  updateTitle: t('修改顾问'),
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    adviser_id: 'id',
  },
  delete: {
    adviser_id: 'id',
  },
  recover: {
    adviser_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    adviser_id: 'id',
  },
};

const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  slotName: 'worker_status',
  field_1: '-无-',
  field_2: '工作中',
  field_3: '结束',
  field_4: '申请签证中',
  field_5: '尚未开始',
  field_6: '潜在雇员',
  field_7: '离职',
  field_8: '待定',
});

export default () => {
  const route = useRoute();
  search_config.model = reactive({ ...search_config.model });
  search_config.model.search_text = route.query.q;
  search_config.model.ids = Number(route.query.ids);

  const { baseColumns } = cerp_adviser_editModal(t, {});
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_adviser_list,
    addData: create_adviser,
    updateData: update_adviser,
    removeData: delete_adviser,
    recoverData: recover_adviser,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
    // rowSelection: reactive({ type: 'checkbox' }),
    // selectedRowKeys: ref([]), // 可以在上面定义。但是要注意 多实例的问题，类似current_org问题。
  };
};
