import {
  create_managementfeeformula,
  delete_managementfeeformula,
  query_managementfeeformula_list,
  recover_managementfeeformula,
  update_managementfeeformula,
} from '@/api/baseinfo-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/baseinfo/baseinfo-managementfeeformula-config';

import { reactive } from 'vue';
import { getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('page.baseinfo.managementfeeformula.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写公式名称',
      placeholder_i18n: t('page.baseinfo.managementfeeformula.form.placeholder.name'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
  }),
};
const search_options = {};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('page.baseinfo.managementfeeformula.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写公式名称',
      placeholder_i18n: t('page.baseinfo.managementfeeformula.form.placeholder.name'),
    },
    {
      type: 'number',
      name: 'min_fee',
      label: '最低管理费',
      label_i18n: t('最低管理费'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写最低管理费',
      placeholder_i18n: t('请填写最低管理费'),
    },
    {
      type: 'textarea',
      name: 'description',
      label: '描述',
      label_i18n: t('page.baseinfo.managementfeeformula.form.label.description'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 500,
      defaultValue: '',
      placeholder: '请填写描述',
      placeholder_i18n: t('page.baseinfo.managementfeeformula.form.placeholder.description'),
    },
    {
      type: 'textarea',
      name: 'formula',
      label: '公式',
      label_i18n: t('page.baseinfo.managementfeeformula.form.label.formula'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 500,
      defaultValue: '',
      placeholder: '请填写公式',
      placeholder_i18n: t('page.baseinfo.managementfeeformula.form.placeholder.formula'),
    },
  ],
  rules: {
    name: [
      { required: true, message: t('page.baseinfo.managementfeeformula.form.placeholder.name') },
    ],
    formula: [
      { required: true, message: t('page.baseinfo.managementfeeformula.form.placeholder.formula') },
    ],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    description: null,
    formula: null,
    min_fee: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('page.baseinfo.managementfeeformula.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写公式名称',
      placeholder_i18n: t('page.baseinfo.managementfeeformula.form.placeholder.name'),
    },
    {
      type: 'number',
      name: 'min_fee',
      label: '最低管理费',
      label_i18n: t('最低管理费'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写最低管理费',
      placeholder_i18n: t('请填写最低管理费'),
    },
    {
      type: 'textarea',
      name: 'description',
      label: '描述',
      label_i18n: t('page.baseinfo.managementfeeformula.form.label.description'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 500,
      defaultValue: '',
      placeholder: '请填写描述',
      placeholder_i18n: t('page.baseinfo.managementfeeformula.form.placeholder.description'),
    },
    {
      type: 'textarea',
      name: 'formula',
      label: '公式',
      label_i18n: t('page.baseinfo.managementfeeformula.form.label.formula'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      maxlength: 500,
      defaultValue: '',
      placeholder: '请填写公式',
      placeholder_i18n: t('page.baseinfo.managementfeeformula.form.placeholder.formula'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    description: null,
    formula: null,
    min_fee: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('管理费计算方式'),
  addModalTitle: t('新建'),
  updateTitle: t('修改管理费计算方式'),
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    managementfeeformula_id: 'id',
  },
  delete: {
    managementfeeformula_id: 'id',
  },
  recover: {
    managementfeeformula_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    managementfeeformula_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.baseinfo.managementfeeformula.column.title.index'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('page.baseinfo.managementfeeformula.column.title.id'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.baseinfo.managementfeeformula.column.title.name'),
    width: 150,
    dataIndex: 'name',
    checked: true,
  },
  {
    title: t('page.baseinfo.managementfeeformula.column.title.description'),
    width: 350,
    dataIndex: 'description',
    checked: true,
  },
  {
    title: t('page.baseinfo.managementfeeformula.column.title.formula'),
    width: 350,
    dataIndex: 'formula',
    checked: true,
  },
  {
    title: t('最低管理费'),
    width: 150,
    dataIndex: 'min_fee',
    checked: true,
  },
  {
    title: t('page.baseinfo.managementfeeformula.column.title.create_time'),
    dataIndex: 'create_time',
    width: 130,
    checked: true,
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('page.baseinfo.managementfeeformula.column.title.update_time'),
    dataIndex: 'update_time',
    checked: true,
    width: 130,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('page.baseinfo.managementfeeformula.column.title.is_active'),
    dataIndex: 'is_active',
    checked: true,
    width: 130,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },
  {
    title: t('page.baseinfo.managementfeeformula.column.title.action'),
    width: 120,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  // slotName: 'is_active',
  field_true: '可用',
  field_false: '禁用',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_managementfeeformula_list,
    addData: create_managementfeeformula,
    updateData: update_managementfeeformula,
    removeData: delete_managementfeeformula,
    recoverData: recover_managementfeeformula,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
