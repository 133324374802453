import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';

import modalBox from '@/components/form-modal/modal-tools';
import { createVNode, reactive } from 'vue';
import { message } from 'ant-design-vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { change_apiurl_auth, import_apiurl } from '@/api/permission/apiurl-manage';
import { Modal } from 'ant-design-vue/es';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';

export const event_obj = mitt();
const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
export const btn_list = [
  {
    name: '设为需要授权',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.is_active && !record.is_auth;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('是否设为需要授权') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            change_apiurl_auth({
              org_id: current_org.id,
              apiurl_id: record.id,
              is_auth: true,
            }).then((res: any) => {
              message.success(t('操作成功'));
              Object.assign(record, res);
              resolve(null);
            });
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
  },
  {
    name: '设为无需授权',
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.is_active && record.is_auth;
    },
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      Modal.confirm({
        title: t('是否设为无需授权') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            change_apiurl_auth({
              org_id: current_org.id,
              apiurl_id: record.id,
              is_auth: false,
            }).then((res: any) => {
              message.success(t('操作成功'));
              Object.assign(record, res);
              resolve(null);
            });
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list = [
  {
    name: '导入接口',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any, dataSource: any, selectedRowKeys: any, searchConfigOptions: any, t: any) => {
      const editModal = reactive({
        visible: true,
        title: t('导入api接口json文件'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'textarea',
              name: 'api_json',
              label: '接口JSON',
              label_i18n: '接口JSON',
              placeholder_i18n: '接口JSON',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 100,
              defaultValue: '',
              placeholder: '接口JSON',
              minRows: 17,
            },
          ],
          rules: {
            api_json: [{ username: true, message: t('请填写账号名称') }],
          },
          model: reactive({
            org_id: current_org.id,
            api_json: '',
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            import_apiurl({
              ...payload,
            })
              .then(() => {
                message.success(t('导入成功'));
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
];
