import { create_status, delete_status, query_status_list, recover_status, update_status } from '@/api/baseinfo-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/baseinfo/baseinfo-status-config';

import { reactive } from 'vue';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '记录类型',
      label_i18n: t('page.baseinfo.status.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写记录类型',
      placeholder_i18n: t('page.baseinfo.status.form.placeholder.name'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '记录类型',
      label_i18n: t('page.baseinfo.status.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写记录类型',
      placeholder_i18n: t('page.baseinfo.status.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'description',
      label: '描述',
      label_i18n: t('page.baseinfo.status.form.label.description'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写描述',
      placeholder_i18n: t('page.baseinfo.status.form.placeholder.description'),
    },
  ],
  rules: {
    name: [{ required: true, message: '请填输入记录类型' }],
    description: [{ required: true, message: '请填输入描述' }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    description: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '记录类型',
      label_i18n: t('page.baseinfo.status.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写记录类型',
      placeholder_i18n: t('page.baseinfo.status.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'description',
      label: '描述',
      label_i18n: t('page.baseinfo.status.form.label.description'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写描述',
      placeholder_i18n: t('page.baseinfo.status.form.placeholder.description'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    description: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: '个人状态管理',
  addModalTitle: '新建个人状态',
  updateTitle: '修改个人状态',
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    status_id: 'id',
  },
  delete: {
    status_id: 'id',
  },
  recover: {
    status_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    status_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.baseinfo.status.column.title.index'),
    width: 50,
    dataIndex: 'index',
  },
  {
    title: t('page.baseinfo.status.column.title.id'),
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.baseinfo.status.column.title.name'),
    dataIndex: 'name',
    checked: true,
  },
  {
    title: t('page.baseinfo.status.column.title.description'),
    dataIndex: 'description',
    checked: true,
  },
  {
    title: t('page.baseinfo.status.column.title.create_time'),
    dataIndex: 'create_time',
    checked: true,

    slots: { customRender: 'datetime' },
  },
  {
    title: t('page.baseinfo.status.column.title.update_time'),
    dataIndex: 'update_time',
    checked: true,

    slots: { customRender: 'datetime' },
  },
  {
    title: t('page.baseinfo.status.column.title.is_active'),
    dataIndex: 'is_active',
    checked: true,

    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('page.baseinfo.status.column.title.action'),
    width: 120,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  // slotName: 'is_active',
  field_true: '可用',
  field_false: '禁用',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_status_list,
    addData: create_status,
    updateData: update_status,
    removeData: delete_status,
    recoverData: recover_status,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
