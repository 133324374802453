import {
  create_next_of_kin,
  delete_next_of_kin,
  query_next_of_kin_list,
  recover_next_of_kin,
  update_next_of_kin,
} from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/cerp/cerp-nextofkin-config';

import { reactive } from 'vue';
import { getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'search_text',
      label: '模糊搜索',
      label_i18n: t('page.cerp.nextofkin.form.label.search_text'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写搜索内容',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.search_text'),
    },
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '隶属顾问',
      label_i18n: t('page.cerp.nextofkin.form.label.adviser_id'),
      disabled: false,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.adviser_id'),
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: t('选择隶属顾问'),
      modalType: 'cerp-adviser',
    },
    {
      type: 'input',
      name: 'name',
      label: '姓名',
      label_i18n: t('page.cerp.nextofkin.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写姓名',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'releationship',
      label: '关系',
      label_i18n: t('page.cerp.nextofkin.form.label.releationship'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写关系',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.releationship'),
    },
    {
      type: 'input',
      name: 'contact_number',
      label: '联系电话',
      label_i18n: t('page.cerp.nextofkin.form.label.contact_number'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写联系电话',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.contact_number'),
    },
    {
      type: 'input',
      name: 'email',
      label: '联系邮箱',
      label_i18n: t('page.cerp.nextofkin.form.label.email'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写联系邮箱',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.email'),
    },
    {
      type: 'select',
      name: 'is_active',
      label: '是否可用',
      label_i18n: t('page.cerp.nextofkin.form.label.is_active'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择是否可用',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.is_active'),
      datasource: 'is_active',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    search_text: '',
    adviser_id: null,
    name: null,
    releationship: null,
    contact_number: null,
    email: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: t('全部') },
    { value: True, name: t('可用') },
    { value: False, name: t('禁用') },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'remotemodal',
      name: 'adviser_id',
      label: '隶属顾问',
      label_i18n: t('page.cerp.nextofkin.form.label.adviser_id'),
      disabled: false,
      defaultValue: '',
      labelKey: 'full_name',
      valueKey: 'id',
      placeholder: '隶属顾问',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.adviser_id'),
      show_search: false,
      maxTagCount: 5,
      mode: 'default',
      title: t('选择隶属顾问'),
      modalType: 'cerp-adviser',
    },
    {
      type: 'input',
      name: 'name',
      label: '姓名',
      label_i18n: t('page.cerp.nextofkin.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写姓名',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'releationship',
      label: '关系',
      label_i18n: t('page.cerp.nextofkin.form.label.releationship'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写关系',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.releationship'),
    },
    {
      type: 'input',
      name: 'contact_number',
      label: '联系电话',
      label_i18n: t('page.cerp.nextofkin.form.label.contact_number'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写联系电话',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.contact_number'),
    },
    {
      type: 'input',
      name: 'email',
      label: '联系邮箱',
      label_i18n: t('page.cerp.nextofkin.form.label.email'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写联系邮箱',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.email'),
    },
  ],
  rules: {
    name: [{ required: true, message: t('请填输入姓名') }],
    releationship: [{ required: true, message: t('请填输入关系') }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    adviser_id: null,
    name: null,
    releationship: null,
    contact_number: null,
    email: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'adviser__full_name',
      label: '隶属顾问',
      label_i18n: t('page.cerp.nextofkin.form.label.adviser__full_name'),
      disabled: true,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
    },
    {
      type: 'input',
      name: 'name',
      label: '姓名',
      label_i18n: t('page.cerp.nextofkin.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写姓名',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'releationship',
      label: '关系',
      label_i18n: t('page.cerp.nextofkin.form.label.releationship'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写关系',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.releationship'),
    },
    {
      type: 'input',
      name: 'contact_number',
      label: '联系电话',
      label_i18n: t('page.cerp.nextofkin.form.label.contact_number'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写联系电话',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.contact_number'),
    },
    {
      type: 'input',
      name: 'email',
      label: '联系邮箱',
      label_i18n: t('page.cerp.nextofkin.form.label.email'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写联系邮箱',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.email'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    adviser_id: null,
    name: null,
    releationship: null,
    contact_number: null,
    email: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('紧急联系人管理'),
  addModalTitle: t('新建紧急联系人'),
  updateTitle: t('修改紧急联系人'),
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    next_of_kin: 'id',
  },
  delete: {
    next_of_kin: 'id',
  },
  recover: {
    next_of_kin: 'id',
  },
  updateData: {
    org_id: 'org_id',
    next_of_kin: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.cerp.nextofkin.column.title.index'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('page.cerp.nextofkin.column.title.id'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.cerp.nextofkin.column.title.adviser__full_name'),
    minWidth: 150,
    dataIndex: 'adviser__full_name',
    checked: true,
  },
  {
    title: t('page.cerp.nextofkin.column.title.name'),
    width: 150,
    dataIndex: 'name',
    checked: true,
  },
  {
    title: t('page.cerp.nextofkin.column.title.releationship'),
    width: 150,
    dataIndex: 'releationship',
    checked: true,
  },
  {
    title: t('page.cerp.nextofkin.column.title.contact_number'),
    width: 150,
    dataIndex: 'contact_number',
    checked: true,
  },
  {
    title: t('page.cerp.nextofkin.column.title.email'),
    width: 150,
    dataIndex: 'email',
    checked: true,
  },
  {
    title: t('page.cerp.nextofkin.column.title.create_time'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('page.cerp.nextofkin.column.title.update_time'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('page.cerp.nextofkin.column.title.is_active'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,

    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('page.cerp.nextofkin.column.title.action'),
    width: 200,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  // slotName: 'is_active',
  field_true: '可用',
  field_false: '禁用',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_next_of_kin_list,
    addData: create_next_of_kin,
    updateData: update_next_of_kin,
    removeData: delete_next_of_kin,
    recoverData: recover_next_of_kin,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
