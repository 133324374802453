import {
  create_receipt_template,
  delete_receipt_template,
  query_receipt_template_list,
  recover_receipt_template,
  update_receipt_template,
} from '@/api/receipt-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/receipt/receipt-receipt_template-config';
import { reactive } from 'vue';
import { notification } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import { getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('名称'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('请填写名称'),
    },
    {
      type: 'remoteselect',
      name: 'cc_company_id',
      label: 'CC公司',
      label_i18n: t('CC公司'),
      disabled: false,
      placeholder: '请选择CC公司',
      placeholder_i18n: t('请选择CC公司'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-cccompany',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    cc_company_id: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '票据模板名称',
      label_i18n: t('票据模板名称'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写票据模板名称',
      placeholder_i18n: t('请填写票据模板名称'),
    },
    {
      type: 'input',
      name: 'description',
      label: '描述',
      label_i18n: t('描述'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写描述',
      placeholder_i18n: t('请填写描述'),
    },
    {
      type: 'remoteselect',
      name: 'cc_company_id',
      label: 'CC公司',
      label_i18n: t('CC公司'),
      placeholder: '请选择CC公司',
      placeholder_i18n: t('请选择CC公司'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-cccompany',
    },
    {
      type: 'radio',
      name: 'is_vat',
      label: '是否VAT模板',
      label_i18n: t('是否VAT模板'),
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'radio',
      name: 'is_default',
      label: '是否默认模板',
      label_i18n: t('是否默认模板'),
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'remoteselect',
      name: 'email_template_id',
      label: '发票邮件模板',
      label_i18n: t('发票邮件模板'),
      disabled: false,
      placeholder: '请选择发票邮件模板',
      placeholder_i18n: t('请选择发票邮件模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'number',
      name: 'customfield_num',
      label: '自定义项数量',
      label_i18n: t('自定义项数量'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写自定义项数量',
      placeholder_i18n: t('请填写自定义项数量'),
    },
    {
      type: 'attributetable',
      name: 'data',
      label: '模板数据',
      label_i18n: t('模板数据'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写模板数据',
      placeholder_i18n: t('请填写模板数据'),
    },
  ],
  rules: {
    name: [{ required: true, message: t('请填写票据模板名称') }],
    cc_company_id: [{ required: true, message: t('请选择CC公司') }],
  },
  model: reactive({
    org_id: current_org?.id,
    name: null,
    description: null,
    data: null,
    customfield_num: 0,
    cc_company_id: null,
    email_template_id: null,
    is_vat: false,
    is_default: false,
  }),
};
const edit_model_config_options = {
  is_vat: [
    { value: true, name: t('是') },
    { value: false, name: t('否') },
  ],
  is_default: [
    { value: true, name: t('是') },
    { value: false, name: t('否') },
  ],
};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '票据模板名称',
      label_i18n: t('票据模板名称'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写票据模板名称',
      placeholder_i18n: t('请填写票据模板名称'),
    },
    {
      type: 'input',
      name: 'description',
      label: '描述',
      label_i18n: t('描述'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写描述',
      placeholder_i18n: t('请填写描述'),
    },
    {
      type: 'remoteselect',
      name: 'cc_company_id',
      label: 'CC公司',
      label_i18n: t('CC公司'),
      placeholder: '请选择CC公司',
      placeholder_i18n: t('请选择CC公司'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-cccompany',
    },
    {
      type: 'radio',
      name: 'is_vat',
      label: '是否VAT模板',
      label_i18n: t('是否VAT模板'),
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'radio',
      name: 'is_default',
      label: '是否默认模板',
      label_i18n: t('是否默认模板'),
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'remoteselect',
      name: 'email_template_id',
      label: '发票邮件模板',
      label_i18n: t('发票邮件模板'),
      disabled: false,
      placeholder: '请选择发票邮件模板',
      placeholder_i18n: t('请选择发票邮件模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'number',
      name: 'customfield_num',
      label: '自定义项数量',
      label_i18n: t('自定义项数量'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写自定义项数量',
      placeholder_i18n: t('请填写自定义项数量'),
    },
    {
      type: 'attributetable',
      name: 'data',
      label: '模板数据',
      label_i18n: t('模板数据'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写模板数据',
      placeholder_i18n: t('请填写模板数据'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    name: null,
    description: null,
    data: null,
    customfield_num: null,
    cc_company_id: null,
    email_template_id: null,
    is_vat: null,
    is_default: null,
  }),
};
const update_model_config_options = {
  is_vat: [
    { value: true, name: t('是') },
    { value: false, name: t('否') },
  ],
  is_default: [
    { value: true, name: t('是') },
    { value: false, name: t('否') },
  ],
};

const titleList = {
  title: t('票据模板'),
  addModalTitle: t('新建票据模板'),
  updateTitle: t('修改票据模板'),
  is_create: true, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    receipt_template_id: 'id',
  },
  delete: {
    receipt_template_id: 'id',
  },
  recover: {
    receipt_template_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    receipt_template_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('ID'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('名称'),
    minWidth: 150,
    dataIndex: 'name',
    checked: true,
  },
  {
    title: t('描述'),
    minWidth: 150,
    dataIndex: 'description',
    checked: true,
  },
  {
    title: t('CC公司'),
    width: 200,
    dataIndex: 'cc_company__name',
    checked: true,
  },
  {
    title: t('是否VAT模板'),
    width: 100,
    dataIndex: 'is_vat',
    checked: true,
    cellComponent: 'boolean',
    name1: t('是'),
    name2: t('否'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('是'), value: true },
      { text: t('否'), value: false },
    ],
  },
  {
    title: t('是否默认模板'),
    width: 100,
    dataIndex: 'is_default',
    checked: true,
    cellComponent: 'boolean',
    name1: t('是'),
    name2: t('否'),
  },
  {
    title: t('发票邮件模板'),
    width: 150,
    dataIndex: 'email_template__name',
    checked: true,
  },
  {
    title: t('自定义项数量'),
    minWidth: 100,
    dataIndex: 'customfield_num',
    checked: true,
  },
  {
    title: t('模板数据'),
    width: 150,
    dataIndex: 'data',
    checked: true,
    cellComponent: 'attributetable',
  },
  {
    title: t('上传附件'),
    width: 250,
    dataIndex: 'files_json',
    checked: true,
    cellComponent: 'click',
    content: (record: any) => {
      if (record.files_json) {
        return record.files_json?.name;
      } else {
        return '';
      }
    },
    cb: (record: any) => {
      if (record.files_json && record.files_json.file_url) {
        const url: string =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' +
          encodeURIComponent(record.files_json.file_url);
        window.open(url, '_blank');
      } else {
        notification.error({
          message: t('提示'),
          description: t('无对应附件'),
        });
      }
    },
  },
  {
    title: t('创建时间'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('修改时间'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('是否可用'),
    width: 100,
    dataIndex: 'is_active',
    checked: true,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('操作'),
    width: 220,
    dataIndex: 'action',
    checked: true,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push();

export default () => {
  const route = useRoute();
  search_config.model = reactive({ ...search_config.model });
  search_config.model.ids = Number(route.query.ids);
  search_config.model.cc_company_id = Number(route.query.cc_company_id);
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_receipt_template_list,
    addData: create_receipt_template,
    updateData: update_receipt_template,
    removeData: delete_receipt_template,
    recoverData: recover_receipt_template,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
