import { query_wechat_update_record_list } from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/cerp/cerp-wechatupdaterecord-config';

import { reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import modalBox from '@/components/form-modal/modal-tools';
import { getDateTime } from '@/utils/function';

export default () => {
  const None = null;
  const True = true;
  const False = false;

  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

  const { t } = useI18n();
  const search_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
      {
        type: 'remotemodal',
        name: 'adviser_id',
        label: '隶属顾问',
        label_i18n: '隶属顾问',
        placeholder_i18n: '请选择隶属顾问',
        disabled: false,
        defaultValue: '',
        labelKey: 'full_name',
        valueKey: 'id',
        placeholder: '隶属顾问',
        show_search: false,
        maxTagCount: 5,
        mode: 'default',
        title: t('选择隶属顾问'),
        modalType: 'cerp-adviser',
      },
      {
        type: 'input',
        name: 'model_str',
        label: 'model标记',
        label_i18n: 'model标记',
        placeholder_i18n: '请填写model_flag',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写model_flag',
      },
      {
        type: 'input',
        name: 'model_name',
        label: 'model名称',
        label_i18n: 'model名称',
        placeholder_i18n: '请填写model名称',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写model名称',
      },
      {
        type: 'select',
        name: 'status',
        label: '审批状态',
        label_i18n: '审批状态',
        placeholder_i18n: '请选择审批状态',
        mode: 'default',
        disabled: false,
        defaultValue: '',
        labelKey: 'name',
        valueKey: 'value',
        placeholder: '请选择审批状态',
        datasource: 'status',
      },
      {
        type: 'select',
        name: 'is_active',
        label: '是否可用',
        label_i18n: '是否可用',
        placeholder_i18n: '请选择是否可用',
        mode: 'default',
        disabled: false,
        defaultValue: '',
        labelKey: 'name',
        valueKey: 'value',
        placeholder: '请选择是否可用',
        datasource: 'is_active',
      },
    ],
    rules: {},
    model: reactive({
      org_id: current_org?.id,
      is_active: true,
      status: null,
      adviser_id: null,
      model_name: null,
      model_str: null,
    }),
  };
  const search_options = {
    is_active: [
      { value: None, name: t('全部') },
      { value: True, name: t('可用') },
      { value: False, name: t('禁用') },
    ],
    status: [
      { value: None, name: t('全部') },
      { value: 0, name: t('未审批') },
      { value: 1, name: t('通过') },
      { value: 2, name: t('拒绝') },
    ],
  };

  const edit_model_config: SearchConfig = {
    settings: {},
    fields: [],
    rules: {},
    model: reactive({}),
  };
  const edit_model_config_options = {};

  const update_model_config: SearchConfig = {
    settings: {},
    fields: [],
    rules: {},
    model: reactive({}),
  };
  const update_model_config_options = {};

  const titleList = {
    title: t('微信小程序数据变更'),
    addModalTitle: t('新建地址'),
    updateTitle: t('修改地址'),
    is_create: false, // 是否有【新建】按钮
    is_update: false, // 是否有【修改】按钮
    is_remove: false, // 是否有【删除】按钮
    is_recover: false, // 是否有【恢复】按钮
  };

  const api_fields: Record = {
    update: {
      address_id: 'id',
    },
    delete: {
      address_id: 'id',
    },
    recover: {
      address_id: 'id',
    },
    updateData: {
      org_id: 'org_id',
      address_id: 'id',
    },
  };

  const baseColumns: TableColumn[] = [
    {
      title: t('序号'),
      dataIndex: 'index',
      width: 50,
      fixed: 'left',
    },
    {
      title: 'ID',
      width: 150,
      dataIndex: 'id',
      checked: false,
      fixed: 'left',
    },
    {
      title: t('顾问名称'),
      minWidth: 150,
      dataIndex: 'adviser__full_name',
      checked: true,
      fixed: 'left',
    },
    {
      title: t('工号'),
      width: 100,
      dataIndex: 'adviser__employee_number',
      checked: true,
      fixed: 'left',
    },
    {
      title: t('modal标记'),
      minWidth: 150,
      dataIndex: 'model_str',
      checked: true,
    },
    {
      title: t('modal名称'),
      width: 150,
      dataIndex: 'model_name',
      checked: true,
      filterMultiple: false,
      filters: [
        { value: '', text: t('全部') },
        { value: '顾问', text: t('顾问') },
        { value: '银行账户信息', text: t('银行账户信息') },
        { value: '紧急联系人', text: t('紧急联系人') },
        { value: '地址', text: t('地址') },
        { value: '受益人', text: t('受益人') },
        { value: '个人参考号', text: t('个人参考号') },
      ],
    },
    {
      title: t('审批状态'),
      width: 120,
      dataIndex: 'status',
      checked: true,
      cellComponent: 'tag',
      content: (record: any) => {
        let color = '';
        switch (record.status) {
          case 0:
            color = t('未审批');
            break;
          case 1:
            color = t('通过');
            break;
          case 2:
            color = t('拒绝');
            break;
          default:
            color = '';
            break;
        }
        return color;
      },
      color: (record: any) => {
        let color = '';
        switch (record.status) {
          case 0:
            color = '';
            break;
          case 1:
            color = 'blue';
            break;
          case 2:
            color = 'red';
            break;
          default:
            color = '';
            break;
        }
        return color;
      },
      filterMultiple: false,
      filters: [
        { value: '', text: t('全部') },
        { value: '0', text: t('未审批') },
        { value: '1', text: t('通过') },
        { value: '2', text: t('拒绝') },
      ],
    },
    {
      title: t('旧数据json'),
      minWidth: 150,
      dataIndex: 'old_json',
      checked: true,
      cellComponent: 'click',
      cb: (record: any) => {
        const loading = ref(false);
        const editModal = reactive({
          visible: true,
          title: t('代码内容'),
          component: 'code-editing-modal',
          loading: loading,
          mode: 'javascript',
          params: record.old_json,
          okText: t('确定'),
          hold: () => {
          },
        });
        modalBox(editModal);
      },
    },
    {
      title: t('新数据json'),
      minWidth: 150,
      dataIndex: 'new_json',
      checked: true,
      cellComponent: 'click',
      cb: (record: any) => {
        const loading = ref(false);
        const editModal = reactive({
          visible: true,
          title: t('代码内容'),
          component: 'code-editing-modal',
          loading: loading,
          mode: 'javascript',
          params: record.new_json,
          okText: t('确定'),
          hold: () => {
          },
        });
        modalBox(editModal);
      },
    },
    {
      title: t('创建时间'),
      width: 150,
      dataIndex: 'create_time',
      checked: true,
      content: (record: any) => {
        return getDateTime(record.create_time);
      },
    },
    {
      title: t('修改时间'),
      width: 150,
      dataIndex: 'update_time',
      checked: true,
      content: (record: any) => {
        return getDateTime(record.update_time);
      },
    },
    {
      title: t('是否可用'),
      width: 150,
      dataIndex: 'is_active',
      checked: true,
      cellComponent: 'boolean',
      name1: t('可用'),
      name2: t('禁用'),
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('可用'), value: true },
        { text: t('禁用'), value: false },
      ],
    },

    {
      title: t('操作'),
      width: 150,
      dataIndex: 'action',
      checked: false,
      cellComponent: 'action',
      fixed: 'right',
    },
  ];
  const newSlotList: any = [];
  Object.assign(newSlotList, slotList);
  newSlotList.push(
    {
    type: 'tag',
    slotName: 'status1',
    content: (record: any) => {
      return record.status == 0
        ? '未审批'
        : record.status == 1
        ? '通过'
        : record.status == 2
        ? '拒绝'
        : '';
    },
    color: (record: any) => {
      if (record.status == 1) {
        return 'blue';
      } else if (record.status == 2) {
        return 'red';
      } else {
        return '';
      }
    },
  },
    {
      type: 'click',
      slotName: 'old_json',
      cb: (record: any) => {
        const loading = ref(false);
        const editModal = reactive({
          visible: true,
          title: t('代码内容'),
          component: 'code-editing-modal',
          loading: loading,
          mode: 'javascript',
          params: record.old_json,
          okText: '确定',
          hold: () => {
          },
        });
        modalBox(editModal);
      },
    },
    {
      type: 'click',
      slotName: 'new_json',
      cb: (record: any) => {
        const loading = ref(false);
        const editModal = reactive({
          visible: true,
          title: t('代码内容'),
          component: 'code-editing-modal',
          loading: loading,
          mode: 'javascript',
          params: record.new_json,
          okText: '确定',
          hold: () => {
          },
        });
        modalBox(editModal);
      },
    },
  );
  const route = useRoute();
  search_config.model = reactive({ ...search_config.model });
  search_config.model.ids = Number(route.query.ids);
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_wechat_update_record_list,
    addData: null,
    updateData: null,
    removeData: null,
    recoverData: null,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
