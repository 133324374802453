import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';
import { reactive, ref } from 'vue';
import { message, Modal } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { useRouter } from 'vue-router';
import {
  cc_sign_receipt_contract,
  create_receipt_contract,
  create_receipt_contract_pdf,
  query_receipt_contract_template_list,
  send_receipt_contract_email,
  send_receipt_contract_email_by_manul,
  update_receipt_contract,
  upload_receipt_contract_files,
  view_receipt_contract_pdf,
} from '@/api/receipt-model';
import notification from '@/views/account/settings/pages/notification.vue';
import { useI18n } from 'vue-i18n';
import { query_c_c_email_list } from '@/api/baseinfo-model';

const router = useRouter();
const { t } = useI18n();
const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
export const event_obj = mitt();
export const btn_list = [
  {
    name: t('修改'),
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const list: any = [];
      Object.keys(record.data).map((key: string) => {
        list.push({
          name: key,
          value: record.data[key],
        });
      });
      const editModal = reactive({
        visible: true,
        title: t('修改票据合同'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [6, 4],
          },
          fields: [
            {
              type: 'remoteselect',
              name: 'receipt_contract_template_id',
              label: '票据合同模板',
              label_i18n: t('票据合同模板'),
              disabled: false,
              placeholder: '请选择票据合同模板',
              placeholder_i18n: t('请选择票据合同模板'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'name',
              valueKey: 'id',
              modalType: 'receipt-query_receipt_contract_template_list',
            },
            // {
            //   type: 'remoteselect',
            //   name: 'receipt_template_id',
            //   label: '票据模板',
            //   label_i18n: t('票据模板'),
            //   disabled: false,
            //   placeholder: '请选择票据模板',
            //   placeholder_i18n: t('请选择票据模板'),
            //   datasourceType: 'remote',
            //   mode: 'default',
            //   allowClear: true,
            //   labelKey: 'name',
            //   valueKey: 'id',
            //   modalType: 'receipt-query_receipt_template_list',
            // },
            {
              type: 'remoteselect',
              name: 'customer_id',
              label: '客户',
              label_i18n: t('客户'),
              disabled: false,
              placeholder: '请选择客户',
              placeholder_i18n: t('请选择客户'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'name',
              valueKey: 'id',
              modalType: 'receipt-query_customer_list',
            },
            {
              type: 'rangepicker',
              name: 'start_end_date',
              label: '合同起始-结束日期',
              label_i18n: t('合同起始-结束日期'),
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请选择合同起始-结束日期',
              placeholder_i18n: t('请选择合同起始-结束日期'),
            },
            {
              type: 'remoteselect',
              name: 'cc_company_id',
              label: 'CC公司',
              label_i18n: t('CC公司'),
              disabled: false,
              placeholder: '请选择CC公司',
              placeholder_i18n: t('请选择CC公司'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-cccompany',
            },
            {
              type: 'remoteselect',
              name: 'cc_bank_account_id',
              label: 'CC银行账户',
              label_i18n: t('CC银行账户'),
              placeholder_i18n: t('请选择CC银行账户'),
              disabled: false,
              placeholder: '请选择CC银行账户',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'account_flag',
              valueKey: 'id',
              modalType: 'baseinfo-ccbankaccountscc',
            },
            {
              type: 'remoteselect',
              name: 'cc_email_id',
              label: 'CC邮箱',
              label_i18n: t('CC邮箱'),
              disabled: false,
              placeholder: '请选择CC邮箱',
              placeholder_i18n: t('请选择CC邮箱'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-ccemail',
            },
            {
              type: 'remoteselect',
              name: 'contract_email_template_id',
              label: '合同邮箱模板',
              label_i18n: t('合同邮箱模板'),
              disabled: false,
              placeholder: '请选择合同邮箱模板',
              placeholder_i18n: t('请选择合同邮箱模板'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-emailtemplate',
            },
            {
              type: 'remoteselect',
              name: 'sign_email_template_id',
              label: '签署合同确认邮件',
              label_i18n: t('签署合同确认邮件'),
              disabled: false,
              placeholder: '请选择签署合同确认邮件',
              placeholder_i18n: t('请选择签署合同确认邮件'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-emailtemplate',
            },
            {
              type: 'attributetable',
              name: 'data',
              label: '模板数据',
              label_i18n: t('模板数据'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写模板数据',
              placeholder_i18n: t('请填写模板数据'),
            },
          ],
          rules: {},
          model: reactive({
            org_id: current_org?.id,
            receipt_contract_id: record.id,
            receipt_contract_template_id: record.receipt_contract_template_id,
            // receipt_template_id: record.receipt_template_id,
            customer_id: record.customer_id,
            start_end_date: [record.start_date, record.end_date],
            cc_email_id: record.cc_email_id,
            cc_company_id: record.cc_company_id,
            contract_email_template_id: record.contract_email_template_id,
            sign_email_template_id: record.sign_email_template_id,
            cc_bank_account_id: record.cc_bank_account_id,
            data: list,
          }),
          watch: {
            receipt_contract_template_id: (data1: any) => {
              if (data1.receipt_contract_template_id) {
                query_receipt_contract_template_list({
                  org_id: current_org?.id,
                  ids: data1.receipt_contract_template_id,
                }).then((res: any) => {
                  data1.data = [];
                  if (res && res.data && res.data.length > 0) {
                    res.data[0].data.forEach((item: any) => {
                      data1.data.push({
                        name: item.value,
                        value: null,
                      });
                    });
                  }
                });
              }
            },
            cc_company_id: (data1: any) => {
              if (data1.cc_company_id) {
                query_c_c_email_list({
                  org_id: current_org?.id,
                  cc_company_id: data1.cc_company_id,
                }).then((res1: any) => {
                  if (res1 && res1.data && res1.data.length > 0) {
                    if (res1.data[0].id) {
                      data1.cc_email_id = res1.data[0].id;
                    }
                  }
                });
              }
            },
          },
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            const obj: any = {};
            if (payload?.data && payload?.data?.length > 0) {
              payload.data.forEach((item: any) => {
                obj[item.name] = item.value;
              });
            }
            update_receipt_contract({
              ...payload,
              data: JSON.stringify(obj),
              start_date: payload.start_end_date[0],
              end_date: payload.start_end_date[1],
            })
              .then(() => {
                message.success(t('修改成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: t('上传合同附件'),
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any) => {
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('上传合同附件'),
        group: '合同',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          return new Promise(resolve => {
            loading.value = true;
            if (data.appendix_ids) {
              payload.push(...data.appendix_ids.split(','));
            }
            upload_receipt_contract_files({
              receipt_contract_id: data.id,
              appendix_ids: payload,
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('添加成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: t('预览合同PDF'),
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any) => {
      view_receipt_contract_pdf({
        org_id: current_org.id,
        receipt_contract_id: data.id,
      }).then((res: any) => {
        if (res) {
          const url: string = res;
          window.open(url, '_blank');
        } else {
          notification.error({
            message: t('提示'),
            description: t('无对应附件'),
          });
        }
      });
    },
  },
  {
    name: t('生成合同PDF'),
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any) => {
      Modal.confirm({
        title: t('提示'),
        content: '是否生成合同PDF？',
        onOk() {
          return new Promise(resolve => {
            create_receipt_contract_pdf({ org_id: current_org.id, receipt_contract_id: data.id })
              .then(function (param: any) {
                message.success(t('操作成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: t('发送邮件'),
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any) => {
      Modal.confirm({
        title: t('提示'),
        content: '是否发送邮件？',
        onOk() {
          return new Promise(resolve => {
            send_receipt_contract_email({ org_id: current_org.id, receipt_contract_id: data.id })
              .then(function (param: any) {
                message.success(t('操作成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: t('手动发送邮件'),
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any) => {
      Modal.confirm({
        title: t('提示'),
        content: '是否手动发送邮件？',
        onOk() {
          return new Promise(resolve => {
            send_receipt_contract_email_by_manul({
              org_id: current_org.id,
              receipt_contract_id: data.id,
            })
              .then(function (param: any) {
                message.success(t('操作成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  // {
  //   name: t('三方合同上传确认'),
  //   type: 'dropdown',
  //   clazz: 'primary',
  //   is_show: (record: any) => {
  //     return !record.is_three_upload;
  //   },
  //   cb: (record: any) => {
  //     Modal.confirm({
  //       title: t('提示'),
  //       content: '确认已上传三方合同',
  //       onOk() {
  //         return new Promise(resolve => {
  //           set_receipt_contract_upload_status({
  //             org_id: current_org?.id,
  //             is_three_upload: true,
  //             receipt_contract_id: record.id,
  //           })
  //             .then(() => {
  //               message.success(t('确认三方合同上传成功'));
  //               event_obj.emit('refresh');
  //             })
  //             .finally(() => {
  //               resolve(null);
  //             });
  //         });
  //       },
  //       onCancel() {
  //       },
  //     });
  //   },
  // },
  // {
  //   name: t('作废三方合同'),
  //   type: 'dropdown',
  //   clazz: 'primary',
  //   is_show: (record: any) => {
  //     return record.is_three_upload;
  //   },
  //   cb: (record: any) => {
  //     Modal.confirm({
  //       title: t('提示'),
  //       content: '作废三方合同?',
  //       onOk() {
  //         return new Promise(resolve => {
  //           set_receipt_contract_upload_status({
  //             org_id: current_org?.id,
  //             is_three_upload: false,
  //             receipt_contract_id: record.id,
  //           })
  //             .then(() => {
  //               message.success(t('操作成功'));
  //               event_obj.emit('refresh');
  //             })
  //             .finally(() => {
  //               resolve(null);
  //             });
  //         });
  //       },
  //       onCancel() {
  //       },
  //     });
  //   },
  // },
  // {
  //   name: t('双方合同上传确认'),
  //   type: 'dropdown',
  //   clazz: 'primary',
  //   is_show: (record: any) => {
  //     return !record.is_double_upload;
  //   },
  //   cb: (record: any) => {
  //     Modal.confirm({
  //       title: t('提示'),
  //       content: '确认已上传双方合同',
  //       onOk() {
  //         return new Promise(resolve => {
  //           set_receipt_contract_upload_status({
  //             org_id: current_org?.id,
  //             is_double_upload: true,
  //             receipt_contract_id: record.id,
  //           })
  //             .then(() => {
  //               message.success(t('操作成功'));
  //               event_obj.emit('refresh');
  //             })
  //             .finally(() => {
  //               resolve(null);
  //             });
  //         });
  //       },
  //       onCancel() {
  //       },
  //     });
  //   },
  // },
  // {
  //   name: t('作废双方合同'),
  //   type: 'dropdown',
  //   clazz: 'primary',
  //   is_show: (record: any) => {
  //     return record.is_double_upload;
  //   },
  //   cb: (record: any) => {
  //     Modal.confirm({
  //       title: t('提示'),
  //       content: '确认作废双方合同',
  //       onOk() {
  //         return new Promise(resolve => {
  //           set_receipt_contract_upload_status({
  //             org_id: current_org?.id,
  //             is_double_upload: false,
  //             receipt_contract_id: record.id,
  //           })
  //             .then(() => {
  //               message.success(t('操作成功'));
  //               event_obj.emit('refresh');
  //             })
  //             .finally(() => {
  //               resolve(null);
  //             });
  //         });
  //       },
  //       onCancel() {
  //       },
  //     });
  //   },
  // },
  {
    name: t('CC签署'),
    type: 'dropdown',
    clazz: 'primary',
    is_show: (record: any) => {
      return record.status === 3;
    },
    cb: (record: any) => {
      Modal.confirm({
        title: t('提示'),
        content: '确认CC签署合同',
        onOk() {
          return new Promise(resolve => {
            cc_sign_receipt_contract({
              org_id: current_org?.id,
              receipt_contract_id: record.id,
            })
              .then(() => {
                message.success(t('操作成功'));
                event_obj.emit('refresh');
              })
              .finally(() => {
                resolve(null);
              });
          });
        },
        onCancel() {},
      });
    },
  },
  {
    name: t('新建发票'),
    clazz: 'primary',
    type: 'dropdown',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        title: t('新建发票'),
        component: 'ReceiptModel',
        obj: {
          ...record,
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list = [
  {
    name: t('新建票据合同'),
    clazz: 'primary',
    cb: (data: any) => {
      const editModal = reactive({
        visible: true,
        title: t('新建票据合同'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [6, 4],
          },
          fields: [
            {
              type: 'remoteselect',
              name: 'receipt_contract_template_id',
              label: '票据合同模板',
              label_i18n: t('票据合同模板'),
              disabled: false,
              placeholder: '请选择票据合同模板',
              placeholder_i18n: t('请选择票据合同模板'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'name',
              valueKey: 'id',
              modalType: 'receipt-query_receipt_contract_template_list',
            },
            // {
            //   type: 'remoteselect',
            //   name: 'receipt_template_id',
            //   label: '票据模板',
            //   label_i18n: t('票据模板'),
            //   disabled: false,
            //   placeholder: '请选择票据模板',
            //   placeholder_i18n: t('请选择票据模板'),
            //   datasourceType: 'remote',
            //   mode: 'default',
            //   allowClear: true,
            //   labelKey: 'name',
            //   valueKey: 'id',
            //   modalType: 'receipt-query_receipt_template_list',
            // },
            {
              type: 'remoteselect',
              name: 'customer_id',
              label: '客户',
              label_i18n: t('客户'),
              disabled: false,
              placeholder: '请选择客户',
              placeholder_i18n: t('请选择客户'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'name',
              valueKey: 'id',
              modalType: 'receipt-query_customer_list',
            },
            {
              type: 'rangepicker',
              name: 'start_end_date',
              label: '合同起始-结束日期',
              label_i18n: t('合同起始-结束日期'),
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              showTime: false,
              valueFormat: 'YYYY-MM-DD',
              placeholder: '请选择合同起始-结束日期',
              placeholder_i18n: t('请选择合同起始-结束日期'),
            },
            {
              type: 'remoteselect',
              name: 'cc_company_id',
              label: 'CC公司',
              label_i18n: t('CC公司'),
              disabled: false,
              placeholder: '请选择CC公司',
              placeholder_i18n: t('请选择CC公司'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-cccompany',
            },
            {
              type: 'remoteselect',
              name: 'cc_bank_account_id',
              label: 'CC银行账户',
              label_i18n: t('CC银行账户'),
              placeholder_i18n: t('请选择CC银行账户'),
              disabled: false,
              placeholder: '请选择CC银行账户',
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              labelKey: 'account_flag',
              valueKey: 'id',
              modalType: 'baseinfo-ccbankaccountscc',
            },
            {
              type: 'remoteselect',
              name: 'cc_email_id',
              label: 'CC邮箱',
              label_i18n: t('CC邮箱'),
              disabled: false,
              placeholder: '请选择CC邮箱',
              placeholder_i18n: t('请选择CC邮箱'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-ccemail',
            },
            {
              type: 'remoteselect',
              name: 'contract_email_template_id',
              label: '合同邮箱模板',
              label_i18n: t('合同邮箱模板'),
              disabled: false,
              placeholder: '请选择合同邮箱模板',
              placeholder_i18n: t('请选择合同邮箱模板'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-emailtemplate',
            },
            {
              type: 'remoteselect',
              name: 'sign_email_template_id',
              label: '签署合同确认邮件',
              label_i18n: t('签署合同确认邮件'),
              disabled: false,
              placeholder: '请选择签署合同确认邮件',
              placeholder_i18n: t('请选择签署合同确认邮件'),
              datasourceType: 'remote',
              mode: 'default',
              allowClear: true,
              modalType: 'baseinfo-emailtemplate',
            },
            {
              type: 'attributetable',
              name: 'data',
              label: '模板数据',
              label_i18n: t('模板数据'),
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写模板数据',
              placeholder_i18n: t('请填写模板数据'),
            },
          ],
          rules: {
            customer_id: [{ required: true, message: t('请选择客户') }],
            start_end_date: [{ required: true, message: t('请选择合同起始-结束日期') }],
            cc_company_id: [{ required: true, message: t('请选择CC公司') }],
            cc_bank_account_id: [{ required: true, message: t('请选择CC银行账户') }],
          },
          model: reactive({
            org_id: current_org?.id,
            receipt_contract_template_id: null,
            // receipt_template_id: null,
            customer_id: null,
            start_end_date: null,
            cc_email_id: null,
            cc_company_id: null,
            contract_email_template_id: null,
            sign_email_template_id: null,
            data: null,
            cc_bank_account_id: null,
          }),
          watch: {
            receipt_contract_template_id: (data1: any) => {
              if (data1.receipt_contract_template_id) {
                query_receipt_contract_template_list({
                  org_id: current_org?.id,
                  ids: data1.receipt_contract_template_id,
                }).then((res: any) => {
                  data1.data = [];
                  if (res && res.data && res.data.length > 0) {
                    res.data[0].data.forEach((item: any) => {
                      data1.data.push({
                        name: item.value,
                        value: null,
                      });
                    });
                  }
                });
              }
            },
            cc_company_id: (data1: any) => {
              if (data1.cc_company_id) {
                query_c_c_email_list({
                  org_id: current_org?.id,
                  cc_company_id: data1.cc_company_id,
                }).then((res1: any) => {
                  if (res1 && res1.data && res1.data.length > 0) {
                    if (res1.data[0].id) {
                      data1.cc_email_id = res1.data[0].id;
                    }
                  }
                });
              }
            },
          },
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            const obj: any = {};
            if (payload?.data && payload?.data?.length > 0) {
              payload.data.forEach((item: any) => {
                obj[item.name] = item.value;
              });
            }
            create_receipt_contract({
              ...payload,
              data: JSON.stringify(obj),
              start_date: payload.start_end_date[0],
              end_date: payload.start_end_date[1],
            })
              .then(() => {
                message.success(t('新建成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
];
