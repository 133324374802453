import {
  create_to_do_event_priority,
  delete_to_do_event_priority,
  query_to_do_event_priority_list,
  recover_to_do_event_priority,
  update_to_do_event_priority,
} from '@/api/baseinfo-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/baseinfo/baseinfo-to_do_event_priority-config';

import { reactive } from 'vue';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '描述',
      label_i18n: t('page.baseinfo.to_do_event_priority.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写描述',
      placeholder_i18n: t('page.baseinfo.to_do_event_priority.form.placeholder.name'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
  }),
};
const search_options = {};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '描述',
      label_i18n: t('page.baseinfo.to_do_event_priority.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写描述',
      placeholder_i18n: t('page.baseinfo.to_do_event_priority.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'priority',
      label: '优先等级',
      label_i18n: t('page.baseinfo.to_do_event_priority.form.label.priority'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写优先等级',
      placeholder_i18n: t('page.baseinfo.to_do_event_priority.form.placeholder.priority'),
    },
  ],
  rules: {
    name: [{ required: true, message: t('page.baseinfo.to_do_event_priority.form.placeholder.name') }],
    priority: [{ required: true, message: t('page.baseinfo.to_do_event_priority.form.placeholder.priority') }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    priority: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '描述',
      label_i18n: t('page.baseinfo.to_do_event_priority.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写描述',
      placeholder_i18n: t('page.baseinfo.to_do_event_priority.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'priority',
      label: '优先等级',
      label_i18n: t('page.baseinfo.to_do_event_priority.form.label.priority'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写优先等级',
      placeholder_i18n: t('page.baseinfo.to_do_event_priority.form.placeholder.priority'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    priority: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('待办优先等级'),
  addModalTitle: t('新建待办优先等级'),
  updateTitle: t('修改待办优先等级'),
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    todoevent_priority_id: 'id',
  },
  delete: {
    todoevent_priority_id: 'id',
  },
  recover: {
    todoevent_priority_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    todoevent_priority_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.baseinfo.to_do_event_priority.column.title.index'),
    width: 50,
    dataIndex: 'index',
  },
  {
    title: t('page.baseinfo.to_do_event_priority.column.title.id'),
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.baseinfo.to_do_event_priority.column.title.name'),
    dataIndex: 'name',
    checked: true,
  },
  {
    title: t('page.baseinfo.to_do_event_priority.column.title.priority'),
    dataIndex: 'priority',
    checked: true,
  },
  {
    title: t('page.baseinfo.to_do_event_priority.column.title.is_active'),
    dataIndex: 'is_active',
    checked: true,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },

  {
    title: t('page.baseinfo.to_do_event_priority.column.title.action'),
    width: 120,
    dataIndex: 'action',
    checked: true,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  // slotName: 'is_active',
  field_true: '可用',
  field_false: '禁用',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_to_do_event_priority_list,
    addData: create_to_do_event_priority,
    updateData: update_to_do_event_priority,
    removeData: delete_to_do_event_priority,
    recoverData: recover_to_do_event_priority,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};