import { TableColumn } from '@/typing';
import { slotList } from '@/components/page-model/service/service-service-config';
import { getDateTime } from '@/utils/function';
import { useI18n } from 'vue-i18n';

export default (current_org: any) => {
  const { t } = useI18n();
  const None = null;
  const True = true;
  const False = false;
  const baseColumns: TableColumn[] = [
    {
      title: t('序号'),
      dataIndex: 'index',
      width: 50,
    },
    {
      title: 'ID',
      width: 150,
      dataIndex: 'id',
      checked: false,
    },
    {
      title: t('客服名称'),
      minWidth: 200,
      dataIndex: 'name',
      checked: true,
    },
    {
      title: t('描述'),
      minWidth: 200,
      dataIndex: 'description',
      checked: true,
    },
    {
      title: t('电子邮箱'),
      minWidth: 200,
      dataIndex: 'email',
      checked: true,
    },
    {
      title: t('模板邮箱'),
      minWidth: 50,
      dataIndex: 'email_template_name',
      checked: true,
    },
    {
      title: t('领班'),
      minWidth: 50,
      dataIndex: 'is_leader',
      checked: true,
      cellComponent: 'boolean',
      name1: t('是'),
      name2: t('否'),
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('是'), value: true },
        { text: t('否'), value: false },
      ],
    },
    {
      title: t('用户ID'),
      minWidth: 200,
      dataIndex: 'user_id',
      checked: false,
    },
    {
      title: t('创建时间'),
      width: 150,
      dataIndex: 'create_time',
      checked: false,
      content: (record: any) => {
        return getDateTime(record.create_time);
      },
    },
    {
      title: t('更新时间'),
      width: 150,
      dataIndex: 'update_time',
      checked: false,
      content: (record: any) => {
        return getDateTime(record.update_time);
      },
    },
    {
      title: t('状态'),
      width: 150,
      dataIndex: 'status',
      checked: true,
      cellComponent: 'tag',
      content: (record: any) => {
        let color = '';
        switch (record.status) {
          case 1:
            color = t('工作中');
            break;
          case 2:
            color = t('离线');
            break;
          default:
            color = '';
            break;
        }
        return color;
      },
      color: (record: any) => {
        let color = '';
        switch (record.status) {
          case 1:
            color = 'blue';
            break;
          case 2:
            color = 'red';
            break;
          default:
            color = '';
            break;
        }
        return color;
      },
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('工作中'), value: 1 },
        { text: t('离线'), value: 2 },
      ],
    },
    {
      title: t('是否可用'),
      width: 150,
      dataIndex: 'is_active',
      checked: true,
      cellComponent: 'boolean',
      name1: t('可用'),
      name2: t('禁用'),
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('可用'), value: true },
        { text: t('禁用'), value: false },
      ],
    },

    {
      title: t('操作'),
      width: 150,
      dataIndex: 'action',
      checked: true,
      cellComponent: 'action',
      fixed: 'right',
    },
  ];

  const newSlotList: any = [];
  Object.assign(newSlotList, slotList);

  return {
    baseColumns: baseColumns,
    newSlotList: newSlotList,
  };
};
