import {
  create_receipt_contract,
  delete_receipt_contract,
  query_receipt_contract_list,
  recover_receipt_contract,
  update_receipt_contract,
  upload_receipt_contract_files,
} from '@/api/receipt-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_COMPANY, STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/receipt/receipt-contract-config';
import { useRoute } from 'vue-router';
import { getDate, getDateTime } from '@/utils/function';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { createVNode, reactive } from 'vue';
import notification from '@/views/account/settings/pages/notification.vue';
import { message, Modal } from 'ant-design-vue';

const None = null;
const True = true;
const False = false;
export default () => {
  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  const current_company = ls.get(STORAGE_CURRENT_COMPANY); // 当前cc公司

  const { t } = useI18n();
  const search_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
      {
        type: 'remoteselect',
        name: 'customer_id',
        label: '客户',
        label_i18n: t('客户'),
        disabled: false,
        placeholder: '请选择客户',
        placeholder_i18n: t('请选择客户'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        labelKey: 'name',
        valueKey: 'id',
        modalType: 'receipt-query_customer_list',
      },
      // {
      //   type: 'remoteselect',
      //   name: 'cc_company_id',
      //   label: 'CC公司',
      //   disabled: false,
      //   placeholder: '请选择CC公司',
      //   datasourceType: 'remote',
      //   mode: 'default',
      //   allowClear: true,
      //   modalType: 'baseinfo-cccompany',
      // },
      {
        type: 'remoteselect',
        name: 'receipt_contract_template_id',
        label: '票据合同模板',
        label_i18n: t('票据合同模板'),
        disabled: false,
        placeholder: '请选择票据合同模板',
        placeholder_i18n: t('请选择票据合同模板'),
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        labelKey: 'name',
        valueKey: 'id',
        modalType: 'receipt-query_receipt_contract_template_list',
      },
      {
        type: 'remoteselect',
        name: 'cc_bank_account_id',
        label: 'CC银行账户',
        label_i18n: 'CC银行账户',
        placeholder_i18n: '请选择CC银行账户',
        disabled: false,
        placeholder: '请选择CC银行账户',
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        labelKey: 'account_flag',
        valueKey: 'id',
        modalType: 'baseinfo-ccbankaccountscc',
      },
      {
        type: 'input',
        name: 'cc_company_name',
        label: 'CC公司',
        label_i18n: t('CC公司'),
        disabled: true,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写CC公司',
        placeholder_i18n: t('请填写CC公司'),
      },
    ],
    rules: {},
    model: reactive({
      org_id: current_org?.id,
      is_active: true,
      customer_id: null,
      cc_company_id: current_company?.id,
      receipt_contract_template_id: null,
      cc_company_name: current_company?.name !== '全部公司' ? current_company?.name : '',
      cc_bank_account_id: null,
      sort_fields: ['-create_time'],
    }),
  };
  const search_options = {
    is_active: [
      { value: None, name: '全部' },
      { value: True, name: '可用' },
      { value: False, name: '禁用' },
    ],
  };

  const edit_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [],
    rules: {
      name: [{ required: true, message: '请填写票据种类名称' }],
    },
    model: reactive({
      org_id: current_org?.id,
      name: null,
    }),
  };
  const edit_model_config_options = {};

  const update_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [],
    rules: {},
    model: reactive({
      org_id: current_org?.id,
      name: null,
    }),
  };
  const update_model_config_options = {};

  const titleList = {
    title: t('票据合同'),
    addModalTitle: '',
    updateTitle: '',
    is_create: false, // 是否有【新建】按钮
    is_update: false, // 是否有【修改】按钮
    is_remove: true, // 是否有【删除】按钮
    is_recover: true, // 是否有【恢复】按钮
  };

  const api_fields: Record = {
    update: {
      receipt_contract_id: 'id',
    },
    delete: {
      receipt_contract_id: 'id',
    },
    recover: {
      receipt_contract_id: 'id',
    },
    updateData: {
      org_id: 'org_id',
      receipt_contract_id: 'id',
    },
  };

  const baseColumns: TableColumn[] = [
    {
      title: t('序号'),
      dataIndex: 'index',
      width: 50,
      fixed: 'left',
    },
    {
      title: t('ID'),
      width: 150,
      dataIndex: 'id',
      checked: false,
      fixed: 'left',
    },
    {
      title: t('客户'),
      width: 200,
      dataIndex: 'customer__name',
      checked: true,
      fixed: 'left',
    },
    {
      title: t('客户属性'),
      width: 150,
      dataIndex: 'customer__customer_attr',
      checked: true,
      fixed: 'left',
      content: (record: any, text: any) => {
        let color = '';
        switch (text) {
          case 0:
            color = t('个人');
            break;
          case 1:
            color = t('公司');
            break;
          default:
            color = '';
            break;
        }
        return color;
      },
    },
    {
      title: t('客户类型'),
      width: 80,
      dataIndex: 'customer__customer_type',
      checked: true,
      content: (record: any, text: any) => {
        let color = '';
        switch (text) {
          case 0:
            color = t('顾问');
            break;
          case 1:
            color = t('顾问公司');
            break;
          case 2:
            color = t('CC公司');
            break;
          case 3:
            color = t('其他');
            break;
          default:
            color = '';
            break;
        }
        return color;
      },
    },
    {
      title: t('合同起始时间'),
      width: 105,
      dataIndex: 'start_date',
      checked: true,
      fixed: 'left',
    },
    {
      title: t('合同结束时间'),
      width: 105,
      dataIndex: 'end_date',
      checked: true,
      fixed: 'left',
    },
    {
      title: t('合同状态'),
      width: 90,
      dataIndex: 'status',
      checked: true,
      content: (record: any) => {
        let color = '';
        switch (record.status) {
          case 1:
            color = t('草稿');
            break;
          case 2:
            color = t('发起合同');
            break;
          case 3:
            color = '客户签署';
            break;
          case 4:
            color = t('签署完成');
            break;
          case 5:
            color = t('手动发送');
            break;
          default:
            color = '';
            break;
        }
        return color;
      },
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('草稿'), value: '1' },
        { text: t('发起合同'), value: '2' },
        { text: '客户签署', value: '3' },
        { text: t('签署完成'), value: '4' },
        { text: t('手动发送'), value: '5' },
      ],
    },
    {
      title: t('CC公司'),
      width: 150,
      dataIndex: 'cc_company__name',
      checked: true,
    },
    {
      title: t('CC银行账户'),
      width: 150,
      dataIndex: 'cc_bank_account__account_flag',
      checked: true,
    },
    {
      title: t('票据合同数据'),
      width: 150,
      dataIndex: 'data',
      checked: true,
      cellComponent: 'attributetable',
      content: (record: any, text: any) => {
        const list: any = [];
        if (record.data) {
          Object.keys(record.data).map((key: string) => {
            list.push({
              name: key,
              value: record.data[key],
            });
          });
        }
        return list;
      },
    },
    {
      title: t('票据合同模板'),
      width: 150,
      dataIndex: 'receipt_contract_template__name',
      checked: true,
    },
    // {
    //   title: t('发票模板'),
    //   width: 150,
    //   dataIndex: 'receipt_template__name',
    //   checked: true,
    // },
    {
      title: t('CC邮箱'),
      width: 80,
      dataIndex: 'cc_email__name',
      checked: true,
    },
    {
      title: t('合同邮件模板'),
      width: 200,
      dataIndex: 'contract_email_template__name',
      checked: true,
    },
    {
      title: t('附件'),
      width: 200,
      dataIndex: 'appendix_json',
      checked: true,
      cellComponent: 'clicklist',
      cb: (record: any) => {
        if (record && record.file_url) {
          const url: string =
            'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + encodeURIComponent(record.file_url);
          window.open(url, '_blank');
        } else {
          notification.error({
            message: t('提示'),
            description: t('无对应附件'),
          });
        }
      },
      del: (record: any, text: any, file: any) => {
        if (file.file_id) {
          Modal.confirm({
            title: t('提示'),
            content: t('是否删除') + '[' + file.name + ']',
            icon: createVNode(ExclamationCircleOutlined),
            onOk() {
              return new Promise(resolve => {
                const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
                let ids: any = [];
                text.forEach((itme: any) => {
                  if (itme.file_id != file.file_id) {
                    ids.push(itme.file_id);
                  }
                });
                if (ids.length == 0) {
                  ids = 0;
                }
                upload_receipt_contract_files({
                  receipt_contract_id: record.id,
                  appendix_ids: ids,
                  org_id: current_org?.id,
                })
                  .then(() => {
                    message.success(t('删除成功'));
                    event_obj.emit('refresh');
                  })
                  .finally(() => {
                    resolve(null);
                  });
              });
            },
            onCancel() {},
          });
        }
      },
    },
    {
      title: t('邮件发送记录'),
      width: 150,
      dataIndex: 'email_record',
      checked: true,
    },
    {
      title: t('邮件发送状态'),
      width: 105,
      dataIndex: 'email_record__status',
      checked: true,
      content: (record: any) => {
        if (record.email_record__status == 1) {
          return t('发送成功');
        } else if (record.email_record__status == 2) {
          return t('发送失败');
        } else if (record.email_record__status == 0) {
          return t('已发送');
        }
      },
    },
    {
      title: t('邮件发送时间'),
      width: 130,
      dataIndex: 'email_record__update_time',
      checked: true,
      content: (record: any) => {
        return getDateTime(record.email_record__update_time);
      },
    },
    {
      title: t('CC签署日期'),
      width: 105,
      dataIndex: 'cc_sign_date',
      checked: true,
      content: (record: any) => {
        return getDate(record.cc_sign_date);
      },
    },
    {
      title: t('客户确认日期'),
      width: 105,
      dataIndex: 'confirm_date',
      checked: true,
      content: (record: any) => {
        return getDate(record.confirm_date);
      },
    },
    // {
    //   title: t('三方合同上传'),
    //   width: 105,
    //   dataIndex: 'is_three_upload',
    //   checked: true,
    //   cellComponent: 'boolean',
    //   name1: t('是'),
    //   name2: t('否'),
    // },
    // {
    //   title: t('双方合同上传'),
    //   width: 105,
    //   dataIndex: 'is_double_upload',
    //   checked: true,
    //   cellComponent: 'boolean',
    //   name1: t('是'),
    //   name2: t('否'),
    // },
    {
      title: t('创建时间'),
      width: 130,
      dataIndex: 'create_time',
      checked: true,
      content: (record: any) => {
        return getDateTime(record.create_time);
      },
    },
    {
      title: t('修改时间'),
      width: 130,
      dataIndex: 'update_time',
      checked: true,
      content: (record: any) => {
        return getDateTime(record.update_time);
      },
    },
    {
      title: t('是否可用'),
      width: 150,
      dataIndex: 'is_active',
      checked: true,
      cellComponent: 'boolean',
      name1: t('可用'),
      name2: t('禁用'),
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('可用'), value: true },
        { text: t('禁用'), value: false },
      ],
    },

    {
      title: t('操作'),
      width: 135,
      dataIndex: 'action',
      checked: true,
      cellComponent: 'action',
      fixed: 'right',
    },
  ];
  const newSlotList: any = [];
  Object.assign(newSlotList, slotList);
  newSlotList.push();

  const route = useRoute();
  search_config.model = reactive({ ...search_config.model });
  search_config.model.cc_company_id = route.query.cc_company_id
    ? route.query.cc_company_id
    : current_company?.id;
  search_config.model.cc_company_name = route.query.cc_company_name
    ? route.query.cc_company_name !== '全部公司'
      ? route.query.cc_company_name
      : ''
    : current_company?.name !== '全部公司'
    ? current_company?.name
    : '';
  search_config.model.ids = Number(route.query.ids);
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_receipt_contract_list,
    addData: create_receipt_contract,
    updateData: update_receipt_contract,
    removeData: delete_receipt_contract,
    recoverData: recover_receipt_contract,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
