import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import { resetPassword, updatePerson } from '@/api/permission/user-manage';
import modalBox from '@/components/form-modal/modal-tools';
import { createVNode, reactive } from 'vue';
import { message, Modal } from 'ant-design-vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';

export const event_obj = mitt();
export const btn_list = [
  {
    name: '修改',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      let role_ids: any = [];
      data.role_list.map((item: any) => {
        role_ids.push(item.id);
      });
      let group_ids: any = [];
      data.group_list.map((item: any) => {
        group_ids.push(item.id);
      });
      const editModal = reactive({
        visible: true,
        title: t('修改'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'remoteselect',
              name: 'aim_org_id',
              label: '目标组织',
              label_i18n: '目标组织',
              placeholder_i18n: '请选择目标组织',
              disabled: false,
              labelKey: 'name',
              valueKey: 'id',
              placeholder: '请填组织id',
              datasourceType: 'remote',
              datasource: 'aim_org_id',
              mode: 'default',
              modalType: 'select-org-id',
            },
            {
              type: 'input',
              name: 'username',
              label: '手机号',
              label_i18n: '手机号',
              placeholder_i18n: '请填手机号',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              maxlength: 100,
              defaultValue: '',
              placeholder: '请填手机号',
            },
            {
              type: 'input',
              name: 'realname',
              label: '真实姓名',
              label_i18n: '真实姓名',
              placeholder_i18n: '请填真实姓名',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 100,
              defaultValue: '',
              placeholder: '请填真实姓名',
            },
            {
              type: 'input',
              name: 'title',
              label: '职务',
              label_i18n: '职务',
              placeholder_i18n: '请填职务',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 100,
              defaultValue: '',
              placeholder: '请填职务',
            },
            {
              type: 'input',
              name: 'email',
              label: '电子邮箱',
              label_i18n: '电子邮箱',
              placeholder_i18n: '请填电子邮箱',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              maxlength: 100,
              defaultValue: '',
              placeholder: '请填电子邮箱',
            },
            {
              type: 'remoteselect',
              name: 'group_ids',
              label: '所属部门',
              label_i18n: '所属部门',
              placeholder_i18n: '请选择所属部门',
              disabled: false,
              labelKey: 'name',
              valueKey: 'id',
              placeholder: '请选择所属部门',
              datasource: '',
              mode: 'multiple',
              modalType: 'sys-query_group_by_org_list',
            },
            {
              type: 'remoteselect',
              name: 'role_ids',
              label: '角色',
              label_i18n: '角色',
              placeholder_i18n: '请选择角色',
              disabled: false,
              labelKey: 'name',
              valueKey: 'id',
              placeholder: '请选择角色',
              datasource: '',
              mode: 'multiple',
              modalType: 'sys-query_role_list',
            },
          ],
          rules: {},
          model: reactive({
            org_id: current_org?.id,
            aim_org_id: data.org_id,
            person_id: data.id,
            username: data.user__username,
            realname: data.realname,
            title: data.title,
            email: data.email,
            role_ids: role_ids,
            group_ids: group_ids,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            updatePerson({
              ...payload,
            })
              .then((res: any) => {
                message.success(t('修改成功'));
                Object.assign(data, res);
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '重置密码',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      Modal.confirm({
        title: t('是否重置密码') + '?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            resetPassword({
              org_id: current_org.id,
              username: record.user__username,
            }).then((res: any) => {
              message.success(t('重置成功'));
              resolve(null);
            });
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
