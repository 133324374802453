//API_IMPORT_START ====
import {
  create_custom_panel,
  delete_custom_panel,
  query_custom_panel_list,
  recover_custom_panel,
  update_custom_panel,
} from '@/api/sandbox-model';
//API_IMPORT_END
import { Record, SearchConfig } from '@/components/page-model/typing';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { btn_list, event_obj, head_btn_list } from '@/components/page-model/sandbox/sandbox-custompanel-config';
import column_config from '@/components/page-model/sandbox/sandbox-custompanel-column-config';
import edit_config from '@/components/page-model/sandbox/sandbox-custompanel-edit-config';
import update_config from '@/components/page-model/sandbox/sandbox-custompanel-update-config';

import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';

export default () => {
  const { t } = useI18n();

  const None = null;
  const True = true;
  const False = false;
  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  const { baseColumns, newSlotList } = column_config(current_org);
  const { edit_model_config, edit_model_config_options } = edit_config(current_org);
  const { update_model_config, update_model_config_options } = update_config(current_org);

  const search_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
      {
        type: 'input',
        name: 'name',
        label: '名称',
        label_i18n: t('名称'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写名称',
        placeholder_i18n: t('请填写名称'),
      },
      {
        type: 'input',
        name: 'modal_type',
        label: '查询表类型',
        label_i18n: t('查询表类型'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写查询表类型',
        placeholder_i18n: t('请填写查询表类型'),
      },
    ],
    rules: {},
    model: reactive({
      org_id: current_org?.id,
      is_active: true,
      name: null,
      modal_type: null,
    }),
  };
  const search_options = {
    is_active: [
      { value: None, name: t('全部') },
      { value: True, name: t('可用') },
      { value: False, name: t('禁用') },
    ],
  };

  const titleList = {
    title: t('首页自定义查询看板管理'),
    addModalTitle: t('新建'),
    updateTitle: t('修改'),
    is_create: true, // 是否有【新建】按钮
    is_update: true, // 是否有【修改】按钮
    is_remove: true, // 是否有【删除】按钮
    is_recover: true, // 是否有【恢复】按钮
  };

  const api_fields: Record = {
    update: {
      custompanel_id: 'id',
    },
    delete: {
      custompanel_id: 'id',
    },
    recover: {
      custompanel_id: 'id',
    },
    updateData: {
      org_id: 'org_id',
      custompanel_id: 'id',
    },
  };

  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_custom_panel_list,
    addData: create_custom_panel,
    updateData: update_custom_panel,
    removeData: delete_custom_panel,
    recoverData: recover_custom_panel,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
