import {
  create_c_c_email,
  delete_c_c_email,
  query_c_c_email_list,
  recover_c_c_email,
  update_c_c_email,
} from '@/api/baseinfo-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/baseinfo/baseinfo-ccemail-config';

import { reactive } from 'vue';
import { getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '账号',
      label_i18n: t('page.baseinfo.ccemail.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写账号',
      placeholder_i18n: t('page.baseinfo.ccemail.form.placeholder.name'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
  }),
};
const search_options = {};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '账号',
      label_i18n: t('page.baseinfo.ccemail.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写账号',
      placeholder_i18n: t('page.baseinfo.ccemail.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'email',
      label: '邮箱地址',
      label_i18n: t('page.baseinfo.ccemail.form.label.email'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写邮箱地址',
      placeholder_i18n: t('page.baseinfo.ccemail.form.placeholder.email'),
    },
    {
      type: 'input',
      name: 'password',
      label: '邮箱密码',
      label_i18n: t('page.baseinfo.ccemail.form.label.password'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写邮箱密码',
      placeholder_i18n: t('page.baseinfo.ccemail.form.placeholder.password'),
    },
    {
      type: 'input',
      name: 'smtp',
      label: '邮箱服务器',
      label_i18n: t('page.baseinfo.ccemail.form.label.smtp'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写邮箱服务器',
      placeholder_i18n: t('page.baseinfo.ccemail.form.placeholder.smtp'),
    },
    {
      type: 'input',
      name: 'port',
      label: '服务器端口号',
      label_i18n: t('page.baseinfo.ccemail.form.label.port'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写服务器端口号',
      placeholder_i18n: t('page.baseinfo.ccemail.form.placeholder.port'),
    },
    {
      type: 'remoteselect',
      name: 'cc_company_id',
      label: 'CC公司',
      label_i18n: t('CC公司'),
      disabled: false,
      placeholder: '请选择CC公司',
      placeholder_i18n: t('请选择CC公司'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-cccompany',
    },
  ],
  rules: {
    name: [{ required: true, message: t('page.baseinfo.ccemail.form.placeholder.name') }],
    email: [{ required: true, message: t('page.baseinfo.ccemail.form.placeholder.email') }],
    password: [{ required: true, message: t('page.baseinfo.ccemail.form.placeholder.password') }],
    smtp: [{ required: true, message: t('page.baseinfo.ccemail.form.placeholder.smtp') }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    email: null,
    password: null,
    smtp: null,
    port: null,
    cc_company_id: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '账号',
      label_i18n: t('page.baseinfo.ccemail.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写账号',
      placeholder_i18n: t('page.baseinfo.ccemail.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'email',
      label: '邮箱地址',
      label_i18n: t('page.baseinfo.ccemail.form.label.email'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写邮箱地址',
      placeholder_i18n: t('page.baseinfo.ccemail.form.placeholder.email'),
    },
    {
      type: 'input',
      name: 'password',
      label: '邮箱密码',
      label_i18n: t('page.baseinfo.ccemail.form.label.password'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写邮箱密码',
      placeholder_i18n: t('page.baseinfo.ccemail.form.placeholder.password'),
    },
    {
      type: 'input',
      name: 'smtp',
      label: '邮箱服务器',
      label_i18n: t('page.baseinfo.ccemail.form.label.smtp'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写邮箱服务器',
      placeholder_i18n: t('page.baseinfo.ccemail.form.placeholder.smtp'),
    },
    {
      type: 'input',
      name: 'port',
      label: '服务器端口号',
      label_i18n: t('page.baseinfo.ccemail.form.label.port'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写服务器端口号',
      placeholder_i18n: t('page.baseinfo.ccemail.form.placeholder.port'),
    },
    {
      type: 'remoteselect',
      name: 'cc_company_id',
      label: 'CC公司',
      label_i18n: t('CC公司'),
      disabled: false,
      placeholder: '请选择CC公司',
      placeholder_i18n: t('请选择CC公司'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-cccompany',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    email: null,
    password: null,
    smtp: null,
    port: null,
    cc_company_id: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('CC邮箱管理'),
  addModalTitle: t('新建'),
  updateTitle: t('修改CC邮箱'),
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    cc_email_id: 'id',
  },
  delete: {
    cc_email_id: 'id',
  },
  recover: {
    cc_email_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    cc_email_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.baseinfo.ccemail.column.title.index'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('page.baseinfo.ccemail.column.title.id'),
    dataIndex: 'id',
    width: 50,
    checked: false,
  },
  {
    title: t('page.baseinfo.ccemail.column.title.name'),
    dataIndex: 'name',
    width: 150,
    checked: true,
  },
  {
    title: t('page.baseinfo.ccemail.column.title.email'),
    dataIndex: 'email',
    checked: true,
    width: 150,
  },
  {
    title: t('page.baseinfo.ccemail.column.title.password'),
    dataIndex: 'password',
    checked: true,
    width: 150,
  },
  {
    title: t('page.baseinfo.ccemail.column.title.smtp'),
    dataIndex: 'smtp',
    checked: true,
    width: 150,
  },
  {
    title: t('page.baseinfo.ccemail.column.title.port'),
    dataIndex: 'port',
    checked: true,
    width: 150,
  },
  {
    title: t('CC公司'),
    dataIndex: 'cc_company__name',
    checked: true,
    width: 200,
  },
  {
    title: t('page.baseinfo.ccemail.column.title.create_time'),
    dataIndex: 'create_time',
    checked: true,
    width: 150,
    content: (record: any, text: any) => {
      return getDateTime(text);
    },
  },
  {
    title: t('page.baseinfo.ccemail.column.title.update_time'),
    dataIndex: 'update_time',
    width: 150,
    checked: true,
    content: (record: any, text: any) => {
      return getDateTime(text);
    },
  },
  {
    title: t('page.baseinfo.ccemail.column.title.is_active'),
    dataIndex: 'is_active',
    checked: true,
    width: 150,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },
  {
    title: t('page.baseinfo.ccemail.column.title.action'),
    width: 120,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  // slotName: 'is_active',
  field_true: '可用',
  field_false: '禁用',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_c_c_email_list,
    addData: create_c_c_email,
    updateData: update_c_c_email,
    removeData: delete_c_c_email,
    recoverData: recover_c_c_email,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
