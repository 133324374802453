import {
  create_c_c_company_template,
  delete_c_c_company_template,
  query_c_c_company_template_list,
  recover_c_c_company_template,
  update_c_c_company_template,
} from '@/api/baseinfo-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_COMPANY, STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/baseinfo/baseinfo-cccompany_contract-config';

import { reactive } from 'vue';
import { getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;
export default () => {
  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  const current_company = ls.get(STORAGE_CURRENT_COMPANY); // 当前组织org

  const { t } = useI18n();
  const search_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
      {
        type: 'input',
        name: 'cc_company_name',
        label: 'CC公司',
        label_i18n: t('CC公司'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写CC公司',
        placeholder_i18n: t('请填写CC公司'),
      },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    cc_company_id: current_company?.id,
    cc_company_name: current_company?.name !== '全部公司' ? current_company?.name : '',
  }),
};
const search_options = {};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: [5, 5, 5],
  },
  fields: [
    {
      type: 'remoteselect',
      name: 'cc_company_id',
      label: 'CC公司',
      label_i18n: t('CC公司'),
      disabled: false,
      placeholder: '请选择CC公司',
      placeholder_i18n: t('请选择CC公司'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-cccompany',
    },
    {
      type: 'remoteselect',
      name: 'recuitment_company_id',
      label: '顾问公司（用于查找选择请款邮箱地址）',
      label_i18n: t('顾问公司'),
      disabled: false,
      placeholder: '请选择顾问公司',
      placeholder_i18n: t('请选择顾问公司'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'company_name',
      valueKey: 'id',
      modalType: 'baseinfo-recuitmentcompany',
    },
    {
      type: 'remoteselect',
      name: 'recuitment_company_person_id',
      label: '请款邮箱地址',
      label_i18n: t('请款邮箱地址'),
      disabled: false,
      placeholder: '请选择请款邮箱地址',
      placeholder_i18n: t('请选择请款邮箱地址'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'name',
      valueKey: 'id',
      modalType: 'baseinfo-recuitmentcompanyperson1',
    },
    {
      type: 'remoteselect',
      name: 'invoice_email_template_id',
      label: '请款单邮箱模板',
      label_i18n: t('请款单邮箱模板'),
      disabled: false,
      placeholder: '请选择请款单邮箱模板',
      placeholder_i18n: t('请选择请款单邮箱模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'adviser_email_template_id',
      label: '请款时顾问提示邮箱模板',
      label_i18n: t('请款时顾问提示邮箱模板'),
      disabled: false,
      placeholder: '请选择请款时顾问提示邮箱模板',
      placeholder_i18n: t('请选择请款时顾问提示邮箱模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'contract_template_id',
      label: '合同模板',
      label_i18n: t('合同模板'),
      disabled: false,
      placeholder: '请选择合同模板',
      placeholder_i18n: t('请选择合同模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-contracttemplate',
    },
    {
      type: 'remoteselect',
      name: 'contract_email_template_id',
      label: '合同邮箱模板',
      label_i18n: t('合同邮箱模板'),
      disabled: false,
      placeholder: '请选择合同邮箱模板',
      placeholder_i18n: t('请选择合同邮箱模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'expiration_email_template_id',
      label: '合同到期提醒邮箱模板',
      label_i18n: t('合同到期提醒邮箱模板'),
      disabled: false,
      placeholder: '请选择合同到期提醒邮箱模板',
      placeholder_i18n: t('请选择合同到期提醒邮箱模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'sign_email_template_id',
      label: '签署合同确认邮箱模板',
      label_i18n: t('签署合同确认邮箱模板'),
      disabled: false,
      placeholder: '请选择签署合同确认邮箱模板',
      placeholder_i18n: t('请选择签署合同确认邮箱模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'payslip_email_template_id',
      label: '工资单邮箱模板',
      label_i18n: t('工资单邮箱模板'),
      disabled: false,
      placeholder: '请选择工资单邮箱模板',
      placeholder_i18n: t('请选择工资单邮箱模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'cc_email_id',
      label: 'CC邮箱',
      label_i18n: t('CC邮箱'),
      disabled: false,
      placeholder: '请选择CC邮箱',
      placeholder_i18n: t('请选择CC邮箱'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-ccemail',
    },
    {
      type: 'remoteselect',
      name: 'cc_expense_email_template_id',
      label: 'CC报销请款单邮箱模板',
      label_i18n: t('CC报销请款单邮箱模板'),
      disabled: false,
      placeholder: '请选择CC报销请款单邮箱模板',
      placeholder_i18n: t('请选择CC报销请款单邮箱模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'expense_email_template_id',
      label: '报销请款单邮箱模板',
      label_i18n: t('报销请款单邮箱模板'),
      disabled: false,
      placeholder: '请选择报销请款单邮箱模板',
      placeholder_i18n: t('请选择报销请款单邮箱模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'overtime_email_template_id',
      label: '加班请款单邮箱模板',
      label_i18n: t('加班请款单邮箱模板'),
      disabled: false,
      placeholder: '请选择加班请款单邮箱模板',
      placeholder_i18n: t('请选择加班请款单邮箱模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'cn_email_template_id',
      label: 'CN请款单邮箱模板',
      label_i18n: t('CN请款单邮箱模板'),
      disabled: false,
      placeholder: '请选择CN请款单邮箱模板',
      placeholder_i18n: t('请选择CN请款单邮箱模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
  ],
  rules: {
    cc_company_id: [{ required: true, message: t('请选择CC公司') }],
    recuitment_company_id: [{ required: true, message: t('请选择顾问公司') }],
    recuitment_company_person_id: [{ required: true, message: t('请选择请款邮箱地址') }],
    contract_template_id: [{ required: true, message: t('请选择合同模板') }],
    cc_email_id: [{ required: true, message: t('请选择CC邮箱') }],
    cc_expense_email_template_id: [{ required: true, message: t('请选择CC报销请款单邮箱模板') }],
    invoice_email_template_id: [{ required: true, message: t('请选择请款单邮箱模板') }],
    contract_email_template_id: [{ required: true, message: t('请选择合同邮箱模板') }],
    expense_email_template_id: [{ required: true, message: t('请选择报销请款单邮箱模板') }],
    expiration_email_template_id: [{ required: true, message: t('请选择合同到期提醒邮箱模板') }],
    adviser_email_template_id: [{ required: true, message: t('请选择请款时顾问提示邮箱模板') }],
    overtime_email_template_id: [{ required: true, message: t('请选择加班请款单邮箱模板') }],
    sign_email_template_id: [{ required: true, message: t('请选择签署合同确认邮箱模板') }],
    payslip_email_template_id: [{ required: true, message: t('请选择工资单邮箱模板') }],
    cn_email_template_id: [{ required: true, message: t('请选择CN请款单邮箱模板') }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    cc_company_id: null,
    recuitment_company_id: null,
    recuitment_company_person_id: null,
    contract_template_id: null,
    cc_email_id: null,
    cc_expense_email_template_id: null,
    invoice_email_template_id: null,
    contract_email_template_id: null,
    expense_email_template_id: null,
    expiration_email_template_id: null,
    adviser_email_template_id: null,
    overtime_email_template_id: null,
    sign_email_template_id: null,
    payslip_email_template_id: null,
    cn_email_template_id: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: [5, 5, 5],
  },
  fields: [
    {
      type: 'remoteselect',
      name: 'cc_company_id',
      label: 'CC公司',
      label_i18n: t('CC公司'),
      disabled: false,
      placeholder: '请选择CC公司',
      placeholder_i18n: t('请选择CC公司'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-cccompany',
    },
    {
      type: 'remoteselect',
      name: 'recuitment_company_id',
      label: '顾问公司（用于查找选择请款邮箱地址）',
      label_i18n: t('顾问公司'),
      disabled: false,
      placeholder: '请选择顾问公司',
      placeholder_i18n: t('请选择顾问公司'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'company_name',
      valueKey: 'id',
      modalType: 'baseinfo-recuitmentcompany',
    },
    {
      type: 'remoteselect',
      name: 'recuitment_company_person_id',
      label: '请款邮箱地址',
      label_i18n: t('请款邮箱地址'),
      disabled: false,
      placeholder: '请选择请款邮箱地址',
      placeholder_i18n: t('请选择请款邮箱地址'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      labelKey: 'name',
      valueKey: 'id',
      modalType: 'baseinfo-recuitmentcompanyperson1',
    },
    {
      type: 'remoteselect',
      name: 'invoice_email_template_id',
      label: '请款单邮箱模板',
      label_i18n: t('请款单邮箱模板'),
      disabled: false,
      placeholder: '请选择请款单邮箱模板',
      placeholder_i18n: t('请选择请款单邮箱模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'adviser_email_template_id',
      label: '请款时顾问提示邮箱模板',
      label_i18n: t('请款时顾问提示邮箱模板'),
      disabled: false,
      placeholder: '请选择请款时顾问提示邮箱模板',
      placeholder_i18n: t('请选择请款时顾问提示邮箱模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'contract_template_id',
      label: '合同模板',
      label_i18n: t('合同模板'),
      disabled: false,
      placeholder: '请选择合同模板',
      placeholder_i18n: t('请选择合同模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-contracttemplate',
    },
    {
      type: 'remoteselect',
      name: 'contract_email_template_id',
      label: '合同邮箱模板',
      label_i18n: t('合同邮箱模板'),
      disabled: false,
      placeholder: '请选择合同邮箱模板',
      placeholder_i18n: t('请选择合同邮箱模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'expiration_email_template_id',
      label: '合同到期提醒邮箱模板',
      label_i18n: t('合同到期提醒邮箱模板'),
      disabled: false,
      placeholder: '请选择合同到期提醒邮箱模板',
      placeholder_i18n: t('请选择合同到期提醒邮箱模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'sign_email_template_id',
      label: '签署合同确认邮箱模板',
      label_i18n: t('签署合同确认邮箱模板'),
      disabled: false,
      placeholder: '请选择签署合同确认邮箱模板',
      placeholder_i18n: t('请选择签署合同确认邮箱模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'payslip_email_template_id',
      label: '工资单邮箱模板',
      label_i18n: t('工资单邮箱模板'),
      disabled: false,
      placeholder: '请选择工资单邮箱模板',
      placeholder_i18n: t('请选择工资单邮箱模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'cc_email_id',
      label: 'CC邮箱',
      label_i18n: t('CC邮箱'),
      disabled: false,
      placeholder: '请选择CC邮箱',
      placeholder_i18n: t('请选择CC邮箱'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-ccemail',
    },
    {
      type: 'remoteselect',
      name: 'cc_expense_email_template_id',
      label: 'CC报销请款单邮箱模板',
      label_i18n: t('CC报销请款单邮箱模板'),
      disabled: false,
      placeholder: '请选择CC报销请款单邮箱模板',
      placeholder_i18n: t('请选择CC报销请款单邮箱模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'expense_email_template_id',
      label: '报销请款单邮箱模板',
      label_i18n: t('报销请款单邮箱模板'),
      disabled: false,
      placeholder: '请选择报销请款单邮箱模板',
      placeholder_i18n: t('请选择报销请款单邮箱模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'overtime_email_template_id',
      label: '加班请款单邮箱模板',
      label_i18n: t('加班请款单邮箱模板'),
      disabled: false,
      placeholder: '请选择加班请款单邮箱模板',
      placeholder_i18n: t('请选择加班请款单邮箱模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
    {
      type: 'remoteselect',
      name: 'cn_email_template_id',
      label: 'CN请款单邮箱模板',
      label_i18n: t('CN请款单邮箱模板'),
      disabled: false,
      placeholder: '请选择CN请款单邮箱模板',
      placeholder_i18n: t('请选择CN请款单邮箱模板'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-emailtemplate',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    cc_company_id: null,
    recuitment_company_id: null,
    recuitment_company_person_id: null,
    contract_template_id: null,
    cc_email_id: null,
    cc_expense_email_template_id: null,
    invoice_email_template_id: null,
    contract_email_template_id: null,
    expense_email_template_id: null,
    expiration_email_template_id: null,
    adviser_email_template_id: null,
    overtime_email_template_id: null,
    sign_email_template_id: null,
    payslip_email_template_id: null,
    cn_email_template_id: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: t('CC公司合同录入模板'),
  addModalTitle: t('新建'),
  updateTitle: t('修改'),
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    cc_company_template_id: 'id',
  },
  delete: {
    cc_company_template_id: 'id',
  },
  recover: {
    cc_company_template_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    cc_company_template_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.baseinfo.cccompany.column.title.index'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('page.baseinfo.cccompany.column.title.id'),
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('CC公司'),
    dataIndex: 'cc_company__name',
    checked: true,
    width: 200,
  },
  {
    title: t('请款邮箱地址'),
    dataIndex: 'recuitment_company_person__name',
    checked: true,
    width: 200,
  },
  {
    title: t('请款邮箱模板'),
    dataIndex: 'invoice_email_template__name',
    checked: true,
    width: 200,
  },
  {
    title: t('请款时顾问提示邮箱模板'),
    dataIndex: 'adviser_email_template__name',
    checked: true,
    width: 200,
  },
  {
    title: t('合同模板'),
    dataIndex: 'contract_template__name',
    checked: true,
    width: 200,
  },
  {
    title: t('合同邮箱模板'),
    dataIndex: 'contract_email_template__name',
    checked: true,
    width: 200,
  },
  {
    title: t('签署合同确认邮箱模板'),
    dataIndex: 'sign_email_template__name',
    checked: true,
    width: 200,
  },
  {
    title: t('合同到期提醒邮箱模板'),
    dataIndex: 'expiration_email_template__name',
    checked: true,
    width: 200,
  },
  {
    title: t('工资单邮箱模板'),
    dataIndex: 'payslip_email_template__name',
    checked: true,
    width: 200,
  },
  {
    title: t('CC邮箱'),
    dataIndex: 'cc_email__name',
    checked: true,
    width: 200,
  },
  {
    title: t('报销请款单邮箱模板'),
    dataIndex: 'expense_email_template__name',
    checked: true,
    width: 200,
  },
  {
    title: t('加班请款单邮箱模板'),
    dataIndex: 'overtime_email_template__name',
    checked: true,
    width: 200,
  },
  {
    title: t('CN请款单邮箱模板'),
    dataIndex: 'cn_email_template__name',
    checked: true,
    width: 200,
  },
  {
    title: t('cc报销请款单邮箱模板'),
    dataIndex: 'cc_expense_email_template__name',
    checked: true,
    width: 200,
  },
  {
    title: t('page.baseinfo.cccompany.column.title.update_time'),
    dataIndex: 'update_time',
    checked: true, content: (record: any) => {
      return getDateTime(record.update_time);
    },
    width: 150,
  },
  {
    title: t('page.baseinfo.cccompany.column.title.is_active'),
    dataIndex: 'is_active',
    checked: true,
    cellComponent: 'boolean',
    name1: t('可用'),
    name2: t('禁用'),
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
    width: 100,
  },
  {
    title: t('page.baseinfo.cccompany.column.title.action'),
    width: 120,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
  const newSlotList: any = [];
  Object.assign(newSlotList, slotList);
  newSlotList.push();


  const route = useRoute();
  search_config.model = reactive({ ...search_config.model });
  search_config.model.cc_company_id = route.query.cc_company_id ? route.query.cc_company_id : current_company?.id;
  search_config.model.cc_company_name = route.query.cc_company_name ? (route.query.cc_company_name !== '全部公司' ? route.query.cc_company_name : '') : (current_company?.name !== '全部公司' ? current_company?.name : '');
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_c_c_company_template_list,
    addData: create_c_c_company_template,
    updateData: update_c_c_company_template,
    removeData: delete_c_c_company_template,
    recoverData: recover_c_c_company_template,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
