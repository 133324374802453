//API_IMPORT_START ====
import {
  create_service,
  delete_service,
  query_service_list,
  recover_service,
  update_service,
} from '@/api/service-model';
//API_IMPORT_END
import { Record, SearchConfig } from '@/components/page-model/typing';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY, STORAGE_CURRENT_SERVICE_KEY } from '@/store/mutation-type';
import { btn_list, event_obj, head_btn_list } from '@/components/page-model/service/service-service-config';
import column_config from '@/components/page-model/service/service-service-column-config';
import edit_config from '@/components/page-model/service/service-service-edit-config';
import update_config from '@/components/page-model/service/service-service-update-config';
import { useI18n } from 'vue-i18n';

import { reactive } from 'vue';

export default () => {
  const { t } = useI18n();
  const None = null;
  const True = true;
  const False = false;
  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  const service = ls.get(STORAGE_CURRENT_SERVICE_KEY); // 当前客服
  const { baseColumns, newSlotList } = column_config(current_org);
  const { edit_model_config, edit_model_config_options } = edit_config(current_org, t);
  const { update_model_config, update_model_config_options } = update_config(current_org, t);

  const search_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
      {
        type: 'input',
        name: 'name',
        label: '客服名称',
        label_i18n: '客服名称',
        placeholder_i18n: '请填写客服名称',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写客服名称',
      },
    ],
    rules: {},
    model: reactive({
      org_id: current_org?.id,
      is_active: true,
      ids: [service?.id],
      name: null,
    }),
  };
  const search_options = {};

  const titleList = {
    title: t('客服人员'),
    addModalTitle: t('新建客服人员'),
    updateTitle: t('修改客服人员'),
    is_create: !service?.id, // 是否有【新建】按钮
    is_update: !service?.id, // 是否有【修改】按钮
    is_remove: !service?.id, // 是否有【删除】按钮
    is_recover: !service?.id, // 是否有【恢复】按钮
  };

  const api_fields: Record = {
    update: {
      service_id: 'id',
    },
    delete: {
      service_id: 'id',
    },
    recover: {
      service_id: 'id',
    },
    updateData: {
      org_id: 'org_id',
      service_id: 'id',
    },
  };

  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_service_list,
    addData: create_service,
    updateData: update_service,
    removeData: delete_service,
    recoverData: recover_service,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
