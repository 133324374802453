import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';

import { message, notification } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import { reactive, ref } from 'vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { update_contract_template } from '@/api/baseinfo-model';

export const event_obj = mitt();
export const btn_list = [
  {
    name: '上传模板',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('上传模板'),
        group: '合同模板',
        type: 'picture',
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          if (payload.length > 1) {
            notification.error({
              message: t('提示'),
              description: t('只能上传一个模板，默认上传第一个'),
            });
          }
          return new Promise(resolve => {
            loading.value = true;
            update_contract_template({
              contracttemplate_id: data.id,
              nsfile_id: payload[0],
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('添加成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  // {
  //   name: '上传签章合同模板',
  //   type: 'dropdown',
  //   clazz: 'primary',
  //   cb: (data: any) => {
  //     const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  //     const loading = ref(false);
  //     const editModal = reactive({
  //       visible: true,
  //       title: '上传签章合同模板',
  //       group: '合同模板',
  //       type: 'picture',
  //       component: 'upload-image-modal',
  //       loading: loading,
  //       ok: (payload: any) => {
  //         if (payload.length > 1) {
  //           notification.error({
  //             message: t('提示'),
  //             description: '只能上传一个模板，默认上传第一个',
  //           });
  //         }
  //         return new Promise(resolve => {
  //           loading.value = true;
  //           update_contract_template({
  //             contracttemplate_id: data.id,
  //             sign_nsfile_id: payload[0],
  //             org_id: current_org?.id,
  //           })
  //             .then(() => {
  //               message.success(t('添加成功'));
  //               event_obj.emit('refresh');
  //               resolve(null);
  //             })
  //             .finally(() => {
  //               loading.value = false;
  //             });
  //         });
  //       },
  //     });
  //     modalBox(editModal);
  //   },
  // },
  // {
  //   name: '模板预览',
  //   type: 'dropdown',
  //   is_show: (record: any) => {
  //     return record.template_id != null;
  //   },
  //   cb: (record: any) => {
  //     const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  //     notification.success({
  //       message: '数据加载中',
  //       description: '请稍后',
  //     });
  //     view_contract_template_pdf({ org_id: current_org.id, contracttemplate_id: record.id }).then(
  //       (res: any) => {
  //         console.log('res', res);
  //         if (res && res.file_url) {
  //           // window.open('http://erp-cc.oss-cn-hongkong.aliyuncs.com/' + res.file_url, '_blank');
  //           axios.get('/' + res.file_url,{
  //             responseType: 'blob' }).then((res: any) => {
  //             var blob = res.data;
  //             // FileReader主要用于将文件内容读入内存
  //             var reader = new FileReader();
  //             reader.readAsDataURL(blob);
  //             // onload当读取操作成功完成时调用
  //             reader.onload = (e: any) => {
  //               var a = document.createElement('a');
  //               // 获取文件名fileName
  //               var fileName = res.config["url"].split("/");
  //               fileName = fileName[fileName.length - 1];
  //               // fileName = fileName.replace(/"/g, "");
  //               a.download = fileName;
  //               a.href = e.target.result;
  //               document.body.appendChild(a);
  //               a.click();
  //               document.body.removeChild(a);
  //             }
  //           })
  //         } else {
  //           notification.error({
  //             message: t('提示'),
  //             description: '没有对应模板',
  //           });
  //         }
  //       },
  //     );
  //   },
  // },
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        params: {
          object_id: record.id,
          note_type_flag: 'baseinfo.contracttemplate',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
