import { query_social_insurance_list } from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/cerp/cerp-social_insurance-config';

import { reactive } from 'vue';
import { getDate, getDateTime } from '@/utils/function';

export default () => {
  const None = null;
  const True = true;
  const False = false;

  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

  const { t } = useI18n();
  const search_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
      {
        type: 'remotemodal',
        name: 'adviser_id',
        label: '隶属顾问',
        label_i18n: '隶属顾问',
        placeholder_i18n: '请选择隶属顾问',
        disabled: false,
        defaultValue: '',
        allowClear: true,
        labelKey: 'full_name',
        valueKey: 'id',
        placeholder: '隶属顾问',
        show_search: false,
        maxTagCount: 5,
        mode: 'default',
        title: t('选择隶属顾问'),
        modalType: 'cerp-adviser',
      },
      {
        type: 'select',
        name: 'household_type',
        label: '户籍类型',
        label_i18n: '户籍类型',
        placeholder_i18n: '请选择户籍类型',
        mode: 'default',
        disabled: false,
        allowClear: true,
        defaultValue: '',
        labelKey: 'name',
        valueKey: 'value',
        placeholder: '请选择户籍类型',
        datasource: 'household_type',
      },
    ],
    rules: {},
    model: reactive({
      org_id: current_org?.id,
      is_active: true,
      adviser_id: null,
      household_type: null,
    }),
  };
  const search_options = {
    household_type: [
      { value: 0, name: t('本地城镇') },
      { value: 1, name: t('本地农村') },
      { value: 2, name: t('外地城镇') },
      { value: 3, name: t('外地农村') },
    ],
  };

  const edit_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [],
    rules: {},
    model: reactive({}),
  };
  const edit_model_config_options = {};

  const update_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [],
    rules: {},
    model: reactive({}),
  };
  const update_model_config_options = {};

  const titleList = {
    title: t('社保菜单'),
    addModalTitle: '',
    updateTitle: '',
    is_create: false, // 是否有【新建】按钮
    is_update: false, // 是否有【修改】按钮
    is_remove: false, // 是否有【删除】按钮
    is_recover: false, // 是否有【恢复】按钮
  };

  const api_fields: Record = {
    update: {
      address_id: 'id',
    },
    delete: {
      address_id: 'id',
    },
    recover: {
      address_id: 'id',
    },
    updateData: {
      org_id: 'org_id',
      address_id: 'id',
    },
  };

  const baseColumns: TableColumn[] = [
    {
      title: t('序号'),
      width: 50,
      dataIndex: 'index',
      checked: true,
    },
    {
      title: t('顾问'),
      minWidth: 150,
      dataIndex: 'adviser__full_name',
      checked: true,
    },
    {
      title: t('工号'),
      width: 100,
      dataIndex: 'adviser__employee_number',
      checked: true,
    },
    {
      title: t('入职日期'),
      width: 150,
      dataIndex: 'join_date',
      checked: true,
      content: (record: any) => {
        return getDate(record.join_date);
      },
    },
    {
      title: t('起保月份'),
      width: 150,
      dataIndex: 'start_date',
      checked: true,
      cellComponent: 'month',
    },
    {
      title: t('缴纳城市'),
      width: 150,
      dataIndex: 'city',
      checked: true,
    },
    {
      title: t('户籍类型'),
      width: 120,
      dataIndex: 'household_type',
      checked: true,
      content: (record: any) => {
        let color = '';
        switch (record.household_type) {
          case 0:
            color = t('本地城镇');
            break;
          case 1:
            color = t('本地农村');
            break;
          case 2:
            color = t('外地城镇');
            break;
          case 3:
            color = t('外地农村');
            break;
          default:
            color = '';
            break;
        }
        return color;
      },
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('本地城镇'), value: 0 },
        { text: t('本地农村'), value: 1 },
        { text: t('外地城镇'), value: 2 },
        { text: t('外地农村'), value: 3 },
      ],
    },
    {
      title: t('缴费项目'),
      width: 150,
      dataIndex: 'payment_items',
      checked: true,
      cellComponent: 'list',
    },
    {
      title: t('创建时间'),
      width: 150,
      dataIndex: 'create_time',
      checked: true,
      content: (record: any) => {
        return getDateTime(record.create_time);
      },
    },
    {
      title: t('更新时间'),
      width: 150,
      dataIndex: 'update_time',
      checked: true,
      content: (record: any) => {
        return getDateTime(record.update_time);
      },
    },
    {
      title: t('page.cerp.address.column.title.is_active'),
      width: 150,
      dataIndex: 'is_active',
      checked: true,
      cellComponent: 'boolean',
      name1: t('可用'),
      name2: t('禁用'),
      filterMultiple: false,
      filters: [
        { text: t('全部'), value: '' },
        { text: t('可用'), value: true },
        { text: t('禁用'), value: false },
      ],
    },
  ];
  const newSlotList: any = [];
  Object.assign(newSlotList, slotList);
  newSlotList.push({
    type: 'custom',
    slotName: 'household_type',
    field_0: '本地城镇',
    field_1: '本地农村',
    field_2: '外地城镇',
    field_3: '外地农村',
  });

  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_social_insurance_list,
    addData: null,
    updateData: null,
    removeData: null,
    recoverData: null,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
