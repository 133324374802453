import {
  create_api_info,
  delete_api_info,
  query_api_info_list,
  recover_api_info,
  update_api_info,
} from '@/api/apidoc-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model/apidoc/apidoc-apiinfo-config';

import { reactive } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '接口名称',
      label_i18n: '接口名称',
      placeholder_i18n: '请填写接口名称',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写接口名称',
    },
    {
      type: 'input',
      name: 'path',
      label: 'url路径',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写url路径',
    },
    {
      type: 'input',
      name: 'info',
      label: '接口描述',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写接口描述',
    },
    {
      type: 'remoteselect',
      name: 'app_id',
      label: '隶属app',
      disabled: false,
      placeholder: '请选择隶属app',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'apidoc-app',
    },
    {},
    {
      type: 'remoteselect',
      name: 'git_branch_id',
      label: 'git的分支名',
      disabled: false,
      placeholder: '请选择git的分支名',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'apidoc-gitbranch',
    },
    {
      type: 'input',
      name: 'select_flag',
      label: 'Select标识',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写Select标识',
    },
    {
      type: 'input',
      name: 'select_value_field',
      label: 'Select值字段',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写Select值字段',
    },
    {
      type: 'input',
      name: 'select_label_field',
      label: 'Select显示字段',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写Select显示字段',
    },
    {
      type: 'input',
      name: 'modal_flag',
      label: 'Modal标识',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写Modal标识',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    path: null,
    info: null,
    app_id: null,
    project_id: null,
    git_branch_id: null,
    http_method: null,
    is_org: null,
    is_login: null,
    is_permission: null,
    is_modefy: null,
    is_select: null,
    select_flag: null,
    select_value_field: null,
    select_label_field: null,
    is_modal: null,
    modal_flag: null,
  }),
};
const search_options = {
  http_method: [
    { value: None, name: '全部' },
    { value: 'GET', name: 'GET' },
    { value: 'POST', name: 'POST' },
  ],
  is_org: [
    { value: None, name: '全部' },
    { value: True, name: '有org_id' },
    { value: False, name: '无org_id' },
  ],
  is_login: [
    { value: None, name: '全部' },
    { value: True, name: '有org_id' },
    { value: False, name: '无org_id' },
  ],
  is_permission: [
    { value: None, name: '全部' },
    { value: True, name: '需要权限' },
    { value: False, name: '不校验权限' },
  ],
  is_modefy: [
    { value: None, name: '全部' },
    { value: True, name: '允许代码修改' },
    { value: False, name: '不允许代码修改' },
  ],
  is_select: [
    { value: None, name: '全部' },
    { value: True, name: '需要RemoteSelect' },
    { value: False, name: '不需要RemoteSelect' },
  ],
  is_modal: [
    { value: None, name: '全部' },
    { value: True, name: '需要RemoteModal' },
    { value: False, name: '不需要RemoteModal' },
  ],
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '接口名称',
      label_i18n: '接口名称',
      placeholder_i18n: '请填写接口名称',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写接口名称',
    },
    {
      type: 'input',
      name: 'path',
      label: 'url路径',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写url路径',
    },
    {
      type: 'input',
      name: 'info',
      label: '接口描述',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写接口描述',
    },
    {
      type: 'remoteselect',
      name: 'git_branch_id',
      label: 'git的分支名',
      disabled: false,
      placeholder: '请选择git的分支名',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'apidoc-gitbranch',
    },
    {
      type: 'remoteselect',
      name: 'app_id',
      label: '隶属app',
      disabled: false,
      placeholder: '请选择隶属app',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'apidoc-app',
    },
    {
      type: 'radio',
      name: 'http_method',
      label: '默认调用方法',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择默认调用方法',
    },
    {
      type: 'radio',
      name: 'is_org',
      label: '是否org_id必填',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否org_id必填',
    },
    {
      type: 'radio',
      name: 'is_login',
      label: '是否需先登录',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否需先登录',
    },
    {
      type: 'radio',
      name: 'is_permission',
      label: '是否权限校验',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否权限校验',
    },
    {
      type: 'radio',
      name: 'is_modefy',
      label: '是否允许代码自动修改',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否允许代码自动修改',
    },
    {
      type: 'radio',
      name: 'is_select',
      label: '是否创建Select',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否创建Select',
    },
    {
      type: 'input',
      name: 'select_flag',
      label: 'Select标识',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写Select标识',
    },
    {
      type: 'input',
      name: 'select_value_field',
      label: 'Select值字段',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写Select值字段',
    },
    {
      type: 'input',
      name: 'select_label_field',
      label: 'Select显示字段',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写Select显示字段',
    },
    {
      type: 'radio',
      name: 'is_modal',
      label: '是否创建Modal',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否创建Modal',
    },
    {
      type: 'input',
      name: 'modal_flag',
      label: 'Modal标识',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写Modal标识',
    },
    {
      type: 'input',
      name: 'select_watch',
      label: 'Select监听字段',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写Select监听字段',
    },
  ],
  rules: {
    name: [{ required: true, message: '请填输入接口名称' }],
    path: [{ required: true, message: '请填输入url路径' }],
    git_branch_id: [{ required: true, message: '请填输入git的分支名' }],
    app_id: [{ required: true, message: '请填输入隶属app' }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    path: null,
    info: null,
    git_branch_id: null,
    app_id: null,
    http_method: null,
    is_org: null,
    is_login: null,
    is_permission: null,
    is_modefy: null,
    is_select: null,
    select_flag: null,
    select_value_field: null,
    select_label_field: null,
    is_modal: null,
    modal_flag: null,
    select_watch: null,
  }),
};
const edit_model_config_options = {
  http_method: [
    { value: 'GET', name: 'GET' },
    { value: 'POST', name: 'POST' },
  ],
  is_org: [
    { value: True, name: '有org_id' },
    { value: False, name: '无org_id' },
  ],
  is_login: [
    { value: True, name: '有org_id' },
    { value: False, name: '无org_id' },
  ],
  is_permission: [
    { value: True, name: '需要权限' },
    { value: False, name: '不校验权限' },
  ],
  is_modefy: [
    { value: True, name: '允许代码修改' },
    { value: False, name: '不允许代码修改' },
  ],
  is_select: [
    { value: True, name: '需要RemoteSelect' },
    { value: False, name: '不需要RemoteSelect' },
  ],
  is_modal: [
    { value: True, name: '需要RemoteModal' },
    { value: False, name: '不需要RemoteModal' },
  ],
};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '接口名称',
      label_i18n: '接口名称',
      placeholder_i18n: '请填写接口名称',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写接口名称',
    },
    {
      type: 'input',
      name: 'path',
      label: 'url路径',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写url路径',
    },
    {
      type: 'input',
      name: 'info',
      label: '接口描述',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写接口描述',
    },
    {
      type: 'remoteselect',
      name: 'git_branch_id',
      label: 'git的分支名',
      disabled: false,
      placeholder: '请选择git的分支名',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'apidoc-gitbranch',
    },
    {
      type: 'remoteselect',
      name: 'app_id',
      label: '隶属app',
      disabled: false,
      placeholder: '请选择隶属app',
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'apidoc-app',
    },
    {
      type: 'radio',
      name: 'http_method',
      label: '默认调用方法',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择默认调用方法',
    },
    {
      type: 'radio',
      name: 'is_org',
      label: '是否org_id必填',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否org_id必填',
    },
    {
      type: 'radio',
      name: 'is_login',
      label: '是否需先登录',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否需先登录',
    },
    {
      type: 'radio',
      name: 'is_permission',
      label: '是否权限校验',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否权限校验',
    },
    {
      type: 'radio',
      name: 'is_modefy',
      label: '是否允许代码自动修改',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否允许代码自动修改',
    },
    {
      type: 'radio',
      name: 'is_select',
      label: '是否创建Select',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否创建Select',
    },
    {
      type: 'input',
      name: 'select_flag',
      label: 'Select标识',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写Select标识',
    },
    {
      type: 'input',
      name: 'select_value_field',
      label: 'Select值字段',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写Select值字段',
    },
    {
      type: 'input',
      name: 'select_label_field',
      label: 'Select显示字段',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写Select显示字段',
    },
    {
      type: 'radio',
      name: 'is_modal',
      label: '是否创建Modal',
      disabled: false,
      allowClear: true,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
      placeholder: '请选择是否创建Modal',
    },
    {
      type: 'input',
      name: 'modal_flag',
      label: 'Modal标识',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写Modal标识',
    },
    {
      type: 'input',
      name: 'select_watch',
      label: 'Select监听字段',
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写Select监听字段',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    name: null,
    path: null,
    info: null,
    git_branch_id: null,
    app_id: null,
    http_method: null,
    is_org: null,
    is_login: null,
    is_permission: null,
    is_modefy: null,
    is_select: null,
    select_flag: null,
    select_value_field: null,
    select_label_field: null,
    is_modal: null,
    modal_flag: null,
    select_watch: null,
  }),
};
const update_model_config_options = {
  http_method: [
    { value: 'GET', name: 'GET' },
    { value: 'POST', name: 'POST' },
  ],
  is_org: [
    { value: True, name: '有org_id' },
    { value: False, name: '无org_id' },
  ],
  is_login: [
    { value: True, name: '有org_id' },
    { value: False, name: '无org_id' },
  ],
  is_permission: [
    { value: True, name: '需要权限' },
    { value: False, name: '不校验权限' },
  ],
  is_modefy: [
    { value: True, name: '允许代码修改' },
    { value: False, name: '不允许代码修改' },
  ],
  is_select: [
    { value: True, name: '需要RemoteSelect' },
    { value: False, name: '不需要RemoteSelect' },
  ],
  is_modal: [
    { value: True, name: '需要RemoteModal' },
    { value: False, name: '不需要RemoteModal' },
  ],
};

const titleList = {
  title: '接口管理',
  addModalTitle: '新建接口',
  updateTitle: '修改接口',
  is_create: true, // 是否有【新建】按钮
  is_update: true, // 是否有【修改】按钮
  is_remove: true, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    apiinfo_id: 'id',
  },
  delete: {
    apiinfo_id: 'id',
  },
  recover: {
    apiinfo_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    apiinfo_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('序号'),
    dataIndex: 'index',
    width: 50,
    slots: { customRender: 'index' },
  },
  {
    title: '项目主键',
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: '接口名称',
    width: 150,
    dataIndex: 'name',
    checked: true,
  },
  {
    title: 'url路径',
    width: 150,
    dataIndex: 'path',
    checked: true,
  },
  {
    title: 'git的分支名',
    width: 150,
    dataIndex: 'git_branch_id',
    checked: true,
  },
  {
    title: '分支名称',
    width: 150,
    dataIndex: 'git_branch__name',
    checked: true,
  },
  {
    title: 'git项目',
    width: 150,
    dataIndex: 'git_branch__project_id',
    checked: true,
  },
  {
    title: 'git项目名',
    width: 150,
    dataIndex: 'git_branch__project__name',
    checked: true,
  },
  {
    title: '隶属app',
    width: 150,
    dataIndex: 'app_id',
    checked: true,
  },
  {
    title: '应用名称',
    width: 150,
    dataIndex: 'app__name',
    checked: true,
  },
  {
    title: '默认调用方法',
    width: 150,
    dataIndex: 'http_method',
    checked: true,

    slots: { customRender: 'http_method' },
    filterMultiple: true,
    filters: [
      { text: t('全部'), value: '' },
      { text: 'GET', value: 'GET' },
      { text: 'POST', value: 'POST' },
    ],
  },
  {
    title: '是否org_id必填',
    width: 150,
    dataIndex: 'is_org',
    checked: true,

    slots: { customRender: 'is_org' },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: '有org_id', value: true },
      { text: '无org_id', value: false },
    ],
  },
  {
    title: '是否需先登录',
    width: 150,
    dataIndex: 'is_login',
    checked: true,

    slots: { customRender: 'is_login' },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: '有org_id', value: true },
      { text: '无org_id', value: false },
    ],
  },
  {
    title: '是否权限校验',
    width: 150,
    dataIndex: 'is_permission',
    checked: true,

    slots: { customRender: 'is_permission' },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: '需要权限', value: true },
      { text: '不校验权限', value: false },
    ],
  },
  {
    title: '是否允许代码自动修改',
    width: 150,
    dataIndex: 'is_modefy',
    checked: true,

    slots: { customRender: 'is_modefy' },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: '允许代码修改', value: true },
      { text: '不允许代码修改', value: false },
    ],
  },
  {
    title: '是否创建Select',
    width: 150,
    dataIndex: 'is_select',
    checked: true,

    slots: { customRender: 'is_select' },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: '需要RemoteSelect', value: true },
      { text: '不需要RemoteSelect', value: false },
    ],
  },
  {
    title: 'Select标识',
    width: 150,
    dataIndex: 'select_flag',
    checked: true,
  },
  {
    title: 'Select值字段',
    width: 150,
    dataIndex: 'select_value_field',
    checked: true,
  },
  {
    title: 'Select显示字段',
    width: 150,
    dataIndex: 'select_label_field',
    checked: true,
  },
  {
    title: '是否创建Modal',
    width: 150,
    dataIndex: 'is_modal',
    checked: true,

    slots: { customRender: 'is_modal' },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: '需要RemoteModal', value: true },
      { text: '不需要RemoteModal', value: false },
    ],
  },
  {
    title: 'Modal标识',
    width: 150,
    dataIndex: 'modal_flag',
    checked: true,
  },
  {
    title: 'Select监听字段',
    width: 150,
    dataIndex: 'select_watch',
    checked: true,
  },
  {
    title: t('创建时间'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,

    slots: { customRender: 'datetime' },
  },
  {
    title: '更新日期',
    width: 150,
    dataIndex: 'update_time',
    checked: false,

    slots: { customRender: 'datetime' },
  },
  {
    title: t('是否可用'),
    width: 150,
    dataIndex: 'is_active',
    checked: true,

    slots: { customRender: 'is_active' },
    filterMultiple: false,
    filters: [
      { text: t('全部'), value: '' },
      { text: t('可用'), value: true },
      { text: t('禁用'), value: false },
    ],
  },
  {
    title: '接口描述',
    width: 150,
    dataIndex: 'info',
    checked: true,
  },

  {
    title: t('操作'),
    width: 200,
    dataIndex: 'action',
    checked: false,
    slots: { customRender: 'action' },
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push(
  { type: 'custom', slotName: 'http_method', field_GET: 'GET', field_POST: 'POST' },
  { type: 'custom', slotName: 'is_org', field_true: '有org_id', field_false: '无org_id' },
  { type: 'custom', slotName: 'is_login', field_true: '有org_id', field_false: '无org_id' },
  { type: 'custom', slotName: 'is_permission', field_true: '需要权限', field_false: '不校验权限' },
  {
    type: 'custom',
    slotName: 'is_modefy',
    field_true: '允许代码修改',
    field_false: '不允许代码修改',
  },
  {
    type: 'custom',
    slotName: 'is_select',
    field_true: '需要RemoteSelect',
    field_false: '不需要RemoteSelect',
  },
  {
    type: 'custom',
    slotName: 'is_modal',
    field_true: '需要RemoteModal',
    field_false: '不需要RemoteModal',
  },
  { type: 'custom', field_true: '可用', field_false: '禁用' },
);

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_api_info_list,
    addData: create_api_info,
    updateData: update_api_info,
    removeData: delete_api_info,
    recoverData: recover_api_info,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
