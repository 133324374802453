import { reactive } from 'vue';
import { config } from '@/components/page-model/service/service-service-config';
import { query_user_list } from '@/api/sys-model';

export default (current_org: any, t: any) => {
  const None = null;
  const True = true;
  const False = false;

  const edit_model_config = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [
      {
        type: 'remoteselect',
        name: 'user_id',
        label: '用户',
        disabled: false,
        defaultValue: '',
        labelKey: 'realname',
        valueKey: 'user_id',
        placeholder: '用户',
        show_search: false,
        maxTagCount: 5,
        mode: 'default',
        title: '选择用户',
        modalType: 'sys-user_for_service_list',
      },
      {
        type: 'input',
        name: 'name',
        label: '客服名称',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写客服名称',
      },
      {
        type: 'radio',
        name: 'status',
        label: '状态',
        label_i18n: '状态',
        mode: 'default',
        disabled: false,
        defaultValue: '',
        labelKey: 'name',
        valueKey: 'value',
        datasource: 'status',
      },
      {
        type: 'radio',
        name: 'is_leader',
        label: '是否领班',
        mode: 'default',
        disabled: false,
        defaultValue: '',
        labelKey: 'name',
        valueKey: 'value',
        datasource: 'is_leader',
      },
      {
        type: 'textarea',
        name: 'description',
        label: '描述',
        label_i18n: '描述',
        placeholder_i18n: '请填描述',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写描述',
      },
      {
        type: 'input',
        name: 'email',
        label: '邮箱地址',
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写邮箱地址',
      },
      {
        type: 'remoteselect',
        name: 'email_template_id',
        label: '邮箱模板',
        disabled: false,
        placeholder: '请选择请邮箱模板',
        datasourceType: 'remote',
        mode: 'default',
        allowClear: true,
        modalType: 'baseinfo-emailtemplate',
      },
    ],
    watch: {
      user_id: (data: any) => {
        query_user_list({
          org_id: current_org?.id,
          user_ids: data['user_id'],
        }).then((res: any) => {
          data['email'] = res.data[0].email;
          data['name'] = res.data[0].realname;
        });
      },
    },
    rules: {
      name: [{ required: true, message: '请输入客服名称' }],
    },
    model: reactive({
      org_id: current_org?.id,
      name: null,
      is_active: true,
      description: null,
      user_id: null,
      email: null,
      email_template_id: null,
      status: 2,
      is_leader: false,
    }),
  };
  const edit_model_config_options = {
    status: [
      { value: 1, name: '上班' },
      { value: 2, name: '下班' },
    ],
    is_leader: [
      { value: true, name: t('是') },
      { value: false, name: t('否') },
    ]
  };

  return {
    edit_model_config: edit_model_config,
    edit_model_config_options: edit_model_config_options,
  };
};
