import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';

import modalBox from '@/components/form-modal/modal-tools';
import { reactive, ref } from 'vue';
import { message } from 'ant-design-vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { update_notice, update_notice_tags } from '@/api/baseinfo-model';
import { useRouter } from 'vue-router';
import { notification } from 'ant-design-vue/es';

export const event_obj = mitt();
const router = useRouter();
const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
export const btn_list = [
  {
    name: '上传PDF',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const loading = ref(false);
      const editModal = reactive({
        visible: true,
        title: t('上传模板'),
        group: '重要提示',
        type: 'picture',
        filetype: ['pdf'],
        component: 'upload-image-modal',
        loading: loading,
        ok: (payload: any) => {
          if (payload.length > 1) {
            notification.error({
              message: t('提示'),
              description: t('只能上传一个模板，默认上传第一个'),
            });
          }
          return new Promise(resolve => {
            loading.value = true;
            update_notice({
              notice_id: data.id,
              appendix_ids: [payload[0]],
              org_id: current_org?.id,
            })
              .then(() => {
                message.success(t('添加成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                loading.value = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '详情',
    type: 'dropdown',
    clazz: 'default',
    cb: (data: any) => {
      router.push({
        path: '/static-info-manage/baseinfo-notice/' + data.id + '/',
        query: { customTitle: data.title },
      });
    },
  },
  {
    name: '修改',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      const editModal = reactive({
        visible: true,
        title: t('修改'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'title',
              label: '标题',
              label_i18n: '标题',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写标题',
              placeholder_i18n: '请填写标题',
            },
            {
              type: 'radio',
              name: 'notice_type',
              label: '类型',
              label_i18n: '类型',
              disabled: false,
              labelKey: 'name',
              valueKey: 'value',
              defaultValue: '',
            },
            {
              type: 'input',
              name: 'sorted_index',
              label: '排序',
              label_i18n: '排序',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写排序',
              placeholder_i18n: '请填写排序',
            },
          ],
          rules: {
            title: [{ username: true, message: t('请填写标题') }],
          },
          model: reactive({
            org_id: current_org?.id,
            is_active: true,
            notice_id: data.id,
            title: data.title,
            notice_type: data.notice_type,
            sorted_index: data.sorted_index,
          }),
        },
        options: {
          notice_type: [
            { value: 0, name: t('公司公告') },
            { value: 1, name: t('重要信息') },
          ],
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            update_notice({
              ...payload,
            })
              .then((res: any) => {
                message.success(t('修改成功'));
                Object.assign(data, res);
                event_obj.emit('refresh_data');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  {
    name: '修改标签',
    type: 'dropdown',
    clazz: 'primary',
    cb: (data: any, dataSource: any, index: any, requestParams: any, t: any) => {
      let list: any = [];
      if (data.tags && data.tags.length > 0) {
        data.tags.map((item: any) => {
          list.push(item.id);
        });
      }
      const editModal = reactive({
        visible: true,
        title: t('修改标签'),
        loading: false,
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'remoteselect',
              name: 'tag_ids',
              label: '标签',
              label_i18n: '标签',
              disabled: false,
              placeholder: '请选择标签',
              placeholder_i18n: '请选择标签',
              datasourceType: 'remote',
              mode: 'multiple',
              allowClear: true,
              labelKey: 'name',
              valueKey: 'id',
              modalType: 'baseinfo-tag',
            },
          ],
          rules: {},
          model: reactive({
            org_id: current_org?.id,
            notice_id: data.id,
            tag_ids: list,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            editModal.loading = true;
            update_notice_tags({
              ...payload,
            })
              .then((res: any) => {
                message.success(t('修改成功'));
                event_obj.emit('refresh');
                resolve(null);
              })
              .finally(() => {
                editModal.loading = false;
              });
          });
        },
      });
      modalBox(editModal);
    },
  },
  // {
  //   name: '上传附件',
  //   type: 'dropdown',
  //   clazz: 'primary',
  //   is_show: (data: any) => {
  //     return data.notice_type == 1;
  //   },
  //   cb: (data: any) => {
  //     const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  //     const loading = ref(false);
  //     const editModal = reactive({
  //       visible: true,
  //       title: '上传附件',
  //       group: '重要信息附件',
  //       type: 'picture',
  //       component: 'upload-image-modal',
  //       loading: loading,
  //       ok: (payload: any) => {
  //         return new Promise(resolve => {
  //           loading.value = true;
  //           if (data.appendix_ids) {
  //             payload.push(...data.appendix_ids.split(','));
  //           }
  //           update_notice({
  //             notice_id: data.id,
  //             appendix_ids: payload,
  //             org_id: current_org?.id,
  //           })
  //             .then(() => {
  //               message.success(t('添加成功'));
  //               event_obj.emit('refresh');
  //               resolve(null);
  //             })
  //             .finally(() => {
  //               loading.value = false;
  //             });
  //         });
  //       },
  //     });
  //     modalBox(editModal);
  //   },
  // },
  {
    name: '备注',
    type: 'dropdown',
    clazz: 'primary',
    cb: (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'NoteModel',
        params: {
          object_id: record.id,
          note_type_flag: 'baseinfo.notice',
        },
      });
      modifyModal.visible = true;
      modifyModal.ok = () => {
        event_obj.emit('refresh');
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list: btnTd[] = [];
